<template>
  <GlobalWindow
    class="handle-table-dialog"
    title="结算单"
    :visible.sync="visible"
    :withFooter="false"
    :width="'800px'"
    :confirm-working="isWorking"
    :loading='pageLoading'
  >
    <el-container class="tab" v-if="baseValue" >
        <el-aside class="left">收款方</el-aside>
        <el-main class="right">
            <div class="top">
                <div class="item">收款单位</div>
                <div class="item">管理员 </div>
            </div>
            <div class="bottom">
                <div class="item">{{type==1?baseValue[0].shopName:'西安美程实业有限公司'}}</div>
                <div class="item">{{type==1?(baseValue[0].adminUserName+'/'+baseValue[0].adminUserAccount):isCreate?(userInfo.username+'/'+userInfo.account):(baseValue[0].settName+'/'+baseValue[0].settAccount)}}</div>
            </div>
        </el-main>
    </el-container>

    <el-container class="tab">
        <el-aside class="left">付款方</el-aside>
        <el-main class="right">
            <div class="top">
                <div class="item">付款单位</div>
                <div class="item">结算人 </div>
            </div>
            <div class="bottom">
                <div class="item">{{type==1?'西安美程实业有限公司':baseValue[0].shopName}}</div>
                <div class="item">{{type==1?isCreate?(userInfo.username+'/'+userInfo.account):(baseValue[0].settName+'/'+baseValue[0].settAccount):(baseValue[0].adminUserName+'/'+baseValue[0].adminUserAccount)}}</div>
            </div>
        </el-main>
    </el-container>

    <el-container class="tab">
        <el-aside class="left">结算概要</el-aside>
        <el-main class="right">
            <div class="top">
                <div class="item">结算单总金额</div>
                <div class="item">拉运/核验总重量 </div>
            </div>
            <div class="bottom">
                <div class="item">{{totalPrice}}</div>
                <div class="item">{{totalWeight}}</div>
            </div>
        </el-main>
    </el-container>

    <el-container class="tab">
        <el-header class="titleTop" >结算明细</el-header>
        <el-main class="right" >
            <el-table
                v-if="type==1"
                ref="chooseNumbers"
                :data="list"
                v-loading="pageLoading"
                stripe
            >
                <el-table-column prop="orderNo" label="拉运单编号" min-width="120px" align="center"></el-table-column>
                <el-table-column prop="driverInfos" label="司机" min-width="140px" align="center">
                    <div slot-scope="{row}">
                        <div v-if="row.driverInfos" v-for="item in row.driverInfos">{{item.driverUserName}}/{{item.driverUserAccount}}</div>
                        <span v-else>-</span>
                    </div>
                </el-table-column>
                <el-table-column prop="settWeight" label="总拉运重量" min-width="100px" align="center"></el-table-column>
                <el-table-column prop="settPrice" label="协议价格" min-width="80px" align="center">
                    <div  slot-scope="{row}" >
                        {{row.settPrice?row.settPrice+'元/千克':'-'}}
                    </div>
                </el-table-column>
                <el-table-column prop="settTotalPrice" label="拉运结算总金额" min-width="110px" align="center">
                    <div  slot-scope="{row}" >
                        <el-input type="number" v-model="row.settTotalPrice" v-if="isCreate">
                            <div slot="append">元</div>
                        </el-input>
                        <span v-else>{{row.settTotalPrice}}元</span>
                    </div>
                </el-table-column>
            </el-table>

            <el-table
                v-if="type==2"
                ref="out"
                v-loading="pageLoading"
                :data="list"
                stripe
            >
                <el-table-column prop="orderNo" label="出库单编号" min-width="100px" align="center"></el-table-column>
                <el-table-column prop="settVerificationWeight" label="核验重量" min-width="100px" align="center"></el-table-column>
                <el-table-column prop="settPrice" label="协议价格" min-width="80px" align="center">
                    <div  slot-scope="{row}" >
                        {{row.settPrice?row.settPrice+'元/千克':'-'}}
                    </div>
                </el-table-column>
                <el-table-column prop="settTotalPrice" label="出库结算金额" min-width="110px" align="center">
                    <div  slot-scope="{row}" >
                        <el-input type="number" v-model="row.settTotalPrice" v-if="isCreate">
                            <div slot="append">元</div>
                        </el-input>
                        <span v-else>{{row.settTotalPrice}}元</span>
                    </div>
                </el-table-column>
            </el-table>
        </el-main>
    </el-container>
    
    <div class="完成结算" v-if="!isCreate">
        <div class="line">
            <div class="label">备注</div>
            <div class="content">
                <el-input v-model="remark" placeholder="请输入备注"></el-input>
            </div>
        </div>
        <div class="line">
            <div class="label">上传附件</div>
            <div class="content">
                <Enclosure  v-model="enclosures" :size="80"></Enclosure>
            </div>
        </div>
        <div class="btns">
            <el-button type="primary" :loading="isWorking" @click="finishStatement">完成结算</el-button>
            <el-button @click='visible = false'>取消</el-button>

        </div>

    </div>
    <div class="btns" v-if="isCreate">
        <el-button type="primary" @click="createStatement" :loading="isWorking">发起结算</el-button>
    </div>

    
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import Enclosure from '../../../components/layun/common/Enclosure.vue'

// import ImageUpload from '../../../common/ImageUpload.vue'
import {createStatement  as createStatementLY,getInfo as getInfoLY,CompleteOrder as CompleteOrderLY} from '../../../api/layun/financialManagement/transportationSettlement'
import {createStatement as createStatementCK,getInfo as getInfoCK,CompleteOrder as CompleteOrderCK} from '../../../api/layun/financialManagement/warehousingSettlement'
import { mapState } from 'vuex'

export default {
  name: 'StatementWindow',
  extends: BaseOpera,
  components: {  GlobalWindow  , Enclosure  },
  data () {
    
    return {
      form:{
      },
      visible:false,
      search:false,
      list:[],
      baseValue:null,
      type:1,// 1 拉运 2 出库
      isCreate:true,
      pageLoading:false,
      enclosures:[],
      remark:'',
    }
  },
  computed:{
    totalPrice(){
        if(this.type==1){
          return  this.list.reduce(function (total, item) {
                return total + Number(item.settTotalPrice);
            }, 0);
        }else{
            return  this.list.reduce(function (total, item) {
                return total +  Number(item.settTotalPrice);
            }, 0);
        }   
    },
    totalWeight(){
        if(this.type==1){
            return this.list.reduce(function (total, item) {
                return total + item.settWeight;
            }, 0);
        }else{
            return this.list.reduce(function (total, item) {
                return total + item.settVerificationWeight;
            }, 0);
        }   
    },
    baseTotalPrice(){ //未曾改变过的价格
        if(this.type==1){
          return  this.baseValue.reduce(function (total, item) {
                return total + Number(item.settTotalPrice);
            }, 0);
        }else{
            return  this.baseValue.reduce(function (total, item) {
                return total +  Number(item.settTotalPrice);
            }, 0);
        }
    },
    ...mapState({
        userInfo:state=>state.init.userInfo
    })
  
  },
  methods: {
    /*
    @type,1 拉运 2 出库
    @isCreate： true:生成结算单 false 完成结算单
    @value: 结算单具体值 / row  当前条的数据
    */
    async open(type,isCreate,value) {
        console.log(type,'_____target',isCreate,value);
        //处理基本数据
        // settTotalPrice

        this.type = type
        
        this.isCreate = isCreate
        this.visible = true
        if(isCreate){
            this.list = value
            this.baseValue = JSON.parse(JSON.stringify(value))
            
        }else{
            //获取结算单详情后回显
            this.pageLoading = true;
            let getInfo = type==1?getInfoLY:getInfoCK
            getInfo({settId:value.id}).then(res=>{
                console.log(res,'-sds-d-s-d-s---');
                this.remark = res.remark
                this.enclosures = res.enclosures || []
                let curValue = res.detailList || []
                curValue.forEach(i=>{
                    i.shopName = res.shopName
                    i.adminUserName =  res.receiveAdminName
                    i.adminUserAccount =  res.receiveAdminAccount
                    i.settWeight = i.settTotalWeight
                    i.settId = value.id
                    i.settName = value.settName
                    i.settAccount = value.settAccount

                })
                this.list = curValue
                this.baseValue = JSON.parse(JSON.stringify(curValue))
            })
            .catch(e => {
                this.$tip.apiFailed(e,false)
            })
            .finally(() => {
                this.pageLoading = false
            })
        }
        
    },
    //生成结算单
    createStatement(){
        let param = {}
        let api = null
        
        param.settTotalPrice = this.totalPrice
        param.settTotalWeight = this.totalWeight
        param.settType = 2
        param.details = []
        if(this.type==1){
            param.receiveAdminId = this.baseValue[0].adminUserId
            param.receiveId = this.baseValue[0].shopId
            this.list.map((item,index)=>{
                param.details.push({
                    isChange:item.settTotalPrice!=this.baseValue[index].settTotalPrice,
                    transportId: item.id,
                    tsettTotalPrice: Number(item.settTotalPrice) || 0
                })
            })
            api = createStatementLY
        
        }else{
            param.payerId = this.baseValue[0].shopId
            this.list.map((item,index)=>{
                param.details.push({
                    isChange:item.settTotalPrice!=this.baseValue[index].settTotalPrice,
                    exId: item.exId,
                    tsettTotalPrice: Number(item.settTotalPrice) || 0
                })
            })
            api = createStatementCK

        
        }
        

        console.log('生成结算单的数据',param);
        
        this.isWorking = true
        api(param).then(() => {
            this.visible = false
            this.$tip.apiSuccess('新增成功')
            this.$emit('success')
        })
        .catch(e => {
            this.$tip.apiFailed(e,false)
        })
        .finally(() => {
            this.isWorking = false
        })
    
    },

    //完成结算
    finishStatement(){
        let CompleteOrder = this.type==1?CompleteOrderLY:CompleteOrderCK
        this.isWorking = true
        CompleteOrder({
            enclosures:this.enclosures,
            remark:this.remark,
            settId:this.list[0].settId
        }).then(() => {
            this.visible = false
            this.$tip.apiSuccess('新增成功')
            this.$emit('success')
        })
        .catch(e => {
            this.$tip.apiFailed(e,false)
        })
        .finally(() => {
            this.isWorking = false
        })
    }
  },
  created () {
    // this.config({
    //   api: '/layun/contractManagement/signedManagement', 
    // })
    
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables";
$icon-background-color: $primary-color;
.btns{
    text-align: center;
    margin: 20px 0;
}
.line{
    display: flex;
    align-items: center;
    margin:20px 0;
    .label{
        min-width: 80px;
        flex-shrink: 0;
        text-align: right;
        margin-right: 20px;
    }
    .content{
        flex: 1;
    }
}
.tab{
    display: table;
    width:100%;
    background: $primary-color;
    vertical-align: middle;
    margin: 30px 0;

    .left{
        width: 100px !important;
        height: 100%;
        color: white;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
    }
    .titleTop{
        color: white;
        text-align: center;
        line-height: 60px;
        width: 100%;
    }
    .right{
        display: table-cell;
        width: 100%;
        
        background: #fff;
        display: table;
        vertical-align: middle;
        padding: 0;
        .item{
            display: table-cell;
            min-width: 200px;
            border-bottom: 1px solid #ccc;
            vertical-align: middle;
            text-align: center;
            padding: 20px 0;
        }

        .top{
            display: table-row;
            font-weight: bold;
        }
        .bottom{
            display: table-row;
        }
    }


}

</style>
