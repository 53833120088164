<template>
  <GlobalWindow
    class="handle-table-dialog"
    title="账号移交"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
    
    
  >
    
    <el-form :model="form" ref="form" :rules="rules" v-loading="dialogLoading">
      <el-form-item label="原账号" >
        {{form.originalUserName+'/'+form.originalAccount}}
      </el-form-item>
      <el-form-item label="移交角色" prop="roleId" required>
        <RoleSelect v-model="form.roleId" :optionId="optionId" selectMode="changeUser" :multiple="true" placeholder="请选择用户角色" :inline="false" :showAlert="false"></RoleSelect>
      </el-form-item>
      <el-form-item label="新账号" prop="account" required>
        <el-input v-model.trim="form.account" placeholder="请输入账号"  @input="changeUser"></el-input>
      </el-form-item>
      <el-form-item label="新账号姓名" prop="userName" required>
        <el-input v-model.trim="form.userName" placeholder="请输入姓名" :disabled='!userNameEditable' ></el-input>
      </el-form-item>
      <el-form-item label="新账号已有角色" v-if="targetRoleArr.length>0">
        <div>
          <el-tag v-for="item in targetRoleArr" style="margin-right:10px;margin-top:10px">{{item.roleName}} <span v-if="item.status==1">(已冻结)</span></el-tag>
        </div>
      </el-form-item>
      <div v-if="form.roleId.findIndex(i=>i==roles.sale)!=-1&&shopList.length>0">
        <p style="text-align:left;color:#ff0000">移交成功后,以下商家归属销售变更为新账号</p>
        <div class="scrollBox">
          <el-tag v-for="item in shopList" style="margin-right:10px;margin-top:10px">{{item.name}}</el-tag>
        </div>
      </div>
      
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import RoleSelect from '../../common/RoleSelect.vue'
import UserSelect from '../../common/UserSelect.vue';//别的userSelect
import {targetUserList,handoverRoles,selectSystemAllUser} from '../../../api/system/user'
// import {,} from '../../../api/system/user'

import {fetchList} from '../../../api/layun/accountManagement/shop'
import {checkMobile} from '../../../utils/form'
import { refreshCache} from '../../../api/layun/systemUser'

import { mapState, Store } from 'vuex'

export default {
  name: 'HandoverRolesOpera',
  extends: BaseOpera,
  components: {  GlobalWindow , RoleSelect , UserSelect  },
  computed:{
    ...mapState({
      userInfo:state=>state.init.userInfo,
      roles:state=>state.init.roles
    })
  },
  data () {
    let checkRoles = (rule, value, callback)=>{
      console.log(value,'---');
      if (value.length==0) {
        callback(new Error('至少选择一个角色'))
        return
      }
      callback()
    }
    return {
      // 表单数据
      form: {
        userId: null,//originalID
        originalAccount:'',
        originalUserName:'',
        roleId:[],//需要被移交的角色
        // newUserId:'',//targetID
        account:null,//targetAccount no ID
        userName:'',
        businessRoleId:1,

      },
      optionId:[],//可移交角色的展示
      userOptions:[],//可选的移交账号
      targetRoleArr:[],//targetRoles
      dialogLoading:false,//页面数据加载中
      // 验证规则
      rules: {
        roleId: [
          { required: true, message: '请选择用户角色' },
          {validator:checkRoles}
        ],
        account: [
          { required: true, message: '请输入移交账号' },
          {validator:checkMobile}
        ],
        userName:[
          { required: true, message: '请输入移交账号姓名' },
        ]
      },
      shopList:[],//改变所属销售的商家列表
      userNameEditable:true
    }
  },
  watch:{
    'form.roleId':{
      handler(val,olv){
        console.log(val,'--roleId变化了');
        this.$refs.form.validateField('roleId')
        // this.targetUserList();
      }
    }
  },
  methods: {
    /**
     * @title: '账户移交
     * @target: row
     * @parent: businessRoleId
     */
    open (title, target, parent) {
      console.log(target,'-----新建');
      this.title = title
      this.visible = true
      this.userNameEditable  = true
      this.selectSystemAllUser();
      // 编辑
      this.$nextTick(() => {
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        this.form.businessRoleId = parent?parent:1;
        let idArr = []
        let nameArr = []
        // if(!target.isTransfer){
          idArr = [target.roleId]
          nameArr = [target.roleName]
        // }
       
        if(target.children&&target.children.length>0){
          
          target.children.map(item=>{
            // if(!item.isTransfer){
              idArr.push(item.roleId)
              nameArr.push(item.roleName)
            // }
          })
        }
        //当前为新增管理员则不可移交新增管理员的角色
        let newManagerId = this.roles.newManager;
        let managerId = this.roles.manager;
        if(this.userInfo.currentRole==newManagerId){
          idArr = idArr.filter(i=>(i!=newManagerId&&i!=managerId))
        }
        
        this.optionId = idArr
        


        this.form.roleId = []
        this.form.userId = target.id
        this.form.businessRoleId = parent?parent:1
        this.form.account = null;
        this.form.originalAccount = target.account
        this.form.originalUserName = target.username


        setTimeout(() => {
          this.$refs.form.clearValidate()
        }, 30);

        if(this.optionId.findIndex(i=>i==this.roles.sale)!=-1){
          //展示 销售是这个人的商家名称
          this.getShopList();
        }
        
      })
    },
    selectUserByAllUser(account){
      return new Promise((suc,err)=>{
        selectSystemAllUser({account}).then(data=>{
          console.log(data,'---')
          if(data && data.length){
            data[0].disabled = data[0].businessRoleId&&data[0].businessRoleId.findIndex(i=>i==1)!=-1;//存在企业内账号即为disable用户
          }
          suc(data && data.length ? data[0] : null)
        })

      })      
    },
    selectSystemAllUser(){ //获取美程账号列表
      return
      this.dialogLoading = true
      selectSystemAllUser({}).then(res=>{
        console.log('12345678',res);
        this.userOptions = res || []
      }).finally(()=>{
        this.dialogLoading = false
      })
    },
    async changeUser(value){
      console.log('value--changeUser',value,this.userOptions);
      
      this.targetRoleArr = []
      this.form.account = value
      this.userNameEditable  = true
      if (!/^1\d{10}$/.test(value)) {
        return 
      }
      var findItem = await this.selectUserByAllUser(value)
      if(findItem){
        this.form.userName = findItem.userName
        this.userNameEditable  = findItem.userName?false:true
      }
      // let idx = this.userOptions.findIndex(i=>i.account==value)
      // if(idx!=-1){
      //   this.form.userName = this.userOptions[idx].userName
      //   this.userNameEditable  = this.userOptions[idx].userName?false:true
      // }
    
    },
    getShopList(){
        this.shopList = []
        fetchList({
            page:1,
            capacity:10000,
            model:{
              listType:1,
              sale:this.form.originalAccount,
            },
            sorts:[]
        }).then(data=>{
            console.log(data,'--asa');
            this.shopList = data.records || []
        }).catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
        })
    
    },

    confirm(){
       this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        }
        if (this.form.account == this.form.originalAccount) {
          return this.$message.error('新账号与移交账号相同')
        }
        console.log('---移交账号啦',{
          userId: this.form.userId,
          account:this.form.account,
          userName:this.form.userName,
          roleId:this.form.roleId,
          businessRoleId:1, 
        });
        // return 
        // 调用新建接口
        this.isWorking = true
        handoverRoles({
          userId: this.form.userId,
          account:this.form.account, //发送的账号
          userName:this.form.userName,
          roleId:this.form.roleId,
          businessRoleId:1,
        })
          .then((res) => {
            console.log(res,'--移交成功的返回');
            if(res.code==500){
              this.$message.error(res.message);
              return 
            }
            this.visible = false
            refreshCache();
            this.$tip.apiSuccess(res.data?res.data:'移交成功')
            this.$emit('success')
          })
          .catch(e => {
            this.$tip.apiFailed(e)
          })
          .finally(() => {
            this.isWorking = false
          })
      })
    
    },

  },
  created () {
    // this.config({
    //   api: '/layun/basicDataManagement/equipmentData'
    // })
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables";
$icon-background-color: $primary-color;
.global-window {
  .tip {
    margin-bottom: 12px;
    em {
      font-style: normal;
      color: $primary-color;
      font-weight: bold;
    }
  }
  // 图标
  /deep/ .form-item-icon {
    .el-form-item__content {
      height: 193px;
      overflow-y: auto;
    }
    .el-radio-group {
      background: $icon-background-color;
      padding: 4px;
      .el-radio {
        margin-right: 0;
        color: #fff;
        padding: 6px;
        &.is-checked {
          background: $icon-background-color - 30;
          border-radius: 10px;
        }
        .el-radio__input.is-checked + .el-radio__label {
          color: #fff;
        }
      }
      .el-radio__input {
        display: none;
      }
      .el-radio__label {
        padding-left: 0;
        // element-ui图标
        i {
          font-size: 30px;
        }
        // 自定义图标
        [class^="eva-icon-"], [class*=" eva-icon-"] {
          width: 30px;
          height: 30px;
          background-size: 25px;
          vertical-align: bottom;
        }
      }
      .el-radio--small {
        height: auto;
        padding: 8px;
        margin-left: 0;
      }
    }
  }
}
.scrollBox{
  max-height: 100px;
  // background: #dec;
  padding:10px;
  overflow-y: auto;
}
</style>
