<template>
<!-- 在用 -->
  <GlobalWindow
    class="position-user-window"
    width="90%"
    title="关联商家"
    :visible.sync="visible"
    :with-footer="false"
  >
    <TableLayout :with-breadcrumb="false">
      <!-- 搜索表单 -->
      <template v-slot:table-wrap v-if="row">
        <h2  style="margin:0px 20px;padding:20px 0;">管理员：{{row.adminUserName}}/{{row.adminUserAccount}}</h2>
        <ul class="toolbar" v-if="userInfo.currentMcRoleStatus==0&&currentRole!=roles.treasurer">
          <li ><el-button icon="el-icon-download" type="primary" @click="exportTable(exportObj)">导出</el-button></li>
        </ul>
        <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
        @sort-change="handleSortChange"
      >
        <el-table-column type="selection" width="55px" align="center"></el-table-column>

        <el-table-column prop="contractId" label="合同ID" min-width="100px" align="center">
          <div slot-scope="{row}">
            {{row.contractId || '-'}}  
          </div>
        </el-table-column>
        <el-table-column prop="contractNo" label="合同编号" min-width="100px" align="center">
          <div slot-scope="{row}">
            <!-- <div v-if="row.contractNo"></div> -->
            <div >
              <span>{{row.contractNo || '-'}}</span>
              <i class="el-icon-edit" style="margin-left:4px;color:#2E68EC;" @click="$refs.operaSignedListChangeWindow.open('合同编号',row,1)"></i>
            </div>    
          </div>
        </el-table-column>

        <el-table-column prop="shopName" label="商家名称" min-width="220px" :show-overflow-tooltip="true" align="center">
            <div slot-scope="{row}">
              <img v-if="row.isCoreUser" src="../../../assets/images/isCore.png" alt="" srcset="" style="width:18px;height:18px;margin-right:2px;">
              <img v-if="row.historyShop" src="../../../assets/images/historyShop.png" alt="" srcset="" style="width:18px;height:16px;margin-right:2px;">
              <img v-if="row.multiShop" src="../../../assets/images/multiShop.png" alt="" srcset=""  style="width:18px;height:18px;margin-right:2px;cursor:pointer;" @click="$refs.linkedShopListWindow.open(row)"> 
              <img v-if="row.isPartTimeBusiness" src="../../../assets/images/isPartTime.png" alt="" srcset=""  style="width:18px;height:18px;margin-right:4px;">  
              {{row.shopName == null ? '-' : row.shopName}}
            </div>
        </el-table-column>
        <el-table-column prop="adminUserName" label="管理员" min-width="100px" align="center">
          <!-- isCoreUser -->
          <div slot-scope="{row}">
              <div v-if="row.isTransfer" style="width:22px;height:22px;border-radius:50%;color:red;border:1px solid red;font-size:12px;display:inline-flex;align-items:center;justify-content:center;padding-top:3px;">移</div> 
              {{row.adminUserName == null ? '-' : row.adminUserName}}
            </div>
        </el-table-column>
        <el-table-column prop="adminUserAccount" label="账号" min-width="100px" align="center">
          <div slot-scope="{row}" >
              <div v-if="row.isTransfer" style="width:22px;height:22px;border-radius:50%;color:red;border:1px solid red;font-size:12px;display:inline-flex;align-items:center;justify-content:center;padding-top:3px;">移</div> 
              {{row.adminUserAccount}}
          </div>
        </el-table-column>
        <el-table-column prop="creditCode" label="统一社会信用代码" min-width="124px" align="center"></el-table-column>

        <el-table-column prop="streetOfficeName" label="所属街道办" min-width="100px" align="center">
          <div slot-scope="{row}">
            <!-- <div v-if="row.streetOfficeName">{{row.streetOfficeName}}</div> -->
            <div >
              <span>{{row.streetOfficeName || '-'}}</span>
              <i class="el-icon-edit" v-if="userInfo.currentMcRoleStatus==0&&currentRole!=roles.treasurer" style="margin-left:4px;color:#2E68EC;" @click="$refs.operaSignedListChangeWindow.open('所属街道办',row,2)"></i>
            </div>    
          </div>
        </el-table-column>
        <el-table-column prop="settPrice" label="协议价格" min-width="100px" align="center">
          <div slot-scope="{row}">
            <!-- <div v-if="row.settPrice">{{row.settPrice}}</div> -->
            <div >
              <span>{{row.settPrice!=null?row.settPrice:'-'}}</span>
              <i class="el-icon-edit" v-if="userInfo.currentMcRoleStatus==0&&currentRole!=roles.treasurer" style="margin-left:4px;color:#2E68EC;" @click="$refs.operaSignedListChangeWindow.open('协议价格',row,3)"></i>
            </div>    
          </div>
        </el-table-column>
        <el-table-column prop="settType" label="结算方式" min-width="100px" align="center">
          <div slot-scope="{row}">
            <!-- <div v-if="row.settType">{{row.settType==1?'现结':'周期结算/'+row.settCycle+'天'}}</div> -->
            <div >
              <span>{{row.settType==1?'现结':row.settType==2?'周期结算/'+row.settCycle+'天':'-'}}</span>
              <i class="el-icon-edit" v-if="userInfo.currentMcRoleStatus==0&&currentRole!=roles.treasurer" style="margin-left:4px;color:#2E68EC;" @click="$refs.operaSignedListChangeWindow.open('结算方式',row,4)"></i>
            </div>    
          </div>
        </el-table-column>
        <el-table-column prop="saleUserName" label="所属销售" min-width="100px" align="center">
          <div slot-scope="{row}">
            <!-- <div v-if="row.saleUserName">{{row.saleUserName}}</div> -->
            <div >
              <span>{{row.saleUserName || '待分配'}}</span>
              <i class="el-icon-edit" style="margin-left:4px;color:#2E68EC;" v-if="userInfo.currentMcRoleStatus==0&&currentRole!=roles.treasurer&&currentRole!=roles.sale" @click="$refs.operaSignedListChangeWindow.open('所属销售',row,5)"></i>
            </div>    
          </div>
        </el-table-column>
        <el-table-column prop="saleAccount" label="销售手机" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="invitationUserName" label="邀约人" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="createTime" label="签约时间" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="status" label="补充协议状态" min-width="100px" align="center">
          <div slot-scope="{row}">
            {{row.status==1?'待签约':row.status==2?'已签约':row.status==3?'已拒绝':row.status==0?'待发起':'-'}}  
          </div>
        </el-table-column>
        <el-table-column prop="address" label="地址" min-width="220px" :show-overflow-tooltip="true" align="center">
          <div slot-scope="{row}">
            {{row.address || ''}}{{row.addressName || ''}}  
          </div>
        </el-table-column>
        <el-table-column prop="areaName" label="业务区域" min-width="150px" :show-overflow-tooltip="true" align="center" >
            <div slot-scope="{row}">
                <span v-if="row.areaName">{{row.areaName}}</span>
                <el-tag v-else type="danger">异常</el-tag>
            </div>
        </el-table-column>

        <el-table-column prop="remark" label="备注" min-width="100px" align="center">
          <div slot-scope="{row}">
            <!-- <div v-if="row.remarks">{{row.remarks}}</div> -->
            <div >
              <span>{{row.remarks || '-'}}</span>
              <i class="el-icon-edit" v-if="userInfo.currentMcRoleStatus==0&&currentRole!=roles.treasurer" style="margin-left:4px;color:#2E68EC;" @click="$refs.remarkUpdateWindow.open(row,10)"></i>
            </div>    
          </div>
        </el-table-column>

        <el-table-column
          v-if="userInfo.currentMcRoleStatus==0&&currentRole!=roles.treasurer"
          label="操作" align="center"
          width="270"
          fixed="right"
        >
          <div  slot-scope="{row}" >
            <el-button  type="text" icon="el-icon-view" @click="queryAgreement(row,1)":loading="isWorking.look1">查看协议</el-button>
            <el-button  type="text" icon="el-icon-edit-outline" @click="$refs.operaEditSignMerchantWindow.open(row)">修改/补充信息</el-button>
            <template v-if="row.isSigning">
              <el-button v-if="row.status==0 || row.status==3"  type="text" icon="el-icon-s-promotion" @click="sendCoreAgreement(row,'add')">发起核心客户补充协议</el-button>
              <el-button v-if="row.status==1"  type="text" icon="el-icon-s-promotion"  @click="sendCoreAgreement(row,'edit')">修改核心客户补充协议</el-button>
              <el-button v-if="row.status==2"   type="text" icon="el-icon-s-promotion" @click="queryAgreement(row,2)" :loading="isWorking.look2">查看补充协议</el-button>
            </template>
            
            <el-button  type="text" icon="el-icon-chat-dot-square" @click="$refs.remarkUpdateWindow.open(row,10)">备注</el-button>
            <el-button  type="text" icon="el-icon-notebook-2" @click="$refs.operaRecordWindow.open(2,row,1)">操作记录</el-button>
          </div>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
      <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      >
      <div v-if="dialogVisible &&dialogData.curShop&&dialogData.shopBlankList">
        当前商家：<el-link type="primary">{{dialogData.curShop.shopName}}</el-link>，管理员 {{dialogData.curShop.adminUserName}} / {{dialogData.curShop.adminUserAccount}}  存在多家关联商家。其中：
        <el-link v-for="item in dialogData.shopBlankList" type="primary" style="margin:0 8px;"> {{item.shopName}}<span> , </span> </el-link>未完成商家信息维护。 需完成编辑后，方可发起核心客户补充协议。
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 认</el-button>
      </span>
    </el-dialog>

    <!-- 关联商家列表 -->
    <LinkedShopListWindow ref="linkedShopListWindow"  @success="handlePageChange(pageIndex)"></LinkedShopListWindow>
    <!-- 发起核心客户补充协议 -->
    <SupplementalAgreementWindow ref="supplementalAgreementWindow" @success="handlePageChange(pageIndex)"></SupplementalAgreementWindow>
    <!-- 列表上可修改字段的修改 -->
    <OperaSignedListChangeWindow ref="operaSignedListChangeWindow" @success="handlePageChange(pageIndex)"></OperaSignedListChangeWindow>
    <!-- 修改/补充信息 -->
    <OperaEditSignMerchantWindow ref="operaEditSignMerchantWindow" @success="handlePageChange(pageIndex)"></OperaEditSignMerchantWindow>
    <!-- 填写/更新备注 -->
    <RemarkUpdateWindow  ref="remarkUpdateWindow"  @success="handlePageChange(tableData.pagination.pageIndex)"></RemarkUpdateWindow>
    <!-- 操作记录 -->
    <OperaRecordWindow ref="operaRecordWindow"></OperaRecordWindow>
      </template>
    </TableLayout>
  </GlobalWindow>
</template>

<script>
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import GlobalWindow from '@/components/common/GlobalWindow'
import Pagination from '@/components/common/Pagination'
// ../../../components/layun/contractManagement/SupplementalAgreementWindow.vue
import SupplementalAgreementWindow from '../contractManagement/SupplementalAgreementWindow.vue'
import LinkedShopListWindow from '../contractManagement/LinkedShopListWindow.vue'
import OperaSignedListChangeWindow from '../contractManagement/OperaSignedListChangeWindow.vue'
import OperaEditSignMerchantWindow from '../contractManagement/OperaEditSignMerchantWindow.vue'
import RemarkUpdateWindow from '../common/RemarkUpdateWindow.vue'
import OperaRecordWindow from '../common/OperaRecordWindow.vue'


import { fetchList , queryAgreement } from '../../../api/layun/contractManagement/signedManagement'
import { mapState } from 'vuex'
export default {
  name: 'LinkedShopListWindow',
  extends: BaseTable,
  components: { GlobalWindow , TableLayout, Pagination  , SupplementalAgreementWindow , LinkedShopListWindow , OperaSignedListChangeWindow , OperaEditSignMerchantWindow , RemarkUpdateWindow , OperaRecordWindow ,  },
  data () {
    return {
      row:null,
      visible: false,
      // 仅查看当前店铺
      onlyCurrentDept: true,
      // 搜索表单
      searchForm: {
        adminUserNameAccount:''
      },
      dialogVisible:false,
      dialogData:{
        curShop:null,
        shopBlankList:[],
      },
      exportObj:{
        tHeader:['合同ID','合同编号','商家名称','管理员','账号','统一社会信用代码','所属街道办','协议价格','结算方式','结算周期','所属销售','销售手机','邀约人','签约时间','补充协议状态','地址','备注'],
        filterVal:[
          {key:'contractId'},
          {key:'contractNo',},
          {key:'shopName',},
          {key:'adminUserName',},
          {key:'adminUserAccount',},
          {key:'creditCode',},

          {key:'streetOfficeName',},
          {key:'settPrice',},
          {key:'settType',type:2,default:'', item:[{value:1,label:'现结'},{value:2,label:'周期结算'}]},
          {key:'settCycle',},

          {key:'saleUserName',},
          {key:'saleAccount',},
          {key:'invitationUserName'},
          {key:'createTime'},
          {key:'status',type:2,default:'状态异常', item:[{value:0,label:'待发起'},{value:1,label:'待签约'},{value:2,label:'已签约'},{value:3,label:'已关闭'}]},
          {key:'address',type:3,item:['address','addressName']},
          {key:'remarks'},
        ],
        fileName:'签约商家列表',
      }
    }
  },
  computed:{
    ...mapState({
      userInfo:state=>state.init.userInfo || {},
      currentRole:state=>state.init.userInfo.currentRole,
      roles:state=>state.init.roles
    }),
  },
  methods: {
    // 打开查看人员窗口
    open (target) {
      this.row = target
      this.searchForm.adminUserNameAccount = target.adminUserAccount
      this.visible = true
      // this.config({
      //   api: '/layun/contractManagement/signedManagement', 
      // })
      this.search()
    },
    sendCoreAgreement(row,type){
        let obj = { //基础新增展示数据
          adminUserName:row.adminUserName,
          adminUserAccount:row.adminUserAccount,
          adminUserId:row.adminId,
          agreementId:row.agreementId,//
          shopList:[
            {
              shopName:row.shopName,
              shopId:row.id,
              saleUserName:row.saleUserName,
              saleUserAccount:row.saleAccount,
              saleUserId:row.saleUserId,
              contractNo:row.contractNo?row.contractNo:row.contractId?row.contractId:''
            }
          ],
          editable:row.agreementId==null?null:{
            id:row.agreementId,
            contractContent:row.contractContent,
            equipmentDeposit:row.equipmentDeposit,
            price1:row.setIsBarrel==1?row.deposit:'',
            price2:row.setIsBarrel==2?row.deposit:'',
            setIsBarrel:row.setIsBarrel,//1 台 2  桶
            startTime:row.startTime,
            endTime:row.endTime,
            longTerm:row.longTerm?true:false,
            deposit:row.deposit,
          }
        }
      if(row.multiShop){
        //调接口
        fetchList({
          page: 1,
          capacity: 200,
          model: {
            adminUserNameAccount:row.adminUserAccount
          },
          sorts: []
        }).then(res=>{
          console.log('__',res);
          if(res.records&&res.records.length>0){
            let isAllCanCoreAgree = true;
            let shopBlankList = []
            
            res.records.map(i=>{
              if(!i.isSigning){
                isAllCanCoreAgree = false;
                shopBlankList.push(i)
              }else{
                if(row.id==i.id){
                  return 
                }
                obj.shopList.push({
                  shopName:i.shopName,
                  shopId:i.id,
                  saleUserName:i.saleUserName,
                  saleUserAccount:i.saleAccount,
                  saleUserId:i.saleUserId,
                  contractNo:i.contractNo?i.contractNo:i.contractId?i.contractId:''
                })
              }
            
            })
            if(!isAllCanCoreAgree){
              //弹提示
              console.log('弹提示');
              this.dialogData.shopBlankList = shopBlankList;
              this.dialogData.curShop = {
                shopId:row.id,
                shopName:row.shopName,
                saleUserName:row.saleUserName,
                saleUserAccount:row.saleAccount,
                saleUserId:row.saleUserId,
                contractNo:row.contractNo?row.contractNo:row.contractId?row.contractId:'',
                adminUserName:row.adminUserName,
                adminUserAccount:row.adminUserAccount,
                adminUserId:row.adminId,
              }
              this.dialogVisible = true
            }else{
              // obj.shopList = shopList
              this.$refs.supplementalAgreementWindow.open((row.agreementId?'编辑':'发起')+'核心客户补充协议',obj)

            }
          }
        }).catch(err=>{
        
        })

      }else{

        this.$refs.supplementalAgreementWindow.open((row.agreementId?'编辑':'发起')+'核心客户补充协议',obj)
      }
      
    },
    queryAgreement(row,type){
      //type: 1签约 2 核心
      this.isWorking['look'+type] = true
      queryAgreement({shopId:row.id}).then(res=>{
        console.log(res,'---');
        let url = type==1?res.seeOilAgreemment:res.seeAgreemment
        if(!url){
          this.$message.error('协议不存在')
        }else{
          // window.open(url)
          this.previewPDF(url);//预览而非打开
        }
      }).catch(e => {
        this.$tip.apiFailed(e)
      })
      .finally(() => {
        this.isWorking['look'+type] = false
      })
    
    
    },
    
  },
  created () {
    this.config({
      api: '/layun/contractManagement/signedManagement', 
    })
  },
}
</script>

<style scoped lang="scss">
.position-user-window {
  /deep/ .table-search-form {
    padding: 0;
  }
  /deep/ .window__body {
    background: #f7f7f7;
    .table-content {
      padding: 0;
      .table-wrap {
        padding: 0;
      }
    }
  }
  .toolbar {
    margin-top: 10px;
    padding: 6px 12px;
    background: #fff;
    font-size: 13px;
    label {
      margin-left: 8px;
      vertical-align: middle;
      color: #999;
    }
  }
  // 列表头像处理
  .table-column-avatar {
    img {
      width: 48px;
    }
  }
  // 列表角色处理
  .table-column-role {
    ul {
      li {
        display: inline-block;
        background: #eee;
        border-radius: 3px;
        padding: 0 3px;
        margin-right: 3px;
      }
    }
  }
}
</style>
