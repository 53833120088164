<template>
  <GlobalWindow
    class="handle-table-dialog"
    :title="title"
    :visible.sync="visible"
    :withFooter="false"
  >
    <!-- <el-input v-model="keyword" aria-placeholder="请输入商家名称、管理员姓名/手机号">
        <template slot="append">
            <span>搜索</span>
        </template>
    </el-input> -->
    <el-select v-model="keyword" clearable filterable remote reserve-keyword placeholder="请输入商家名称、管理员姓名/手机号"
      :remote-method="remoteMethod"
      @change="selectShopChange"
      :loading="loading">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value">
        <span style="float: left">{{ item.label }}</span>
        <span v-if="type==1&&item.adminId" style="float: right; color: #8492a6; font-size: 13px;margin-left:20px;">{{item.adminUserName}}/{{item.adminUserAccount}}</span>
      </el-option>
    </el-select>
    <div class="chooseNumbers">
       <TableLayout  :withBreadcrumb="false">
          <el-table
              v-if="type==1"
              ref="chooseNumbers"
              v-loading="search"
              :data="tableData.list"
              @selection-change="handleSelectionChange"
              stripe
          >
              <el-table-column type="selection" width="55px" align="center"></el-table-column>
              <el-table-column prop="shopName" label="商家名称" min-width="100px" align="center"></el-table-column>
              <el-table-column prop="orderNo" label="拉运单编号" min-width="100px" align="center"></el-table-column>
              <el-table-column prop="settTotalPrice" :label="priceLabel" min-width="100px" align="center"></el-table-column>
              <el-table-column prop="status" label="拉运状态" min-width="80px" align="center">
                  <div  slot-scope="{row}" >
                      {{row.status!=null&&lyStatus.findIndex(i=>i.status==row.status)!=1?lyStatus[lyStatus.findIndex(i=>i.status==row.status)].label:'异常状态'}}
                  </div>
              </el-table-column>
          </el-table>
          <el-table
              v-if="type==2"
              ref="chooseNumbers"
              v-loading="search"
              :data="tableData.list"
              @selection-change="handleSelectionChange"
              stripe
          >
              <el-table-column type="selection" width="55px" align="center"></el-table-column>
              <el-table-column prop="shopName" label="商家名称" min-width="100px" align="center"></el-table-column>
              <el-table-column prop="orderNo" label="拉运单编号" min-width="100px" align="center"></el-table-column>
              <el-table-column prop="settTotalPrice" label="出库结算总金额" min-width="100px" align="center"></el-table-column>
          </el-table>
          <pagination @size-change="handleSizeChange" @current-change="handlePageChange" :pagination="tableData.pagination" :pageSize="[1,2,10,20,30,40,100,]"></pagination>
       </TableLayout>
        
        <div class="btns">
            <el-button  type="primary" @click="createSett">生成结算单</el-button>
        </div>
    </div>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'


import GlobalWindow from '@/components/common/GlobalWindow'
import { fetchList as aimedList } from '../../../api/layun/basicDataManagement/outboundMerchant'
import { fetchList as shopList } from '../../../api/layun/accountManagement/shop' //签署列表




export default {
  name: 'ChooseNumbersWindow',
  extends: BaseTable,
  components: {  GlobalWindow  , TableLayout , Pagination ,   },
  props:{
    type:String
  },
  data () {
    
    return {
      visible:false,
      search:false,
      title:'',
      priceLabel:'',
      tableData:{
      // list:[1,2,3,4,5,6],
      list:[]
      },
      type:null,
      keyword:'',
      loading:false,
      options:[],
      lyStatus:[
        {status:null,label:'全部'},
        {status:0,label:'待委派'},
        {status:2,label:'拉运中'},
        {status:3,label:'待入库'},
        {status:4,label:'待核验'},
        {status:5,label:'确认入库'},
        {status:6,label:'拉运中-待确认'},
        {status:7,label:'拉运中-调增拒绝'},
        {status:8,label:'已取消'},
        {status:9,label:'取消中-待司机确认'},
        {status:10,label:'拉运中-变更待审核'},
      ],
    }
  },
  methods: {
    async open(type,row) {
        this.type = type
        this.title = type==1?'选择拉运单':'选择出库单'
        this.priceLabel = `${this.type==1?'拉运':'出库'}结算总金额`
        this.visible = true
    },

    //远程搜索
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        let api = this.type==1?shopList:aimedList
        let model = this.type==1?{
          listType:1,
          shopAdminName:query,
        }:{
          shopAdminName:query,
        }
        api({
          page: 1,
          capacity: 1000,
          model: model
        }).then(data => {
          console.log(data,'____');
          data.records && data.records.forEach(i=>{
            if(this.type==1){
              i.value = i.id
              i.label = i.name
            }else{
              i.value = i.id
              i.label = i.businessName
            }
            
          })
          this.options = data.records || []
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          this.loading = false
        })
      } else {
        this.options = [];
      }
    },

    //查询 待结算
    selectShopChange(value){
      if(value){
        //查询表格数据
        if(this.type==1){
          this.config({
            api: '/layun/financialManagement/transportationSettlement',
          })
          this.searchForm = {
            listType:2,
            shopId:value,// 待结算拉运单
            // settStatus:0,
            settType:2,//周期结算的
            isBatchSett:true
          
          }
          this.handlePageChange(1)
        }else{
          this.config({
            api: '/layun/financialManagement/warehousingSettlement',
          })
          this.searchForm = {
            listType:2,
            shopId:value,// 待结算拉运单
            settStatus:0,
            isBatchSett:true
          }
          this.handlePageChange(1)
        }
      }else{
        this.tableData.list = []
        this.tableData.selectedRows = []
      }
    },
    //生成结算单
    createSett(){
      if(this.tableData.selectedRows.length==0){
        return this.$message.error(this.type==1?'请至少选择一个拉运单':'请至少选择一个出库单')
      }
      this.visible = false
      this.$emit('success',this.tableData.selectedRows)
    },
    handleSizeChange(pageSize){
      this.tableData.pagination.pageSize = pageSize
      this.handlePageChange(1)
    }
  },
  created () {
    console.log('--选择拉运单');
    this.config({
      api: this.types==1?'/layun/financialManagement/transportationSettlement':'/layun/financialManagement/warehousingSettlement', 
    })
    this.handlePageChange(1)
    
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables";
$icon-background-color: $primary-color;
.btns{
  margin: 10px 0;
  text-align: center;
}

</style>
