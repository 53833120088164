<template>
  <TableLayout :permissions="['basics:warehouse:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="80px" inline>
      <el-form-item label="名称" prop="warehouseName">
        <el-input v-model="searchForm.warehouseName" v-trim placeholder="请输入仓库名称" @keypress.enter.native="search" style="width:250px"/>
      </el-form-item>

      <section>
        <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>

    <template v-slot:table-wrap>
        <ul class="toolbar" v-permissions="['basics:warehouse:create']" v-if="userInfo.currentMcRoleStatus==0&&currentRole!=roles.treasurer">
            <li v-permissions="['basics:warehouse:create']"><el-button icon="el-icon-plus" type="primary" @click="$refs.OperaWarehouseDataWindow.open('新建仓库')">新增</el-button></li>
        </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
        @sort-change="handleSortChange"
      >
        <el-table-column type="selection" width="55px" align="center"></el-table-column>
        <el-table-column prop="warehouseName" label="仓库名称" min-width="120px" align="center">
        </el-table-column>
        <el-table-column prop="warehouseAddressName" label="地址" min-width="100px" align="center"></el-table-column>

        <el-table-column
          v-if="containPermissions(['basics:warehouse:query', 'basics:warehouse:create', 'basics:warehouse:delete'])&&userInfo.currentMcRoleStatus==0&&currentRole!=roles.treasurer"
          label="操作" align="center"
          width="270"
          fixed="right"
        >
          <div  slot-scope="{row}" >
            <el-button v-permissions="['basics:warehouse:create']"  type="text" icon="el-icon-edit" @click="$refs.OperaWarehouseDataWindow.open('修改仓库',row)">修改</el-button>
            <!-- <el-button v-permissions="['basics:warehouse:delete']"  type="text" icon="el-icon-edit" @click="deleteById(row)">删除 要去掉</el-button> -->
            <el-button  type="text" icon="el-icon-notebook-2" @click="$refs.operaRecordWindow.open(1,row,2)">操作记录</el-button>
          </div>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaWarehouseDataWindow ref="OperaWarehouseDataWindow" @success="handlePageChange(tableData.pagination.pageIndex)"/>
    <!-- 操作记录 -->
    <OperaRecordWindow ref="operaRecordWindow"></OperaRecordWindow>
  </TableLayout>
</template>

<script>
import Pagination from '@/components/common/Pagination'
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import OperaWarehouseDataWindow from '../../../components/layun/basicDataManagement/OperaWarehouseDataWindow.vue'
import OperaRecordWindow from '../../../components/layun/common/OperaRecordWindow.vue'
import { mapState } from 'vuex'
// import { fetchList } from '@/api/system/user'

export default {
  name: 'WarehouseData',
  extends: BaseTable,
  components: { OperaWarehouseDataWindow, TableLayout, Pagination,OperaRecordWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        warehouseName:null,
      },
    }
  },
  computed: {
    ...mapState({
      userInfo:state=>state.init.userInfo || {},
      currentRole:state=>state.init.userInfo.currentRole,
      roles:state=>state.init.roles
    }),
  },
  created () {
    this.config({
      module: '账号',
      api: '/layun/basicDataManagement/warehouseData',
      'field.main': 'warehouseName',
      sorts: [{
        property: 'CREATE_TIME',
        direction: 'DESC'
      }]
    })
    this.search()
  },
  methods:{
        handleCommand(command) {
            this.$message('click on item ' + command);
        }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";
// 列表头像处理
.table-column-avatar {
  img {
    width: 48px;
  }
}
</style>
