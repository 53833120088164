<template>
<!-- 在用 -->
  <GlobalWindow
    class="position-user-window"
    width="80%"
    title="邀约人列表"
    :visible.sync="visible"
    :with-footer="false"
    v-loading="loading"
  >
    <TableLayout :with-breadcrumb="false" v-if="row">
      <!-- 搜索表单 -->
      <div slot="search-form">
        <div class="topContent">
          <div class="left">
            <div style="display:flex;margin-bottom:10px;">
              <h3>受邀账号：{{row.beInvitedPhone}}</h3>
              <span style="margin-left:20px;color:#ff5500" v-if="row.isMainteined">{{row.maintainedName}}已编辑信息</span>
            </div>
              <h4>昵称：{{row.beInvitedName || '-'}}</h4>   
          </div>
          <div class="right">
            <el-button v-if="row.isMainteined" type="warning"  @click="$refs.inviterDetail.open(tableData.list[0])">查看信息</el-button>
            <el-button v-if="row.isMainteined" type="primary"  @click="$refs.operaMerchantWindow.open('编辑邀约',tableData.list[0],{historyShop:tableData.list[0].historyShop || false,type:3})">再次编辑</el-button>
            <el-button v-if="!row.isMainteined" type="primary" @click="$refs.operaMerchantWindow.open('编辑邀约',tableData.list[0],{historyShop:tableData.list[0].historyShop || false,type:3})">编辑邀约</el-button>
          </div>

        </div>
      </div>
      <template v-slot:table-wrap>
        <h3 style="padding:20px ;">受邀记录:{{tableData.pagination.total || '-'}}条</h3>
        <ul class="toolbar" v-if="tableData.list.length>0" v-loading="batchLoading">
            <li><el-button type="primary" @click="changeInvitationStatusInBatch(1)">批量开启</el-button></li>
            <li><el-button type="primary" @click="changeInvitationStatusInBatch(2)">批量关闭</el-button></li>
            <li><el-button type="warning" v-if="!tableData.list[0].isAllOn" @click="changeInvitationStatusInBatch(3)">全部开启</el-button></li>
            <li><el-button type="warning" v-if="!tableData.list[0].isCloseAll" @click="changeInvitationStatusInBatch(4)">全部关闭</el-button></li>
        </ul>
        <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        
        stripe 
        @selection-change="handleSelectionChange"
        @sort-change="handleSortChange"
        style="margin:10px 0"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="invitationUserPhone" label="邀约人手机" width="100px" class-name="table-column-avatar" align="center" ></el-table-column>
        <el-table-column prop="invitationNickName" label="邀约人昵称" min-width="100px" align="center">
          <div slot-scope="{row}">{{row.invitationNickName || '-'}}</div>
        </el-table-column>
        <el-table-column prop="invitationUserName" label="邀约人姓名" min-width="100px" align="center">
          <div slot-scope="{row}">{{row.invitationUserName || '-'}}</div>
        </el-table-column>
        <el-table-column prop="beInvitedName" label="受邀人姓名" min-width="100px" align="center">
          <div slot-scope="{row}">{{row.beInvitedUserName || '-'}}</div>
        </el-table-column>
        <el-table-column prop="shopName" label="公司名称" min-width="100px" align="center" :show-overflow-tooltip="true">
          <div slot-scope="{row}">{{row.shopName || '-'}}</div>
        </el-table-column>
        <el-table-column prop="createTime" label="邀约时间" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="invitationStatus" label="状态" min-width="100px" align="center">
          <div slot-scope="{row}">{{row.invitationStatus==false?'已关闭':row.invitationStatus==true?'已开启':'异常'}}</div>
        </el-table-column>
        <el-table-column        
        label="操作"
        width="270"
        fixed="right"
        >
        <div  slot-scope="{row}" >
            <el-button  type="text" icon="el-icon-edit"  v-if="row.invitationStatus==1"  @click="changeInvitationStatusInBatch(6,row)">关闭邀约</el-button>
            <el-button  type="text" icon="el-icon-edit"  v-if="row.invitationStatus==0"  @click="changeInvitationStatusInBatch(5,row)">开启邀约</el-button>
            <el-button  type="text" icon="el-icon-notebook-2" @click="$refs.inviterDetail.open(row)">查看详情</el-button>
        </div>
        </el-table-column>
      </el-table>
        <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
        ></pagination>
      </template>
      <!-- 查看详情 -->
      <InviterDetail ref="inviterDetail"></InviterDetail>
      <!-- 编辑邀约 -->
      <OperaMerchantWindow ref="operaMerchantWindow" @success="handlePageChange(tableData.pagination.pageIndex)"/>

    </TableLayout>
  </GlobalWindow>
</template>

<script>
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import GlobalWindow from '@/components/common/GlobalWindow'
import Pagination from '@/components/common/Pagination'
import InviterDetail from './InviterDetail.vue'
import OperaMerchantWindow from '../accountManagement/OperaMerchantWindow.vue'

import { beInvitedTotalRecordList , changeInvitationStatusInBatch } from '../../../api/layun/accountManagement/shop'
export default {
  name: 'InviterListWindow',
  extends: BaseTable,
  components: { Pagination, GlobalWindow, TableLayout , InviterDetail , OperaMerchantWindow ,   },
  data () {
    return {
      row:null,
      shopId: null,
     
      visible: false,
      loading:false,
      batchLoading:false,//批量关闭 loading
      // 仅查看当前店铺
      onlyCurrentDept: true,
      // 搜索表单
      searchForm: {
        adminId: null,
        id: null,
      },
      tableData:{
        list:[
        {}
        ]
      }
    }
  },
  methods: {
    // 打开查看人员窗口
    open (row) {
      this.row = row
      this.searchForm.adminId = row.beInvitedUserId
      this.searchForm.id = row.id
      this.visible = true
      this.loading = true
      this.search()
    },
    // 处理分页
    handlePageChange (pageIndex) {
      this.tableData.pagination.pageIndex = pageIndex
      this.isWorking.search = true
      beInvitedTotalRecordList({
        page: pageIndex,
        capacity: this.tableData.pagination.pageSize,
        model: this.searchForm
      })
        .then(data => {
          this.tableData.list = data.records
          this.tableData.pagination.total = data.total
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          this.isWorking.search = false
          this.loading = false
        })
    },
    // 开启关闭（单个、批量、全部）
    changeInvitationStatusInBatch(type,row){
      // 1 批量开启,2 批量关闭,3 全部开启,4 全部关闭邀约	 5单个开启 6 单个关闭
      if( this.tableData.selectedRows.length === 0 && ( type == 1 || type == 2 )){
        this.$tip.warning('请至少选择一条数据')
        return
      }
      if(type == 1  || type == 2){
        console.log(this.tableData.selectedRows,'___this.tableData.selectedRows');
      
      }
      let id = null;// batch id 
      let message = `确认吗`
      if( type == 1 || type == 2){
        message = `${type==1?'开启':'关闭'}已选中的 ${this.tableData.selectedRows.length} 条邀约吗?`
        id = this.tableData.selectedRows.map(row => row.id)
      }else if( type == 3 || type == 4){
        message = `${type==3?'开启':'关闭'}全部邀约?`
      }else{
        message = `${type==5?'开启':'关闭'}当前邀约吗?`
        id = [row.id]
      }
      this.$confirm(message)
        .then(() => {
          this.batchLoading = true
          changeInvitationStatusInBatch({
            adminId:this.searchForm.adminId,
            batchType:type==5?1:type==6?2:type,
            invitationId:id
          })
            .then(() => {
              this.$tip.apiSuccess((type==1 || type==3 || type==5)?'开启成功':'关闭成功')
              this.handlePageChange (this.tableData.pagination.pageIndex)
            })
            .catch(e => {
              this.$tip.apiFailed(e)
            })
            .finally(() => {
              this.batchLoading = false
            })
        })
        .catch(() => {})
    
    },


    //批量删除
    deleteByIdInBatch (childConfirm = false) {
      // this.__checkApi()
      if (this.tableData.selectedRows.length === 0 ) {
        this.$tip.warning('请至少选择一条数据')
        return
      }
      let message = `确认删除已选中的 ${this.tableData.selectedRows.length} 条${this.module}记录吗?`
      if (childConfirm) {
        const containChildrenRows = []
        for (const row of this.tableData.selectedRows) {
          if (row.children != null && row.children.length > 0) {
            containChildrenRows.push(row[this.configData['field.main']])
          }
        }
        if (containChildrenRows.length > 0) {
          message = `本次将删除${this.module}【${containChildrenRows.join('、')}】及其子${this.module}记录，确认删除吗？`
        }
      }
      this.$dialog.deleteConfirm(message)
        .then(() => {
          this.isWorking.delete = true
          this.api.deleteByIdInBatch(this.tableData.selectedRows.map(row => row.id).join(','))
            .then(() => {
              this.$tip.apiSuccess('删除成功')
              this.__afterDelete(this.tableData.selectedRows.length)
            })
            .catch(e => {
              this.$tip.apiFailed(e)
            })
            .finally(() => {
              this.isWorking.delete = false
            })
        })
        .catch(() => {})
    },
  }
}
</script>

<style scoped lang="scss">
.position-user-window {
  /deep/ .table-search-form {
    padding: 0;
  }
  /deep/ .window__body {
    background: #f7f7f7;
    .table-content {
      padding: 0;
      .table-wrap {
        padding: 0;
      }
    }
  }
  .toolbar {
    margin-top: 10px;
    padding: 6px 12px;
    background: #fff;
    font-size: 13px;
    label {
      margin-left: 8px;
      vertical-align: middle;
      color: #999;
    }
  }
  
}
.topContent{
  display: flex;
  justify-items: flex-start;
  padding: 20px 0;

  .left{
  
  }
  .right{
    margin-left:20px;
  }
}
</style>
