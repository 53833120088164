<template>
<div>
    <div style="width:100%;display:flex">
        <el-autocomplete
        style="margin-right:20px;flex:1"
        popper-class="autoAddressClass"
        v-model="form.address"
        :fetch-suggestions="getSuggestions"
        :trigger-on-focus="false"
        placeholder="详细地址"
        @select="chooseSuggestion"
        :disabled="disabled"
        clearable>
        <template slot-scope="{ item }">
            <i class="el-icon-search fl mgr10"></i>
            <div style="overflow:hidden;">
            <div class="title">{{ item.title }}</div>
            <span class="address ellipsis">{{ item.address }}</span>
            </div>
        </template>
        </el-autocomplete>
        <!-- <el-button type="primary" @click="onSubmit">提交</el-button> -->
    </div>
    <div id="map-container" style="width:100%;height:300px;margin:30px auto;border:1px solid #eee;text-align:center;overflow: hidden;">{{map?'':'地图加载失败'}}</div>

</div>
  
  
</template>

<script>
import loadBMap from "../../../utils/loadBMap";
import loadTMap from "../../../utils/loadTMap";

import {baseCityData} from '../../../utils/baseCityData'
import { fetchList } from "@/api/system/user";
export default {
  name: "MapSelect",
  components: {},
  props: {
    value: {
      type: String,
      default: ''
    },
    isAdd:{
      default:true
    },
    longitude:{
      type: Number,
      required: false,
      // value:108.93425
    },
    latitude:{
      type: Number,
      required: false,
      // value:34.23053
    },
    disabled:{
      default:false
    },
    visible:{
      type:Boolean
    }
    
  },
  data() {
    return {
      form: {
        address: "", //详细地址
        addrPoint: {
          //详细地址经纬度
          lng: 0,
          lat: 0,
        },
        addressComponents:null,//省市区汉字
      },
      map: "", //地图实例
      mk: "", //Marker实例
      zoomChanged:false,//缩放比例是否改变了
    };
  },
  watch: {
  'form.address':{

    handler(val){
      console.log('this.visible = true',this.form.address);
      if(this.form.address){
        // this.$emit('change',this.form)
      }else{
      console.log('清空后');
        this.$emit('change',{
          clear:true,//是否是清空
        })
      }
    
    }
  },
  // visible:{
  //   handler(val){
  //     console.log('地图组件visible',val,this.map);
    
  //   },
  //   immediate:true
  // }
  
  },
    methods: {

    // 逆地址解析函数
    getAddrByPoint(location) {
        // console.log(location,'__location');
        var geocoder = new TMap.service.Geocoder();
        geocoder.getAddress({ location: location }) // 将给定的坐标位置转换为地址
        .then((result) => {
            console.log(result,'__result');
            if(result.status==0){
                // 显示搜索到的地址
                let res = result.result
                this.form.address = res.address + res.formatted_addresses.recommend
                this.form.addressObj = {
                    address:res.address,
                    addressName:res.formatted_addresses.recommend
                }
                this.form.addrPoint = res.location
                let arr = [res.ad_info.province,res.ad_info.city,res.ad_info.district]
                this.form.addressIds = {
                    provinceId : this.getId(res.ad_info.province,arr),
                    cityId : this.getId(res.ad_info.city,arr),
                    districtId : this.getId(res.ad_info.district,arr),
                    provinceName : res.ad_info.province,
                    cityName : res.ad_info.city,
                    districtName : res.ad_info.district,
                }
                this.$emit('change',this.form)
            
            }else{
                this.$message.error('选点异常')
            }
            
        });
   
    },
    // 地址搜索
    querySearchAsync(str, cb) {
      
      var options = {
        onSearchComplete: function(res) {
        console.log('地址搜索',res.getPoi(0));
          var s = []
          if (local.getStatus() == BMAP_STATUS_SUCCESS) {
            for (var i = 0; i < res.getCurrentNumPois(); i++) {
              s.push(res.getPoi(i))
            }
            cb(s)
          } else {
            cb(s)
          }
        }
      }
      var local = new BMap.LocalSearch(this.map, options)
      local.search(str)
    },

    // 根据 省市区 汉字 返回当前数据库存在的省市区id
    getId(areaName,arrs){
      let idx  = arrs.findIndex(i=>i==areaName)

      console.log('areaName',areaName,idx);
      let arr = baseCityData.list.filter(i=>{
        if(idx==0){

          return i.areaName==areaName
        
        }else{
          return (i.areaName==areaName)&&(i.levelPath.indexOf(arrs[idx-1])!=-1)
        
        }
        // idx==0?(i.areaName==areaName):(i.areaName==areaName&&i.levelPath.indexOf(i=>i==arrs[idx-1])!=-1)
      })
      
      return arr.length==1?arr[0].id:0;
    },
    initTMap(longitude,latitude){
    // 108.93425,34.23053  
        var center = new TMap.LatLng(latitude,longitude);
        //初始化地图
        this.map = null
        this.map = new TMap.Map("map-container", {
            rotation: 20,//设置地图旋转角度
            pitch:30, //设置俯仰角度（0~45）
            zoom:longitude==108.93425&&latitude==34.23053&&this.isAdd?8:16,//设置地图缩放级别
            center: center//设置地图中心点坐标
        });
        //初始化marker图层
        this.markerLayer = new TMap.MultiMarker({
            id: 'marker-layer',
            map: this.map
        });
        this.markerLayer.setGeometries([{
            "id": '0',
            "position": {
                lng:longitude,
                lat:latitude,
                height:0
            },
            "properties": {
                "title": "marker"
            }
        }]);
        // this.getAddrByPoint({
        //     lng:longitude,
        //     lat:latitude,
        //     height:0
        // })

        //监听点击事件添加marker
        
        this.map.on("click", (evt) => {
        console.log(evt);
            if(this.disabled){
              return 
              // this.$message.info('禁止点击')
            }
            this.markerLayer.setGeometries([{
                "id": '0',
                "position": evt.latLng,
                "properties": {
                    "title": "marker"
                }
            }]);
            
            
            this.getAddrByPoint(evt.latLng)
        });
    },

    //查询搜索建议
    getSuggestions(queryString,cb) {
        console.log(queryString,cb);
        // 使用者在搜索框中输入文字时触发
        if (queryString) {
        var suggest = new TMap.service.Suggestion({
            // 新建一个关键字输入提示类
            pageSize: 10, // 返回结果每页条目数
            // region: '北京', // 限制城市范围
            regionFix: false, // 搜索无结果时是否固定在当前城市
        });
            suggest.getSuggestions({ keyword: queryString, location: this.map.getCenter() })
            .then((result) => {
                cb(result.data || [])
            })
            .catch((error) => {
                console.log(error);
            });
        }
    },
    //选择某一个搜索建议
    chooseSuggestion(item) {
        console.log(item);
        this.form.address = item.address + item.title
        this.form.addressObj = {
            address:item.address,
            addressName:item.title
        }
        this.form.addrPoint = item.location
        let arr = [item.province,item.city,item.district]
        this.form.addressIds = {
            provinceId : this.getId(item.province,arr),
            cityId : this.getId(item.city,arr),
            districtId : this.getId(item.district,arr),
            provinceName : item.province,
            cityName : item.city,
            districtName : item.district,
        }
        this.$emit('change',this.form)
       
        this.markerLayer.setGeometries([]);
        this.markerLayer.updateGeometries([
            {
            id: '0', // 点标注数据数组
            position: item.location,
            },
        ]);
        this.map.setCenter(item.location);
    },
  },

  async mounted() {
  // console.log('地图组件visible---mounted',this.map);
    if(this.map){
      this.map.destroy();
    }
    // key: ROKBZ-XMZK3-IHU3N-YIDJ4-AMMPE-EDF2Y
    let res = await loadTMap('BTJBZ-KLJCD-WI44K-PN5JU-26HBJ-33BFH')
    // this.$nextTick(()=>{
      if(this.isAdd){
        this.form = {
          address: "", //详细地址
          addrPoint: {
            //详细地址经纬度
            lng: 0,
            lat: 0,
          },
          addressComponents:null,//省市区汉字
        }
        // 
        this.initTMap(108.93425,34.23053)
        
      }else{
        this.initTMap(this.longitude,this.latitude)
        this.form.addrPoint = {
          lat:this.latitude,
          lng:this.longitude
        }
        if(this.latitude!=0&&this.longitude!=0){
          let location = new TMap.LatLng(this.latitude, this.longitude);
          this.getAddrByPoint(location)
        
        }
        
      }

      if(this.value){
          this.form.address = this.value
      }
    
    
    // })
    
  },
  
};
</script>
<style lang="scss"  scoped  >
.autoAddressClass{
    li {
        i.el-icon-search {margin-top:11px;}
        .mgr10 {margin-right: 10px;}
        .title {
        text-overflow: ellipsis;
        overflow: hidden;
        }
        .address {
        line-height: 1;
        font-size: 12px;
        color: #b4b4b4;
        margin-bottom: 5px;
        }
    }
}
</style>
