<template>
  <!-- <TreeSelect
    :placeholder="placeholder"
    :value="value"
    :data="data"
    :clearable="clearable"
    :append-to-body="appendToBody"
    :inline="inline"
    :multiple="multiple"
    :flat="multiple"
    @input="inputFun"
  /> -->
  <!-- <el-cascader placeholder="请输入省市区" v-model="value" :options="options" :clearable="clearable" :disabled='disabled' :props="{ checkStrictly: checkStrictly, }" filterable @change="cityChange"></el-cascader> -->

  <el-select v-model="val" filterable placeholder="请选择" :disabled="disabled" :clearable="clearable" :inline="inline" :multiple="multiple" :placeholder="placeholder" @change="inputFun" :loading='loading'>
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
  </el-select>
</template>

<script>
// import TreeSelect from './TreeSelect'
import { selectSubName } from '../../../api/layun/basicDataManagement/subDistrictOfficeData'
import {baseCityData} from '../../../utils/baseCityData'
export default {
  name: 'StreetSelect',
  components: {  },
  props: {
    value: {},
    disabled:{
      default:false
    },
    filter:{
      default:()=>  {
        provinceName:''
        cityName:''
        districtName:''
      }
    },
    
    clearable:{
      default:true
    },
    inline: {
      default: true
    },
    multiple: {
      default: false
    },
    placeholder:{
      default:'请选择街道办'
    },
    searchId:{
      default:'',
    }
  },
  data () {
    return {
      options:[],
      val:'',
      loading:false,
    }
  },
  watch: {
    // value:{
    //   handler(val){
    //     this.val = val?String(val):''

    //   },
    //   immediate:true,
      
    // },
    filter:{
      handler(val){
        console.log(val,'---变化的街道办',this.value);
        this.val = this.value?String(this.value):''
        this.selectSubName(val)
      },
      immediate:true,
      deep:true
      
    },
  },
  methods: {
    inputFun(id){
    
      this.$emit('input', id?Number(id):null);
      let data =  this.options.filter(i=>i.id==id)
      this.$emit('row', data[0]);
    },
    selectSubName(value){
      if(value.provinceName=='' && value.cityName=='' &&value.districtName==''){
        return 
      }
      this.loading = true
      selectSubName({
        provinceName:value.provinceName,
        cityName:value.cityName,
        districtName:value.districtName
      }).then(res=>{
      
        console.log(res,'---');
        this.options = []
        this.__fillData(this.options, res || [],{
        idKey:['id'],
        labelKey:['areaName']
        })
      }).finally(()=>{
        this.loading = false
      })
    
    },
    __fillData (list, pool,keys) {
      console.log(list,pool,'----');
      for (const dept of pool) {
        if (dept.id === this.excludeId) {
          continue
        }
        let id = dept.id;
        let label = dept.username;

        if(keys){
          let keyId = []
          let keyLabel = []

          if(keys.idKey&&keys.idKey.length>0){
            keys.idKey.map(item=>{
              keyId.push(dept[item])
            })
          }
          if(keys.labelKey&&keys.labelKey.length>0){
            keys.labelKey.map(item=>{
              keyLabel.push(dept[item])
            })
          }
          id = keyId.join('/')
          label = keyLabel.join('/')
        }
        const deptNode = {
          id: id,
          value:id,
          label: label,
          item:dept
        }
        list.push(deptNode)
      }
    },
    
  },
  created () {
    // this.selectSubName({
    //     provinceName:'陕西省',
    //     cityName:'西安市',
    //     districtName:'碑林区'
    // })
  },
  mounted(){

  }
}
</script>
