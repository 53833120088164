<template>
  <GlobalWindow
    class="handle-table-dialog"
    title="验证付款码"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
    :withFooter="false"
  >
    
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="商家名称" >
        {{form.name}}
      </el-form-item>
      <el-form-item label="管理员姓名" >
        {{form.adminUserName}}
      </el-form-item>
      <el-form-item label="管理员电话" >
        {{form.adminUserAccount}}
      </el-form-item>
      <el-form-item label="商家收款码" >
       <el-image v-if="form.payCode!=null"
            style="width: 150px; height: 150px"
            :src="form.payCode" 
            :preview-src-list="[form.payCode]">
        </el-image>
      </el-form-item>
      <section>
          <el-button type="primary" :loading="isWorking&&curBtn==2" @click="updatePayCodeVerStatus(2)">验证通过</el-button>
          <el-button  :loading="isWorking&&curBtn==3"   @click="updatePayCodeVerStatus(3)">验证失败/通知商家</el-button>
      </section>
    </el-form>
  </GlobalWindow>
</template>

<script>
// payStatus  付款状态 0待上传 1待验证 2已验证 3验证无效
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import { updatePayCodeVerStatus } from '../../../api/layun/accountManagement/shop'
export default {
  name: 'OperaVerifyPayCodeWindow',
  extends: BaseOpera,
  components: {  GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        shopId:null,
        shopType:null,
        name:'',
        adminUserName:'',
        adminUserAccount:'',
        payCode:null
      },
      curBtn:0,//
      required:false,//备注是否必填
      // 验证规则
      rules: {
      }
    }
  },
  methods: {
    /**
     * @title: 窗口标题
     * @target: 编辑的菜单对象
     * @parent: 新建时的上级菜单
     */
    open (title, target, parent) {
     console.log(title, target, parent,'--结局');
      this.title = title
      this.visible = true
      this.$nextTick(() => {
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        this.form.shopId = target.id
      })
    },

    updatePayCodeVerStatus(status){
       this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        }
        // 调用新建接口
        this.isWorking = true
        this.curBtn = status
        updatePayCodeVerStatus({
          id:this.form.shopId,
          shopType:this.form.shopType,
          payStatus:status
        })
          .then((res) => {
            if(res.code==500){
              this.$message.error(res.message);
              return 
            }
            this.visible = false
            this.$tip.apiSuccess(res.data?res.data:'状态提交成功')
            this.$emit('success')
          })
          .catch(e => {
            this.$tip.apiFailed(e)
          })
          .finally(() => {
            this.isWorking = false
          })
      })
    
    },
  },
  created () {
    // this.config({
    //   api: '/layun/basicDataManagement/equipmentData'
    // })
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables";
$icon-background-color: $primary-color;
.global-window {
  .tip {
    margin-bottom: 12px;
    em {
      font-style: normal;
      color: $primary-color;
      font-weight: bold;
    }
  }
  // 图标
  /deep/ .form-item-icon {
    .el-form-item__content {
      height: 193px;
      overflow-y: auto;
    }
    .el-radio-group {
      background: $icon-background-color;
      padding: 4px;
      .el-radio {
        margin-right: 0;
        color: #fff;
        padding: 6px;
        &.is-checked {
          background: $icon-background-color - 30;
          border-radius: 10px;
        }
        .el-radio__input.is-checked + .el-radio__label {
          color: #fff;
        }
      }
      .el-radio__input {
        display: none;
      }
      .el-radio__label {
        padding-left: 0;
        // element-ui图标
        i {
          font-size: 30px;
        }
        // 自定义图标
        [class^="eva-icon-"], [class*=" eva-icon-"] {
          width: 30px;
          height: 30px;
          background-size: 25px;
          vertical-align: bottom;
        }
      }
      .el-radio--small {
        height: auto;
        padding: 8px;
        margin-left: 0;
      }
    }
  }
}
</style>
