<template>
<!-- 在用 -->
  <GlobalWindow
    class="position-user-window"
    :title="nameList[type].name"
    :visible.sync="visible"
    :with-footer="false"
  >
    <el-input v-model="remark" placeholder="请输入备注"></el-input> 
    <el-button type="primary" :loading="isWorking" class="btn" @click="confirm">确认</el-button>
  </GlobalWindow>
</template>

<script>
import GlobalWindow from '@/components/common/GlobalWindow'
import { updateRemarks } from '../../../api/system/user'
import {updateRemarksAndEnclosure} from '../../../api/layun/contractManagement/invitationFundManagement'
import {updateRemark as updateRemarkLY} from '../../../api/layun/financialManagement/transportationSettlement'
import {updateRemark as updateRemarkCK, updateRemarkMX as updateRemarkLYMX} from '../../../api/layun/financialManagement/warehousingSettlement'
import {updateRemark as updateRemarkMSG} from '../../../api/layun/systemMessageList/message'

export default {
  name: 'RemarkUpdateWindow',
  components: { GlobalWindow,  },
  data () {
    return {
        visible:false,
        remark:'',
        type:1,// 1预备商家列表备注 2游客列表备注,
        nameList:[
            {type:0,name:'商家管理员列表的备注',apiKey:updateRemarks},
            {type:1,name:'预备商家列表备注',apiKey:updateRemarks},
            {type:2,name:'游客列表备注',apiKey:updateRemarks},
            {type:3,name:'邀约金明细列表备注',apiKey:updateRemarksAndEnclosure},
            {type:4,name:'返现记录列表',apiKey:updateRemarksAndEnclosure},
            {type:5,name:'拉运结算明细列表的备注',apiKey:updateRemarkLYMX},
            {type:6,name:'拉运结算单列表的备注',apiKey:updateRemarkLY},
            {type:7,name:'出库结算明细列表的备注',apiKey:updateRemarkCK},
            {type:8,name:'出库结算单列表备注',apiKey:updateRemarkCK},
            {type:9,name:'系统消息列表备注',apiKey:updateRemarkMSG},
            {type:10,name:'签约管理/商家列表备注',apiKey:updateRemarks},
            {type:0,name:'占位的备注',apiKey:null},
            {type:0,name:'占位的备注',apiKey:null},
            {type:0,name:'占位的备注',apiKey:null},
            {type:0,name:'占位的备注',apiKey:null},
            {type:0,name:'占位的备注',apiKey:null},
            {type:0,name:'占位的备注',apiKey:null},
            {type:0,name:'占位的备注',apiKey:null},
            {type:0,name:'占位的备注',apiKey:null},


        ],
        row:null
    }
  },
  methods: {
    /*
    @row:当前行数据
    @type:类型与nameList对应
    
    */
    open (row,type) {
      console.log('--sds',row,type);
      this.visible = true
      this.remark = row.remarks?row.remarks:row.remark?row.remark:'';
      this.id = row.id
      this.type = type;
      this.row = row
        
    },
    confirm(){
        this.visible = false;
        // this.$emit('success')
        this.isWorking = true
        let api = this.nameList[this.type].apiKey
        let param = (this.type==0 || this.type==1 || this.type==2  || this.type==5  || this.type==7  || this.type==9)?{
            type:this.type,
            id:this.id,
            remarks:this.remark,
            remark:this.remark
        }:(this.type==10)?{
            type:3,
            id:this.id,
            remarks:this.remark,
            remark:this.remark
        }:(this.type==6 || this.type == 8)?{
            settId:this.row.id,
            remark:this.remark,
            type:this.type
        }:this.type==3?{
            id:this.row.id,
            remarks:this.remark,
            type:1
        }:this.type==4?{
            userId:this.row.userId,
            remarks:this.remark,
            type:2
        }:{}
        console.log(param,'_________备注的传参',api);
        api(param).then((res) => {
            if(res.code==500){
                this.$message.error(res.message);
                return 
            }
            this.visible = false
            this.$tip.apiSuccess(res.data?res.data:'备注修改成功')
            this.$emit('success')
        })
        .catch(e => {
            this.$tip.apiFailed(e)
        })
        .finally(() => {
            this.isWorking = false
        })
    },
  }
}
</script>

<style scoped lang="scss">
.btn{
    margin-top: 10px;
}
.position-user-window {
  /deep/ .table-search-form {
    padding: 0;
  }
  /deep/ .window__body {
    background: #f7f7f7;
    text-align: center;
  }
  // 列表头像处理
  .table-column-avatar {
    img {
      width: 48px;
    }
  }

}
</style>
