<template>
<!-- 在用 -->
  <GlobalWindow
    class="position-user-window"
    width="80%"
    :title="departmentName + '人员列表'"
    :visible.sync="visible"
    :with-footer="false"
  >
    <TableLayout :with-breadcrumb="false">
      <!-- 搜索表单 -->
      <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="80px" inline>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="searchForm.name" placeholder="请输入用户名" v-trim @keypress.enter.native="search"/>
        </el-form-item>
        <!-- <el-form-item label="姓名" prop="realname">
          <el-input v-model="searchForm.realname" placeholder="请输入姓名" v-trim @keypress.enter.native="search"/>
        </el-form-item> -->
        <el-form-item label="账号" prop="account">
          <el-input v-model="searchForm.account" placeholder="请输入账号(手机号)" v-trim @keypress.enter.native="search"/>
        </el-form-item>
        <section>
          <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
          <el-button @click="reset">重置</el-button>
        </section>
      </el-form>
      <!-- <div slot="space" class="toolbar">
        <el-switch v-model="onlyCurrentDept" @change="search" :disabled="isWorking.search"/>
        <label>仅查看当前部门人员</label>
      </div> -->
      <template v-slot:table-wrap>

        <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe border
        @selection-change="handleSelectionChange"
        @sort-change="handleSortChange"
        style="margin:10px 0"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="avatar" label="头像" width="80px" class-name="table-column-avatar" align="center" >
          <div slot-scope="{row}">
            <img :src="row.avatar == null ? '/static/avatar/man.png' : row.avatar">
          </div>
        </el-table-column>
        <el-table-column prop="account" label="账号" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="username" label="姓名" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="role" label="角色" min-width="120px" align="center">
          <div slot-scope="{row}">{{row.roleName == null ? '暂无角色' : row.roleName}}</div>
        </el-table-column>
        <!-- <el-table-column prop="company" label="关联公司" min-width="120px" align="center">
          <div slot-scope="{row}">{{row.company == null ? '无' : row.company.name}}</div>
        </el-table-column> -->
        <!-- <el-table-column prop="department" label="部门" min-width="120px" align="center">
          <div slot-scope="{row}">{{row.department == null ? '无' : row.department.name}}</div>
        </el-table-column> -->
        <el-table-column prop="createTime" label="创建时间" min-width="100px" align="center"></el-table-column>
      </el-table>
        <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
        ></pagination>
      </template>
    </TableLayout>
  </GlobalWindow>
</template>

<script>
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import GlobalWindow from '@/components/common/GlobalWindow'
import Pagination from '@/components/common/Pagination'
import { fetchUserList } from '@/api/system/department'
export default {
  name: 'DepartmentUserWindow',
  extends: BaseTable,
  components: { Pagination, GlobalWindow, TableLayout },
  data () {
    return {
      departmentId: null,
      departmentName: '',
      visible: false,
      // 仅查看当前部门
      onlyCurrentDept: true,
      // 搜索表单
      searchForm: {
        departmentId: null,
        name: '',
        account: ''
      }
    }
  },
  methods: {
    // 打开查看人员窗口
    open (departmentId, departmentName) {
      this.departmentId = departmentId
      this.departmentName = departmentName
      this.searchForm.departmentId = departmentId
      this.searchForm.name = ''
      this.searchForm.account = ''
      this.visible = true
      this.search()
    },
    // 处理分页
    handlePageChange (pageIndex) {
      // 仅查看当前部门处理
      this.searchForm.strictDeptId = null
      this.searchForm.rootDeptId = this.searchForm.departmentId
      if (this.onlyCurrentDept) {
        this.searchForm.strictDeptId = this.searchForm.departmentId
        this.searchForm.rootDeptId = null
      }
      this.tableData.pagination.pageIndex = pageIndex
      this.isWorking.search = true
      fetchUserList({
        page: pageIndex,
        capacity: this.tableData.pagination.pageSize,
        model: this.searchForm
      })
        .then(data => {
          this.tableData.list = data.records
          this.tableData.pagination.total = data.total
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          this.isWorking.search = false
        })
    },
    //重置
    reset(){
      this.searchForm.name = null
      this.searchForm.account = null

      this.search()
    }
  }
}
</script>

<style scoped lang="scss">
.position-user-window {
  /deep/ .table-search-form {
    padding: 0;
  }
  /deep/ .window__body {
    background: #f7f7f7;
    .table-content {
      padding: 0;
      .table-wrap {
        padding: 0;
      }
    }
  }
  .toolbar {
    margin-top: 10px;
    padding: 6px 12px;
    background: #fff;
    font-size: 13px;
    label {
      margin-left: 8px;
      vertical-align: middle;
      color: #999;
    }
  }
  // 列表头像处理
  .table-column-avatar {
    img {
      width: 48px;
    }
  }
  // 列表角色处理
  .table-column-role {
    ul {
      li {
        display: inline-block;
        background: #eee;
        border-radius: 3px;
        padding: 0 3px;
        margin-right: 3px;
      }
    }
  }
}
</style>
