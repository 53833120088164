<template>
  <div class="main-header">
    <div class="header">
      <h2>
        <!-- <i class="el-icon-s-unfold" v-if="menuData.collapse" @click="switchCollapseMenu(null)"></i>
        <i class="el-icon-s-fold" v-else @click="switchCollapseMenu(null)"></i> -->
        {{ title }}
      </h2>
      <div class="tabs">
        <img class="tabItemS" src="../../assets/images/headerImg.png" alt="" srcset="" />
      </div>
      <div class="tabs" style="flex:0;flex-shrink:0;min-width:80px">
        <div>
          <el-popover placement="top-start" width="200" trigger="hover">
            <img  src="../../assets/images/ly.png" alt="" srcset="" style="width:200px" />
            <el-button type="primary" style="width:100%" @click="downLoad">下载图片</el-button>

            <img slot="reference" src="../../assets/images/qrcode.png" alt="" srcset="" style="width:20px"/>
          </el-popover>

          <span>一键拉运</span>
        </div>
      </div>
      <div class="user">
        <el-select v-if="userInfo != null" v-model="roleName" filterable placeholder="请选择" @change="changeUserRole" v-loading.fullscreen.lock="fullscreenLoading"
          :disabled="false" :clearable="false" :inline="true" :multiple="false" class="role" 
        >
          <el-option v-for="item in userInfo.allRoles" :key="item.id" :label="item.mcRoleStatus==0?item.roleName:item.roleName+'('+(item.mcRoleStatus==1?'已冻结':'待补充')+')'" :value="item.roleId" :disabled="item.mcRoleStatus!=2?false:true" ></el-option>
        </el-select>

        <img v-if="userInfo != null" :src=" userInfo.avatar == null ? '@/assets/images/avatar/man.png' : userInfo.avatar " alt="" @click="$router.push('/layun/personalCenter/index')" />
        <el-dropdown trigger="click">
          <span class="el-dropdown-link" >
            {{displayName}}
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="$refs.updatePassword.changePwd()"
              >修改密码</el-dropdown-item
            >
            <!-- @click.native="changePwd" -->
            <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <UpdatePassword ref='updatePassword'></UpdatePassword>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import GlobalWindow from "./GlobalWindow";
import { logout, updatePwd } from "@/api/system/common";
import {changeUserRole} from '../../api/common'
import UpdatePassword from './updatePassword.vue'
export default {
  name: "Header",
  components: { GlobalWindow , UpdatePassword },
  computed: {
    ...mapState({
      menuData: (state) => state.init.menuData,
      // userInfos:state=>{
      //   return state.init.userInfo
      // },
    }),
    title() {
      return this.$route.meta.title;
    },
    userInfo() {
      this.roleName = this.$store.state.init.userInfo.currentRole;
      return this.$store.state.init.userInfo;
    },
    // 展示名称
    displayName() {
      if (this.userInfo == null) {
        return "";
      }
      // if (this.userInfo.realname != null && this.userInfo.realname.trim().length > 0) {
      //   return this.userInfo.realname;
      // }
      return this.userInfo.username || this.userInfo.nickName ;
    },
  },
  data() {
    return {
      roleName: "",
      fullscreenLoading:false,
      visible: {
        // 修改密码
        changePwd: false,
      },
      isWorking: {
        // 修改密码
        changePwd: false,
      },
      username: "bob", // 用户名
      // 修改密码弹框
      changePwdData: {
        form: {
          oldPwd: "",
          newPwd: "",
          confirmPwd: "",
        },
        rules: {
          oldPwd: [{ required: true, message: "请输入原始密码" }],
          newPwd: [{ required: true, message: "请输入新密码" }],
          confirmPwd: [{ required: true, message: "请再次输入新密码" }],
        },
      },
    };
  },

  filters: {
    
  },
  methods: {
    ...mapMutations(["setUserInfo", "switchCollapseMenu"]),
    // 修改密码
    changePwd() {
      this.visible.changePwd = true;
      this.$nextTick(() => {
        this.$refs.changePwdDataForm.resetFields();
      });
    },
    // 确定修改密码
    confirmChangePwd() {
      if (this.isWorking.changePwd) {
        return;
      }
      this.$refs.changePwdDataForm.validate((valid) => {
        if (!valid) {
          return;
        }
        // 验证两次密码输入是否一致
        if (
          this.changePwdData.form.newPwd !== this.changePwdData.form.confirmPwd
        ) {
          this.$tip.warning("两次密码输入不一致");
          return;
        }
        // 执行修改
        this.isWorking.changePwd = true;
        updatePwd({
          oldPwd: this.changePwdData.form.oldPwd,
          newPwd: this.changePwdData.form.newPwd,
        })
          .then(() => {
            this.$tip.apiSuccess("修改成功");
            this.visible.changePwd = false;
          })
          .catch((e) => {
            this.$tip.apiFailed(e);
          })
          .finally(() => {
            this.isWorking.changePwd = false;
          });
      });
    },
    // 退出登录
    logout() {
      logout()
        .then(() => {
          this.$router.push({ name: "login" });
          this.setUserInfo(null);
        })
        .catch((e) => {
          this.$tip.apiFailed(e);
        });
    },
    //change role
    changeUserRole(value){
      console.log(value,'--变化');
      this.fullscreenLoading = true
      changeUserRole({
        currentRole: value,
        businessRoleId: this.$store.state.init.userInfo.businessRoleId,
        currentShop: this.$store.state.init.userInfo.currentShop
      }).then(res=>{
        window.location.href = '/'

      }).catch((e)=>{
        this.$tip.apiFailed(e);
      }).finally(()=>{
        this.fullscreenLoading = false;

      })

    },
    downLoad(){
      fetch('https://zlt-doc.oss-cn-zhangjiakou.aliyuncs.com/ly.png').then(async res => await res.blob()).then((blob) => {
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = URL.createObjectURL(blob);
        a.download = '拉运码';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";
.main-header {
  height: 60px;
}
.header {
  overflow: hidden;
  padding: 0 25px;
  background: #fff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  h2 {
    // width: 50%;
    max-width: 200px;
    flex-shrink: 0;
    line-height: $header-height;
    font-size: 19px;
    font-weight: 600;
    color: #606263;
    display: inline;
    & > i {
      font-size: 20px;
      margin-right: 12px;
    }
  }
  .tabs {
    flex: 1;
    // margin-right: 40px;
    display: flex;
    justify-content: center;
    color: #666666;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 10px;
      text-align: right;
      cursor: pointer;
      i {
        font-size: 22px;
      }
      span {
        font-size: 12px;
      }
      font-family: PingFang SC;
      font-weight: 500;
    }
  }
  .user {
    // width: 50%;
    flex-shrink: 0;
    text-align: right;
    display: flex;
    align-items: center;
    .el-dropdown {
      // margin-left: 20px;
    }
    .el-dropdown-link {
      // top: 0;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    img {
      width: 32px;
      position: relative;
      // top: 10px;
      margin-right: 10px;
      cursor: pointer;
    }
  }
}
// 下拉菜单框
.el-dropdown-menu {
  width: 140px;
  right: 0px;
  .el-dropdown-menu__item:hover {
    background: #e3edfb;
    color: $primary-color;
  }
}
//角色切换的样式调整
.role {
  width: 140px;
  /deep/ .el-input__inner {
    border: none;
  }
}
</style>
