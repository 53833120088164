import store from '../store/index'
// 验证手机号码
export function checkMobile (rule, value, callback) {
  console.log(value,'---');
  if (value == null || String(value).trim() === '') {
    callback()
    return
  }
  if (!/^1\d{10}$/.test(value)) {
    callback(new Error('手机号码格式不正确'))
    return
  }
  callback()
}
// 手机号码加座机
export function phoneCheck (rule, value, callback) {
  if (value == null || String(value).trim() === '') {
    callback()
    return
  }else {
      let regPone = null;
      let mobile = /^1(3|4|5|6|7|8|9)\d{9}$/; //最新16手机正则
      let tel = /^(0[0-9]{2,3}\-)([2-9][0-9]{4,7})+(\-[0-9]{1,4})?$/; //座机
      if (value.charAt(0) == 0) {    // charAt查找第一个字符方法，用来判断输入的是座机还是手机号
        regPone = tel;         
      } else {          
        regPone = mobile;
      }
      if (!regPone.test(value)) {
        return callback(
          new Error("请输入电话(座机格式'区号-座机号码')")
        );
      }
      callback();        
  }
}


//统一社会信用代码
export function validBizLicenseKey (rule, value, callback) {
  if (value == null || String(value).trim() === '') {
    callback()
    return
  }
  // if (!/(^(?:(?![IOZSV])[\dA-Z]){2}\d{6}(?:(?![IOZSV])[\dA-Z]){10}$)|(^\d{15}$)/.test(value)) {
  //   callback(new Error('统一社会信用代码不正确'))
  //   return
  // }
  if(value.length!=15 &&  value.length!=18){
    callback(new Error('统一社会信用代码不正确'))
    return
  }
  callback()
}


// 验证邮箱
export function checkEmail (rule, value, callback) {
  if (value == null || value.trim() === '') {
    callback()
    return
  }
  if (!/^\S+@\S+\.\S+$/.test(value)) {
    callback(new Error('邮箱格式不正确'))
    return
  }
  callback()
}
// 新增系统角色时，多角色变化的冲突
export function checkeRoleId (rule, value, callback) {
  // console.log(Obj,'--检查');
  if (value == null || value.length===0) {
    callback()
    return
  }
  if(typeof value=='number'){
      callback()
      return 
  }
  // 角色id
  // admin  管理员、新增管理员、司机、财务、库管、后勤、销售
  let roles = store.state.init.roles
  
  let isManager = value.findIndex(i=>i==roles.manager)!=-1?true:false;
  let isNewManager = value.findIndex(i=>i==roles.newManager)!=-1?true:false;
  let isDriver = value.findIndex(i=>i==roles.driver)!=-1?true:false;
  let isTreasurer = value.findIndex(i=>i==roles.treasurer)!=-1?true:false; //财务
  let isWarehouseman = value.findIndex(i=>i==roles.warehouseman)!=-1?true:false;
  let isSale = value.findIndex(i=>i==roles.sale)!=-1?true:false;
  let isLogistics = value.findIndex(i=>i==roles.logistics)!=-1?true:false; //后勤
  // 司机和其它角色一律不可同时存在；财务和其它角色一律不可同时存在；销售和财务、司机以及库管不可同时存在。新增、编辑、移交皆按该规律执行。
  if(isDriver&&(isManager || isNewManager ||isTreasurer || isWarehouseman || isSale || isLogistics)){
      callback(new Error('司机和其它角色一律不可同时存在'))
      return
  }
  if(isTreasurer&&(isManager || isNewManager ||isDriver || isWarehouseman || isSale || isLogistics)){
      callback(new Error('财务和其它角色一律不可同时存在'))
      return
  }
  // let arr = [isSale,isTreasurer,isDriver,isWarehouseman]
  // if(arr.filter(i=>i==true).length>=2){
  //     callback(new Error('销售和财务、司机以及库管不可同时存在'))
  //     return
  // }
  callback()
}

