<template lang="">
    <div style="width:820px;margin:80px auto;" v-loading="pageLoading">
        <el-form :model="form" ref="form" :rules="rules" label-width="120px">
            <el-form-item label="头像" prop="avatar" required>
                <ImageUpload v-model='form.avatar'></ImageUpload>
            </el-form-item>
            <el-form-item label="姓名" prop="username" required>
              {{form.username}}
                <!-- <el-input v-model="form.username" placeholder="请输入姓名" v-trim maxlength="50" class="inputLength mr10"/> -->
            </el-form-item>
            <el-form-item label="手机号" prop="account" required>
                {{form.account}} 
                <el-button type="primary" style="margin-left:10px" @click="$refs.updatePassword.changePwd()" >修改密码</el-button>

                <!-- <span></span><el-button type="primary">更换手机号可通过移交</el-button> -->
            </el-form-item>
            <el-form-item label="是否绑定微信">
              <div style="display:flex">
                <span v-if="form.unionid">已绑定</span>  
                <template v-else>
                  <el-button  type="primary" style="height:30px" @click="weChatBinding" >去绑定</el-button><span style="color:#ff5500;margin-left:10px">微信号需绑定登录账户</span>
                </template>
                
              </div>
                
            </el-form-item>
            <el-form-item label="其他联系方式">
                <span>{{form.bakMobile}}</span> <span></span>
                <el-button type="primary" v-if="!form.bakMobile" @click="bakChangeVisible = true">新增备用手机号</el-button>
                <el-button type="primary" v-if="form.bakMobile"  @click="bakChangeVisible = true">修改备用手机号</el-button>
            </el-form-item>
            
            <el-form-item style="margin-top:60px;">
                <el-button type="primary" @click="updateBasics" :loading="isWorking.update">更新个人信息</el-button>

            </el-form-item>
            
        </el-form>
        <el-dialog :title="null" :visible.sync="bakChangeVisible" width="600px" :before-close="handleClose">
          <el-steps :active="active" finish-status="success" align-center>
            <el-step title="校验身份" center></el-step>
            <el-step :title="form.bakMobile?'修改备用手机号':'新增备用手机号'"></el-step>
          </el-steps>
          <div v-if="active==0" class="center">
              <div v-if="showSend">
                <el-button-group>
                  <el-button size="medium" class="mediumPlus" type="primary" plain @click='sendCode(1)' :loading="verCodeLoading&&codeStatus==1">发送验证码到手机号</el-button>
                  <el-button size="medium" class="mediumPlus"  plain @click='sendCode(2)' :disabled="!form.bakMobile" :loading="verCodeLoading&&codeStatus==2">发送验证码到备用手机号</el-button>
                </el-button-group>
              </div>
              <div v-else>{{count+'s后可重试'}}</div>
              

              <el-input class="curInput" v-model="verificationCode" placeholder="请输入验证码"  prefix-icon="eva-icon-password" maxlength="30" />
              <el-button type="primary" size="medium" style="display:block;margin:20px auto 0;" @click="confirmVer" :loading="isWorking.checkVerCode">校验</el-button>
              
          </div>

          <div v-if="active==1" class="center">
              <el-input class="curInput" placeholder="请输入手机号" v-model="account" prefix-icon="el-icon-user-solid" maxlength="50" v-trim>
                <template slot="append">
                  <span  @click='sendCode(3)'>{{showSend?verCodeLoading?'验证码发送中':'发送验证码':count+'s后可重试'}}</span>
                  </template>
              </el-input>
              <el-input class="curInput" v-model="verificationCode" placeholder="请输入验证码"  prefix-icon="eva-icon-password" maxlength="30" />
              <el-button type="primary" size="medium" style="display:block;margin:20px auto 0;" @click="confirm">确认</el-button>  
          </div>
        </el-dialog>
        <!-- 修改密码 -->
        <UpdatePassword ref='updatePassword'></UpdatePassword>





    </div>
</template>
<script>
import ImageUpload from '../../../components/common/ImageUpload.vue'
import UpdatePassword from '../../../components/common/updatePassword.vue'
import {query , updateBasics } from '../../../api/layun/personalCenter'
import {verificationCode,checkVerificationCode,saveBakMobile,updateBakMobile} from '../../../api/system/common'
import { refreshCache } from '../../../api/layun/systemUser'

export default {
  name: "PersonalCenter",
  components: {
    ImageUpload , UpdatePassword
  },
  computed: {
    
  },
  data() {
    return {
    // 表单数据
      form: {
        id: null,
        phone:'',
      },
      isWorking:false,
      pageLoading:false,
      // 验证规则
      rules: {
        account: [
          { required: true, message: '手机号不能为空' },
        //   { validator: checkMobile,trigger: 'blur' }
        ],
        username:[
          { required: true, message: '姓名不能为空' },
        ],
        avatar:[
          { required: true, message: '头像不能为空' },
        ],
      },
      bakChangeVisible:false,
      active:0,


      account:'',
      verCodeLoading:false,
      verificationCode:'',
      verCode:'',
      timeCount: 60,
      showSend: true,
      timer: null,
      count: '', // 初始化次数

      isWorking: {
        update:false,
        // 检验备用手机号
        checkVerCode: false
      },
      // 修改密码弹框
      changePwdData: {
        form: {
          updateBakMobile: '',
          updateVerCode: ''
        },
        rules: {
          oldPwd: [
            { required: true, message: '请输入原始密码' }
          ],
          newPwd: [
            { required: true, message: '请输入新密码' }
          ],
          confirmPwd: [
            { required: true, message: '请再次输入新密码' }
          ]
        }
      },
      codeStatus:0,//点的哪个按钮发的验证码


      
    };
  },
  mounted(){
    this.query();
  },
  methods:{
  
    //个人信息查询
    query(){
      this.pageLoading = true
      query().then(res=>{
        console.log(res,'--');
        this.form = res
      }).finally(()=>{
      this.pageLoading = false
      })
    },
    //个人信息修改
    updateBasics(){
      this.$refs.form.validate((valid) => {
        if(valid){
        this.isWorking.update = true
          updateBasics({
            avatar:this.form.avatar,
            // username:this.form.username
          }).then(() => {
            refreshCache();
            this.$tip.apiSuccess('更新成功')
            this.query();
            this.$store.commit('setUserInfo', {
              ...this.$store.state.init.userInfo,
              avatar: this.data.avatar
            })
          })
          .catch(e => {
            this.$tip.apiFailed(e,false)
          })
          .finally(() => {
            this.isWorking.update = false
          })
        
        
        }
      })
    },
    //微信绑定
    weChatBinding() {
        let appid = 'wx11e01f6cdb2eb692' ;
        let url = encodeURIComponent('https://ly.meictt.com/#/binding');
        window.location.href =`https://open.weixin.qq.com/connect/qrconnect?appid=${appid}&redirect_uri=${url}&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect`
    },

    //备用手机号弹框关闭
    handleClose(){
      //步骤回到1
      this.active = 0;
      // 倒计时清空
      this.bakChangeVisible = false
    },
    next(){
      if (this.active++ > 1) this.active = 0;
      this.showSend = true
      clearInterval(this.timer) // 清除定时器
      this.timer = null
      // this.count = this.timeCount
    },
    //发送验证码倒计时
      sendCode(type) {
      //1 主手机号 2 备用手机号 3 新的备用手机号
      this.codeStatus = type
        if(this.account.length==0&&type==3){
            this.$tip.warning('请先输入手机号')
            return
        }
        if(type==3&&!/^1\d{10}$/.test(this.account)){
          return this.$tip.warning('请输入正确格式的备用手机号')
        }
        if(this.showSend&&this.verCodeLoading){
            return 
        }
        if (!this.timer) {
          this.verCodeLoading = true;

          verificationCode({
            account:this.form.account,
            bakAccount:type==3?this.account:null,
            bakMobile:type==1?false:true,
          }).then((res) => {
            console.log(res, '验证码获取成功')
            if (res=='发送成功') {
              this.$message.success("验证码发送成功")
              this.count = this.timeCount
              this.showSend = false
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= this.timeCount) {
                  this.count--
                } else {
                  this.showSend = true
                  clearInterval(this.timer) // 清除定时器
                  this.timer = null
                  this.count = this.timeCount
                }
              }, 1000)
              
            } else {
              this.$message.error(res)
            }
          }).catch((e)=>{
            this.$tip.apiFailed(e)
            this.showSend = true
            clearInterval(this.timer) // 清除定时器
            this.timer = null
            
          }).finally(()=>{
            this.verCodeLoading = false
          })


        }
      },
      //确认输入了验证码
      confirmVer(){
        if(!this.verificationCode.length>0){
            this.$tip.warning('请先输入验证码')
            return
        }
        this.isWorking.checkVerCode = true
        checkVerificationCode({
          account:this.form.account,
          bakMobile:this.codeStatus==1?false:true,
          verificationCode:this.verificationCode,
        }).then((res) => {
            this.$tip.apiSuccess('校验成功')
            this.verificationCode = '';
            this.next();
          })
          .catch(e => {
            this.$tip.apiFailed(e)
          })
          .finally(() => {
            this.isWorking.checkVerCode = false
          })
      },
      // 确定修改或新增备用手机号
    confirm () {
      if (this.isWorking.update) {
        return
      }

      if(this.account.length==0 || !/^1\d{10}$/.test(this.account)){
        return this.$tip.warning('请输入正确格式的备用手机号')
      }
      if(this.verificationCode.length==0 ){
        return this.$tip.warning('请输入验证码')
      }
      // 执行修改
      this.isWorking.update = true
      let api = this.form.bakMobile?updateBakMobile:saveBakMobile
      api({
          account:this.form.account,
          bakAccount:this.account,
          bakMobile:this.codeStatus==1?false:true,
          verificationCode:this.verificationCode,
      }).then(() => {
        this.$tip.apiSuccess('修改成功')
        this.query();
        this.handleClose()
        // this.bakChangeVisible = false
      })
      .catch(e => {
        this.$tip.apiFailed(e)
      })
      .finally(() => {
        this.isWorking.update = false
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.inputLength{
  width: 200px;
  margin-right:10px
}
.mediumPlus{
  padding: 17px 29px;
}
.mr10{
  margin-right:10px
}
.curInput{
  width:400px;
  margin:10px 0;
}
.center{
  text-align: center;
  padding: 20px;
}
/deep/ .el-input {
  .el-input__inner {
    height: 50px;
    background: #F9F9F9;
    // border: 1px solid transparent;
    
  }
}
.home {
  background: #fff;
  width: 100%;
  height: 100vh;

  
}
.pageC{
    width:700px;
    height:300px;
    margin:90px auto 0;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 10px #ccc;
  
  }
</style>