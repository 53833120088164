import { render, staticRenderFns } from "./invitationFundManagement.vue?vue&type=template&id=a4b9553a&scoped=true"
import script from "./invitationFundManagement.vue?vue&type=script&lang=js"
export * from "./invitationFundManagement.vue?vue&type=script&lang=js"
import style0 from "./invitationFundManagement.vue?vue&type=style&index=0&id=a4b9553a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4b9553a",
  null
  
)

export default component.exports