import request from '@/utils/request';
// 查询
export function fetchList (data) {
    return request.post('/business/invitation/pageSign', data) 
}
// 修改
export function updateById (data) {
    return request.post('/business/invitation/updateSign', data, {
        trim: true
    })
}

//核心协议
// 查询
export function queryAgreement (data) {
    return request.post(`/business/agreemment/selectById/${data.shopId}`, data) 
}



//新增
export function createCoreAgreement (data) {
    return request.post('/business/agreemment/create', data) 
}

// 编辑
export function editCoreAgreement (data) {
    return request.post('/business/agreemment/updateById', data) 
}