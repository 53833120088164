<template>
  <div class="page" >
    <!-- 管理中心 数据中心 项目看板
    <el-button @click="pieCharts">饼图</el-button> -->
    <div class="top">
      <div class="leftTop">
      <div class="leftTopItem" v-for='(item,key) in dataSum' :key="key">
        <div class="title">{{item.type}}</div>
        <div class="number">{{item.number}}</div>
      </div>
      <!-- <div class="item">
          <div class="title">{{item.type}}</div>
          <div class="number">{{item.number}}</div>
        </div>
        <div class="item">
          <div class="title">已完成</div>
          <div class="number">{{item.complete}}</div>
        </div> -->
    </div>

    <div id="pieArea" ></div>
    </div>
    
    <div class="shape" id="shape">
      <div id='block1' class="block"></div>
      <div id='block2' class="block"></div>
      <div id='block3' class="block"></div>
      <div id='block4' class="block">
        <div class="title">负责人</div>
          <vue-seamless-scroll :data="block4" :class-option="optionCustomer">
            <div class="content">
                <div class="item" v-for="items in block4" >
                  <div class="userInfo">
                    <img src="../../assets/images/login.jpg" alt="" class="avator">
                    <div class="name">{{items.username}}</div>
                  </div>
                  <div class="projectParent">
                    <vue-seamless-scroll :data="items.projects" :class-option="optionCustomerInner">
                      <div class="projectInfo" v-for='item in items.projects'>
                        <div class="proItem">
                          <div class="proTitle">{{item.title}}</div>
                          <div class="proStatus">
                            <span v-if='item.status==1'>进行中</span>
                          </div>
                          <div class="proStatusDot" v-if="item.status==1" style="background:red"></div>
                          <div class="proStatusDot" v-if="item.status==2" style="background:yellow"></div>
                        </div>

                      </div>
                    </vue-seamless-scroll>
                  </div>
                </div> 
            </div>
          </vue-seamless-scroll>

      </div>
    </div>
    


  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: '',
  data(){
    return{
      dataSum:[
        {id:1,type:'项目总数',number:120,complete:12},
        {id:2,type:'数字化转型项目',number:20,complete:12},
        {id:3,type:'数字化机建项目',number:20,complete:12},
        {id:4,type:'数字化运营项目',number:80,complete:12},
      ],
      optionCustomer:{
        step: 0.5,
        limitMoveNum: 4,
        openTouch: false,
        waitTime: 1,
        direction: 2,
        // navigation:true
        // singleWidth: 30
      },
      optionCustomerInner:{
        step: 0.5,
        limitMoveNum: 4,
        openTouch: false,
        waitTime: 1,
        // direction: 2,
      },
      block4:[
        {avator:'',username:'1秦琦', projects:[
            {title:'1项目库名称真难起',status:1},
            {title:'2项目库名称真难起',status:1},
            {title:'3项目库名称真难起',status:1},
            {title:'4项目库名称真难起',status:1},
            {title:'5项目库名称真难起',status:1},
            {title:'6项目库名称真难起',status:1},
            {title:'7项目库名称真难起',status:1},
          ]
        },
        {avator:'',username:'2张三', projects:[
            {title:'1项目库名称真难起',status:1},
            {title:'2项目库名称真难起',status:1},
            {title:'3项目库名称真难起',status:1},
            {title:'4项目库名称真难起',status:1},
            {title:'5项目库名称真难起',status:1},
            {title:'6项目库名称真难起',status:1},
            {title:'7项目库名称真难起',status:1},
          ]
        },
        {avator:'',username:'3李四', projects:[
            {title:'1项目库名称真难起',status:1},
            {title:'2项目库名称真难起',status:1},
            {title:'3项目库名称真难起',status:1},
            {title:'4项目库名称真难起',status:1},
            {title:'5项目库名称真难起',status:1},
            {title:'6项目库名称真难起',status:1},
            {title:'7项目库名称真难起',status:1},
          ]
        },
        {avator:'',username:'4王五', projects:[
            {title:'1项目库名称真难起',status:1},
            {title:'2项目库名称真难起',status:1},
            {title:'3项目库名称真难起',status:1},
            {title:'4项目库名称真难起',status:1},
            {title:'5项目库名称真难起',status:1},
            {title:'6项目库名称真难起',status:1},
            {title:'7项目库名称真难起',status:1},
          ]
        },
        {avator:'',username:'5周谷爱', projects:[
            {title:'1项目库名称真难起',status:1},
            {title:'2项目库名称真难起',status:1},
            // {title:'3项目库名称真难起',status:1},
            // {title:'4项目库名称真难起',status:1},
            // {title:'5项目库名称真难起',status:1},
            // {title:'6项目库名称真难起',status:1},
            // {title:'7项目库名称真难起',status:1},
          ]
        },
        {avator:'',username:'6秋秋', projects:[
            {title:'1项目库名称真难起',status:1},
            {title:'2项目库名称真难起',status:1},
            {title:'3项目库名称真难起',status:1},
            {title:'4项目库名称真难起',status:1},
            // {title:'5项目库名称真难起',status:1},
            // {title:'6项目库名称真难起',status:1},
            // {title:'7项目库名称真难起',status:1},
          ]
        },
        {avator:'',username:'7巴贝', projects:[
            {title:'1项目库名称真难起',status:1},
            {title:'2项目库名称真难起',status:1},
            {title:'3项目库名称真难起',status:1},
            // {title:'4项目库名称真难起',status:1},
            // {title:'5项目库名称真难起',status:1},
            // {title:'6项目库名称真难起',status:1},
            // {title:'7项目库名称真难起',status:1},
          ]
        },

      ],
      animate:false,

    }
  },
  mounted(){
    // window.addEventListener('resize', setScale())
    this.pieCharts()
    this.lineChart('block1')
    this.lineChart('block2')
    this.barChart('block3')

    // this.loop(this.block4);
   

  },
  methods:{
    loop(data){
      if(data.length<=4){
        return
      }
      this.animate=true;    // 因为在消息向上滚动的时候需要添加css3过渡动画，所以这里需要设置true
      //  setTimeout(()=>{      //  这里直接使用了es6的箭头函数，省去了处理this指向偏移问题，代码也比之前简化了很多
      //          this.data.push(this.data[0]);  // 将数组的第一个元素添加到数组的
      //          this.data.shift();             //删除数组的第一个元素


      //          this.animate=false;  // margin-top 为0 的时候取消过渡动画，实现无缝滚动
      //  },500)

    },
    pieCharts(){
    this.pieChart('pie1')
    this.pieChart('pie2')
    this.pieChart('pie3')
    this.pieChart('pie4')
    },
    barChart(id){
      var div = document.createElement('div');
      div.id = id;
      div.style.width='770px';
      div.style.height='230px';
      // div.style.background='#def'
      div.className='block'
      document.getElementById('shape').appendChild(div);
      var chartDom = document.getElementById(id);
      var myChart = echarts.init(chartDom);
      var option;
      option = {
          legend: {
            textStyle:{
              fontSize:10,
              color:'#FFFEFE'
            }
          },
          tooltip: {},
          dataset: {
              source: [
                  ['product', '已暂停', '进行中', '未启动','已完成','已取消'],
                  ['一月', 43.3, 85.8, 93.7,34,55],
                  ['二月', 83.1, 73.4, 55.1,78,2],
                  ['三月', 86.4, 65.2, 82.5,23,0],
                  ['四月', 72.4, 53.9, 39.1,99,-10],
                  ['五月', 83.1, 73.4, 55.1,78,2],
                  ['六月', 86.4, 65.2, 82.5,23,0],
              ]
          },
          xAxis: {type: 'category'},
          yAxis: {
            splitLine: {
                show: false
              }
          },
          series: [
            // barMinHeight:2,
              {type: 'bar',barMinHeight:1,itemStyle:{color:'#EE6666'}},
              {type: 'bar',barMinHeight:1,itemStyle:{color:'#5470C6'}},
              {type: 'bar',barMinHeight:1,itemStyle:{color:'#73C0DE'}},
              {type: 'bar',barMinHeight:1,itemStyle:{color:'#FAC858'}},
              {type: 'bar',barMinHeight:1,itemStyle:{color:'#999999'}}
          ]
      };

      option && myChart.setOption(option);
    },
    lineChart(id){
      var div = document.createElement('div');
      div.id = id;
      div.style.width='770px';
      div.style.height='230px';
      // div.style.background='#def'
      div.className='block'
      document.getElementById('shape').appendChild(div);
      var chartDom = document.getElementById(id);
      var myChart = echarts.init(chartDom);
      var option;
      option = {
          xAxis: {
              type: 'category',
              data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
              splitLine: {
                show: false
              }
          },
          yAxis: {
              type: 'value',
              show:true,
              splitLine: {
                  show: false
              }
          },
          series: [{
              data: [820, 932, 901, 934, 1290, 1330, 1320],
              type: 'line',
              smooth: true,
              symbol:'circle',
              symbolSize: 8,
              itemStyle:{
                normal:{
                  color:'#FFEBD5',
                  lineStyle:{
                    color:'#FFEBD5'
                  },
                }
              },
              areaStyle: {
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [{
                        offset: 0, color: '#36393C' // 0% 处的颜色
                    }, {
                        offset: 1, color: '#535760' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                }
              },
              markPoint:{
                itemStyle:{
                  color:'red'
                }
              }
          }]
      };

      option && myChart.setOption(option);

    },
    pieChart(id){
      var div = document.createElement('div');
      div.id = id;
      div.style.width='261px';
      div.style.height='210px';
      // div.style.background='#def'
      div.className='singlePie'
      document.getElementById('pieArea').appendChild(div);
      var chartDom = document.getElementById(id);
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        title: {
            text: '某站点用户访问来源',
            left: 'center',
            top:'10px',
            textStyle:{
              color:'#FFFFFF',
              fontWeight:500,
              fontSize: '16px',
              'font-family':' Source Han Sans CN',
              'font-weight': 500,
            }
        },
          tooltip: {
              trigger: 'item'
          },
          legend: {
              orient: 'horizontal',
              left: 'center',
              bottom:'10px',
              itemWidth:10,
              itemHeight:10,
              itemStyle:{

              },
              textStyle:{
                fontSize:10,
                color:'#FBDFC8'
              }
          },
          
          series: [
              {
                  name: '访问来源',
                  type: 'pie',
                  // radius: '50%',
                  radius:['25%', '50%'],
                  center: ['50%', '45%'],
                  // avoidLabelOverlap: false,
                  itemStyle: {
                      // borderRadius: 5,
                      // borderColor: '#fff',
                      // borderWidth: 1
                  },
                  label: {
                      show: false
                  },
                  data: [
                      {value: 1048, name: '搜索引擎:12'},
                      {value: 735, name: '直接访问'},
                      {value: 580, name: '邮件营销:0'},
                      {value: 484, name: '联盟广告'},
                      {value: 300, name: '视频广告'}
                  ],
                  emphasis: {
                      itemStyle: {
                          shadowBlur: 10,
                          shadowOffsetX: 0,
                          shadowColor: 'rgba(0, 0, 0, 0.5)'
                      }
                  }
              }
          ]
      };

      option && myChart.setOption(option);
    },

  }
}
</script>

<style scoped lang="scss">
.anim{
    transition: all 0.5s;
    margin-left: -30px;
}
.page{
  width:1570px;
  height:730px;
  margin: 10px auto;
  background: linear-gradient(190deg, #404550 0%, #1B1D20 100%);
  color: #fff;
  padding: 5px 5px;
  
}
.top{
  display: flex;
}
#pieArea{
  width: 1046px;
  height: 210px;
  background: linear-gradient(-270deg, #1B1D20 0%, #404550 100%);
  border: 1px solid rgba(255, 244, 232, 0.4);
  box-shadow: 0px 5px 9px 1px rgba(24, 31, 39, 0.5);
  border-radius: 10px; 
  margin-left: 10px;
  display: flex;
  .singlePie{
    flex-shrink: 0;
  }
}
.leftTop{
  display: flex;
  width: 504px;
  height: 210px;
  flex-wrap: wrap;
  justify-content: space-around;


  .leftTopItem{
    width: 242px;
    height: 100px;
    margin-bottom: 5px;
    background: linear-gradient(-270deg, #1B1D20 0%, #404550 100%);
    border: 2px solid rgba(255, 244, 232, 0.4);
    box-shadow: 0px 6px 10px 0px rgba(24, 31, 39, 0.5);
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    &:nth-child(2n+1){
      margin-right: 5px;
    }

    .title{
      font-size: 16px;
      width: 84px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 22px;
    }
    .number{
      font-size: 36px;
      font-family: DIN;
      font-weight: 500;
      color: #FFEBD5;
      line-height: 22px;
    }
  }
}
.shape{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.block{
  width: 770px;
  height: 230px;
  background: linear-gradient(-270deg, #1B1D20 0%, #404550 100%);
  border: 1px solid rgba(255, 244, 232, 0.4);
  box-shadow: 0px 5px 9px 1px rgba(24, 31, 39, 0.5);
  border-radius: 10px;
  margin:10px 0px;
  overflow: hidden;
  .title{
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFEBD5;
    margin: 10px 20px;
  }
  .content{
    // margin: 10px 0px;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;

    .item{
      width: 180px;
      height: 180px;
      // display: inline-block;
      background: linear-gradient(-270deg, #1B1D20 0%, #404550 100%);
      border: 1px solid rgba(255, 244, 232, 0.5);
      box-shadow: 0px 5px 9px 1px rgba(24, 31, 39, 0.5);
      border-radius: 10px;
      margin-right: 9px;
      &:first-child{
        margin-left: 9px;
      }
      .userInfo{
        display: flex;
        align-items: center;
        margin: 14px 0 8px 14px;
        .avator{
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-right: 10px;
        }
        .name{
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 11px;
        }
      }
      .projectParent{
        max-height: 136px;

        overflow: hidden;
      }
      .projectInfo{
        margin: 0px 10px 8px 10px;
        overflow: hidden;
        
        .proItem{
          display: flex;
          align-items: center;
          width: 148px;
          height: 22px;
          padding: 0 5px;
          background: #FFFFFF;
          border: 1px solid rgba(255, 255, 255, 0.4);
          box-shadow: 0px 5px 5px 0px rgba(36, 44, 60, 0.5);
          border-radius: 11px;

          .proTitle{
            font-size: 10px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            line-height: 11px;
            max-width: 108px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 2px;
          }
          .proStatus{
            flex-shrink: 0;
            font-size: 8px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            line-height: 153px;
            margin-right: 2px;
          }
          .proStatusDot{
            flex-shrink: 0;
            width: 9px;
            height: 9px;
            background: #FAC858;
            border-radius: 4px;
          }
        }
      }
    }
  }
}

</style>
