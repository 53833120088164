import request from '@/utils/request'

// 获取图片验证码
export function getCaptcha () {
  return request.get('/common/captcha')
}

//新建
export function create (data) {
  return request.post('/business/project/create',data)
}

// 查询
export function fetchList (data) {
  return request.post('/business/project/projectKanbanList',data)
}

//查询项目详情 展示

export function projectDetailShow (data) {
  return request.get('/business/project/show',{
    params:data
  })
}