<template lang="">
    <div style="display:flex;align-items:center;flex-wrap:wrap">
       <!-- <div v-for="(item,index) in value" class="pictures">
            <el-image class="imag" :style="{width:size+'px',height:'auto',maxHeight:size+'px'}"   :src="item"  :preview-src-list="value"></el-image>
            <i class="el-icon-close closeIcon" v-if="showDel" @click="remove(index)"></i>
        </div> -->
        <el-upload
            style="border:1px solid #ccc;"
            :style="{width:size-2+'px',height:size-2+'px'}" 
            class="upload-demo"
            action=""
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :http-request="customUpload"  
            >
                <el-button type="primary" style="margin-left:70px" :loading="imgLoading">导入历史商家</el-button>
                <!-- <i class="el-icon-plus" :style="{fontSize:size/4+'px',padding:'100%',marginLeft:'-100%'}" ></i> -->
        </el-upload>
    </div>
</template>
<script>
import {importHistoryShop} from '../../../api/layun/accountManagement/shop'

export default {
    name: 'importHistoryShop',
    props:{
        size:{
            type:Number,
            default:30
        },
        limit:{ //附件上传数量限制
            type:Number,
            default:1,
        },
        // value:{
        //     type:Array,
        //     default:()=>{
        //         return []
        //     }
        // },
        // isRealDel:{ //移除是否真实删除（调接口的那种）默认否
        //     type:Boolean,
        //     default:false,
        // },
        // showDel:{ //是否展示删除按钮
        //     type:Boolean,
        //     default:true,
        // },
        // showAdd:{ //是否展示新增上传的按钮
        //     type:Boolean,
        //     default:true,
        // }
    
    },
    data() {
      return {
        fileList: [{name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, {name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}],
        list:[
            'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100',
            'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
        ],
        imgLoading:false
      };
    },
    watch:{
        // value:{
        // handler(){
        //     this.imageUrl = ''
        //     if(this.value){
        //         this.imageUrl = this.value
        //     }
        // },
        // immediate:true
        // }
    },
    methods: {
      handleAvatarSuccess(res, file) {
        // this.imageUrl = URL.createObjectURL(file.raw);
      },
      beforeAvatarUpload(file) {
        console.log(file,'上传的excel');
        // const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 5;
        const fileTypes = [".xlsx"];
        const filePath = file.name;
        //当括号里面的值为0、空字符、false 、null 、undefined的时候就相当于false
        if(filePath){
            var isNext = false;
            var fileEnd = filePath.substring(filePath.lastIndexOf("."));
            for (var i = 0; i < fileTypes.length; i++) {
            console.log(fileTypes[i] , fileEnd);
                if (fileTypes[i] == fileEnd) {
                    isNext = true;
                    break;
                }
            }
            if (!isNext){
                this.$message.error('EXCEL后缀应为.xlsx');
                file.value = "";
                return false;
            }
        }else {
            this.$message.error('上传的excel异常');
        }

        if (!isLt2M) {
          this.$message.error('上传的excel大小不能超过 5MB!');
        }
        return filePath && isLt2M;
      },
      customUpload(option){
      console.log('option',option);

        let self = this
        let formData = new FormData();
        this.imgLoading = true
        formData.append('uploadFile',option.file);
        importHistoryShop(formData).then((res) => {
          console.log(res,'---上传的excel')
          
          if(res){
            let message= `本次导入${res.totalStrip}条，失败${res.failStrip}条，成功${res.successStrip}条`
            if(res.successStrip>=res.failStrip){
              this.$message.success(message)
            }else{
              this.$message.warning(message)
            }
            
            //历史商家
            if(res.agreementStrip){
              this.$confirm(`新增历史商家成功，请前往完善签约信息，之后系统将自动生成该商家的战略合作协议`, '提示', {
                confirmButtonText: '前往',
                cancelButtonText: '暂不处理',
                type: 'warning'
              }).then(() => {
                this.$router.push({
                  path:'/layun/contractManagement/signedManagement',
                  query:{
                    isCoreUser:false,
                    historyShop:true,
                  }
                })
              }).catch(() => {    
                this.$emit('input', res) //没啥用其实
              }); 
            }
            
            }else{
              this.$emit('input', res)//没啥用其实
            }
          
        }).catch(e=>{
          this.$message.error('上传excel失败');
        }).finally(()=>{
          this.imgLoading = false;
          this.fileList = []

        })
      },
      remove(idx){
        if(this.isRealDel){
            return this.$emit('del', idx)
        }
        let path = this.value.filter((i,index)=>index!=idx)
        this.$emit('input', path)
      
      }
    }
    
}
</script>
<style lang="scss" scoped>
    .pictures{
        position:relative;
        display:inline-block;
        margin-right:10px;
        .image{
            width: 30px;
            height: 30px;
        }

        .closeIcon{
            position:absolute;
            top:-2px;
            right:-10px;
            cursor:pointer;
            &:hover{
                color:red
            }
        }
    }
    .upload-demo{
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
    .upload-demo /deep/.el-upload-list{
    display: none;
    }
</style>