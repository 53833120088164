<template>
  <div class="page">
  <!-- <div>
    微信绑定中，正在为您跳转页面
  <div>
    {{errorMessage}}
   
  </div>
   <a-button v-if="errorMessage.length>0" style="margin-top:10px" @click="$router.push('/')" type="primary">返回首页</a-button>
  </div> -->
    
</div>
</template>

<script>
import {bindingWeChat } from '../api/layun/personalCenter'


export default {
  name: 'Loading',
  data () {
    return {
      redirect: undefined,
      errorMessage:''
    }
  },
  created() {
      let code = this.$route.query.code
      if(!code){
        this.$message.error('url参数错误')
        this.errorMessage = 'url参数错误'
        setTimeout(()=>{
          // this.$router.push("/#/login")
          window.location.href = '/#/layun/personalCenter/index'

        },1000)
        // window.location.href = '/#/layun/personalCenter/index'
        return 
      }
      if(code){
        console.log('有code了 接口登录吧');
        bindingWeChat({
          qrCode:code,
        }).then(res=>{
            console.log(res, '微信授权登录')
            this.$message.success("绑定成功")
             
        }).catch(e => {
          this.$tip.apiFailed(e,false)
          this.errorMessage = '微信绑定失败'
        }).finally(()=>{
          setTimeout(()=>{
            // this.$router.push("/#/login")
            window.location.href = '/#/layun/personalCenter/index'

          },1000)
          //  window.location.href = '/#/layun/personalCenter/index'
        })
      }
      
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";
.home {
  text-align: center;
  color: #777;
  .wrap {
    margin-top: 80px;
    img {
      width: 240px;
    }
  }
  h2 {
    font-size: 32px;
    color: #555;
    margin-bottom: 20px;
  }
  p {
    line-height: 24px;
    margin: 0;
  }
  .start-up {
    margin-top: 8px;
  }
  .guide {
    margin: 30px 0 40px 0;
    display: flex;
    justify-content: center;
    a {
      margin-right: 12px;
      padding: 12px 40px;
      border-radius: 30px;
      background: $primary-color;
      color: #fff;
      text-decoration: none;
    }
  }
  em,a {
    font-style: normal;
    font-weight: bold;
    margin: 0 3px;
    color: $primary-color;
  }
  .award {
    position: absolute;
    right: 20px;
    bottom: 60px;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 12px;
    border: 1px solid #eee;
    box-shadow: -1px 1px 10px #ccc;
    h4 {
      font-weight: bold;
      margin-bottom: 8px;
    }
    img {
      width: 160px;
      margin-bottom: 20px;
      transition: opacity ease .3s;
    }
  }
}
</style>
