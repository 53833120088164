<template>
  <TableLayout >
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="消息类型" prop="businessType">
        <el-select v-model="searchForm.businessType" placeholder="请选择消息类型" >
            <el-option  key="0" label="全部" :value="null"  ></el-option>
            <el-option  key="1" label="常规消息" :value="1"  ></el-option>
            <el-option  key="2" label="服务通知" :value="2"  ></el-option>
            <el-option  key="3" label="告警" :value="3"  ></el-option>
            <!-- <el-option  key="4" label="短信" :value="4"  ></el-option> -->
        </el-select>
      </el-form-item>
      <el-form-item label="发送对象" prop="businessRoleId">
        <el-select v-model="searchForm.businessRoleId" placeholder="请选择发送对象/角色" >
            <el-option  key="0" label="全部" :value="null"  ></el-option>
            <el-option  key="1" label="美程" :value="1"  ></el-option>
            <el-option  key="2" label="商家" :value="2"  ></el-option>
            <el-option  key="3" label="预备商家" :value="3"  ></el-option>
            <el-option  key="4" label="游客" :value="4"  ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="美程角色" prop="shopRole" v-if="searchForm.businessRoleId==1">
        <el-select v-model="searchForm.shopRole" placeholder="请选择发送角色" >
            <el-option  key="null" label="全部" :value="null"></el-option>
            <el-option  :key="index" :label="item.name" :value="item.id"  v-for="(item,index) in roles.roleArr"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="标题/内容" prop="searchWord">
        <el-input v-model="searchForm.searchWord" v-trim placeholder="请输入标题/内容" @keypress.enter.native="search" style="width:250px"/>
      </el-form-item>
      <el-form-item label="接收人" prop="receiveWord">
        <el-input v-model="searchForm.receiveWord" v-trim placeholder="请输入姓名/手机号" @keypress.enter.native="search" style="width:250px"/>

      </el-form-item>
      <el-form-item label="未入库告警" prop="weiruk" v-if="searchForm.businessType == 3">
     <el-switch v-model="searchForm.weiruk"></el-switch>

      </el-form-item>
      <el-form-item label="发送时间" >
        <el-date-picker v-model="time" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="dateChange"
            :picker-options="pickerOptions" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
        </el-date-picker>
      </el-form-item>

      <section>
        <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>

    <template v-slot:table-wrap>
        <ul class="toolbar" >
            <li >告警条数总计：{{total}}条</li>
            <li ><el-button icon="el-icon-download" type="primary" @click="exportTable(exportObj)">导出</el-button></li>
        </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
        @sort-change="handleSortChange"
      >
        <el-table-column type="selection" width="55px"></el-table-column>
        <el-table-column prop="id" label="序号" min-width="50px" align="center">
          
        </el-table-column>
        <el-table-column prop="businessType" label="消息类型" min-width="100px"  align="center">
          <div slot-scope="{row}">
            <i class="el-icon-warning-outline" v-if="row.businessType==3" style="color:red;margin-right:4px"></i>
            <span>{{(row.businessType==1 || row.businessType==4) ?'常规消息':row.businessType==2?'服务通知':row.businessType==3?'告警':'无类型'}}</span>
          </div>
        </el-table-column>
        <el-table-column prop="title" label="标题" min-width="100px" align="center" >
<!-- <div >{{row.receiveModels?row.receiveModels.username+'/'+row.receiveModels.account:'-----'}}</div> -->
          <el-popover slot-scope="{row}" placement="top" width="300" trigger="hover"
            :content="row.content">
            <el-button slot="reference" type="text">{{row.title}}</el-button>
          </el-popover>

        </el-table-column>
        <el-table-column prop="roles" label="发送对象/角色" min-width="100px" align="center" >
          <div slot-scope="{row}">{{row.roles.join('、')}}</div>
        </el-table-column>
        <el-table-column prop="users" label="接收账号" min-width="160px" align="center" >
          <div slot-scope="{row}">
              
              <div >
                <el-popover placement="top" width="400" trigger="click" v-if="row.users.length>2">
                  <el-table :data="row.users" :height="200" :max-height="300">
                    <el-table-column type="index" label="序号" align="center"></el-table-column>
                    <el-table-column prop="name" label="姓名" align="center"></el-table-column>
                    <el-table-column prop="account" label="账号" align="center"></el-table-column>
                    <el-table-column prop="role" label="角色" align="center"></el-table-column>
                  </el-table>
                  <div slot="reference" style="cursor:pointer">
                    <span  v-for="(item,key) in row.usersTwo" >
                      {{item.name}}/{{item.account}}-{{key}},<br v-if="key!=1" />
                    </span>
                    <span >...</span>
                  </div>
                </el-popover>
                <div v-else v-for="item in row.users">
                  {{item.name}}/{{item.account}}
                </div>
              </div>

          </div>
        </el-table-column>
        <el-table-column prop="createTime" label="发送时间" min-width="100px" align="center" ></el-table-column>
        <el-table-column prop="remark" label="备注" min-width="100px" align="center" >
          <div slot-scope="{row}">
              <span>{{row.remark?row.remark:'/'}}</span>
              <i class="el-icon-edit" style="margin-left:4px;color:#2E68EC;" @click="$refs.remarkUpdateWindow.open(row,9)" v-if="(currentRole==roles.manager || currentRole==roles.newManager || currentRole==roles.logistics || currentRole==-1)"></i>
          </div>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>
    <!-- 备注 -->
    <RemarkUpdateWindow ref="remarkUpdateWindow" @success="handlePageChange(tableData.pagination.pageIndex)"></RemarkUpdateWindow>
  </TableLayout>
</template>

<script>
import Pagination from '@/components/common/Pagination'
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import RemarkUpdateWindow from '../../../components/layun/common/RemarkUpdateWindow.vue'
import { mapState } from 'vuex'
// import { fetchList } from '@/api/system/user'

export default {
  name: 'MessageList',
  extends: BaseTable,
  components: { RemarkUpdateWindow ,  TableLayout, Pagination },
  data () {
    return {
      // 搜索
      time:'',
      searchForm: {
        businessRoleId: null,
        businessType: null,
        mids: [],
        receiveWord: "",
        searchWord: "",
        sendTimeEnd: "",
        sendTimeStart: "",
        shopRole: null,
        weiruk:false
      },
      total:0,
      
    }
  },
  computed:{
  ...mapState({
    userInfo:state=>state.init.userInfo,
    roleArr:state=>state.init.roles.roleArr,
    currentRole :state=>state.init.userInfo.currentRole,
    roles:state=>state.init.roles
  })
  
  },
  watch:{
    'searchForm.businessRoleId':{
      handler(val,old){
        if(val!=1&&this.searchForm.shopRole!=null){
          this.searchForm.shopRole = null
        }
      }
    }
  },
  created () {
    this.config({
      module: '账号',
      api: '/layun/systemMessageList/message',
      'field.main': 'account',
    })
    this.handlePageChange(1,true)
  },
  methods:{
      handleCommand(command) {
          this.$message('click on item ' + command);
      },
      dateChange(w){
          console.log(w);
          this.form.sendTimeStart = w[0] || '';
          this.form.sendTimeEnd = w[1] || '';
      },
      handlePageChange (pageIndex,isFirst) {
        this.__checkApi()
        this.tableData.pagination.pageIndex = pageIndex || this.tableData.pagination.pageIndex
        this.isWorking.search = true
        this.api.fetchList({
          page: this.tableData.pagination.pageIndex,
          capacity: this.tableData.pagination.pageSize,
          model: {...this.searchForm,types:this.searchForm.weiruk && this.searchForm.businessType ==3 ? [47] : null},
          sorts: this.tableData.sorts
        })
        .then(data => {
          console.log(data,'---------页码变更');
          this.isWorking.search = false
          if(data){
            if(data.records&&data.records.length>0){
              data.records.forEach(i=>{
                  let roles = []
                  let users = []
                  let userStr = []
                  i.receiveModels.forEach(m=>{
                    
                    // businessRoleId	角色ID，1美程 2商家 3预备商家 4游客
                    let role = ''
                    if(m.businessRoleId==1){
                      let idx = this.roleArr.findIndex(i=>i.id==m.shopRole)
                      if(idx!=-1) role = this.roleArr[idx].name
                    }else if(m.businessRoleId==2){
                      role = m.shopRole==1?'商家管理员':'商家普通员工'
                    }else if(m.businessRoleId==3){
                      role = '预备商家'
                    }else{
                      role = '游客'
                    }
                    if(roles.findIndex(i=>i==role)==-1){
                      roles.push(role)
                    }
                    if(users.findIndex(i=>i.account==m.account)==-1){ //同号码过滤
                      users.push({
                        name:m.username,
                        account:m.account,
                        role:role
                      })
                      userStr.push(m.username+'/'+m.account) 
                    }
                    
                  })

                  i.roles = roles
                  i.users = users
                  i.usersTwo = users.filter((i,idx)=>idx<2)
                  i.userStr = userStr.join(',')

              
              
              
              })
            
            
            }
            this.tableData.list = data.records?data.records:[]
            this.tableData.pagination.total = data.total?data.total:0
            if(isFirst){
              this.total = data.total || 0
            }
          }
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          this.isWorking.search = false
          this.$set(this.isWorking,'search',false)
          // this.adjustWidth()
        })
      },
      reset () {
        this.$refs.searchForm.resetFields()
        this.handlePageChange(1,true)
      },
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";
// 列表头像处理
.table-column-avatar {
  img {
    width: 48px;
  }
}
</style>
