import request from '@/utils/request';
// 查询
export function fetchList (data) {
    return request.post('/business/invitation/pageInvitation', data) 
}
  
// 新建
export function create (data) {
    return request.post('/business/shop/create', data, {
        trim: true
    })
}

// 修改
export function updateById (data) {
    return request.post('/business/shop/updateById', data, {
        trim: true
    })
}