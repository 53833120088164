import request from '@/utils/request'

//新建
export function create (data) {
  return request.post('/business/milepost/create',data)
}

// 查询
export function fetchList (data) {
  return request.post('/business/milepost/page',data)
}

//详情  展示
export function queryMilepostShowById (data) {
    console.log('里程碑详情',data);
  return request.get('/business/milepost/show',{
    params:data
  })
}

//详情  编辑
export function queryMilepostEditById (data) {
  console.log('里程碑详情',data);
return request.get('/business/milepost/query',{
  params:data
})
}

//添加跟进
export function creatMilepostRecord (data) {
    return request.post('/business/milepost/addRecord',data)
}

//完成里程碑
export function completedMilepostById (data) {
  return request.get('/business/milepost/completed',{
    params:data
  })
}

// 删除里程碑
export function deleteMilepostById (data) {
  // deleteById
  return request.get('/business/milepost/deleted',{
    params:data
  })
}
// 
// 附件删除
export function deleteEnclosure (data) {
  return request.get('/business/milepost/deletedFile',{
    params:data
  })
  }

//更新里程碑
export function updateById (data) {
  return request.post('/business/milepost/update',data)
}


//删除回复
export function deleteRecord (data) {
  return request.get('/business/milepost/deleteRecord',{
    params:data
  })
}



// // 修改
// export function updateById (data) {
//     return request.post('/system/department/updateById', data)
//   }
  
//   // 删除
//   export function deleteById (id) {
//     return request.get(`/system/department/delete/${id}`)
//   }
  
//   // 批量删除
//   export function deleteByIdInBatch (ids) {
//     return request.get('/system/department/delete/batch', {
//       params: {
//         ids
//       }
//     })
//   }