<template>
  <div>
    <!-- <TreeSelect
      :placeholder="placeholder"
      :value="value"
      :data="data"
      :clearable="clearable"
      :append-to-body="appendToBody"
      :inline="inline"
      :multiple="multiple"
      :flat="multiple"
      
      @input="inputChange"
    /> -->
    <el-select :value="value" @change="inputChange" @visible-change="visibleChange"  :filterable="filterable" :allow-create="allowCreate" :disabled="disabled" :clearable="clearable" :inline="inline" :multiple="multiple" :placeholder="placeholder" style="width:100%">
    <el-option
      v-for="item in data"
      :key="item.id"
      :label="item.label"
      :value="Number(item.id)">
      <span style="float: left">{{ item.label }}</span>
      <span v-if="item.disabled" style="float: right; color: #8492a6; font-size: 13px">企业内账号</span>
    </el-option>
  </el-select>
  </div>
  
</template>

<script>
import TreeSelect from './TreeSelect'
import { fetchAll } from '@/api/system/user'
export default {
  name: 'MeichengUserSelect',
  components: { TreeSelect },
  props: {
    value: {},
    inline: {
      default: true
    },
    //optionId 只展示这几个id 可供选择
    optionId:{
      default:()=>[]
    },
    optionKey:{
      default:()=>null
    },
    selectMode:{ //人员选择框的适用场景
      default: 'changeUser',//changeUser 移交人员  search 搜索
    },
    multiple: {//是否可多选
      default: false
    },
    placeholder: {
      default: '请选择人员'
    },
    // showAlert:{
    //   //是否展示告警信息
    //   default: false
    // },
    // 是否可清空
    clearable: {
      default: false
    },
    appendToBody: {
      default: false
    },
    // 需被排除的部门ID
    excludeId: {},
    // :allow-create="true" :filterable="true"
    allowCreate:{ //是否允许创建新的条目
      default: false
    },
    filterable:{ //是否可搜索
      default: false
    },
    disabled:{
      default:false
    },

  },
  data () {
    return {
      data: []
    }
  },
  watch: {
    excludeId () {
      this.fetchData()
    },
    optionId () {
      console.log('op',this.optionId);
      this.fetchData()
    }
  },
  methods: {
    // 获取所有人员
    fetchData () {
      if(this.selectMode=='changeUser'){ //传入了默认的
        this.data = []
        // this.__fillData(this.data, this.optionId,{
        // label:['userName','account'],
        // id:['id'],
        // })
        this.__fillData(this.data, this.optionId,this.optionKey)
        return 
      
      }
      fetchAll()
        .then(records => {

          console.log(records,'用户角色');
          this.data = []
          this.__fillData(this.data, records)
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
    },
    // 填充部门树
    __fillData (list, pool,keys) {
      console.log(list,pool,'----');
      for (const dept of pool) {
        if (dept.id === this.excludeId) {
          continue
        }
        let id = dept.id;
        let label = dept.username || dept.userName;

        if(keys){
          let keyId = []
          let keyLabel = []

          if(keys.id&&keys.id.length>0){
            keys.id.map(item=>{
              keyId.push(dept[item])
            })
          }
          if(keys.label&&keys.label.length>0){
            keys.label.map(item=>{
              keyLabel.push(dept[item])
            })
            
          }
          id = keyId.join('/')
          label = keyLabel.join('/')
        }
        const deptNode = {
          id: id,
          label: label,
          item:dept,
          disabled:dept.disabled
        }

        list.push(deptNode)
        // if(this.optionId.length>0){
        //   //存在设置的展示树
        //   let idx = this.optionId.findIndex(i=>i==dept.id)
        //   if(idx!=-1){
        //     list.push(deptNode)
        //   }
        
        // }else{
        //   list.push(deptNode)
        // }
        
      }
    },
    inputChange(value){
        console.log(value,'____');
        if(value&&this.data.filter(i=>i.id==value).length>0&&this.data.filter(i=>i.id==value)[0].disabled){
          this.$message.error('该账号为美程实业工作人员，无法发起邀约！如有特殊情况，请联系美程实业后勤特殊处理！');
        }else{
          this.$emit('input', value)
          this.$emit('change',value)
        }
    },
    visibleChange(visible){
      this.$emit('visible',visible)
    }
  },

  created () {
    this.fetchData()
    
  },
}
</script>
