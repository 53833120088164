import request from '@/utils/request'

// 个人中心查询
export function queryById () {
  return request.get('/system/user/queryById')
}

// 更换头像
export function updateAvatar (data) {
  return request.get('/system/user/updateAvatar',{
    params:data
  })
}

//获取验证码
export function verificationCode (data) {
  return request.get('/common/verificationCode',{
    params:data
  })
}

//更换手机号
export function resetAccount (data) {
  return request.post('/system/user/resetAccount',data)
}


//项目中 人员

// 查询
export function userListByProject (data) {
  return request.get('/business/project/userListByProject',{params:data})
}

// 添加人员
export function addUser (data) {
  return request.post('/business/project/addUsers',data)
}

// 移除人员
export function removeUser (data) {
  return request.get('/business/project/removeUser',{params:data})
}

