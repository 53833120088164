import request from '@/utils/request'

//新建
export function create (data) {
  return request.post('/business/weekly/creatWeekly',data)
}

// 查询
export function fetchList (data) {
  return request.post('/business/weekly/page',data)
}

//详情
export function queryWeeklyInfoById (data) {
    console.log('周报详情',data);
  return request.get('/business/weekly/queryWeeklyInfoById',{
    params:data
  })
}

//回复
export function creatWeeklyReply (data) {
    return request.post('/business/weekly/creatWeeklyReply',data)
}

//删除附件
export function deleteEnclosure (data) {
  return request.get('/business/weekly/deleteEnclosureById',{
    params:data
  })
}

//删除回复
export function deleteFollowUpByWeeklyId (data) {
  return request.get('/business/weekly/deleteRecordById',{
    params:data
  })
}

// 删除周报
export function deleteWeeklyById (data) {
  return request.get('/business/weekly/deleteWeeklyById',{
    params:data
  })
}

// // 修改
// export function updateById (data) {
//     return request.post('/system/department/updateById', data)
//   }
  
//   // 删除
//   export function deleteById (id) {
//     return request.get(`/system/department/delete/${id}`)
//   }
  
//   // 批量删除
//   export function deleteByIdInBatch (ids) {
//     return request.get('/system/department/delete/batch', {
//       params: {
//         ids
//       }
//     })
//   }