import request from '@/utils/request'

//新建
export function create (data) {
  return request.post('/business/task/save',data)
}

// 查询
export function fetchList (data) {
  return request.post('/business/task/page',data)
}

//详情  展示
export function queryTaskShowById (data) {
    console.log('任务详情',data);
  return request.get('/business/task/show',{
    params:data
  })
}

//详情  编辑
export function queryTaskEditById (data) {
  console.log('任务详情',data);
return request.get('/business/task/query',{
  params:data
})
}
//更新进度和状态 
export function updateSpeedAndStatus (data) {
  return request.post('/business/task/updateSpeedAndStatus',data)
}

// 跟进
export function addTaskRecord (data) {
  return request.get('/business/task/addRecord',{
    params:data
  })
}
// 删除跟进
export function deleteRecord (data) {
  return request.get('/business/task/deleteRecord',{
    params:data
  })
}

// 附件删除
export function deleteEnclosure (data) {
return request.get('/business/task/deleteE',{
  params:data
})
}
//任务 删除
export function deleteById (data) {
  return request.get('/business/task/delete',{
    params:data
  })
}

// //详情  编辑
// export function queryTaskEditById (data) {
//   console.log('任务详情',data);
// return request.get('/business/task/query',{
//   params:data
// })
// }

// //添加跟进
// export function creatMilepostRecord (data) {
//     return request.post('/business/milepost/addRecord',data)
// }

// //完成里程碑
// export function completedMilepostById (data) {
//   return request.get('/business/milepost/completed',{
//     params:data
//   })
// }

// // 删除里程碑
// export function deleteMilepostById (data) {
//   return request.get('/business/milepost/deleted',{
//     params:data
//   })
// }

// //更新里程碑
// export function updateById (data) {
//   return request.post('/business/milepost/update',data)
// }


// // //删除回复
// // export function deleteFollowUpByWeeklyId (data) {
// //   return request.get('/business/weekly/deleteRecordById',{
// //     params:data
// //   })
// // }



