<template>
<!-- 在用 -->
  <GlobalWindow
    class="position-user-window"
   
    title="查看详情"
    :visible.sync="visible"
    :with-footer="false"
  >
   <div v-if="row&&info&&type==1" :loading="loading">
    <div class="line inlineFlex fweBold">
      <div class="label">商家名称：</div>
      <div class="content">{{info.shopName}}</div>
    </div>
    <div class="line ">
      <div class="label">管理员：</div>
      <div class="content">{{info.adminUserName}}/{{info.adminUserAccount}}</div>
    </div>
    <div class="line ">
      <div class="label">合同ID：</div>
      <div class="content">{{info.contractId || '-'}}</div>
    </div>
    <div class="line ">
      <div class="label">合同编号：</div>
      <div class="content">{{info.contractNo || '-'}}</div>
    </div>
     <div class="line">
      <div class="label">统一社会信用代码：</div>
      <div class="content">{{info.creditCode || '-'}}</div>
    </div>
    <div class="line">
      <div class="label">所属街道办：</div>
      <div class="content">{{info.streetOfficeName || '-'}}</div>
    </div>

    <div class="line">
      <div class="label">协议价格：</div>
      <div class="content">{{info.settPrice?`${info.settPrice}元/千克`:'-'}}</div>
    </div>
    <div class="line">
      <div class="label">结算方式：</div>
      <div class="content">{{info.settType==1?'现结':info.settType==2?`周期结算（${info.settCycle}天）`:'-'}}</div>
    </div>
    <div class="line">
      <div class="label">所属销售：</div>
      <div class="content">{{info.saleUserName}}/{{info.saleAccount}}</div>
    </div>
    <div class="line">
      <div class="label">邀约人：</div>
      <div class="content">{{info.invitationUserName}}/{{info.invitationUserPhone}}</div>
    </div>
    <div class="line">
      <div class="label">签约时间：</div>
      <div class="content">{{info.signedTime || '-'}}</div>
    </div>
    <div class="line">
      <div class="label">补充协议状态：</div>
      <div class="content">{{info.status==0?'待签约':info.status==1?'已签约':info.status==2?'已拒绝':'-'}}</div>
    </div>
    <div class="line">
      <div class="label">商家地址：</div>
      <div class="content">{{info.address || ''}}{{info.addressName || '-'}}</div>
    </div>
    <div class="line">
      <div class="label">备注：</div>
      <div class="content">{{info.remark || '-'}}</div>
    </div>
    
   </div>

   <div v-if="row&&info&&type==2" :loading="loading">
    <div class="line inlineFlex fweBold">
      <div class="label">商家名称：</div>
      <div class="content">{{info.businessName}}</div>
    </div>
    <div class="line ">
      <div class="label">负责人：</div>
      <div class="content">{{info.chargePeopleName}}/{{info.chargePeoplePhone}}</div>
    </div>
    <div class="line">
      <div class="label">协议价格：</div>
      <div class="content">{{info.agreementPrice?`${info.agreementPrice}元/千克`:'-'}}</div>
    </div>
    <div class="line">
      <div class="label">结算方式：</div>
      <div class="content">{{info.settlementMethod==1?'现结':info.settlementMethod==2?`周期结算（${info.settlementPeriod}天）`:'-'}}</div>
    </div>
   
    <div class="line">
      <div class="label">商家地址：</div>
      <div class="content">{{info.outboundMerchantAddress || ''}}</div>
    </div>

    
    
   </div>
  </GlobalWindow>
</template>

<script>
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import GlobalWindow from '@/components/common/GlobalWindow'
import Pagination from '@/components/common/Pagination'
import { fetchList as aimedList } from '../../../api/layun/basicDataManagement/outboundMerchant'
import { fetchList as signedList } from '../../../api/layun/contractManagement/signedManagement' //签署列表

export default {
  name: 'shopDetail',
  extends: BaseTable,
  components: { Pagination, GlobalWindow, TableLayout },
  data () {
    return {
      shopId: null,
      shopName: '',
      visible: false,
      loading:false,
      row:null,
      info:null,
    }
  },
  methods: {
    // 打开查看人员窗口
    open (type,row) {
        this.row = row
        this.type=type
        this.visible = true
        console.log(row,'--');
        if(type==1){
          //签约商家列表详情
          this.getDetail(signedList,1)
        }else if(type==2){
          //出库商家详情
          this.getDetail(aimedList,2)

        }
    },
    getDetail(api,type){
      this.loading = true
      let model = type==1?{shopId:this.row.shopId}:{id:this.row.shopId}
      api({
        page: 1,
        capacity: 10,
        model: model
      }).then(data => {
        console.log(data,'____');
        this.info = data.records[0]
      })
      .catch(e => {
        this.$tip.apiFailed(e)
      })
      .finally(() => {
        this.loading = false
      })
    
    
    
    },
    // 处理分页
    handlePageChange (pageIndex) {
      // 仅查看当前部门处理
      // this.searchForm.strictDeptId = null
      // this.searchForm.rootDeptId = this.searchForm.shopId
      // if (this.onlyCurrentDept) {
      //   this.searchForm.strictDeptId = this.searchForm.shopId
      //   this.searchForm.rootDeptId = null
      // }
      this.tableData.pagination.pageIndex = pageIndex
      this.isWorking.search = true
      fetchStaffsList({
        page: pageIndex,
        capacity: this.tableData.pagination.pageSize,
        model: this.searchForm
      })
        .then(data => {
          this.tableData.list = data.records
          this.tableData.pagination.total = data.total
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          this.isWorking.search = false
        })
    },
    //重置
    reset(){
      this.searchForm.name = null
      this.searchForm.account = null

      this.search()
    }
  }
}
</script>

<style scoped lang="scss">
.inlineFlex{
  display: inline-flex !important;
}
.fweBold{
  font-weight: bold;
  font-size: large;
}
.line{
  display: flex;
  align-items: center;
  margin:20px ;

  .label{
    color: #a2a2a2;
  
  }

  .content{
    color: #535353;
  
  }
}
.position-user-window {
  /deep/ .table-search-form {
    padding: 0;
  }
  /deep/ .window__body {
    background: #f7f7f7;
    .table-content {
      padding: 0;
      .table-wrap {
        padding: 0;
      }
    }
  }
  .toolbar {
    margin-top: 10px;
    padding: 6px 12px;
    background: #fff;
    font-size: 13px;
    label {
      margin-left: 8px;
      vertical-align: middle;
      color: #999;
    }
  }
  // 列表头像处理
  .table-column-avatar {
    img {
      width: 48px;
    }
  }
  // 列表角色处理
  .table-column-role {
    ul {
      li {
        display: inline-block;
        background: #eee;
        border-radius: 3px;
        padding: 0 3px;
        margin-right: 3px;
      }
    }
  }
}
</style>
