<template>
  <div class="wrap">
    <div class="center">
      <!-- <div class="imgs">

      </div> -->
      <div class="login" >
        <!-- <h1>项目管理系统</h1> -->
        <img src="../assets/images/loginTitle.png" alt="" srcset="">
        <el-tabs v-model="activeTab"  >
          <el-tab-pane label="账号密码登录" :name="1">
            <div class="info-input">
              <el-input v-model="account " placeholder="请输入账号(手机号)" prefix-icon="el-icon-user-solid" maxlength="50" v-trim/>
              <el-input v-model="password" placeholder="请输入密码" type="password" prefix-icon="eva-icon-password" maxlength="30" show-password/>
              <el-button @click="toNextPage" type="text" style="text-align:right">忘记密码</el-button>
            </div>
            <el-button :loading="loading" @click="login(true)" class="loginBtn">登&nbsp;&nbsp;录</el-button>
          </el-tab-pane>
          <el-tab-pane label="验证码登录" :name="2">
            <div class="info-input">
              
                <el-input v-model="account " placeholder="请输入手机号" prefix-icon="el-icon-user-solid" maxlength="50" v-trim />
                <div  v-if="showSend">
                  <el-button @click="sendCode(true)" type="text" :loading="verCodeLoading&&isSendBakCode==true">发送验证码到备用手机号</el-button>
                  <el-button @click="sendCode(false)" type="text" :loading="verCodeLoading&&isSendBakCode==false">发送验证码</el-button>
                </div>
                <el-button  type="text" v-if="!showSend">{{count}}s后可重试</el-button>
              <el-input v-model="verificationCode" placeholder="请输入验证码"  prefix-icon="eva-icon-password" maxlength="30" />

            </div>
            <el-button :loading="loading" @click="login(false)" class="loginBtn">登&nbsp;&nbsp;录</el-button>


          </el-tab-pane>
        </el-tabs>
        
        

        
        <div style="text-align:center;color:#2E7DFB">
          <p >使用第三方账号登录</p>
          <img src="../assets/images/wechat.png" alt="" srcset="" style="width:45px;height:45px;cursor:pointer" @click="weChat">
        </div>
      </div>
      <div class="fixed" style="color:#555555">
        <a href="https://beian.miit.gov.cn" target="_blank" style="color:#555555">备案号：陕ICP备19004372号-4</a>
        <div>© 2022 陕西旅业控股集团有限公司  All Rights Reserved</div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { getCaptcha, loginByPassword ,verificationCode } from '@/api/system/common'

export default {
  name: 'Login',
  data () {
    return {
      activeTab:1,
      loading: false,
      verCodeLoading:false,
      account : '',
      password: '',
      verificationCode:'',
      timeCount: 60,
      showSend: true,
      timer: null,
      count: '', // 初始化次数
      isSendBakCode:false,//是否给备用手机号发送验证码
    }
  },
  methods: {
    ...mapMutations(['setUserInfo']),
    toNextPage(){//忘记密码
      this.$router.push({ name: 'forgotPassword' })
    },
    
    // 登录
    login(isPasswordLogin) {
      // window.location.href = '/'
      // return;
      if (this.loading) {
        return
      }
      if (!this.__check(isPasswordLogin)) {
        return
      }
      this.loading = true
      loginByPassword({
        account : this.account.trim(),
        password: this.password,
        bakMobile:isPasswordLogin?false:this.isSendBakCode?true:false,
        verificationCode:this.verificationCode.trim(),
        // code: this.captcha.value.trim(),
        // uuid: this.captcha.uuid
      })
        .then((res) => {
          console.log(res,'登陆成功');
          localStorage.setItem('token',res)
          // return 
          window.location.href = '/'
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 登录前验证
    __check (isPasswordLogin) {
      if (this.account .trim() === '') {
        this.$tip.error('请输入账号')
        return false
      }
      if (isPasswordLogin&&this.password === '') {
        this.$tip.error('请输入密码')
        return false
      }
      return true 
    },
    // ------微信登陆------
      weChat() {
      let appid = 'wx11e01f6cdb2eb692' ;
      let url = encodeURIComponent('https://ly.meictt.com/#/loading');
        //在微信公众号请求用户网页授权之前，开发者需要先到公众平台官网中的“开发 - 接口权限 - 网页服务 - 网页帐号 - 网页授权获取用户基本信息”的配置选项中，修改授权回调域名。请注意，这里填写的是域名（是一个字符串），而不是URL，因此请勿加 http:// 等协议头;
        window.location.href =`https://open.weixin.qq.com/connect/qrconnect?appid=${appid}&redirect_uri=${url}&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect`
      },
    //发送验证码倒计时
      sendCode(isBakMobile) {
      
        this.isSendBakCode = isBakMobile
        if(!this.account.length>0){
            this.$message.error('请先输入手机号')
            return
        }
        if (!this.timer) {
          this.verCodeLoading = true;
          verificationCode({
            account:this.account,
            bakMobile:isBakMobile,
          }).then((res) => {
            this.verCodeLoading = false
            console.log(res, '验证码获取成功')
            if (res == '发送成功') {
              this.$message.success("验证码发送成功")
              this.count = this.timeCount
              this.showSend = false
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= this.timeCount) {
                  this.count--
                } else {
                  this.showSend = true
                  clearInterval(this.timer) // 清除定时器
                  this.timer = null
                }
              }, 1000)
              
            } else {
              this.$message.error('发送失败')
            }
          }).catch(e => {
            this.$tip.apiFailed(e)
          })
          .finally(() => {
            this.isWorking = false
          })


        }
      },
  },
  created () {
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";
.fixed{
  position: fixed;
  bottom: 2px;
  left: 0;
  right: 0;
  text-align: center;
  
}
$input-gap: 30px;
.wrap {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/images/loginbg.png");
  // background-repeat: no-repeat;
  background-size: cover;
  // background-clip: content-box;
  background-position: center center;
  position: relative;
  .center{
    position: absolute;
    width: 200px;
    height:auto;
    left: calc(50% - 100px);
    top: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .imgs{
      height: 310px;
      width: 260px; 
      background-image: url("../assets/images/logo.png");
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-clip: content-box;
      background-position: center;
      margin-bottom: 20px;
    }
  }

  // 右边登录
  .login {
  
    height: 100%;
    width: 38%;
    max-width: 560px;
    min-width: 460px;

    flex-shrink: 0;
    text-align: right;
    background: #fff;
    padding: 20px 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    box-shadow: 0px 4px 49px 0px rgba(27, 100, 74, 0.1);
    border-radius: 20px;
    
    
    .info-input {
      margin-bottom: 30px;
      background: #fff;
      /deep/ .el-input {
        margin-top: 15px;
        .el-input__inner {
          height: 50px;
          background: #F9F9F9;
          border: 1px solid transparent;
          &:focus {
            border: 1px solid $primary-color;
          }
        }
      }
    }
    // 验证码输入
    .captcha-input {
      display: flex;
      margin-top: $input-gap;
      height: 50px;
      .el-input {
        width: 100%;
        margin-top: 0;
      }
      span, img {
        width: 45%;
        height: 100%;
        flex-shrink: 0;
        margin-left: 16px;
      }
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f7f7f7;
        border-radius: 8px;
      }
    }
    .loginBtn {
      height: 50px;
      width: 100%;
      color: #fff;
      font-size: 16px;
      background: linear-gradient(130deg, $primary-color + 20 0%, $primary-color - 20 100%);
    }
  }
}
</style>
