<template>
  <TableLayout :permissions="['system:user:query']">
    <!-- 管理中心 账号设置 账号列表 -->

    <!-- 表格和分页 -->
    <template v-slot:table-wrap>

      <el-table border :data="tableData" header-row-class-name="headerClass">
        <el-table-column prop="role" label="角色名称" width='"20%"' class-name="table-column-avatar" align="center">
          <div slot-scope="{row}">
            <span class="role">{{row.role}}</span>
          </div>
        </el-table-column>
        <el-table-column prop="bussiness" label="业务中心权限" width='"40%"' align="center">
          <div slot-scope="{row}">
            <div class="base">
              <div v-for="(item,key) in row.bussiness" :key="key" :class="item.root=='r'?'r':''">
                {{item.name}}
              </div>
            </div>
          </div>
        </el-table-column>
        <el-table-column prop="manage" label="管理中心权限" width='"40%"' align="center">
          <div slot-scope="{row}">
            <div class="base">
              <div v-for="(item,key) in row.manage" :key="key" :class="item.root=='r'?'r':''">
                {{item.name}}
              </div>
            </div>
          </div>
        </el-table-column>
      </el-table>

    </template>

  </TableLayout>
</template>

<script>
import Pagination from '@/components/common/Pagination'
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import OperaUserWindow from '@/components/system/user/OperaUserWindow'
import RoleConfigWindow from '@/components/system/user/RoleConfigWindow'
import ResetPwdWindow from '@/components/system/user/ResetPwdWindow'
import DepartmentSelect from '@/components/common/DepartmentSelect'
import PositionSelect from '@/components/common/PositionSelect'

export default {
  name: 'ManageUser',
  extends: BaseTable,
  components: { PositionSelect, DepartmentSelect, ResetPwdWindow, RoleConfigWindow, OperaUserWindow, TableLayout, Pagination },
  data () {
    return {
      tableData:[
        {
          role:'超级管理员',
          bussiness:[
            {name:'首页',root:'a'},{name:'项目中心',root:'a'},{name:'周报',root:'a'},{name:'个人中心',root:'a'},
          ],
          manage:[
            {name:'首页',root:'a'},{name:'数据中心',root:'a'},{name:'业务设置',root:'a'},{name:'系统设置',root:'a'},{name:'账号设置',root:'a'},{name:'管理日志',root:'a'},
          ],
        
        },
        {
          role:'集团高层',
          bussiness:[
            {name:'首页',root:'r'},{name:'项目中心',root:'r'},{name:'周报',root:'r'},{name:'个人中心',root:'r'},
          ],
          manage:[
            {name:'首页',root:'a'},{name:'数据中心',root:'r'},{name:'业务设置',root:'r'},{name:'系统设置',root:'r'},{name:'账号设置',root:'r'},{name:'管理日志',root:'r'},
          ],
        
        },
        {
          role:'子公司负责人',
          bussiness:[
            {name:'首页',root:'a'},{name:'项目中心',root:'a'},{name:'周报',root:'a'},{name:'个人中心',root:'a'},
          ],
          manage:[
            {name:'首页',root:'a'},{name:'数据中心',root:'r'},{name:'账号设置',root:'r'}
          ],
        
        },
        // {
        //   role:'主管领导',
        //   bussiness:[
        //     {name:'首页',root:'a'},{name:'项目中心',root:'a'},{name:'周报',root:'a'},{name:'个人中心',root:'a'},
        //   ],
        //   manage:[
        //     {name:'数据中心',root:'r'}
        //   ],
        
        // },
        {
          role:'员工',
          bussiness:[
            {name:'首页',root:'a'},{name:'项目中心',root:'a'},{name:'周报',root:'a'},{name:'个人中心',root:'a'},
          ],
          manage:[
            {name:'首页',root:'r'},
          ],
        
        },
      ]
    }
  },
  created () {

  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";
// 列表头像处理
.table-column-avatar {
  img {
    width: 48px;
  }
}
.headerClass{
  height: 148px !important;
  background:darkcyan;
}

.role{
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
}
.base{
  display: flex;
  flex-wrap: wrap;
  // justify-content: first baseline;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 50px;

  
  div{
    margin:10px;
    width: 120px;
    height: 24px;
    background: #1A69FC;
    border-radius: 12px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFEFE;
  }
  .r{
    // background:#fff;
    // color: #5497C7;
    // border: 1px solid #5497C7;

    background:#fff;
    color: #1A69FC;
    border: 1px solid #1A69FC;

  }
}
</style>
