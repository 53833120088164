<template>
  <!-- <TreeSelect
    :placeholder="placeholder"
    :value="value"
    :data="data"
    :clearable="clearable"
    :append-to-body="appendToBody"
    :inline="inline"
    :multiple="multiple"
    :flat="multiple"
    @input="inputFun"
  /> -->
  <!-- <el-cascader placeholder="请输入省市区" v-model="value" :options="options" :clearable="clearable" :disabled='disabled' :props="{ checkStrictly: checkStrictly, }" filterable @change="cityChange"></el-cascader> -->

  <el-select v-model="val" filterable placeholder="请选择" :disabled="disabled" :clearable="clearable" :inline="inline" :multiple="multiple" :placeholder="placeholder" @change="inputFun">
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
  </el-select>
</template>

<script>
// import TreeSelect from './TreeSelect'
import { getUserRoleMaps } from '../../../api/common'
import {baseCityData} from '../../../utils/baseCityData'
export default {
  name: 'UserSelect',
  components: {  },
  props: {
    value: {},
    disabled:{
      default:false
    },
    clearable:{
      default:false
    },
    inline: {
      default: true
    },
    multiple: {
      default: false
    },
    placeholder:{
      default:'请选择销售'
    }
  },
  data () {
    return {
      options:[],
      val:''
      
    }
  },
  watch: {
    value:{
      handler(val){
        this.val = val?String(val):''

      },
      immediate:true
      
    },
  },
  methods: {
    inputFun(id){
    console.log('销售角色的变化',id);
      this.$emit('input', id?Number(id):null);
      let data =  this.options.filter(i=>i.id==id)
      this.$emit('row', data[0]);
    },
    fetchData(){
      getUserRoleMaps({
        page: 1,
        capacity: 1000,
        model: {
          id:11,//销售 roleId：11
        },
        sorts: []
      })
      .then(res => {
        console.log(res,'销售');
        this.options = []
        this.__fillData(this.options, res.records || [],{
        idKey:['id'],
        labelKey:['username','account']
        })
      })
      .catch(e => {
        this.$tip.apiFailed(e)
      })
    
    },
    __fillData (list, pool,keys) {
      console.log(list,pool,'----');
      for (const dept of pool) {
        if (dept.id === this.excludeId) {
          continue
        }
        let id = dept.id;
        let label = dept.username;

        if(keys){
          let keyId = []
          let keyLabel = []

          if(keys.idKey&&keys.idKey.length>0){
            keys.idKey.map(item=>{
              keyId.push(dept[item])
            })
          }
          if(keys.labelKey&&keys.labelKey.length>0){
            keys.labelKey.map(item=>{
              keyLabel.push(dept[item])
            })
          }
          id = keyId.join('/')
          label = keyLabel.join('/')
        }
        const deptNode = {
          id: id,
          value:id,
          label: label,
          item:dept
        }
        list.push(deptNode)
      }
    },
    
  },
  created () {
    this.fetchData()

  },
  mounted(){

  }
}
</script>
