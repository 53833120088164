<template>
<!-- 在用 -->
  <GlobalWindow
    class="position-user-window"
    :title="shopName + '员工列表'"
    :width="'800px'"
    :visible.sync="visible"
    :with-footer="false"
  >
    <TableLayout :with-breadcrumb="false">
      <template v-slot:table-wrap>

        <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe 
        @selection-change="handleSelectionChange"
        @sort-change="handleSortChange"
        style="margin:10px 0"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="avatar" label="头像" width="80px" class-name="table-column-avatar" align="center" >
          <div slot-scope="{row}">
            <img :src="row.avatar == null ? '/static/avatar/man.png' : row.avatar">
          </div>
        </el-table-column>
        <el-table-column prop="account" label="账号" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="username" label="姓名" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="status" label="状态" min-width="120px" align="center">
          <span slot-scope="{row}">{{row.status==0?'正常':row.status==1?'已冻结':row.status==2?'已禁用':'异常角色'}}</span>
        </el-table-column>

      </el-table>
        <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
        ></pagination>
      </template>
    </TableLayout>
  </GlobalWindow>
</template>

<script>
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import GlobalWindow from '@/components/common/GlobalWindow'
import Pagination from '@/components/common/Pagination'
import { fetchStaffsList } from '../../../api/layun/accountManagement/shop'
export default {
  name: 'ShopStaffsWindow',
  extends: BaseTable,
  components: { Pagination, GlobalWindow, TableLayout },
  data () {
    return {
      shopId: null,
      shopName: '',
      visible: false,
      // 仅查看当前店铺
      onlyCurrentDept: true,
      // 搜索表单
      searchForm: {
        id: null,
      }
    }
  },
  methods: {
    // 打开查看人员窗口
    open (shopId, shopName) {
      this.shopId = shopId
      this.shopName = shopName
      this.searchForm.id = shopId
    
      this.visible = true
      this.search()
    },
    // 处理分页
    handlePageChange (pageIndex) {
      this.tableData.pagination.pageIndex = pageIndex
      this.isWorking.search = true
      fetchStaffsList({
        page: pageIndex,
        capacity: this.tableData.pagination.pageSize,
        model: this.searchForm
      })
        .then(data => {
          this.tableData.list = data.records
          this.tableData.pagination.total = data.total
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          this.isWorking.search = false
        })
    },
    //重置
    reset(){
      this.searchForm.name = null
      this.searchForm.account = null

      this.search()
    }
  }
}
</script>

<style scoped lang="scss">
.position-user-window {
  /deep/ .table-search-form {
    padding: 0;
  }
  /deep/ .window__body {
    background: #f7f7f7;
    .table-content {
      padding: 0;
      .table-wrap {
        padding: 0;
      }
    }
  }
  .toolbar {
    margin-top: 10px;
    padding: 6px 12px;
    background: #fff;
    font-size: 13px;
    label {
      margin-left: 8px;
      vertical-align: middle;
      color: #999;
    }
  }
  // 列表头像处理
  .table-column-avatar {
    img {
      width: 48px;
    }
  }
  // 列表角色处理
  .table-column-role {
    ul {
      li {
        display: inline-block;
        background: #eee;
        border-radius: 3px;
        padding: 0 3px;
        margin-right: 3px;
      }
    }
  }
}
</style>
