<template>
  <TreeSelect
    :placeholder="placeholder"
    :value="value"
    :data="data"
    :clearable="clearable"
    :append-to-body="appendToBody"
    :inline="inline"
    :multiple="multiple"
    :flat="multiple"
    @input="inputFun"
  />
</template>

<script>
import TreeSelect from './TreeSelect'
import { fetchList } from '@/api/system/user'
export default {
  name: 'DepartmentSelect',
  components: { TreeSelect },
  props: {
    value: {},
    inline: {
      default: true
    },
    multiple: {
      default: false
    },
    placeholder: {
      default: '请选择部门'
    },
    // 是否可清空
    clearable: {
      default: false
    },
    appendToBody: {
      default: false
    },
    // 需被排除的部门ID
    excludeId: {}
  },
  data () {
    return {
      data: []
    }
  },
  watch: {
    excludeId () {
      this.fetchData()
    },
    // value:{
    //   handler(){
    //     console.log(this.value,'---部门人员');
    //     // this.fetchData()
    //   },
    //   immediate:true
    // }
  },
  methods: {
    // 获取所有部门
    fetchData () {
      fetchList(
        {
          capacity: 10,
model: {username: "", realname: "", rootDeptId: null, positionId: null, mobile: ""},
page: 1,
sorts: [{property: "CREATE_TIME", direction: "DESC"}]
        }
      )
        .then(records => {
          this.data = []
          if(records.records.length>0){
            this.__fillData(this.data, records.records)
          }
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
    },
    // 填充部门树
    __fillData (list, pool) {
      console.log(list,pool,'修改树结构');
      for (const user of pool) {
        if (user.id === this.excludeId) {
          continue
        }
        const deptNode = {
          ...user,
          id: user.id,
          label: user.username+'-'+user.account,
          // user:JSON.stringify(user)
        }
        list.push(deptNode)
        if (user.children != null && user.children.length > 0) {
          deptNode.children = []
          this.__fillData(deptNode.children, user.children)
          if (deptNode.children.length === 0) {
            deptNode.children = undefined
          }
        }
      }
    },
    inputFun(id){
      this.$emit('input', id);
      let data =  this.data.filter(i=>i.id==id)
      this.$emit('row', data[0]);
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
