<template>
  <!-- <TreeSelect
    :placeholder="placeholder"
    :value="value"
    :data="options"
    :clearable="clearable"
    :append-to-body="appendToBody"
    :inline="inline"
    :multiple="multiple"
    :flat="multiple"
    @input="inputFun"
  /> -->
  <el-cascader placeholder="请输入省市区" v-model="valueCur" :options="options"  :clearable="clearable" :disabled='disabled' :props="{ checkStrictly: checkStrictly,multiple:multiple,disabled:'disabled' }" filterable @change="cityChange"></el-cascader>
</template>

<script>
import TreeSelect from '../../common/TreeSelect.vue'
import { fetchList } from '@/api/system/user'
import {baseCityData} from '../../../utils/baseCityData'
export default {
  name: 'CitySelect',
  components: { TreeSelect },
  props: {
    value: {
      type:Array,
      default:()=>{
        return []
      }
    },
    fixedArr: { //不可被选中的值
      type:Array,
      default:()=>{
        return []
      }
    },
    disabled:{
      default:false
    },
    clearable:{
      default:false
    },

    checkStrictly:{ //在单选模式下  选择任意一级选项
      default: false
    },
    inline: {
      default: true
    },
    multiple: {
      default: false
    },
  },
  data () {
    return {
      data: [],
      options:[],
      form:{},
      valueCur:[],
      
    }
  },
  watch: {
    
    value:{
      handler(val){
        this.valueCur = val || []
      },
      immediate:true,
      deep:true,
    },
    fixedArr:{
      handler(val){
        let baseData = JSON.parse(JSON.stringify(baseCityData))
        this.options =  this.listToTree(baseData)
      },
      immediate:true,
      deep:true,
    },
    
  },
  methods: {
    
    cityChange(value){
      console.log(value,'----城市变化');
      
      if(this.multiple){
        this.operaValue(value)
      }else{
        this.valueCur = value
        this.getBaseData(this.valueCur)
        
      }
      console.log(this.valueCur,'--改变后的数据',this.form,this.options);
      let operateData = []
      this.valueCur.map(item=>{
        if(item.length==3){
          operateData.push(item)
        }
        if(item.length==2){
          let children = this.options.filter(i=>i.id==item[0])[0].children.filter(i=>i.id==item[1])[0].children
          if(children&&children.length>0){
            children.map(i=>{
              let arrI = [...item,i.id]
              operateData.push(arrI)
            })
          }
        }
      })

      this.$emit('input',this.valueCur)
      if(!this.multiple){
        this.$emit('change',{value:this.valueCur,form:this.form,operateData:operateData})
      }
      
      

    },
    operaValue(value){
      let temp = JSON.parse(JSON.stringify(value));
      let result = [];
      /*
      根据每一次选择的值，查找出只会包含父节点的集合
      即处理先选择某一子节点，再选了父节点。要把子节点剔除掉，只保留父节点。
      */
     
      temp.map((item,index) => {
        // 长度为1  说明这个节点是顶级节点
        if (item.length == 1) {
          // result.push(item);
        } else {

          if (result.length > 0) {
            result.map((it,idx) => {
              it.map((x,iidx) => {
                if (item.indexOf(x) == -1) {
                  if(result.findIndex(i=>i.join(',')==item.join(','))==-1){
                    result.push(item);
                  }   
                }
              });
            });
          } else {
            result.push(item);
          }
        }
      });
      
      //把处理后的选择结果更新到级联选择器中，回显正确的选择状态。

      //去掉result的子节点
      let res = result.filter(i=>i.length==2)  || [] ;//将数据为2的第一步先放进去
      result.filter(i=>i.length==3).map((item,idx)=>{
      // 处理长度为3的数据
        let itemString = item.join(',')
        let resL3 = res.filter(i=>i.length==3) || []
        let resL2 = res.filter(i=>i.length==2) || []

        let exsitLen3 = resL3.length>0?resL3.findIndex(i=>i.join(',')==itemString)==-1?false:true:false;
        let exsitLen2 = resL2.length>0?resL2.findIndex(i=>itemString.indexOf(i.join(','))!=-1)==-1?false:true:false
        console.log(item,exsitLen3,exsitLen2,'___________________exsitLen2');
        if(!exsitLen3&&!exsitLen2){
          res.push(item)
        }
        else if(exsitLen3&&!exsitLen2){

        }else if(!exsitLen3&&exsitLen2){
        }
      })
      console.log('处理后的数据',res);
      // 查找length为2的数据里有没有子节点是fixed的数据,去掉 展示非fixed的数据
      let res2 = []
      res.forEach(item=>{
        if(item.length==2){
         let pathId = '-1,46834,'+item.join(',')
         let idx = this.fixedArr.findIndex(fixI=>fixI.indexOf(pathId)!=-1);//该市区内有被固定的对象
         if(idx!=-1){
            //去掉固定对象 回显剩余
            let children = this.options.filter(i=>i.id==item[0])[0].children.filter(i=>i.id==item[1])[0].children
            console.log(children ,'--对象啊');
            children.map(i=>{
              if(!i.fixed){
                let pathIdArr = i.levelPathId.split(',').filter((i,index)=>index>1)
                console.log(pathIdArr,'----pathIdArr');
                res2.push(pathIdArr)
              }
            })
          }else{
            res2.push(item)
          }
        }else{
          res2.push(item)
        }
      
      
      })
      console.log(res2,'结果');

      this.valueCur = res2
      // 过滤出选择父节点的下一级子节点
      let selectArr = [];
      res2.map((item) => {
        if(item.length>1){
          selectArr.push(item[item.length - 1]);
        }
        
      });
      console.log(selectArr,'---selectArr',this.valueCur);
      // 如果过滤后的数组长度为0，说明没有选择任何节点，则所有节点可以选择。反之设置对应子节点的disabled。
      if (selectArr.length > 0) {
        this.updateTreeDataStatus(selectArr);
      } else {
        this.setChildrenDisabled(this.options, false);
      }
    
    },
    // 根据 省市区 id 返回当前区域的汉字
    getBaseData(value){
        if(this.multiple){
          return 
        }
        this.form.provinceId= ''
        this.form.provinceName= '';
        this.form.cityId= '';
        this.form.cityName= '';
        this.form.districtId= '';
        this.form.districtName= '';
        this.form.adCode = '';

        if(value.length>0){
          let pId = this.options.findIndex(op=>op.id==value[0])
          if(pId!=-1){
            this.form.provinceId = value[0]
            this.form.provinceName = this.options[pId].areaName
            if(value.length==1){
              return
            }
            let cId  = this.options[pId].children.findIndex(op=>op.id==value[1])
            if(cId!=-1){
              this.form.cityId = value[1]
              this.form.cityName = this.options[pId].children[cId].areaName
              if(value.length==2){
                return
              }
              let dId  = this.options[pId].children[cId].children.findIndex(op=>op.id==value[2])
              if(dId!=-1){
                this.form.districtId = value[2]
                this.form.districtName = this.options[pId].children[cId].children[dId].areaName
                this.form.adCode = this.options[pId].children[cId].children[dId].adCode
              }
            }
          }

          
        }
    },
    // 更新树形数据的状态
    updateTreeDataStatus(data) {
      let treeData = this.options;
      // 直接把所有节点置为可选，避免父节点取消选择了，子节点还不可选的情况
      this.setChildrenDisabled(treeData, false);
      // 根据上面选择的节点，把不可选的子节点及所有后代节点置为不可选
      data.map((item) => {
        let sub = this.filter(treeData, (node) => {
          return node.value == item;
        });
        if (sub.children) {
          const subChild = this.setChildrenDisabled(sub.children, true);
          sub.children = subChild;
        }
      });
    },
    //递归设置子级部门不可选择
    setChildrenDisabled(tree, status) {
    // console.log(tree,'---反黑',this.fixedArr);
      tree.map((item) => {
        let idx = this.fixedArr.findIndex(i=>item.levelPathId==i)
        // console.log(idx,item.levelPathId);
        if(item.fixed){
          // this.$set(item, "disabled", true);
        }else{
          this.$set(item, "disabled", status);
        }
        if (item.children) {
          this.setChildrenDisabled(item.children, status);
        }
      });
      return tree;
    },
    /**
     * 根据传入的条件 返回json数据中符合条件的数据
     * params: treeList  需要处理的原始数据
     * params: callback 条件function 如:(node)=>node.id==id
     *  */
    filter(treeList, callback) {
      const queue = [...treeList];
      while (queue.length > 0) {
        const cur = queue.shift();
        if (callback(cur)) {
          return cur;
        }
        if (cur.children && cur.children.length > 0) {
          for (const item of cur.children) {
            queue.push(item);
          }
        }
      }
    },

    inputFun(id){
      this.$emit('input', id);
      let data =  this.data.filter(i=>i.id==id)
      this.$emit('row', data[0]);
    },
    listToTree(oldArr) {
      oldArr.list.forEach(element => {
        element.label = element.areaName
        element.value = element.id
       
        // if(idx!=-1){
        //   element.disabled = true
        // }else{
        //   element.disabled = false
        // }
        let parentId = element.parentId;
        if(parentId !== 0){
          oldArr.list.forEach(ele => {
            ele.label = ele.areaName
            ele.value = ele.id
            
            if(ele.id == parentId){ //当内层循环的ID== 外层循环的parendId时，（说明有children），需要往该内层id里建个children并push对应的数组；
            
              if(!ele.children){
                ele.children = [];
              }
              ele.children.push(element); 
            }
          });
        }else{
        
        }
      });
      oldArr.list = oldArr.list.filter(ele => ele.parentId === oldArr.parentId); //这一步是过滤，按树展开，将多余的数组剔除；
      //默认给一个 不可选中的数据
      oldArr.list.forEach(pItem=>{
      //fixed 是否固定住 不可改变状态的

        if(pItem.children){
          //省
          pItem.children.forEach(cItem=>{
          //市
          cItem.fixed = false
          cItem.disabled = false
          if(this.fixedArr.findIndex(i=>cItem.levelPathId==i)!=-1){
            cItem.fixed = true
            cItem.disabled = true
          }
          let childrenToParent = false
            if(cItem.children){
              cItem.children.forEach(dItem=>{
               //区
               dItem.fixed = false
               dItem.disabled = false
               if(cItem.fixed){
                dItem.fixed = true
                dItem.disabled = true
               }else if(this.fixedArr.findIndex(i=>dItem.levelPathId==i)!=-1){
                dItem.fixed = true
                dItem.disabled = true
                childrenToParent = true
               }
              })
            }
            if(childrenToParent){
              // cItem.fixed = true
              // cItem.disabled = true
            }
          })
        } 
      })
      
      return oldArr.list;  
    },
  },
  created () {
    let baseData = JSON.parse(JSON.stringify(baseCityData))
    this.options =  this.listToTree(baseData)
    // this.initBaseData();
  }
}
</script>
