<template>
  <GlobalWindow
    class="handle-table-dialog"
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="省市区" prop="areaArr" required>
        <CitySelect v-model="form.areaArr" @change="cityChange" :disabled="form.id!=null?true:false"></CitySelect>
      </el-form-item>
      <el-form-item label="街道办名称" prop="areaName">
        <el-input v-model="form.areaName" placeholder="请输入街道办名称" v-trim maxlength="200"/>
      </el-form-item>
    </el-form>

    
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import CitySelect from '../../../components/layun/common/CitySelect.vue'
export default {
  name: 'OperaSubDistrictOfficeWindow',
  extends: BaseOpera,
  components: {  GlobalWindow ,CitySelect },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        areaName:'',
        areaArr:[],
      },
      // 验证规则
      rules: {
        areaName: [
          { required: true, message: '请输入街道名称' }
        ],
        areaArr: [
          { required: true, message: '请输入省市区' }
        ]
      }
    }
  },
  methods: {
    /**
     * @title: 窗口标题
     * @target: 编辑的菜单对象
     * @parent: 新建时的上级菜单
     */
    open (title, target, parent) {
    console.log(title, target, parent);
      this.title = title
      this.visible = true
      // 新建，menu为空时表示新建菜单
      if (target == null) {
        // this.excludeMenuId = null
        this.$nextTick(() => {
          this.$refs.form.resetFields()
          this.form.id = null
        })
        return
      }
      // 编辑
      this.$nextTick(() => {
        // this.excludeMenuId = target.id
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        let idArr = target.levelPathId.split(',')
        let nameArr = target.levelPath.split(',')
        this.form.areaArr = [Number(idArr[2]),Number(idArr[3]),Number(idArr[4])]
        this.form.provinceId= idArr[2]
        this.form.provinceName= nameArr[2];
        this.form.cityId= idArr[3];
        this.form.cityName= nameArr[3];
        this.form.districtId= idArr[4];
        this.form.districtName= nameArr[4];
        this.form.adCode = target.adCode;
      })
    },
    cityChange(value){
      console.log(value,'--数据');
      this.form = {
        ...this.form,
        ...value.form
      }
    
    }
  },
  created () {
    this.config({
      api: '/layun/basicDataManagement/subDistrictOfficeData'
    })
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables";
$icon-background-color: $primary-color;
.global-window {
  .tip {
    margin-bottom: 12px;
    em {
      font-style: normal;
      color: $primary-color;
      font-weight: bold;
    }
  }
  // 图标
  /deep/ .form-item-icon {
    .el-form-item__content {
      height: 193px;
      overflow-y: auto;
    }
    .el-radio-group {
      background: $icon-background-color;
      padding: 4px;
      .el-radio {
        margin-right: 0;
        color: #fff;
        padding: 6px;
        &.is-checked {
          background: $icon-background-color - 30;
          border-radius: 10px;
        }
        .el-radio__input.is-checked + .el-radio__label {
          color: #fff;
        }
      }
      .el-radio__input {
        display: none;
      }
      .el-radio__label {
        padding-left: 0;
        // element-ui图标
        i {
          font-size: 30px;
        }
        // 自定义图标
        [class^="eva-icon-"], [class*=" eva-icon-"] {
          width: 30px;
          height: 30px;
          background-size: 25px;
          vertical-align: bottom;
        }
      }
      .el-radio--small {
        height: auto;
        padding: 8px;
        margin-left: 0;
      }
    }
  }
}
</style>
