<template>
<div style="padding:20px ">
    <el-breadcrumb separator="/" style="margin-bottom:20px">
        <el-breadcrumb-item v-for="path in paths" >{{path}}</el-breadcrumb-item>
    </el-breadcrumb>


    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="商家管理员列表" name="first" >
            <TableLayout  :withBreadcrumb="false" v-if="activeName=='first'">
                
                <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
                <el-form-item label="商家管理员" prop="shopAdmin">
                    <el-input v-model="searchForm.shopAdmin" v-trim placeholder="请输入账号(手机号)/姓名搜索" @keypress.enter.native="search" style="width:250px"/>
                </el-form-item>
                <el-form-item label="商家名称" prop="shopName">
                    <el-input v-model="searchForm.shopName" v-trim placeholder="请输入商家名称" @keypress.enter.native="search" style="width:250px"/>
                </el-form-item>
                <el-form-item label="所属销售" prop="sale">
                    <el-input v-model="searchForm.sale" v-trim placeholder="请输入账号(手机号)/姓名搜索" @keypress.enter.native="search" style="width:250px"/>
                </el-form-item>
                <el-form-item label="业务区域" prop="areaCodes" >                     
                    <el-select v-model="searchForm.areaCodes" class="w250" remote clearable multiple filterable :remote-method="querySearchArea">
                        <el-option  v-for="(item,index) in allAreaList" :value="item.id" :label="item.regionName" :key="'allAreaList'+index"></el-option>
                        
                    </el-select>
                </el-form-item>
                <el-form-item label="街道办" prop="streetOfficeCodes" >                     
                    <el-select v-model="searchForm.streetOfficeCodes" class="w250" remote clearable multiple filterable :remote-method="querySearchStreet">
                        <el-option  v-for="(item,index) in allStreetList" :value="item.id" :label="item.areaName" :key="'searchFromUnitId'+index"></el-option>
                        
                    </el-select>
                </el-form-item>
                <el-form-item label="分组" prop="shopLabelIds" >                     
                    <el-select v-model="searchForm.shopLabelIds" class="w250" remote clearable  multiple filterable >
                        <el-option  v-for="(item,index) in allGroupData" :value="item.id" :label="item.name" :key="'searchFromUnitId'+index"></el-option>
                        
                    </el-select>
                </el-form-item>
                <el-form-item label="现金开关" prop="openPay">
                    <el-select v-model="searchForm.openPay" placeholder="请选择" style="width:100px">
                        <el-option  key="-1" label="全部" :value="null"  ></el-option>
                        <el-option  key="0" label="开" :value="1"  ></el-option>
                        <el-option  key="1" label="关" :value="0"  ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态" prop="shopStatus">
                    <el-select v-model="searchForm.shopStatus" placeholder="请选择" style="width:100px">
                        <el-option  key="-1" label="全部" :value="null"  ></el-option>
                        <el-option  key="0" label="已启用" :value="0"  ></el-option>
                        <el-option  key="1" label="已冻结" :value="1"  ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="付款码状态" prop="shopStatus">
                    <el-select v-model="searchForm.payStatus" placeholder="请选择" style="width:100px">
                        <el-option  key="-1" label="全部" :value="null"  ></el-option>
                        <el-option  key="0" label="待上传" :value="0"  ></el-option>
                        <el-option  key="1" label="待验证" :value="1"  ></el-option>
                        <el-option  key="2" label="已验证" :value="2"  ></el-option>
                        <el-option  key="3" label="验证无效" :value="3"  ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否历史商家" prop="shopStatus">
                    <el-select v-model="searchForm.historyShop" placeholder="请选择" style="width:100px">
                        <el-option  key="-1" label="全部" :value="null"  ></el-option>
                        <el-option  key="1" label="是" :value="1"  ></el-option>
                        <el-option  key="0" label="否" :value="0"  ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="设备安装" prop="shopStatus">
                    <el-select v-model="searchForm.equipmentType" placeholder="请选择" style="width:100px">
                        <el-option  key="null" label="全部" :value="null"  ></el-option>
                        <el-option  key="1" label="简易缸" :value="1"  ></el-option>
                        <el-option  key="0" label="油水分离器" :value="0"  ></el-option>
                        <el-option  key="-1" label="未安装" :value="-1"  ></el-option>
                    </el-select>
                </el-form-item>

                <section>
                    <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
                    <el-button @click="reset">重置</el-button>
                </section>
                </el-form>

                <template v-slot:table-wrap>
                <ul class="toolbar"  v-if="userInfo.currentMcRoleStatus==0&&currentRole!=roles.treasurer&&currentRole!=roles.warehouseman&&currentRole!=roles.driver">
                    <li><el-button icon="el-icon-plus" type="primary" @click="$refs.operaMerchantWindow.open('新增商家/预备商家',null,{historyShop:false,type:0})">新增商家/预备商家</el-button></li>
                    <li><el-button icon="el-icon-plus" type="primary" @click="$refs.operaMerchantWindow.open('新增历史商家',null,{historyShop:true,type:1})">新增历史商家</el-button></li>
                    <li><el-button icon="el-icon-delete" type="primary" @click="removeToRecycle()">放入回收站</el-button></li>                  
                    <li ><el-button icon="el-icon-download" type="primary" @click="exportTable(exportObj1)">导出</el-button></li>
                </ul>
                <el-table
                    v-loading="isWorking.search"
                    :data="tableData.list"
                    row-key="id"
                    :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                    
                    stripe
                    @selection-change="handleSelectionChange"
                    @sort-change="handleSortChange"
                >
                    <el-table-column type="selection" width="55px"></el-table-column>
                    <el-table-column prop="shopLabelName" label="分组" width="50px" align="center" show-overflow-tooltip  >
                        <div slot-scope="{row}">
                            <span :style="{background:row.shopLabelColour,display:'inline-block',padding:'0px 5px',borderRadius:'5px',fontWeight:'bold'}">{{ row.shopLabelName }}</span>
                          
                        </div>
                    </el-table-column>
                    <el-table-column prop="adminUserAccount" label="账号" min-width="140px" align="center">
                        <div slot-scope="{row}">
                            <div v-if="row.isTransfer" style="width:22px;height:22px;border-radius:50%;color:red;border:1px solid red;font-size:12px;display:inline-flex;align-items:center;justify-content:center;padding-top:3px;">移</div>
                         <!-- <img v-if="row.historyShop" src="../../../assets/images/historyShop.png" alt="" srcset="" style="width:18px;height:16px;margin-right:2px;">  -->
                        {{row.adminUserAccount == null ? '-' : row.adminUserAccount}}
                        </div>
                    </el-table-column>
                    <el-table-column prop="adminUserName" label="管理员姓名" min-width="100px"  align="center" ></el-table-column>

                    <el-table-column prop="name" label="商家名称" min-width="220px" align="center" :show-overflow-tooltip="true">
                        <div slot-scope="{row}" class="position:relative">
                            <!-- <div style="position:absolute;top:1px;"> -->
                                <img v-if="row.isCoreUser" src="../../../assets/images/isCore.png" alt="" srcset="" style="width:18px;height:18px;margin-right:2px;">
                                <img v-if="row.historyShop" src="../../../assets/images/historyShop.png" alt="" srcset="" style="width:18px;height:16px;margin-right:4px;">
                                <img v-if="row.multiShop" src="../../../assets/images/multiShop.png" alt="" srcset=""  style="width:18px;height:18px;margin-right:4px;cursor:pointer;" @click="$refs.linkedShopListWindow.open(row)"> 
                                <img v-if="row.isPartTimeBusiness" src="../../../assets/images/isPartTime.png" alt="" srcset=""  style="width:18px;height:18px;margin-right:4px;">
                            <!-- </div> -->
                            <span >{{row.name == null ? '-' : row.name}}</span>
                            <img  style="width:18px;height:18px;margin-right:2px;" v-if="row.equipmentType == 1" src="https://zlt-2021.oss-cn-zhangjiakou.aliyuncs.com/mcly/2023/09/05/jianyi.png" />
                            <img  style="width:18px;height:18px;margin-right:2px;" v-if="row.equipmentType == 0" src="https://zlt-2021.oss-cn-zhangjiakou.aliyuncs.com/mcly/2023/09/05/youshui.png" />
                            <img  style="width:18px;height:18px;margin-right:2px;" v-if="row.equipmentType == -1" src="https://zlt-2021.oss-cn-zhangjiakou.aliyuncs.com/mcly/2023/09/05/weianzhuang.png" />
                            
                        </div>
                    </el-table-column>
                    <el-table-column prop="createTime" label="新增时间" min-width="90px" align="center" ></el-table-column>
                    <el-table-column prop="saleUserName" label="所属销售" min-width="100px"  align="center" >
                        <div slot-scope="{row}">
                            <span>{{row.saleUserName || '待分配'}}</span>   
                        </div>
                    </el-table-column>
                    <!-- <el-table-column prop="shopLabelName" label="分组" min-width="80px" align="center" show-overflow-tooltip  >
                        <div slot-scope="{row}">                            
                            <span >{{row.shopLabelName || '-'}}</span>   
                        </div>
                    </el-table-column>
                     -->
                    <el-table-column prop="saleUserAccount" label="销售电话" min-width="100px" align="center"  ></el-table-column>
                    <el-table-column prop="latelyTransportTime" label="最后一次拉油时间" min-width="100px" align="center" sortable='custom'  >
                         <div slot-scope="{row}">
                            <span>{{ lastTimeFn(row.latelyTransportTime)}}</span>   
                        </div>
                    </el-table-column>
                    <el-table-column prop="openPay" label="现金支付开关" min-width="100px" align="center"  >
                        <div slot-scope="{row,$index,store}">
                            <el-switch v-model="row.openPay"  :disabled="row.openPay || userInfo.currentMcRoleStatus==1 || currentRole==roles.treasurer" @change="updateStatus(row,$index,store)"></el-switch>
                        </div>
                    </el-table-column>
                    <el-table-column prop="payCode" label="收款码" min-width="150px" align="center"  >
                        <div slot-scope="{row}" style="display:flex;align-items: center;" v-if="row.openPay">
                            <div v-if="row.payCode">
                            <el-image  
                                style="width: 40px; height: 40px"
                                :src="row.payCode" 
                                :preview-src-list="[row.payCode]">
                            </el-image> <br>
                        </div>
                        <el-upload
                            v-if="currentRole == 7 || currentRole == 8"
                            class="upload-demo"
                            action=""                         
                            :http-request="(options)=>updateQrCode(options,row)"                           
                            :before-upload="beforeUpload"
                            :limit="3"
                            :show-file-list="false">
                            <span  style="padding:0 8px">{{row.payCode ? '更换' : '上传'}}</span>                           
                        </el-upload>

                     
                            <div style="display:flex;flex-direction:column;margin-left:2px;cursor:pointer" v-if="row.payCode" @click="row.payStatus==1&&userInfo.currentMcRoleStatus==0?$refs.operaVerifyPayCodeWindow.open('收款码验证',row):''">
                                <i :class="row.payStatus==1?'el-icon-question':row.payStatus==2?'el-icon-check':row.payStatus==3?'el-icon-close':'el-icon-minus'" :style="{color:row.payStatus==1?'#F59A23':row.payStatus==2?'green':row.payStatus==3?'red':'','font-size':'28px'}" ></i>
                                <span>{{row.payStatus==1?'待验证':row.payStatus==2?'已验证':row.payStatus==3?'无效码':'待上传'}}</span>
                            </div>
                        </div>
                        <div v-else>-</div>
                        
                    </el-table-column>
                    <el-table-column prop="status" label="状态" min-width="80px" align="center"  >
                        <div slot-scope="{row}">
                            <span :style="{color:row.status==0?'green':row.status==1?'#F59A23':row.status==2?'red':''}" >{{row.status==0?'已启用':row.status==1?'已冻结':row.status==2?'已禁用':''}}</span>
                            
                            <!-- <i class="el-icon-edit" style="margin-left:4px;color:#2E68EC;" v-if="row.status!=null&&userInfo.currentMcRoleStatus==0" @click="$refs.operaUserStatusWindow.open('账户冻结',row,{businessRoleId:2,status:row.status==0?1:0})"></i> -->
                        </div>
                    </el-table-column>
                    <el-table-column prop="remarks" label="备注" min-width="100px" >
                        <div slot-scope="{row}">
                            <span>{{row.remarks || '/'}}</span>
                            <!-- <i class="el-icon-edit" style="margin-left:4px;color:#2E68EC;" @click="$refs.remarkUpdateWindow.open(row,0)"></i> -->
                        </div>
                    </el-table-column>
                    <el-table-column prop="address" label="地址" min-width="150px" :show-overflow-tooltip="true" align="center" >
                        <div slot-scope="{row}">{{(row.address?row.address:'')+(row.addressName?row.addressName:'')}}</div>
                    </el-table-column>
                    <el-table-column prop="streetOfficeName" label="所属街道办" min-width="100px" align="center">
                    <div slot-scope="{row}">
                        <!-- <div v-if="row.streetOfficeName">{{row.streetOfficeName}}</div> -->
                        <div >
                        <span>{{row.streetOfficeName || '-'}}</span>
                        </div>    
                    </div>
                    </el-table-column>
                    <el-table-column prop="areaName" label="业务区域" min-width="150px" :show-overflow-tooltip="true" align="center" >
                        <div slot-scope="{row}">
                            <span v-if="row.areaName">{{row.areaName}}</span>
                            <el-tag v-else type="danger">异常</el-tag>
                        </div>
                    </el-table-column>
                    <el-table-column
                    v-if="userInfo.currentMcRoleStatus==0&&currentRole!=roles.treasurer"
                    label="操作"
                    width="270"
                    fixed="right"
                    >
                    <div  slot-scope="{row}" >
                        <el-button  type="text" icon="el-icon-edit" @click="$refs.operaMerchantWindow.open('补充/修改信息',row,{historyShop:row.historyShop,type:2})">补充/修改信息</el-button>
                        <el-dropdown >
                            <el-button type="text" >
                                <i class=" el-icon-more"></i>更多操作
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="a" v-if="row.status==0"><el-button  type="text" icon="el-icon-moon" @click="$refs.operaUserStatusWindow.open('账户冻结',row,{businessRoleId:2,status:1})">冻结</el-button></el-dropdown-item>
                                <el-dropdown-item command="a"  v-if="row.status==1"><el-button  type="text" icon="el-icon-switch-button" style="color:green" @click="$refs.operaUserStatusWindow.open('账户解冻',row,{businessRoleId:2,status:0})">解冻</el-button></el-dropdown-item>
                                <el-dropdown-item command="a"><el-button  type="text" icon="el-icon-share" @click="$refs.operaShopAdminChangeWindow.open('移交账号',row,2)">账号移交</el-button></el-dropdown-item>
                                <el-dropdown-item command="a" v-if="row.isSelfAccount" ><el-button  type="text" icon="el-icon-view" @click="$refs.shopStaffsWindow.open(row.id,row.name)">查看子账号</el-button></el-dropdown-item>

                            </el-dropdown-menu>
                        </el-dropdown>
                        <el-button  type="text" icon="el-icon-notebook-2" @click="$refs.operaRecordWindow.open(2,row,1)">操作记录</el-button>
                    </div>
                    </el-table-column>
                </el-table>
                <pagination
                    @size-change="handleSizeChange"
                    @current-change="handlePageChange"
                    :pagination="tableData.pagination"
                ></pagination>
                </template>
                
                
            </TableLayout>
        </el-tab-pane>
        <el-tab-pane label="预备商家列表" name="second">
            <TableLayout  :withBreadcrumb="false" v-if="activeName=='second'">
                
                <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
                <el-form-item label="邀约人" prop="invitationUser">
                    <el-input v-model="searchForm.invitationUser" v-trim placeholder="请输入姓名、昵称、手机号" @keypress.enter.native="search" style="width:250px"/>
                </el-form-item>
                <!-- <el-form-item label="商家名称" prop="shopName">
                    <el-input v-model="searchForm.shopName" v-trim placeholder="请输入商家名称" @keypress.enter.native="search" style="width:250px"/>
                </el-form-item> -->
                <el-form-item label="受邀人" prop="beInvitedUser">
                    <el-input v-model="searchForm.beInvitedUser" v-trim placeholder="请输入姓名、昵称、手机号" @keypress.enter.native="search" style="width:250px"/>
                </el-form-item>
                <el-form-item label="编辑状态" prop="status">
                    <el-select v-model="searchForm.status" placeholder="请选择" style="width:100px">
                        <el-option  key="-1" label="全部" :value="null"  ></el-option>
                        <el-option  key="1" label="已编辑" :value="1"  ></el-option>
                        <el-option  key="0" label="未编辑" :value="0"  ></el-option>
                    </el-select>
                </el-form-item>

                <section>
                    <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
                    <el-button @click="reset">重置</el-button>
                </section>
                </el-form>

                <template v-slot:table-wrap>
                    <ul  class="toolbar" v-if="userInfo.currentMcRoleStatus==0">
                        <li ><el-button icon="el-icon-download" type="primary" @click="exportTable(exportObj2)">导出</el-button></li>
                    </ul>
                <el-table
                    v-loading="isWorking.search"
                    :data="tableData.list"
                    stripe
                    @selection-change="handleSelectionChange"
                    @sort-change="handleSortChange"
                >
                    <el-table-column type="selection" width="55px"></el-table-column>

                    <el-table-column prop="id" label="ID" min-width="100px" >
                        <div slot-scope="{row}">
                            <span>{{row.id}}</span>
                        </div>
                    </el-table-column>
                    <el-table-column prop="beInvitedPhone" label="受邀人手机" min-width="100px" ></el-table-column>

                    <el-table-column prop="beInvitedName" label="受邀人昵称" min-width="120px">
                        <div slot-scope="{row}">
                            <el-tag type="warning" style="margin-right:6px" v-if="row.isMainteined">已维护</el-tag>
                            <span>{{row.beInvitedName?row.beInvitedName:'-'}}</span>
                        </div>
                    </el-table-column>
                    <!-- <el-table-column prop="createTime" label="受邀人姓名" min-width="100px" ></el-table-column>
                    <el-table-column prop="createTime1" label="商家名称" min-width="100px" ></el-table-column> -->
                    <el-table-column prop="invitationUserPhone" label="最近邀约人手机" min-width="100px" ></el-table-column>
                    <el-table-column prop="invitationNickName" label="最近邀约人昵称" min-width="100px" ></el-table-column>
                    <el-table-column prop="invitationUserName" label="最近邀约人姓名" min-width="100px" ></el-table-column>
                    <el-table-column prop="createTime" label="最近时间" min-width="100px" ></el-table-column>
                    <el-table-column prop="beInvitedNum" label="累计受邀次数" min-width="100px" >
                        <div slot-scope="{row}">
                            <el-button type="text" @click="$refs.inviterListWindow.open(row)">{{row.beInvitedNum || 0}}</el-button>
                        </div>
                    </el-table-column>
                    <el-table-column prop="remark" label="备注" min-width="100px" >
                        <div slot-scope="{row}">
                            <span>{{row.remark || '/'}}</span>
                            <i class="el-icon-edit" v-if="userInfo.currentMcRoleStatus==0" style="margin-left:4px;color:#2E68EC;" @click="$refs.remarkUpdateWindow.open(row,1)"></i>
                        </div>
                    </el-table-column>
                    <el-table-column
                    v-if="userInfo.currentMcRoleStatus==0"
                    label="操作"
                    width="270"
                    fixed="right"
                    >
                    <div  slot-scope="{row}" >
                        <el-button  type="text" icon="el-icon-edit"  @click="$refs.operaMerchantWindow.open('编辑邀约',row,{historyShop:row.historyShop,type:3})">编辑邀约</el-button>
                        <el-button  type="text" icon="el-icon-notebook-2"  @click="$refs.operaRecordWindow.open(2,row,1)">操作记录</el-button>
                    </div>
                    </el-table-column>
                </el-table>
                <pagination
                    @size-change="handleSizeChange"
                    @current-change="handlePageChange"
                    :pagination="tableData.pagination"
                ></pagination>
                </template>
            </TableLayout>
        </el-tab-pane>
        <el-tab-pane label="游客列表" name="third">
            <TableLayout  :withBreadcrumb="false" v-if="activeName=='third'">
                <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
                <el-form-item label="游客" prop="keywords">
                    <el-input v-model="searchForm.keywords" v-trim placeholder="请输入姓名、昵称、手机号" @keypress.enter.native="search" style="width:250px"/>
                </el-form-item>

                <section>
                    <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
                    <el-button @click="reset">重置</el-button>
                </section>
                </el-form>

                <template v-slot:table-wrap>
                    <ul  class="toolbar" v-if="userInfo.currentMcRoleStatus==0">
                        <li ><el-button icon="el-icon-download" type="primary" @click="exportTable(exportObj3)">导出</el-button></li>
                    </ul>
                <el-table
                    v-loading="isWorking.search"
                    :data="tableData.list"
                    stripe
                    @selection-change="handleSelectionChange"
                    @sort-change="handleSortChange"
                >
                    <el-table-column type="selection" width="55px"></el-table-column>

                    <el-table-column prop="account" label="账号" min-width="100px" ></el-table-column>
                    <el-table-column prop="nickName" label="微信昵称" min-width="100px" ></el-table-column>

                    <el-table-column prop="userName" label="游客姓名" min-width="120px">
                    <div slot-scope="{row}">{{row.userName == null ? '-' : row.userName}}</div>
                    </el-table-column>
                    <el-table-column prop="lastVisitTime" label="最后访问时间" min-width="100px" ></el-table-column>
                    <el-table-column prop="remarks" label="备注" min-width="100px" >
                        <div slot-scope="{row}">
                            <span>{{row.remarks?row.remarks:'/'}}</span>
                            <i class="el-icon-edit" v-if="userInfo.currentMcRoleStatus==0" style="margin-left:4px;color:#2E68EC;" @click="$refs.remarkUpdateWindow.open(row,2)"></i>
                        </div>
                    </el-table-column>

                    <el-table-column
                    v-if="userInfo.currentMcRoleStatus==0"
                    label="操作"
                    width="270"
                    fixed="right"
                    >
                    <div  slot-scope="{row}" >
                        <el-button  type="text" icon="el-icon-s-promotion" @click="$refs.operaMerchantWindow.open('新增预备商家',row,{historyShop:false,type:4})">编辑邀约信息&发起邀约</el-button>
                        <el-button  type="text" icon="el-icon-notebook-2"  @click="$refs.operaRecordWindow.open(2,row,1)">操作记录</el-button>
                    </div>
                    </el-table-column>
                </el-table>
                <pagination
                    @size-change="handleSizeChange"
                    @current-change="handlePageChange"
                    :pagination="tableData.pagination"
                ></pagination>
                </template>
            </TableLayout>
        </el-tab-pane>
        <el-tab-pane label="商家回收站列表" name="fourth" >
            <TableLayout  :withBreadcrumb="false" v-if="activeName=='fourth'">
                
                <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
                <el-form-item label="商家管理员" prop="shopAdmin">
                    <el-input v-model="searchForm.shopAdmin" v-trim placeholder="请输入账号(手机号)/姓名搜索" @keypress.enter.native="search" style="width:250px"/>
                </el-form-item>
                <el-form-item label="商家名称" prop="shopName">
                    <el-input v-model="searchForm.shopName" v-trim placeholder="请输入商家名称" @keypress.enter.native="search" style="width:250px"/>
                </el-form-item>
                <el-form-item label="所属销售" prop="sale">
                    <el-input v-model="searchForm.sale" v-trim placeholder="请输入账号(手机号)/姓名搜索" @keypress.enter.native="search" style="width:250px"/>
                </el-form-item>
                <el-form-item label="现金开关" prop="openPay">
                    <el-select v-model="searchForm.openPay" placeholder="请选择" style="width:100px">
                        <el-option  key="-1" label="全部" :value="null"  ></el-option>
                        <el-option  key="0" label="开" :value="1"  ></el-option>
                        <el-option  key="1" label="关" :value="0"  ></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="状态" prop="shopStatus">
                    <el-select v-model="searchForm.shopStatus" placeholder="请选择" style="width:100px">
                        <el-option  key="-1" label="全部" :value="null"  ></el-option>
                        <el-option  key="0" label="已启用" :value="0"  ></el-option>
                        <el-option  key="1" label="已冻结" :value="1"  ></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="付款码状态" prop="shopStatus">
                    <el-select v-model="searchForm.payStatus" placeholder="请选择" style="width:100px">
                        <el-option  key="-1" label="全部" :value="null"  ></el-option>
                        <el-option  key="0" label="待上传" :value="0"  ></el-option>
                        <el-option  key="1" label="待验证" :value="1"  ></el-option>
                        <el-option  key="2" label="已验证" :value="2"  ></el-option>
                        <el-option  key="3" label="验证无效" :value="3"  ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否历史商家" prop="shopStatus">
                    <el-select v-model="searchForm.historyShop" placeholder="请选择" style="width:100px">
                        <el-option  key="-1" label="全部" :value="null"  ></el-option>
                        <el-option  key="1" label="是" :value="1"  ></el-option>
                        <el-option  key="0" label="否" :value="0"  ></el-option>
                    </el-select>
                </el-form-item>

                <section>
                    <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
                    <el-button @click="reset">重置</el-button>
                </section>
                </el-form>

                <template v-slot:table-wrap>
                <ul class="toolbar"  v-if="userInfo.currentMcRoleStatus==0&&currentRole!=roles.treasurer&&currentRole!=roles.warehouseman&&currentRole!=roles.driver">
                    <!-- <li><el-button icon="el-icon-plus" type="primary" @click="$refs.operaMerchantWindow.open('新增商家/预备商家',null,{historyShop:false,type:0})">新增商家/预备商家</el-button></li> -->
                    <!-- <li><el-button icon="el-icon-plus" type="primary" @click="$refs.operaMerchantWindow.open('新增历史商家',null,{historyShop:true,type:1})">新增历史商家</el-button></li> -->
                    <li><el-button icon="el-icon-delete" type="primary" @click="removeFromRecycle()">移出回收站</el-button></li>                  
                    <!-- <li ><el-button icon="el-icon-download" type="primary" @click="exportTable(exportObj1)">导出</el-button></li> -->
                </ul>
                <el-table
                    v-loading="isWorking.search"
                    :data="tableData.list"
                    row-key="id"
                    :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                    
                    stripe
                    @selection-change="handleSelectionChange"
                    @sort-change="handleSortChange"
                >
                    <el-table-column type="selection" width="55px"></el-table-column>

                    <el-table-column prop="adminUserAccount" label="账号" min-width="140px" align="center">
                        <div slot-scope="{row}">
                            <div v-if="row.isTransfer" style="width:22px;height:22px;border-radius:50%;color:red;border:1px solid red;font-size:12px;display:inline-flex;align-items:center;justify-content:center;padding-top:3px;">移</div>
                         <!-- <img v-if="row.historyShop" src="../../../assets/images/historyShop.png" alt="" srcset="" style="width:18px;height:16px;margin-right:2px;">  -->
                        {{row.adminUserAccount == null ? '-' : row.adminUserAccount}}
                        </div>
                    </el-table-column>
                    <el-table-column prop="adminUserName" label="管理员姓名" min-width="100px"  align="center" ></el-table-column>

                    <el-table-column prop="name" label="商家名称" min-width="220px" align="center" :show-overflow-tooltip="true">
                        <div slot-scope="{row}" class="position:relative">
                            <!-- <div style="position:absolute;top:1px;"> -->
                                <img v-if="row.isCoreUser" src="../../../assets/images/isCore.png" alt="" srcset="" style="width:18px;height:18px;margin-right:2px;">
                                <img v-if="row.historyShop" src="../../../assets/images/historyShop.png" alt="" srcset="" style="width:18px;height:16px;margin-right:4px;">
                                <img v-if="row.multiShop" src="../../../assets/images/multiShop.png" alt="" srcset=""  style="width:18px;height:18px;margin-right:4px;cursor:pointer;" @click="$refs.linkedShopListWindow.open(row)"> 
                                <img v-if="row.isPartTimeBusiness" src="../../../assets/images/isPartTime.png" alt="" srcset=""  style="width:18px;height:18px;margin-right:4px;">
                            <!-- </div> -->
                            <span >{{row.name == null ? '-' : row.name}}</span>
                        </div>
                    </el-table-column>
                    <el-table-column prop="createTime" label="新增时间" min-width="90px" align="center" ></el-table-column>
                    <el-table-column prop="saleUserName" label="所属销售" min-width="100px"  align="center" >
                        <div slot-scope="{row}">
                            <span>{{row.saleUserName || '待分配'}}</span>   
                        </div>
                    </el-table-column>
                    <el-table-column prop="saleUserAccount" label="销售电话" min-width="100px" align="center"  ></el-table-column>
                    <el-table-column prop="latelyTransportTime" label="最后一次拉油时间" min-width="100px" align="center" sortable='custom'  >
                         <div slot-scope="{row}">
                            <span>{{ lastTimeFn(row.latelyTransportTime)}}</span>   
                        </div>
                    </el-table-column>
                    <el-table-column prop="openPay" label="现金支付开关" min-width="100px" align="center"  >
                        <div slot-scope="{row,$index,store}">
                            <el-switch v-model="row.openPay"  :disabled="row.openPay || userInfo.currentMcRoleStatus==1 || currentRole==roles.treasurer" @change="updateStatus(row,$index,store)"></el-switch>
                        </div>
                    </el-table-column>                    
                    <el-table-column prop="remarks" label="备注" min-width="100px" >
                        <div slot-scope="{row}">
                            <span>{{row.remarks || '/'}}</span>
                            <!-- <i class="el-icon-edit" style="margin-left:4px;color:#2E68EC;" @click="$refs.remarkUpdateWindow.open(row,0)"></i> -->
                        </div>
                    </el-table-column>
                    <el-table-column prop="address" label="地址" min-width="150px" :show-overflow-tooltip="true" align="center" >
                        <div slot-scope="{row}">{{(row.address?row.address:'')+(row.addressName?row.addressName:'')}}</div>
                    </el-table-column>
                    <el-table-column prop="areaName" label="业务区域" min-width="150px" :show-overflow-tooltip="true" align="center" >
                        <div slot-scope="{row}">
                            <span v-if="row.areaName">{{row.areaName}}</span>
                            <el-tag v-else type="danger">异常</el-tag>
                        </div>
                    </el-table-column>                  
                </el-table>
                <pagination
                    @size-change="handleSizeChange"
                    @current-change="handlePageChange"
                    :pagination="tableData.pagination"
                ></pagination>
                </template>
                
                
            </TableLayout>
        </el-tab-pane>
    </el-tabs>
    <!-- 新建/修改 -->
    <OperaMerchantWindow ref="operaMerchantWindow" @success="handlePageChange(tableData.pagination.pageIndex)"/>
    <!-- 冻结解冻   -->
    <OperaUserStatusWindow ref="operaUserStatusWindow" @success="handlePageChange(tableData.pagination.pageIndex)"></OperaUserStatusWindow>
    <!-- 移交 -->
    <OperaShopAdminChangeWindow  ref="operaShopAdminChangeWindow" @success="handlePageChange(tableData.pagination.pageIndex)"></OperaShopAdminChangeWindow>
    <!-- 验证收款码 -->
    <OperaVerifyPayCodeWindow   ref="operaVerifyPayCodeWindow" width="400px" @success="handlePageChange(tableData.pagination.pageIndex)"></OperaVerifyPayCodeWindow>
    <!-- 操作记录 -->
    <OperaRecordWindow ref="operaRecordWindow"></OperaRecordWindow>
    <!-- 查看当前店铺子账号 -->
    <ShopStaffsWindow  ref="shopStaffsWindow"></ShopStaffsWindow>
    <!-- 邀约次数明细 -->
    <InviterListWindow ref="inviterListWindow"></InviterListWindow>
    <!-- 填写/更新备注 -->
    <RemarkUpdateWindow  ref="remarkUpdateWindow"  @success="handlePageChange(tableData.pagination.pageIndex)"></RemarkUpdateWindow>
    <!-- 关联商家列表 -->
    <LinkedShopListWindow ref="linkedShopListWindow"  @success="handlePageChange(pageIndex)"></LinkedShopListWindow>
    
</div>

  
</template>

<script>
import Pagination from '@/components/common/Pagination'
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import OperaMerchantWindow from '../../../components/layun/accountManagement/OperaMerchantWindow.vue'
import OperaUserStatusWindow from '../../../components/layun/accountManagement/OperaUserStatusWindow.vue'
import OperaShopAdminChangeWindow from '../../../components/layun/accountManagement/OperaShopAdminChangeWindow.vue'
import OperaVerifyPayCodeWindow from '../../../components/layun/accountManagement/OperaVerifyPayCodeWindow.vue'
import OperaRecordWindow from '../../../components/layun/common/OperaRecordWindow.vue'
import ShopStaffsWindow from '../../../components/layun/accountManagement/ShopStaffsWindow.vue'
import InviterListWindow from '../../../components/layun/accountManagement/InviterListWindow.vue'
import LinkedShopListWindow from '../../../components/layun/accountManagement/LinkedShopListWindow.vue'

import RemarkUpdateWindow from '../../../components/layun/common/RemarkUpdateWindow.vue'
import {uploadImage} from '../../../api/common'
import {updateById , updateSonCollectionCode , removeToRecycle , removeFromRecycle} from '../../../api/layun/accountManagement/shop'

import { mapState } from 'vuex'
import {fetchAreaList} from  "../../../api/layun/basicDataManagement/bussinessArea"
import {getGroupData} from "../../../api/layun/basicDataManagement/groupData"
import {fetchStreetList} from  "../../../api/layun/basicDataManagement/subDistrictOfficeData"

// import { fetchList } from '@/api/system/user'
const filter1 = {
    listType:1,// 1商家管理员列表 2 预备商家列表 3游客列表
    historyShop:null,
    openPay: null,
    payStatus: null,
    sale: "",
    shopAdmin: "",
    shopName: "",
    sortType:0,
    shopStatus: null,
    recycle:false ,// 是否回收站
    equipmentType:null
      
}
const filter2 = {
    listType:2,
    beInvitedUser: "",
    invitationUser: "",
    shopName: "",
    status: null
      
}
const filter3 = {
    listType:3,
    historyShop:null,
    openPay: null,
    payStatus: null,
    sale: "",
    shopAdmin: "",
    shopName: "",
    shopStatus: null
      
}
const filter4 = {
    listType:1,// 1商家管理员列表 2 预备商家列表 3游客列表 
    openPay: null,
    payStatus: null,
    sale: "",
    shopAdmin: "",
    shopName: "",
    sortType:0,
    shopStatus: null,
    recycle:true ,// 是否回收站
      
}

export default {
  name: 'ManageUserList',
  extends: BaseTable,
  components: {  TableLayout, Pagination,OperaMerchantWindow,OperaUserStatusWindow ,  OperaShopAdminChangeWindow ,OperaVerifyPayCodeWindow , OperaRecordWindow , ShopStaffsWindow , InviterListWindow , RemarkUpdateWindow , LinkedShopListWindow , },
  computed: {
    paths () {
      return this.$route.meta.paths
    },
    ...mapState({
        userInfo:state=>state.init.userInfo || {},
        currentRole:state=>state.init.userInfo.currentRole,
        roles:state=>state.init.roles
    })
  },
  data () {
    return {
        activeName: 'first',
      // 搜索
      searchForm:{
        ...filter1
      },
      exportObj1:{
        tHeader:['账号','管理员姓名','商家名称','新增时间','所属销售','销售电话','现金支付','收款码','收款码状态','状态','备注','地址','业务区域','是否历史商家','是否关联商家','是否兼职商家'],
        filterVal:[
            {key:'adminUserAccount'},
            {key:'adminUserName',},
            {key:'name',},
            {key:'createTime',},
            {key:'saleUserName',},
            {key:'saleUserAccount',},
            {key:'openPay',type:2,item:[{value:true,label:'开'},{value:false,label:'关'}]},
            {key:'payCode',},
            {key:'payStatus',type:2,item:[{value:1,label:'待验证'},{value:2,label:'已验证'},{value:3,label:'无效码'}]},
            {key:'status',type:2,item:[{value:0,label:'已启用'},{value:1,label:'已冻结'},{value:2,label:'已禁用'},{value:3,label:''}]},
            {key:'remarks',},
            {key:'address',type:3,item:['address','addressName']},
            {key:'areaName', default:'异常'},
            {key:'historyShop',type:2,default:'否' ,item:[{value:true,label:'是'},{value:false,label:'否'}]},
            {key:'multiShop',type:2,default:'否' ,item:[{value:true,label:'是'},{value:false,label:'否'}]},
            {key:'isPartTimeBusiness',type:2,default:'否' ,item:[{value:true,label:'是'},{value:false,label:'否'}]},  
        ],
        fileName:'商家管理员列表',
      },
      exportObj2:{
        tHeader:['ID','受邀人手机','受邀人昵称','最近邀约人手机','最近邀约人昵称','最近邀约人姓名','最近时间','累计受邀次数','备注','是否已维护'],
        filterVal:[
        {key:'id'},
        {key:'beInvitedPhone',},
        {key:'beInvitedName',},
        {key:'invitationUserPhone',},
        {key:'invitationNickName',},
        {key:'invitationUserName',},
        {key:'createTime',},
        {key:'beInvitedNum',},
        {key:'remark'},
        {key:'isSigning',type:2,default:'否' ,item:[{value:true,label:'是'},{value:false,label:'否'}]},
        ],
        fileName:'预备商家列表',
      },
      exportObj3:{
        tHeader:['账号','微信昵称','游客姓名','最后访问时间','备注'],
        filterVal:[
        {key:'account'},
        {key:'nickName',},
        {key:'userName',},
        {key:'updateTime',},
        {key:'remarks',},
        ],
        fileName:'游客列表',
      },
      allAreaList:[],
      allStreetList:[],
      allGroupData:[]

    }
  },
  watch:{
    'searchForm.roleId':{
      handler(val,old){
        if(val!=old){
          this.search()
        }
      }
    }
  },
  created () {
    this.config({
      module: '账号',
      api: '/layun/accountManagement/shop',
      'field.main': 'account',
      sorts: []
    })
    this.search()
    this.getGroupData()
  },
  methods:{
    getGroupData(){
      var params = {"page":1,"capacity":200,"model":{"name":null,"chargePeopleNamePhone":null},"sorts":[{"property":"CREATE_TIME","direction":"DESC"}]}
      getGroupData(params).then(data=>{
        this.allGroupData = data.records || []
      })
    },
    querySearchArea(queryString){
            if(!queryString){
                return 
            }
            var params = {"page":1,"capacity":20,"model":{
                "regionName":queryString,"areaArr":[],"provinceId":"","cityId":"","provinces":"","provinceName":"","cityName":"","districtId":"","districtName":"","adCode":""}}
            fetchAreaList(params).then(data => {
                console.log(data,'---')
                var list = data.records || []                       
                this.allAreaList = list
               
            })

    },
    querySearchStreet(queryString){
            if(!queryString){
                return 
            }
            var params ={"page":1,"capacity":20,"model":{"areaName":queryString,"areaArr":[],"provinceId":"","cityId":"","provinces":"","provinceName":"","cityName":"","districtId":"","districtName":"","adCode":""},"sorts":[{"property":"CREATE_TIME","direction":"DESC"}]}
            fetchStreetList(params).then(data => {
                console.log(data,'---')
                var list = data.records || []                       
                this.allStreetList = list
               
            })

    },
    removeToRecycle(item){
        console.log(this.tableData)
        var selectedRows = this.tableData.selectedRows
        if(!selectedRows || selectedRows.length == 0){
            this.$message({
            type: 'info',
            message: '请选择商家'
          });  
            return 
        }         
        var params = {
            shopIds:selectedRows.map(item=>item.id)
        }
        removeToRecycle(params)
            .then(() => {
                this.visible = false
                this.$tip.apiSuccess('修改成功')
                this.handlePageChange(this.tableData.pagination.pageIndex)
            })
            .catch(e => {
                this.$tip.apiFailed(e,false)
            })
            .finally(() => {
                this.isWorking.search = false
                
            })
        
    },
    removeFromRecycle(){
        var selectedRows = this.tableData.selectedRows
        if(!selectedRows || selectedRows.length == 0){
            this.$message({
            type: 'info',
            message: '请选择商家'
          });  
            return 
        }         
        var params = {
            shopIds:selectedRows.map(item=>item.id)
        }
        removeFromRecycle(params)
            .then(() => {
                this.visible = false
                this.$tip.apiSuccess('修改成功')
                this.handlePageChange(this.tableData.pagination.pageIndex)
            })
            .catch(e => {
                this.$tip.apiFailed(e,false)
            })
            .finally(() => {
                this.isWorking.search = false
                
            })
    },
    beforeUpload(file){
        return true
    },
     updateQrCode(options,row){
        console.log(options,row,'更换收款二维码')
        let formData = new FormData();
        formData.append('file',options.file);
        let size = options.file.size
         uploadImage(formData).then((res) => {
            console.log(res,'成功',row)
            //更换二维码 并且刷新页面
            var params = { 
                payCode:res,
                "shopId":row.id
            }
             updateSonCollectionCode(params).then(resT=>{                  
                    this.search()                                             
            }).catch(e=>{
                this.$message({
                type: 'info',
                message: '修改失败!'
            });   
            })

        }).catch(e=>{
            this.$message({
            type: 'info',
            message: '上传失败!'
          });  
        }).finally(()=>{
         
        })
    },
    handleSortChange(sortData){
        //商家列表排序fn
        this.tableData.sorts = []
        let arr = [
            {value:0,prop:'',tit:'默认'},
            {value:1,prop:'latelyTransportTime',tit:'最后一次拉油时间'}
           
        ]
          let param = {
        sort:1,
        sortType:0,
      }
      console.log('sortData.prop',sortData.prop,sortData);
       if (sortData.order != null) {
          param.sort = sortData.order === 'descending' ? 1 : 2
          let index =  arr.findIndex(i=>i.prop==sortData.prop)
          param.sortType = index==-1?0:arr[index].value
          this.searchForm = {
            ...this.searchForm,
            ...param
          }
      }else{
        this.searchForm.sortType = 0
        this.searchForm.sort = 1
      }   
      this.handlePageChange(this.tableData.pagination.pageIndex)
     
    },
    lastTimeFn(time){
        if(!time){
        return '无'
        }
        var times = new Date(time).getTime();
        var delta = (new Date().getTime() - times) / 1000
        if(Number(delta / (60 * 60 * 24 * 365)) >= 1){
        return Number(delta / (60 * 60 * 24 * 365)).toFixed(0) + '年前'
        }else if(Number(delta / (60 * 60 * 24 * 30)) >= 1){
        return Number(delta / (60 * 60 * 24 * 30)).toFixed(0) + '月前'
        }else if(Number(delta / (60 * 60 * 24 * 7)) >= 1){
        return Number(delta / (60 * 60 * 24 * 7)).toFixed(0) + '星期前'
        }else if(Number(delta / (60 * 60 * 24 )) >= 1){
        return Number(delta / (60 * 60 * 24 )).toFixed(0) + '天前'
        }else if(Number(delta / (60 * 60  )) >= 1){
        return Number(delta / (60 * 60  )).toFixed(0) + '小时前'
        }else if(Number(delta / (60 )) >= 1){
        return Number(delta / (60 )).toFixed(0) + '分钟前'
        }else {
        return '刚刚'
        }

    },
    updateStatus(row,index,store){
        console.log(row,index);
        this.tableData.list[index].openPay = false
        let form = {
        id: row.id,
        userName:row.adminUserName,
        account:row.adminUserAccount,
        userId:row.adminId,//是否是企业内部人员
        name:row.name,
        creditCode:row.creditCode,
        saleUserId:row.saleUserId,
        openPay:true,
        address:row.address,
        addressName:row.addressName,
        latitude:row.latitude,
        longitude:row.longitude,
        streetOfficeCode:row.streetOfficeCode,
        areaCode:row.areaCode,
        contractNo:row.contractNo,
        invitationUserId:'',
        historyShop:row.historyShop,
        shopType:row.shopType,
        sendInvitation:false,
      }
        

        this.$confirm('即将开启现金支付权限，一旦开启则不可关闭,是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
        this.isWorking.search = true
            updateById(form)
            .then(() => {
                this.visible = false
                this.$tip.apiSuccess('修改成功')
                this.handlePageChange(this.tableData.pagination.pageIndex)
            })
            .catch(e => {
                this.$tip.apiFailed(e,false)
            })
            .finally(() => {
                this.isWorking.search = false
                
            })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });       
        });
    },
    handleClick(tab, event) {
        console.log(tab.name, this.activeName);
        this.searchForm.listType = 1;
        this.resetForm(this.activeName=='first'?1:this.activeName=='second'?2:this.activeName == 'third' ?3 : 4)
        this.resetTable();
        this.search()
    },
    resetForm(type){
        let filter = type==1?filter1:type==2?filter2: type ==3 ? filter3 : filter4
        console.log(filter,'-resetFrom')
        this.searchForm = JSON.parse(JSON.stringify(filter))
        this.searchForm.listType = type == 4  ? 1 : type
    },
    reset(){
        this.resetForm(this.activeName=='first'?1:this.activeName=='second'?2: this.activeName == 'third' ? 3 : 4)
        this.search()
    },
    resetTable(){
        this.tableData={
            selectedRows: [],
            sorts:[],
            list:[],
            pagination: {
                pageIndex: 1,
                pageSize: 10,
                total: 0,
            }
        }
        this.isWorking= {
            search: false,
            delete: false,
            export: false
        }

    },
    
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";
// 列表头像处理
.table-column-avatar {
  img {
    width: 48px;
  }
}
</style>
