<template>
  <el-input placeholder="请输入账号" v-model="value"></el-input>
</template>

<script>

export default {
  name: 'AccountInputChange',
  props: {
    value:{}
  },
  data () {
    return {
      
      
    }
  },
  
  methods: {
  },
  created () {
    
  }
}
</script>
