<template>
  <el-upload
    class="avatar-uploader"
    action=""
    :show-file-list="false"
    :on-success="handleAvatarSuccess"
    :before-upload="beforeAvatarUpload"
    :http-request="customUpload"
    v-loading="imgLoading"
    element-loading-spinner="el-icon-loading"
    >
    <img v-if="imageUrl" :src="imageUrl" class="avatar" :style="{width:size+'px',height:size+'px','line-height':size+'px'}">
    <i v-else class="el-icon-plus avatar-uploader-icon" :style="{width:size+'px',height:size+'px','line-height':size+'px'}"></i>
  </el-upload>
      <!--  -->
</template>

<script>
import {uploadImage} from '../../api/common'
export default {
  name: '图片上传',
  props: {
    size:{
      default:100
    },
    value:{
      default:''
    }
    // imageUrl:{
    //   default:''
    // }
  },
  data () {
    return {
      imageUrl: '',
      value:'',
      imgLoading:false

    }
  },
  watch:{
    value:{
      handler(){
        this.imageUrl = ''
        if(this.value){
          this.imageUrl = this.value
        }
      },
      immediate:true
    }
  },
  computed: {

  },
  methods: {
      handleAvatarSuccess(res, file) {
        this.imageUrl = URL.createObjectURL(file.raw);
      },
      beforeAvatarUpload(file) {
        console.log(file,'上传的图片');
        // const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 5;
        const fileTypes = [".jpg", ".png","jpeg"];
        const filePath = file.name;
        //当括号里面的值为0、空字符、false 、null 、undefined的时候就相当于false
        if(filePath){
            var isNext = false;
            var fileEnd = filePath.substring(filePath.indexOf("."));
            for (var i = 0; i < fileTypes.length; i++) {
                if (fileTypes[i] == fileEnd) {
                    isNext = true;
                    break;
                }
            }
            if (!isNext){
                this.$message.error('图片后缀应为.jpg,.png');
                file.value = "";
                return false;
            }
        }else {
            this.$message.error('图片异常');
        }

        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 5MB!');
        }
        return filePath && isLt2M;
      },
      customUpload(option){

        let self = this
        let formData = new FormData();
        this.imgLoading = true
        formData.append('file',option.file);
        uploadImage(formData).then((res) => {
          console.log(res,'---图片')
          this.$emit('input', res)
          this.imageUrl = res
        }).catch(e=>{
          this.$message.error('上传图片失败');
        }).finally(()=>{
          this.imgLoading = false

        })
      }
    }
}
</script>

<style lang="scss">
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 1em;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

</style>
