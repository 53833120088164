import request from '@/utils/request'

// 查询
export function fetchList (data) {
  return request.post('/system/user/page', data)
}
// 查询所有
export function fetchAll () {
  return request.get('/system/user/all')
}
// 新建
export function create (data) {
  return request.post('/system/user/create', data, {
    trim: true
  })
}

// 修改
export function updateById (data) {
  return request.post('/system/user/updateById', data, {
    trim: true
  })
}

// 删除
export function deleteById (id) {
  return request.get(`/system/user/delete/${id}`)
}

// 批量删除
export function deleteByIdInBatch (ids) {
  return request.get('/system/user/delete/batch', {
    params: {
      ids
    }
  })
}

// 配置用户角色
export function createUserRole (data) {
  return request.post('/system/user/createUserRole', data)
}

// 重置密码
export function resetPwd (data) {
  return request.post('/system/user/resetPwd', data)
}

//更改用户角色状态
export function updateRoleStatus (data) {
  return request.post('/system/user/updaterole', data)
}

//商家管理员的冻结与解冻
export function updateShopStatus (data) {
  return request.post('/system/user/updateBusiness', data)
}

//更改用户状态(禁用)
export function updateUserStatus (data) {
  return request.post('/system/user/updateuser', data)
}

// 根据角色id查询可移交的账号列表
export function targetUserList (data) {
  return request.post('/system/user/accountuser', data)
}

// handover  移交角色
export function handoverRoles (data) {
  return request.post('/system/user/transfer', data)
}

//查询系统内所有账号 （美程企业内 商家、预备商家 游客）
export function selectSystemAllUser(data){
  //美程角色的移交
  return request.post('/system/user/selectSysUser', data)
}

//校验统一社会信用代码是否重复
export function isRepeatCreditCode(creditCode){
  return request.get(`/business/shop/isCreditCode/${creditCode}`)
}

// 商家管理员账号可移交列表  查询该商家允许移交的账号
export function selectShopAdminChangeAbleList(data){
  return request.post('/business/shop/accountUser', data)
}

// 商家管理员账号移交
export function shopAdminAccountChange(data){
  return request.post('/business/shop/transfer', data)
}

//备注的添加 各种列表
export function updateRemarks(data){
  return request.post('/system/user/shopRemarks', data)
}



