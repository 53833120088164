//街道办数据信息
import request from '@/utils/request'
// 查询
export function fetchList (data) {
    return request.post('/basics/region/page', data)
}

export function fetchAreaList (data) {
  return request.post('/basics/region/page', data)
}

export function fetchAll (data) {
    return request.post('/basics/region/all', data)
}

// 新建
export function create (data) {
  return request.post('/basics/region/create', data, {
    trim: true
  })
}

// 修改
export function updateById (data) {
  return request.post('/basics/region/updateById', data, {
    trim: true
  })
}
