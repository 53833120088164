<template>
  <TableLayout :permissions="['basics:outboundmerchant:query']">
    <!-- 搜索表单 -->
    <el-form
      ref="searchForm"
      slot="search-form"
      :model="searchForm"
      label-width="100px"
      inline
    >
      <el-form-item label="商家名称" prop="businessName">
        <el-input
          v-model="searchForm.businessName"
          v-trim
          placeholder="请输入商家名称"
          @keypress.enter.native="search"
          style="width: 250px"
        />
      </el-form-item>
      <el-form-item label="管理员名称" prop="chargePeopleNamePhone">
        <el-input
          v-model="searchForm.chargePeopleNamePhone"
          v-trim
          placeholder="请输入姓名/手机号"
          @keypress.enter.native="search"
          style="width: 250px"
        />
      </el-form-item>

      <section>
        <el-button type="primary" icon="el-icon-search" @click="search"
          >查询</el-button
        >
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>

    <template v-slot:table-wrap>
      <ul
        class="toolbar"
        v-permissions="['basics:outboundmerchant:create']"
        v-if="userInfo.currentMcRoleStatus==0&&currentRole!=roles.treasurer"
      >
        <li v-permissions="['basics:outboundmerchant:create']">
          <el-button
            icon="el-icon-plus"
            type="primary"
            @click="$refs.OperaOutboundMerchantWindow.open('新建出库商家')"
            >新增</el-button
          >
        </li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        :default-sort="{ prop: 'createTime', order: 'descending' }"
        stripe
        @selection-change="handleSelectionChange"
        @sort-change="handleSortChange"
      >
        <el-table-column type="selection" width="55px" align="center"></el-table-column>
        <el-table-column prop="businessName" label="商家名称" min-width="120px" align="center">
        </el-table-column>
        <el-table-column prop="chargePeopleName" label="负责人" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="chargePeoplePhone" label="电话" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="agreementPrice" label="协议价格" min-width="100px" align="center">
          <div slot-scope="{row}">{{row.agreementPrice?`${row.agreementPrice}元/千克`:'/'}}</div>
        </el-table-column>
        <el-table-column prop="settlementMethod" label="结算方式" min-width="100px" align="center">
          <div slot-scope="{row}">{{row.settlementMethod==1?'定期结算':row.settlementMethod==2?'现结':'/'}}</div>
        </el-table-column>
        <el-table-column prop="settlementPeriod" label="结算周期" min-width="100px" align="center">
          <div slot-scope="{row}">{{row.settlementPeriod?row.settlementPeriod+'天':'/'}}</div>

        </el-table-column>
        <el-table-column prop="outboundMerchantAddress" label="地址" min-width="120px" :show-overflow-tooltip="true" align="center"></el-table-column>

        <el-table-column
          v-if=" containPermissions([ 'basics:outboundmerchant:query', 'basics:outboundmerchant:create', 'basics:outboundmerchant:delete' ]) &&userInfo.currentMcRoleStatus==0 &&currentRole!=roles.treasurer" label="操作" width="270" fixed="right" align="center"
        >
          <div slot-scope="{ row }">
            <el-button :permissions="['basics:outboundmerchant:create']" type="text" icon="el-icon-edit" @click="$refs.OperaOutboundMerchantWindow.open('修改出库商家',row)">修改</el-button>
            <!-- <el-button :permissions="['basics:outboundmerchant:delete']"  type="text" icon="el-icon-edit" @click="deleteById(row)">删除 要去掉</el-button> -->
            <el-button type="text" icon="el-icon-notebook-2" @click="$refs.operaRecordWindow.open(1,row,3)">操作记录</el-button>
          </div>
        </el-table-column>
      </el-table>
      <pagination @size-change="handleSizeChange" @current-change="handlePageChange" :pagination="tableData.pagination"></pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaOutboundMerchantWindow ref="OperaOutboundMerchantWindow" @success="handlePageChange(tableData.pagination.pageIndex)"/>
    <!-- 操作记录 -->
    <OperaRecordWindow ref="operaRecordWindow"></OperaRecordWindow>
  </TableLayout>
</template>

<script>


import Pagination from "@/components/common/Pagination";
import TableLayout from "@/layouts/TableLayout";
import BaseTable from "@/components/base/BaseTable";
import OperaOutboundMerchantWindow from "../../../components/layun/basicDataManagement/OperaOutboundMerchantWindow.vue";
import OperaRecordWindow from '../../../components/layun/common/OperaRecordWindow.vue'
import { mapState } from 'vuex';

export default {
  name: "出库商家",
  extends: BaseTable,
  components: {
    OperaOutboundMerchantWindow,
    TableLayout,
    Pagination,
    OperaRecordWindow,
  },
  data() {
    return {
      // 搜索
      searchForm: {
        businessName: null,
        chargePeopleNamePhone: null,
      },
    };
  },
  computed: {
    ...mapState({
      userInfo:state=>state.init.userInfo || {},
      currentRole:state=>state.init.userInfo.currentRole,
      roles:state=>state.init.roles
    }),
  },
  created() {
    this.config({
      module: '出库商家',
      api: '/layun/basicDataManagement/outboundMerchant',
      // 'field.main': 'account',
      sorts: [{
        property: 'CREATE_TIME',
        direction: 'DESC'
      }]
    })
    this.search()
  },
  methods: {
    handleCommand(command) {
      this.$message("click on item " + command);
    },
    addressChange(value) {
      console.log("地图选点", value);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";
// 列表头像处理
.table-column-avatar {
  img {
    width: 48px;
  }
}
</style>
