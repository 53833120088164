<template>
<!-- <el-row :gutter="0" class="main">
  <el-col :xs="8" :sm="6" :md="4" :lg="4" :xl="3" class="side">
    <Menu/>
  </el-col>
  <el-col  :xs="16" :sm="18" :md="20" :lg="20" :xl="21" class="content">
    <el-row class="main_header">
      <AppHeader/>
    </el-row>
    <el-row class="realContent" >
      <transition name="fade">
        <router-view></router-view>
      </transition>
    </el-row>
  </el-col>
</el-row> -->

  <el-container class="app-layout">
    <el-aside :class="{collapse:menuData.collapse}">
      <Menu/>
    </el-aside>
    <el-main>
      <header>
        <AppHeader/>
      </header>
      <main  class="realContent">
        <transition name="fade">
          <router-view></router-view>
        </transition>
      </main>
    </el-main>
  </el-container>


</template>

<script>
import { mapState } from 'vuex'
import Header from '@/components/common/Header'
import Menu from '@/components/common/Menu'
export default {
  name: 'DefaultLayout',
  components: { AppHeader: Header, Menu },
  computed: {
    ...mapState({
      menuData: state =>state.init.menuData
    })
  },
  mounted(){
    console.log('appLayout-----');
    this.setScale()
    if (!document.addEventListener) return
    window.addEventListener('resize', this.debounce(this.setScale, 500, true))
    document.addEventListener('DOMContentLoaded', this.debounce(this.setScale, 500, true), false)
  },
  methods:{
    setScale(){
      let div = document.getElementsByClassName('realContent');
      let  originW =  div[0].clientWidth
      let  originH =  div[0].clientHeight
      this.$store.commit('originSize',{originW:originW,originH:originH})
      
    },
    debounce(fn, delay) {
      let delays = delay || 500
      let timer
      return () => {
        let _this = this
        let args = arguments
        if (timer) {
          clearTimeout(timer)
        }
        timer = setTimeout(() => {
          timer = null
          fn.apply(_this, args)
        }, delays)
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";
.el-container {
  background: #f1f1f1;
  // margin: 0 10px;
  padding: 10px;
  height: 100vh;
  display: flex;
  overflow: hidden;
  // 左边菜单
  .el-aside {
    width: $menu-width !important;
    flex-shrink: 0;
    height: calc(100vh - 20px);
    margin-right: 10px;
   
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
    border-radius: 20px 0px 0px 20px;
    // background: $primary-color;
    // color: #fff;
    transition: width ease .3s;
    &.collapse {
      width: 64px !important;
    }
  }
  // 右边内容
  .el-main {
    width: 100%;
    height: 100%;
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background: #fff;
    border-radius: 0px 20px 20px 0px;
    & > header {
      height: $header-height;
      flex-shrink: 0;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
      // border-radius: 0px 20px 0px 0px;
    }
    & > main {
      height: 100%;
      overflow-y: auto;
      background: #fff;
      margin-top: 10px;
      // border-radius: 0px 20px 0px 0px;
    }
  }
}
// 页面过渡动画
.fade-enter-active, .fade-leave-active {
  transition: all .5s;
  opacity: 1;
  position: absolute;
  width: 100%;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateY(200px);
  opacity: 0;
  transition: all .5s;
  position: absolute;
}





.main{
  height: calc(100vh - 20px);
  display: flex;

}

.side{
  
  margin-right: 10px;
  padding: 10px;
  height:100%;
  min-width:200px;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 20px 0px 0px 20px;
  // margin-right: 20px;

}
.content{
  background: #fff;
  // padding: 10px;
  height: 100%;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0px 20px 20px 0px;
  overflow: hidden;


}
// .main_header{
//   height: 70px;
//   background: #FFFFFF;
//   box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
//   border-radius: 0px 20px 0px 0px;
  
// }
.realContent{
  height: calc(100vh - 70px);
  padding-bottom: 60px;
  overflow-y: auto;
  box-sizing: border-box;
  // background: seagreen;
  // background: cornflowerblue;
}
</style>
