<template>
  <GlobalWindow
    class="handle-table-dialog"
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirmC"
  >
    
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="合同编号" prop="contractNo" v-if="type==1" >
        <el-input v-model="form.contractNo" placeholder="需要判断是否重复"></el-input>
      </el-form-item>

      <el-form-item label="所属街道办" prop="streetOfficeCode" v-if="type==2" >
        <StreetSelect v-model="form.streetOfficeCode" :filter="nameObj" :clearable="true" ></StreetSelect>
      </el-form-item>

      <el-form-item label="协议价格" prop="settPrice"  v-if="type==3" >
        <!-- 只针对销售 协议价格不可超过4块 -->
        <el-input  placeholder="请输入" v-model="form.settPrice" type="number" :max="currentRole==roles.sale?4:null"   style="width:300px;">
          <template slot="append">元/千克</template>
        </el-input>
      </el-form-item>

      <el-form-item label="结算方式" prop="settType" v-if="type==4" >
        <el-radio-group v-model="form.settType" style="margin-bottom:10px;">
          <el-radio :label="1">现结</el-radio>
          <el-radio :label="2">周期结算</el-radio>
        </el-radio-group>
        <el-input  placeholder="请输入" v-model="form.settCycle" type="number" v-if="form.settType==2" style="width:300px;" required>
          <template slot="append">天</template>
        </el-input> 
      </el-form-item>

      <el-form-item label="所属销售" prop="saleUserId"  v-if="type==5" >
        <SaleUserSelect v-model="form.saleUserId" placeholder="请选择销售" :clearable="true" ></SaleUserSelect>
      </el-form-item>

      <el-form-item label="备注" prop="remarks"  v-if="type==6">
        <el-input  placeholder="请输入"  v-model="form.remarks"  style="width:300px;">
          <!-- <template slot="append">/千克</template> -->
        </el-input>
      </el-form-item>

      

      


      
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import {updateUserStatus,updateRoleStatus} from '../../../api/system/user'
import StreetSelect from '../../../components/layun/common/StreetSelect.vue'
import SaleUserSelect from '../../../components/layun/common/SaleUserSelect.vue'
import { mapState } from 'vuex'
import loadTMap from "../../../utils/loadTMap";


export default {
  name: 'OperaSignedListChangeWindow',
  extends: BaseOpera,
  components: {  GlobalWindow , StreetSelect , SaleUserSelect , },
  data () {
    return {
      // 表单数据
      form: {
        id:null,
        userId:null,
        userName:'',
        address:'',
        addressName:'',
        areaCode:null,
        areaName:'',
        contractNo:'',
        latitude:0,
        longitude:0,
        name:'',
        saleUserId:0,
        settCycle:0,
        settPrice:null,
        settType:null,
        streetOfficeCode:0,
        addressT:'',//总的地址
        invitationUserName:'',
      },
      type:1,//
      title:'',
      nameObj:{
        provinceName:'',
        cityName:'',
        districtName:''
      },
      required:false,//备注是否必填
      // 验证规则
      rules: {
        remarks: [
          { required: this.required, message: '请输入备注' }
        ],
        // equipmentModel: [
        //   { required: true, message: '请输入型号' }
        // ]
      },
      visible:false
    }
  },
  computed:{
    ...mapState({
        userInfo:state=>state.init.userInfo || {},
        currentRole:state=>state.init.userInfo.currentRole,
        roles:state=>state.init.roles
    })
  },
  methods: {
    /**
     * @title: 窗口标题
     * @target: 编辑的菜单对象
     * @parent: 新建时的上级菜单
     */
    open (title, target, type) {
      this.title = title
      this.type = type
      this.visible = true
      console.log(target)
      this.$nextTick(() => {
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        this.form.userId = target.adminId
        this.form.userName = target.adminUserName
        this.form.name = target.shopName
        this.form.invitationUserName = target.invitationUserName
        this.form.addressT = target.address+target.addressName  
      })
      if(type==2){
        // 获取省市区汉字姓名
        this.getLocation(target.latitude,target.longitude);
      
      }
      
    },
    getLocation(latitude,longitude){
        let location = new TMap.LatLng(latitude,longitude);
        var geocoder = new TMap.service.Geocoder();
        geocoder.getAddress({ location: location }) // 将给定的坐标位置转换为地址
        .then((result) => {
            console.log(result,'__result');
            if(result.status==0){
                // 显示搜索到的地址
                let res = result.result
                this.nameObj = {
                    provinceName : res.ad_info.province,
                    cityName : res.ad_info.city,
                    districtName : res.ad_info.district,
                }
            
            }else{
                this.$message.error('选点异常')
            }
            
        });
    
    },
    //确认前
    confirmC(){
      if(this.type==3&&this.form.settPrice>4&&this.currentRole==this.roles.sale){
        return this.$message.error('协议价格不能高于4元')
      }else{
        this.confirm();
      }
    
    }

    // confirm(){
    //    this.$refs.form.validate((valid) => {
    //     if (!valid) {
    //       return
    //     }
    //     console.log(this.form);
    //     // // 调用新建接口
    //     // this.isWorking = true
    //     // let updateStatus = this.form.status==2?updateUserStatus:updateRoleStatus
    //     // updateStatus(this.form)
    //     //   .then((res) => {
    //     //     if(res.code==500){
    //     //       this.$message.error(res.message);
    //     //       return 
    //     //     }
    //     //     this.visible = false
    //     //     this.$tip.apiSuccess(res.data?res.data:'修改成功')
    //     //     this.$emit('success')
    //     //   })
    //     //   .catch(e => {
    //     //     this.$tip.apiFailed(e)
    //     //   })
    //     //   .finally(() => {
    //     //     this.isWorking = false
    //     //   })
    //   })
    
    // },
  },
  async created () {
    await loadTMap('BTJBZ-KLJCD-WI44K-PN5JU-26HBJ-33BFH')
    this.config({
      api: '/layun/contractManagement/signedManagement', 
    })
    
    
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables";
$icon-background-color: $primary-color;
.global-window {
  .tip {
    margin-bottom: 12px;
    em {
      font-style: normal;
      color: $primary-color;
      font-weight: bold;
    }
  }
  // 图标
  /deep/ .form-item-icon {
    .el-form-item__content {
      height: 193px;
      overflow-y: auto;
    }
    .el-radio-group {
      background: $icon-background-color;
      padding: 4px;
      .el-radio {
        margin-right: 0;
        color: #fff;
        padding: 6px;
        &.is-checked {
          background: $icon-background-color - 30;
          border-radius: 10px;
        }
        .el-radio__input.is-checked + .el-radio__label {
          color: #fff;
        }
      }
      .el-radio__input {
        display: none;
      }
      .el-radio__label {
        padding-left: 0;
        // element-ui图标
        i {
          font-size: 30px;
        }
        // 自定义图标
        [class^="eva-icon-"], [class*=" eva-icon-"] {
          width: 30px;
          height: 30px;
          background-size: 25px;
          vertical-align: bottom;
        }
      }
      .el-radio--small {
        height: auto;
        padding: 8px;
        margin-left: 0;
      }
    }
  }
}
</style>
