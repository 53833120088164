import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  // 登录用户信息
  userInfo: null,
  roles:{},//系统内角色

  // 首页
  homePage: null,
  // 菜单
  menuData: {
    // 菜单列表
    list: [],
    // 是否收起
    collapse: false
  }
}
const mutations = {
  // 切换菜单状态
  switchCollapseMenu (state, value) {
    if (value != null) {
      state.menuData.collapse = value
    } else {
      state.menuData.collapse = !state.menuData.collapse
    }
    window.localStorage.setItem('MENU_STATUS', state.menuData.collapse)
  },
  // 设置已登录的用户信息
  setUserInfo: (state, data) => {
    state.userInfo = data
  },
  //设置全部角色
  setRoles: (state, data) => {
    console.log(data);
    state.roles.manager = data.filter(i=>i.code=='manager')[0].id
    state.roles.newManager = data.filter(i=>i.code=='newManager')[0].id
    state.roles.logistics = data.filter(i=>i.code=='logistics')[0].id
    state.roles.warehouseman = data.filter(i=>i.code=='warehouseman')[0].id
    state.roles.sale = data.filter(i=>i.code=='sale')[0].id
    state.roles.driver = data.filter(i=>i.code=='driver')[0].id
    state.roles.treasurer = data.filter(i=>i.code=='treasurer')[0].id
    state.roles.roleArr = data
  },
  // 设置首页路由信息
  setHomePage (state, homePage) {
    state.homePage = homePage
  },
  // 重置菜单
  resetMenus: (state) => {
    state.menuData.list = []
  }
}
const actions = {
    getUserInfo(){
    
    }

}
const getters = {}
export default ({
  state,
  mutations,
  actions,
  getters
})
