<template>
<!-- 在用 -->
  <GlobalWindow
    class="position-user-window"
   
    title="查看详情"
    :visible.sync="visible"
    :with-footer="false"
  >
   <div v-if="row">
    <div class="line inlineFlex fweBold">
      <div class="label">受邀账号：</div>
      <div class="content">{{row.beInvitedPhone}}</div>
    </div>
    <div class="line inlineFlex">
      <div class="label">昵称：</div>
      <div class="content">{{row.beInvitedName || '-'}}</div>
    </div>
    <div class="line">
      <div class="label">管理员姓名：</div>
      <div class="content">{{row.beInvitedUserName || '-'}}</div>
    </div>
    <div class="line">
      <div class="label">商家名称：</div>
      <div class="content">{{row.shopName || '-'}}</div>
    </div>
    <div class="line">
      <div class="label">统一社会信用代码：</div>
      <div class="content">{{row.creditCode || '-'}}</div>
    </div>
    <div class="line">
      <div class="label">商家地址：</div>
      <div class="content">{{row.address || ''}}{{row.addressName || '-'}}</div>
    </div>
    <div class="line">
      <div class="label">所属街道办：</div>
      <div class="content">{{row.streetOfficeName || '-'}}</div>
    </div>
   </div>
  </GlobalWindow>
</template>

<script>
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import GlobalWindow from '@/components/common/GlobalWindow'
import Pagination from '@/components/common/Pagination'
import { fetchStaffsList } from '../../../api/layun/accountManagement/shop'
export default {
  name: 'InviterListWindow',
  extends: BaseTable,
  components: { Pagination, GlobalWindow, TableLayout },
  data () {
    return {
      shopId: null,
      shopName: '',
      visible: false,
      row:null,
    }
  },
  methods: {
    // 打开查看人员窗口
    open (row) {
    this.row = row
    this.visible = true
    console.log(row,'--');
    //   this.shopId = shopId
    //   this.shopName = shopName
    //   this.searchForm.shopId = shopId
    //   this.searchForm.name = ''
    //   this.searchForm.account = ''
    //   this.visible = true
    //   this.search()
    },
    // 处理分页
    handlePageChange (pageIndex) {
      // 仅查看当前部门处理
      // this.searchForm.strictDeptId = null
      // this.searchForm.rootDeptId = this.searchForm.shopId
      // if (this.onlyCurrentDept) {
      //   this.searchForm.strictDeptId = this.searchForm.shopId
      //   this.searchForm.rootDeptId = null
      // }
      this.tableData.pagination.pageIndex = pageIndex
      this.isWorking.search = true
      fetchStaffsList({
        page: pageIndex,
        capacity: this.tableData.pagination.pageSize,
        model: this.searchForm
      })
        .then(data => {
          this.tableData.list = data.records
          this.tableData.pagination.total = data.total
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          this.isWorking.search = false
        })
    },
    //重置
    reset(){
      this.searchForm.name = null
      this.searchForm.account = null

      this.search()
    }
  }
}
</script>

<style scoped lang="scss">
.inlineFlex{
  display: inline-flex !important;
}
.fweBold{
  font-weight: bold;
  font-size: large;
}
.line{
  display: flex;
  align-items: center;
  margin:20px ;

  .label{
    color: #a2a2a2;
  
  }

  .content{
    color: #535353;
  
  }
}
.position-user-window {
  /deep/ .table-search-form {
    padding: 0;
  }
  /deep/ .window__body {
    background: #f7f7f7;
    .table-content {
      padding: 0;
      .table-wrap {
        padding: 0;
      }
    }
  }
  .toolbar {
    margin-top: 10px;
    padding: 6px 12px;
    background: #fff;
    font-size: 13px;
    label {
      margin-left: 8px;
      vertical-align: middle;
      color: #999;
    }
  }
  // 列表头像处理
  .table-column-avatar {
    img {
      width: 48px;
    }
  }
  // 列表角色处理
  .table-column-role {
    ul {
      li {
        display: inline-block;
        background: #eee;
        border-radius: 3px;
        padding: 0 3px;
        margin-right: 3px;
      }
    }
  }
}
</style>
