<template>
  <TableLayout :permissions="['basics:equipment:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="80px" inline>
      <el-form-item label="名称" prop="equipmentName">
        <el-input v-model="searchForm.equipmentName" v-trim placeholder="请输入设备名称" @keypress.enter.native="search" style="width:250px"/>
      </el-form-item>
      <el-form-item label="型号" prop="equipmentModel">
        <el-input v-model="searchForm.equipmentModel" v-trim placeholder="请输入设备型号" @keypress.enter.native="search" style="width:250px"/>
      </el-form-item>

      <section>
        <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>

    <template v-slot:table-wrap>
        <ul class="toolbar" v-permissions="['basics:equipment:create']" v-if="userInfo.currentMcRoleStatus==0&&currentRole!=roles.treasurer">
            <li><el-button icon="el-icon-plus" type="primary" @click="$refs.operaEquipmentWindow.open('新增设备')">新增</el-button></li>
        </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
        @sort-change="handleSortChange"
      >
        <el-table-column prop="equipmentName" label="设备名称" min-width="120px" align="center">
        </el-table-column>
        <el-table-column prop="equipmentModel" label="型号" min-width="100px" align="center"></el-table-column>

        <el-table-column
          v-if="containPermissions(['basics:equipment:update', 'basics:equipment:delete', 'basics:equipment:query'])&&userInfo.currentMcRoleStatus==0&&currentRole!=roles.treasurer"
          label="操作"
          width="270"
          align="center"
          fixed="right"
        >
          <div  slot-scope="{row}">
            <el-button :permissions="['basics:equipment:update']" type="text" icon="el-icon-edit" @click="$refs.operaEquipmentWindow.open('修改设备',row)">修改</el-button>
            <!-- <el-button :permissions="['basics:equipment:delete']"   type="text" icon="el-icon-edit" @click="deleteById(row)">删除 要去掉</el-button> -->

            <el-button  type="text" icon="el-icon-notebook-2" @click="$refs.operaRecordWindow.open(1,row,1)" >操作记录</el-button>
          </div>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaEquipmentWindow ref="operaEquipmentWindow" @success="handlePageChange(tableData.pagination.pageIndex)"/>
    <!-- 操作记录 -->
    <OperaRecordWindow ref="operaRecordWindow"></OperaRecordWindow>

  </TableLayout>
</template>

<script>
import Pagination from '@/components/common/Pagination'
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import OperaEquipmentWindow from '@/components/layun/basicDataManagement/OperaEquipmentWindow'
import OperaRecordWindow from '../../../components/layun/common/OperaRecordWindow.vue'
import { mapState } from 'vuex'
// import { fetchList } from '@/api/system/user'

export default {
  name: 'ManageUser',
  extends: BaseTable,
  components: {OperaEquipmentWindow, TableLayout, Pagination,OperaRecordWindow},
  data () {
    return {
      // 搜索
      searchForm: {
        equipmentModel:null,
        equipmentName:null
      },
      
    }
  },
  computed: {
    ...mapState({
      userInfo:state=>state.init.userInfo || {},
      currentRole:state=>state.init.userInfo.currentRole,
      roles:state=>state.init.roles
    }),
  },
  created () {
    this.config({
      module: '设备数据',
      api: '/layun/basicDataManagement/equipmentData',
      'field.main': 'equipmentName',
      sorts: [{
        property: 'CREATE_TIME',
        direction: 'DESC'
      }]
    })
    this.search()
  },
  methods:{
        handleCommand(command) {
            this.$message('click on item ' + command);
        }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";
// 列表头像处理
.table-column-avatar {
  img {
    width: 48px;
  }
}
</style>
