<template>
  <el-menu-item v-if="menu.children == null || menu.children.length == 0" :key="menu.index" :index="menu.index">
    <i :class="menu.icon"  class="iconClass"></i>
    <!-- <svg class="iconfont" style="height:18px;width:18px" aria-hidden="true">
          <use xlink:href="#icon-xianxingbeizi"></use>
    </svg> -->
    <span slot="title">{{menu.label}}</span>
  </el-menu-item>
  <el-submenu v-else :index="menu.index">
    <template slot="title">
      <i :class="menu.icon " class="iconClass"></i>
      <span slot="title">{{menu.label}}</span>
    </template>
    <MenuItems v-for="child in menu.children" v-if="menu.children.length>0" :menu="child" :key="child.index"/>
  </el-submenu>
</template>

<script>
export default {
  name: 'MenuItems',
  props: {
    menu: {
      type: Object,
      required: true
    }
  },
  created(){
    // console.log(this.menu);
  }
}
</script>
<style scoped>
.iconClass{
  font-size: 18px;
  font-weight: bold;
  margin-right: 5px;
}
</style>
