// 去空
export function trim (data) {
  if (data == null) {
    return null
  }
  if (typeof data === 'string') {
    return data.trim()
  }
  if (data instanceof Array) {
    for (const item of data) {
      trim(item)
    }
  }
  if (typeof data === 'object') {
    for (const key in data) {
      data[key] = trim(data[key])
    }
  }
  return data
}

export function equalObject(obj1,obj2){
  return JSON.stringify(obj1)==JSON.stringify(obj2)
   
}
function name(params) {
  let exportObj1 = {
    tHeader:['ID','邀约人手机','邀约人昵称','邀约人姓名','受邀人手机','受邀人昵称','受邀人姓名','商家名称','发起时间','签约状态','邀约开关'],
    filterVal:[
      {key:'id'},
      {key:'invitationUserPhone',},
      {key:'invitationNickName',},
      {key:'invitationUserName',},
      {key:'beInvitedPhone',},
      {key:'beInvitedName',},
      {key:'beInvitedUserName',},
      {key:'shopName',},
      {key:'createTime',},
      {key:'status',type:2,default:'状态异常', item:[{value:0,label:'待签约'},{value:1,label:'已签约'},{value:2,label:'已拒绝'},{value:3,label:'已关闭'}]},
      {key:'open',type:2,default:'关', item:[{value:true,label:'开'},{value:false,label:'关'}]},
    ],
    fileName:'邀约管理',
  }
}
