<template>
  <TableLayout :permissions="['basics:common:query']"  :withBreadcrumb="false">
    <template v-slot:table-wrap>
       <el-tag   @click="handleClick(XianData)" style="margin-left:10px;cursor:pointer;">
        {{XianData.commonCityName}}
      </el-tag>
      <el-tag v-for="tag in tableData.list" :key="tag.id" closable @close="handleClose(tag)" @click="handleClick(tag)" style="margin-left:10px;cursor:pointer;">
        {{tag.commonCityName}}
      </el-tag>

        <el-button :permissions="['basics:common:create']"  type="primary" @click="visible = true" style="margin-left:20px">新增</el-button>
        <!-- <el-button  type="primary" @click="search" style="margin-left:20px">刷新城市</el-button> -->

      <pagination v-show="false"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>
    <!-- 新建/修改 -->
    <el-dialog
      class="global-window"
      title="新增热门城市"
      status-icon
      :visible="visible"
      v-if="visible"
      :width="'400px'"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="visible = false"
      :center='true'
      append-to-body
    >
      <div class="window__body" style="text-align:center">
        <el-form :model="form" ref="form" :rules="rules">
          <el-form-item label="省市区" prop="areaArr" required>
              <CitySelect v-if="visible" v-model="form.areaArr" :checkStrictly="true" :clearable="true" @change="cityChange"></CitySelect>
          </el-form-item>
        </el-form>
        <el-button style="display:block;margin:20px auto;" type="primary" @click="confirmC" :loading="isWorkingC">确认</el-button>
      </div>
    </el-dialog>
  </TableLayout>
</template>

<script>
import Pagination from '@/components/common/Pagination'
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import OperaEquipmentWindow from '@/components/layun/basicDataManagement/OperaEquipmentWindow'
import OperaRecordWindow from '../../../components/layun/common/OperaRecordWindow.vue'
import CitySelect from '../common/CitySelect.vue'
import BaseOpera from '../../../minxins/BaseOpera.js'

// import { fetchList } from '@/api/system/user'

export default {
  name: 'HotCity',
  extends: BaseTable,
  mixins: [BaseOpera],
  components: {OperaEquipmentWindow, TableLayout, Pagination,CitySelect},
  data () {
    return {
      visible:false,
      areaArr:[],
      form:{
        areaArr:[],
        cityId: '',
        cityName: "",
        commonCityAddress: "",
        commonCityName: "",
        districtId: '',
        districtName: "",
        provinceId: '',
        provinceName: ""
      },
      rules:{
        areaArr: [
          { required: true, message: '请输入省市区' }
        ]
      },
      XianData:{
        cityId:36243,
        provinceId:15419,
        commonCityAddress:'陕西省西安市',
        commonCityName:'陕西省/西安市'
      }
      
    }
  },
  watch:{
    
  },
  created () {
    this.config({
      module: '热门城市',
      api: '/layun/basicDataManagement/hotCity',
      'field.main': 'commonCityName',
      sorts: [{
        property: 'CREATE_TIME',
        direction: 'DESC'
      }]
    })
    this.handleSizeChange(100000)
    //  {
    //   this.tableData.pagination.pageSize = pageSize
    //   this.search()
    // },
    // this.search()
  },
  methods:{
        handleCommand(command) {
            this.$message('click on item ' + command);
        },
        handleClose(tag){
          this.deleteById(tag)
        },
        handleClick(tag){
          this.$emit('click',tag)
        },
        confirmC(){
        let that = this;
          this.confirm()
          
          // .then(res=>{
          //   that.handlePageChange(1)
          // });

          // setTimeout(()=>{
          // })
          // this.$emit('success')
        },
        cityChange(value){
          console.log(value,'--数据');
          let form = value.form
          let arr = []
          if(form.provinceId){
            arr.push(form.provinceName)
          }
          if(form.cityId){
            arr.push(form.cityName)
          }
          if(form.districtId){
            arr.push(form.districtName)
          }
          this.form = {
              ...value.form,
              areaArr:value.value,
              commonCityAddress: arr.join(''),
              commonCityName: arr.join('/'),
          }
        },
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";
// 列表头像处理
.table-column-avatar {
  img {
    width: 48px;
  }
}
</style>
