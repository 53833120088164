import request from '@/utils/request'

// 查询
/*
listType：
    1邀约金总计列表
    2邀约金明细列表
    3充值金明细列表
    4返现记录列表
*/
export function fetchList (data) {
  console.log(data,'----邀约金管理');
  if(data.model.listType==1){
    return request.post('/business/invitation/money/pageTotal', data) 
  }else if(data.model.listType==2){
    return request.post('/business/invitation/money/pageDetailed', data) 
  }else if (data.model.listType==3){
   return request.post('/business/invitation/money/page', data) 
  }else if(data.model.listType==4){
    return request.post('/business/invitation/money/pageCash', data) 
  }else{
    return this.$message.info('参数有一丢丢小问题')
   }
}

export function fetchBatchList (data) {
  return request.post('/business/invitation/money/pageDetailed', data) 
}

//总计
export function pageSum (data) {
  return request.post('/business/invitation/money/totalMoney', data) 
}



//修改 附件和 备注
export function updateRemarksAndEnclosure (data) {
    return request.post('/business/invitation/money/updateRemarks', data, {
        trim: true
    })
}

/*
 * listType:2邀约金明细列表
 * 
*/
//邀约金修改
export function updateById (data) {
    return request.post('/business/invitation/money/updateByIdInvitation', data, {
        trim: true
    })
}
//邀约金审核
export function verifyById (data) {
    return request.post('/business/invitation/money/updateById', data, {
        trim: true
    })
}

//邀约金返现
export function cashBackById (data) {
    return request.post('/business/invitation/money/cashBack', data, {
        trim: true
    })
}


/*
 * listType:3充值金明细列表
 * 
*/
// 新建 充值金 发起充值
export function create (data) {
  return request.post('/business/invitation/money/create', data, {
    trim: true
  })
}

// 发起返现申请
export function cashBackApply (data) {
  return request.post('/business/invitation/money/echargeCashBack', data, {
    trim: true
  })
}

// 审核&充值金返现
export function updateCashBackStatus (data) {
  return request.post(`/business/invitation/money/updateStatus`, data, {
    trim: true
  })
}