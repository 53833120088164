<template>
<GlobalWindow
    title="修改密码"
    :visible.sync="visible.changePwd"
    @confirm="confirmChangePwd"
    @close="visible.changePwd = false"
>
    <el-form :model="changePwdData.form" ref="changePwdDataForm" :rules="changePwdData.rules" >
    <el-form-item label="原始密码" prop="oldPwd" required>
        <el-input v-model="changePwdData.form.oldPwd" type="password" placeholder="请输入原始密码" maxlength="30" show-password></el-input>
    </el-form-item>
    <el-form-item label="新密码" prop="newPwd" required>
        <el-input v-model="changePwdData.form.newPwd" type="password" placeholder="请输入新密码"  maxlength="30" show-password></el-input>
    </el-form-item>
    <el-form-item label="确认新密码" prop="confirmPwd" required>
        <el-input v-model="changePwdData.form.confirmPwd" type="password" placeholder="请再次输入新密码" maxlength="30" show-password ></el-input>
    </el-form-item>
    </el-form>
</GlobalWindow>
</template>

<script>
import GlobalWindow from '@/components/common/GlobalWindow'
// import { createRolePermission } from '@/api/system/role'
// import { fetchAll } from '@/api/system/permission'
import { updatePwd } from "@/api/system/common";

export default {
  name: 'PasswordUpdated',
  components: { GlobalWindow },
  data () {
    return {
      visible: {
        // 修改密码
        changePwd: false,
      },
      isWorking: {
        // 修改密码
        changePwd: false,
      },
      // 修改密码弹框
      changePwdData: {
        form: {
          oldPwd: "",
          newPwd: "",
          confirmPwd: "",
        },
        rules: {
          oldPwd: [{ required: true, message: "请输入原始密码" }],
          newPwd: [{ required: true, message: "请输入新密码" }],
          confirmPwd: [{ required: true, message: "请再次输入新密码" }],
        },
      },
    }
  },
  methods: {
    // 修改密码
    changePwd() {
      this.visible.changePwd = true;
      this.$nextTick(() => {
        this.$refs.changePwdDataForm.resetFields();
      });
    },
    open() {
      this.visible.changePwd = true;
      this.$nextTick(() => {
        this.$refs.changePwdDataForm.resetFields();
      });
    },
    // 确定修改密码
    confirmChangePwd() {
      if (this.isWorking.changePwd) {
        return;
      }
      this.$refs.changePwdDataForm.validate((valid) => {
        if (!valid) {
          return;
        }
        // 验证两次密码输入是否一致
        if (
          this.changePwdData.form.newPwd !== this.changePwdData.form.confirmPwd
        ) {
          this.$tip.warning("两次密码输入不一致");
          return;
        }
        // 执行修改
        this.isWorking.changePwd = true;
        updatePwd({
          oldPwd: this.changePwdData.form.oldPwd,
          newPwd: this.changePwdData.form.newPwd,
        })
          .then(() => {
            this.$tip.apiSuccess("修改成功");
            this.visible.changePwd = false;
          })
          .catch((e) => {
            this.$tip.apiFailed(e);
          })
          .finally(() => {
            this.isWorking.changePwd = false;
          });
      });
    },
  }
}
</script>

<style scoped lang="scss">

</style>
