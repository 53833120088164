<template>
  <GlobalWindow
    class="handle-table-dialog"
    :title="title"
    :visible.sync="visible"
    :withFooter="false"
    :confirm-working="isWorking"
  >
    <el-form :model="form" ref="form" :rules="rules"  v-if="type==1">
      <el-form-item label="充值账号" prop="account" required>
        {{form.account}}
      </el-form-item>
      <el-form-item label="返现申请金额" prop="echargeMoney" >
        <el-input v-model.number="form.echargeMoney" type="number" placeholder="请输入金额" v-trim  :max="row.stayRechargeGold || null"/>
      </el-form-item>
      <div style="display:flex;justify-content: center;">
          <el-button type="primary" @click="confirm(0)" :loading="isWorking">发起申请</el-button>
          <el-button  @click="visible = false">取消</el-button>
      </div>  
    </el-form>
    <el-form :model="form" ref="form" :rules="rules"  v-if="type==2">
      <el-form-item label="充值账号" prop="account" required>
        {{form.account}}
      </el-form-item>
      <el-form-item label="返现申请金额" prop="toExamineRechargeAmount" required>
        ￥{{form.toExamineRechargeAmount}}
      </el-form-item>
      <el-form-item label="返现备注" prop="remarks" >
        <el-input v-model="form.remarks"  placeholder="请输入备注" />
      </el-form-item>
      <div style="display:flex;justify-content: center;">
          <el-button type="primary" @click="confirm(1)" :loading="isWorking">通过</el-button>
          <el-button  @click="confirm(2)" :loading="isWorking">拒绝</el-button>
      </div>  
    </el-form>
    <el-form :model="form" ref="form" :rules="rules"  v-if="type==3">
      <el-form-item label="返现账号" prop="account" required>
        {{form.account}}
      </el-form-item>
      <el-form-item label="返现金额" prop="toExamineRechargeAmount" required>
        ￥{{form.toExamineRechargeAmount}}
      </el-form-item>
      <el-form-item label="返现备注" prop="remarks" >
        <el-input v-model="form.remarks"  placeholder="请输入备注" />
      </el-form-item>
      <el-form-item label="返现附件" prop="remarks" >
        <Enclosure v-model="form.enclosure" :size="80"></Enclosure>
      </el-form-item>
      <div style="display:flex;justify-content: center;">
          <el-button type="primary" @click="confirm(3)" :loading="isWorking">返现</el-button>
          <el-button  @click="visible = false">取消</el-button>
      </div>  
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import Enclosure from '../../../layun/common/Enclosure.vue'

import {cashBackApply , updateCashBackStatus , } from '../../../../api/layun/contractManagement/invitationFundManagement'


export default {
  name: 'CasReturnApplyAndExamineWindow',//充值金返现申请与返现审核
  extends: BaseOpera,
  components: {  GlobalWindow , Enclosure , },
  data () {
    var checkMoney = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('金额不能为空'));
      }
      setTimeout(() => {
        if (!Number(value)) {
          callback(new Error('请输入数字值'));
        } else {
          if (value > this.row.stayRechargeGold) {
            callback(new Error('返现申请金额不能大于待返现金额'));
          } else {
            callback();
          }
        }
      }, 1000);
    };
    
    return {
      form:{
        echargeMoney:null,
        enclosure:[],
        remarks:'',
        toExamineRechargeAmount:null,
      },
      rules:{
        echargeMoney:[
        {required:true,message:'请填写本次返现金额'},
        { validator: checkMoney, trigger: 'blur' }
        ]
      },
      echargeMoney:'',//返现充值金金额
      remarks:'',
      visible:false,
      row:null,
      type:1,
      title:''
    }
  },
  methods: {
    /**
     * @type: 1 发起返现申请 2 审核
     * @row: 当前行的值
     * 
    */
    async open(type,row) {
        console.log(type,row,'_____target');
        this.type = type
        this.row = row
        this.title = type==1?'发起返现申请':type==2?'审核':'操作返现'

        this.form.account = row.account
        this.form.echargeMoney = row.stayRechargeGold
        this.form.toExamineRechargeAmount = row.toExamineRechargeAmount
        this.form.remarks = row.remarks || ''
        this.form.enclosure = row.enclosure || []

        this.visible = true

    },
    /*
      @btnBool  0，申请 1 同意 2 驳回 3返现
    */
    confirm(btnBool){
      console.log(',,,,',this.form.echargeMoney,this.form.remarks);
      console.log({
        echargeMoney:Number(this.form.echargeMoney),
        userId:this.row.userId
      });
      if(btnBool==2&&this.form.remarks.trim().length==0){
        return this.$message.error('请输入驳回备注')
      }
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        }
        let api = btnBool==0?cashBackApply:updateCashBackStatus
        let param = btnBool==0?{
          echargeMoney:Number(this.form.echargeMoney),
          userId:this.row.userId
        }:btnBool==3?{
          userId:this.row.userId,
          status:btnBool,
          remarks:this.form.remarks,
          enclosure:this.form.enclosure
        }:{
          userId:this.row.userId,
          status:btnBool,
          remarks:this.form.remarks
        }
        this.isWorking = true
        api(param).then(data=>{
            this.$tip.apiSuccess(`${btnBool==1?'审核已通过':btnBool==2?'已驳回':btnBool==3?'已返现成功':'已发起申请'}`)
            this.visible = false
            this.$emit('success')
            
        }).catch(e=>{
            this.$tip.apiFailed(e,false)
        }).finally((res)=>{
            this.isWorking = false
            
        })


      })
    
    }
  },
  created () {
    // this.config({
    //   api: '/layun/contractManagement/signedManagement', 
    // })
    
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables";
$icon-background-color: $primary-color;

</style>
