<script>
import BasePage from './BasePage'
export default {
  name: 'BaseTable',
  extends: BasePage,
  data () {
    return {
      // 接口
      api: null,
      // 模块名称
      module: '数据',
      // 配置数据
      configData: {
        // id字段
        'field.id': 'id',
        // 主字段
        'field.main': 'name'
      },
      // 是否正在执行
      isWorking: {
        // 搜索中
        search: false,
        // 删除中
        delete: false,
        // 导出中
        export: false
      },
      // 表格数据
      tableData: {
        // 已选中的数据
        selectedRows: [],
        // 排序的字段
        sorts: [],
        // 当前页数据
        list: [],
        // 分页
        pagination: {
          pageIndex: 1,
          pageSize: 10,
          total: 0
        }
      },
      pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        value2: '',
        value1:'',
    }
  },
  methods: {
    // 配置
    config (extParams) {
      if (extParams == null) {
        throw new Error('Parameter can not be null of method \'config\' .')
      }
      if (extParams.api == null) {
        throw new Error('Missing config option \'api\'.')
      }
      this.api = require('@/api' + extParams.api)
      console.log(extParams.module,extParams['field.id'] );
      console.log(
        extParams['field.id'] && (this.configData['field.id'] = extParams['field.id']),
        extParams['field.main'] && (this.configData['field.main'] = extParams['field.main'])
      );

      console.log(this.configData);


      extParams.module && (this.module = extParams.module)
      extParams['field.id'] && (this.configData['field.id'] = extParams['field.id'])
      extParams['field.main'] && (this.configData['field.main'] = extParams['field.main'])
      this.tableData.sorts = extParams.sorts
    },
    // 搜索
    search () {
      this.handlePageChange(1)
    },
    // 导出Excel
    exportExcel () {
      this.__checkApi()
      this.$dialog.exportConfirm('确认导出吗？')
        .then(() => {
          this.isWorking.export = true
          this.api.exportExcel({
            page: this.tableData.pagination.pageIndex,
            capacity: 1000000,
            model: this.searchForm,
            sorts: this.tableData.sorts
          })
            .then(response => {
              this.download(response)
            })
            .catch(e => {
              this.$tip.apiFailed(e)
            })
            .finally(() => {
              this.isWorking.export = false
            })
        })
        .catch(() => {})
    },
    // 搜索框重置
    reset () {
      this.$refs.searchForm.resetFields()
      this.value2 =  '';
      this.value1 = ''
      this.search()
    },
    // 每页显示数量变更处理
    handleSizeChange (pageSize) {
      this.tableData.pagination.pageSize = pageSize
      this.search()
    },
    // 行选中处理
    handleSelectionChange (selectedRows) {
      this.tableData.selectedRows = selectedRows
    },
    // 排序
    handleSortChange (sortData) {
      this.tableData.sorts = []
      console.log('sortData',sortData);
      if (sortData.order != null) {
        this.tableData.sorts.push({
          property: sortData.column.sortBy,
          direction: sortData.order === 'descending' ? 'DESC' : 'ASC'
        })
      }
      this.handlePageChange()
    },
    // 页码变更处理
    handlePageChange (pageIndex) {
    console.log('表单查询-',pageIndex);
      this.__checkApi()
      this.tableData.pagination.pageIndex = pageIndex || this.tableData.pagination.pageIndex
      this.isWorking.search = true
      console.log('表单查询',pageIndex);
      this.api.fetchList({
        page: this.tableData.pagination.pageIndex,
        capacity: this.tableData.pagination.pageSize,
        model: this.searchForm,
        sorts: this.tableData.sorts
      })
        .then(data => {
          console.log(data,'---------页码变更');
          this.$set(this.isWorking,'search',false)
          if(data){
            if(data.types){
              this.tableData.types = data.types
            }
            data.records&&data.records.forEach((i,index)=>{
              i.tableIndex = index
            })
            this.tableData.list = data.records?data.records:[]
            this.tableData.pagination.total = data.total?data.total:0
            
            this.adjustWidth()
          }
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          
          setTimeout(()=>{
            this.adjustWidth()
            this.isWorking.search = false
            this.$set(this.isWorking,'search',false)
          },50)
          
        })
    },
    // 删除
    deleteById (row, childConfirm = true) {
      this.__checkApi()
      let message = `确认删除${this.module}【${row[this.configData['field.main']]}】吗?`
      if (childConfirm && row.children != null && row.children.length > 0) {
        message = `确认删除${this.module}【${row[this.configData['field.main']]}】及其子${this.module}吗?`
      }
      this.$dialog.deleteConfirm(message)
        .then(() => {
          this.isWorking.delete = true
          this.api.deleteById(row[this.configData['field.id']])
            .then(() => {
              this.$tip.apiSuccess('删除成功')
              this.__afterDelete()
            })
            .catch(e => {
              this.$tip.apiFailed(e)
            })
            .finally(() => {
              this.isWorking.delete = false
            })
        })
        .catch(() => {})
    },
    /**
     * 批量删除
     * @treeMode 是否添加子节点删除确认
     */
    deleteByIdInBatch (childConfirm = true) {
      this.__checkApi()
      if (this.tableData.selectedRows.length === 0) {
        this.$tip.warning('请至少选择一条数据')
        return
      }
      let message = `确认删除已选中的 ${this.tableData.selectedRows.length} 条${this.module}记录吗?`
      if (childConfirm) {
        const containChildrenRows = []
        for (const row of this.tableData.selectedRows) {
          if (row.children != null && row.children.length > 0) {
            containChildrenRows.push(row[this.configData['field.main']])
          }
        }
        if (containChildrenRows.length > 0) {
          message = `本次将删除${this.module}【${containChildrenRows.join('、')}】及其子${this.module}记录，确认删除吗？`
        }
      }
      this.$dialog.deleteConfirm(message)
        .then(() => {
          this.isWorking.delete = true
          this.api.deleteByIdInBatch(this.tableData.selectedRows.map(row => row.id).join(','))
            .then(() => {
              this.$tip.apiSuccess('删除成功')
              this.__afterDelete(this.tableData.selectedRows.length)
            })
            .catch(e => {
              this.$tip.apiFailed(e)
            })
            .finally(() => {
              this.isWorking.delete = false
            })
        })
        .catch(() => {})
    },
    // 删除处理
    __afterDelete (deleteCount = 1) {
      // 删除当前页最后一条记录时查询上一页数据
      if (this.tableData.list.length - deleteCount === 0) {
        this.handlePageChange(this.tableData.pagination.pageIndex - 1 === 0 ? 1 : this.tableData.pagination.pageIndex - 1)
      } else {
        this.handlePageChange(this.tableData.pagination.pageIndex)
      }
    },
    // 检查接口是否配置
    __checkApi () {
      if (this.api == null) {
        throw new Error('The page is not initialized, you can use method \'this.config\' to initialize this page.')
      }
    },

    /*
    导出表格
    obj:{
        list:[],//导出的数据
        tHeader:[],//表头内容
        filterVal:[
          {
            key:'status',
            type:'',// 1 normal;  普普通通 2replace【status状态类字段】  3 link  普通连接【address+addressName】 defalut:拼接参数 4 array, [driverName,driverAccount],default:'/'   4 特殊连接【周期，tableList里自己加吧】 
            default:'/',
            item:[
              {value:1,label:'待签约'} 
            ],
            arrayName:',
          }
        ],//与表头对应的参数
        fileName:'',//生成的文件名称
      } 
    */ 
    exportTable(obj) {
      if (this.tableData.selectedRows.length == 0) {
        this.$message.warning("至少选择一条");
        return;
      }

      obj.filterVal.forEach(i=>{
        if(!i.type){
          i.type = 1
        }
        if(i.default==undefined){
          i.default = ''
        }
      })
      if(!obj.list){
        obj.list = this.tableData.selectedRows//导出的数据
      }  
      import("@/vendor/Export2Excel").then((excel) => {
        const data = this.formatData(obj.filterVal,obj.list)
        excel.export_json_to_excel({
          header: obj.tHeader,
          data,
          filename: (obj.fileName || '表格'),
          autoWidth: true,
          bookType: "xlsx",
        });
      });
    },
    //格式化数据
    formatData(filterVal,list){
      return list.map((v) =>
        filterVal.map((j) => {
        console.log(j,'___',v[j.key]);
          if(j.type==1){
            let isNull = ( v[j.key]==null || v[j.key]==undefined || v[j.key]=='' || v[j.key]==NaN )
            return isNull?j.default:v[j.key]
          }else if(j.type==2){
            let noExistLen = 0
            let value = ''
            j.item.map(i=>{
              if(i.value==v[j.key]){
                value = i.label
              }else{
                noExistLen++
              }
            })
            if(noExistLen==j.item.length){
              return j.default
            }else{
              return value
            }
          }else if(j.type==3){ //3
            let value = ''
            j.item.map(i=>{
            
              value = value + (v[i]?(j.default+v[i]):'')
            })
            return value
          }
          else if(j.type==4){ 
            let value = ''
            if(j.arrayName&&j.arrayName.length>0){
            driverInfos.map(arrI=>{
              
            
            })
              j.arrayName.map(arr=>{
                // j.item.map(i=>{
                //   value = value + (v[i]?(j.default+v[i]):'')
                // })
              })
            
            
            }
            
            return value
          }else {
            
            return '类型不存在'
          }
        })
      );
    
    },
    adjustWidth() {
        this.$nextTick(() => {
            // console.log('触发了重置宽高',this.$refs);
            if(this.$refs&&this.$refs.sum_xiaoji&&this.$refs.sum_heji&&this.$refs.table&&this.$refs.table.$refs.headerWrapper) {
            // console.log('触发了重置宽高+1',this.$refs);

                var width = getComputedStyle(this.$refs.table.$refs.headerWrapper.querySelector('table')).width;
                this.$refs.sum_xiaoji.style = 'width:' + width;
                this.$refs.sum_heji.style = 'width:' + width;
                // console.log(this.$refs.table.$refs.headerWrapper);
                // console.log(this.$refs.table.$refs.headerWrapper.querySelectorAll('col'));
                Array.from(this.$refs.table.$refs.headerWrapper.querySelectorAll('col')).forEach((n, i) => {
                // console.log(i, n.getAttribute('width'));
                    if(i==this.$refs.table.$refs.headerWrapper.querySelectorAll('col').length+1){
                     return 
                    }
                    if(this.$refs.sum_xiaoji.children[i]){
                      this.$refs.sum_xiaoji.children[i].style = 'width:' + n.getAttribute('width') + 'px';
                    }
                    if(this.$refs.sum_heji.children[i]){
                      this.$refs.sum_heji.children[i].style = 'width:' + n.getAttribute('width') + 'px';
                    }
                });
            }
        })
    },
    getXiaoji(name){
        var sum=0;
        this.tableData.list.forEach((n,i)=>{
          sum+= Number(n[name])?parseFloat(n[name]):0;
        })
        return sum || '-';
    },
    // 格式化导出数据--弃用
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          switch (j) {
            case "rechargeType":
              return v[j] == 1 ? "微信充值" : "线下充值";
            case "rechargeStatus":
              return v[j] == 1
                ? "待确认"
                : v[j] == 2
                ? "已完成"
                : v[j] == 3
                ? "已驳回"
                : "/";
            default:
              return v[j] || "/";
          }
        })
      );
    },
    //预览 pdf
    previewPDF(url, filename) {
        this.getBlob(url,  (blob)=> {
            this.previewAS(blob, filename);
        });
    },
    getBlob(url, cb) {
        let xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.onload = function () {
            if (xhr.status === 200) {
                cb(xhr.response);
            }
        };
        xhr.send();
    },
    previewAS(blob, filename){
        blob = new Blob([blob], {
            type: 'application/pdf;chartset=UTF-8'
        })
        let fileURL = URL.createObjectURL(blob)
        window.open(fileURL)
    },
  }
}
</script>
