<template>
  <!-- 新建/修改 -->
  <GlobalWindow
    :title="title"
    v-if="visible"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
    
  >
    <el-form :model="form" ref="form" :rules="rules">
      <!-- <el-form-item label="用户名" prop="username" required>
        <el-input v-model="form.username" placeholder="请输入用户名" v-trim maxlength="50"/>
      </el-form-item> -->
      <el-form-item label="姓名" prop="username" required>
        <el-input v-model="form.username" placeholder="请输入姓名" v-trim maxlength="50"/>
      </el-form-item>
      <el-form-item label="手机号码" prop="account" required>
        <el-input v-model.trim="form.account" placeholder="请输入手机号码/账号" v-trim maxlength="11"/>
      </el-form-item>
      <el-form-item label="头像" prop="avatar" >
        <ImageUpload v-model='form.avatar'></ImageUpload>
      </el-form-item>
      <el-form-item v-if="form.id == null" label="初始密码" prop="password" required>
        <el-input v-model="form.password" type="password" placeholder="请输入初始密码" maxlength="30" show-password/>
      </el-form-item>
      <el-form-item label="角色" prop="roleId" required>
        <RoleSelect v-model="form.roleId" placeholder="请选择用户角色" :inline="false" clearable/>
      </el-form-item>
      <el-form-item label="关联公司" prop="companyId" v-if="form.roleId!=1 &&form.roleId!=2 "  :required="(form.roleId!=1 &&form.roleId!=2)?true:false">
        <CompanySelect v-model="form.companyId" placeholder="请选择用户关联子公司" :inline="false" clearable/>
      </el-form-item>
      <!-- <el-form-item label="所属部门" prop="departmentId" v-if='form.companyId&&form.roleId!=1&&form.roleId!=2&&form.roleId!=3' :required="form.roleId!=3?true:false">
        <DepartmentSelect v-model="form.departmentId" :companyId="form.companyId" placeholder="请选择用户所属部门" :inline="false" clearable/>
      </el-form-item> -->
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import DepartmentSelect from '@/components/common/DepartmentSelect'
import PositionSelect from '@/components/common/PositionSelect'
import { checkMobile, checkEmail } from '@/utils/form'
import CompanySelect from '../../common/CompanySelect'
import RoleSelect from '../../common/RoleSelect'
import ImageUpload from '../../common/ImageUpload'



export default {
  name: 'OperaUserWindow',
  extends: BaseOpera,
  components: { PositionSelect, DepartmentSelect, GlobalWindow,CompanySelect,RoleSelect,ImageUpload,},
  data () {
    return {
      visible:false,
      // 表单数据
      form: {
        id: null,
        account: null,
        avatar: "",
        code: "--",//唯一编码(后台使用)
        companyId: null,
        password: "",
        roleId: null,
        username: "",

      },
      // 验证规则
      rules: {
        account:[
          { validator: checkMobile,trigger: 'blur' }
          // {validator:}
        ],
        username: [
          { required: true, message: '请输入用户名' }
        ],
        realname: [
          { required: true, message: '请输入姓名' }
        ],
        password: [
          { required: true, message: '请输入密码' }
        ],
        sex: [
          { required: true, message: '请选择用户性别' }
        ],
        mobile: [
          { validator: checkMobile }
        ],
        roleId:[
          { required: true, message: '请选择角色类型' }
        ],
        companyId:[
          { required: true, message: '请选择关联公司' }
        ],
        
        email: [
          { validator: checkEmail }
        ]
      }
    }
  },
  methods: {
    /**
     * @title 窗口标题
     * @target 编辑的用户对象
     */
    open (title, target) {
      this.title = title
      this.visible = true
      // 新建
      if (target == null) {
        this.$nextTick(() => {
          this.form= {
            id: null,
            account: null,
            avatar: "",
            code: "--",//唯一编码(后台使用)
            companyId: null,
            password: "",
            roleId: null,
            username: "",
          }
          this.$refs.form.resetFields()
        })
        return
      }
      // 编辑
      this.$nextTick(() => { 
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        
        this.form.roleId = target.roleId == null ? null : target.roleId
        this.form.companyId = target.departmentId == null ? null : target.departmentId
        this.form.code = '--'
        // this.form.departmentId = target.department == null ? null : target.departmentId
        this.form.positionIds = target.positions == null ? [] : target.positions.map(p => p.id)

      })
    },
   
  },
  created () {
    this.config({
      api: '/system/user'
    })
  },
  beforeDestroy:()=>{
    
  }
}
</script>

<style lang="scss" scoped>
.global-window {
  /deep/ .el-date-editor {
    width: 100%;
  }
  // 表单头像处理
  /deep/ .form-item-avatar {
    .el-radio.is-bordered {
      height: auto;
      padding: 6px;
      margin: 0 10px 0 0;
      .el-radio__input {
        display: none;
      }
      .el-radio__label {
        padding: 0;
        width: 48px;
        display: block;
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
