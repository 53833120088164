<template>
  <TreeSelect
    :placeholder="placeholder"
    :value="useValue"
    :data="data"
    :clearable="clearable"
    :append-to-body="appendToBody"
    :inline="inline"
    :multiple="multiple"
    :flat="multiple"
    :defaultChecked="defaultChecked"
    @input="inputFun"
  />
  <!-- <tree-transfer :from_data='data' :to_data='toData'
  :defaultProps="{label:'name',id:'',pid:'',children}"
  @addBtn='add'
  @removeBtn='remove'
  :mode='mode'
  height='540px'
  filter openAll>

    </tree-transfer> -->
</template>

<script>
import treeTransfer from 'el-tree-transfer'
import TreeSelect from './TreeSelect'
import { toProjectUsers, inProjectUsers } from '@/api/system/department'
export default {
  name: 'projectUser',
  components: { TreeSelect, treeTransfer },
  props: {
    value: {

    },
    inline: {
      default: true
    },
    multiple: {
      default: false
    },
    placeholder: {
      default: '请选择'
    },
    // 是否可清空
    clearable: {
      default: false
    },
    appendToBody: {
      default: false
    },
    // 需被排除的部门ID
    excludeId: {},
    defaultChecked: {},
    fromObjId: {
      default: ''
    }
  },
  data () {
    return {
      data: [],
      toData: [],
      mode: 'transfer', // transfer addressList
      useValue: null
    }
  },
  watch: {
    excludeId () {
      this.fetchData()
    },
    defaultChecked: {
      handler (val, olv) {
        val != olv && this.fetchData()
      }
    },

    value: {
      handler () {
        const inset = this.value
        this.useValue = inset
      },
      immediate: true
    }

  },
  methods: {
    // 获取所有部门
    fetchData () {
      if (this.fromObjId == '') {
        this.toProjectUsers() // 项目新增
      } else {
        this.inProjectUsers()// 项目内人员查询
      }
    },
    // 项目内人员查询 （任务、里程碑等创建时的需要）
    inProjectUsers () {
      inProjectUsers({ projectId: this.fromObjId }).then(records => {
        this.afterApi(records)
      })
      // .catch(e => {
      //   this.$tip.apiFailed(e)
      // })
    },
    // 项目新增  用于创建或编辑新的项目
    toProjectUsers () {
      toProjectUsers()
        .then(records => {
          this.afterApi(records)
        })
        // .catch(e => {
        //   this.$tip.apiFailed(e)
        // })
    },
    afterApi (records) {
      this.data = []
      const data = []
      if (records.length > 0) {
        records.map(item => {
          if (item.users && item.users.length > 0) {
            data.push(...item.users)
          }
          if (item.children && item.children.length > 0) {
            item.children.map(ii => {
              if (ii.users) {
                data.push(...ii.users)
              }

              if (ii.children && ii.children.length > 0) {
                ii.children.map(i => {
                  if (i.users) {
                    data.push(...i.users)
                  }
                })
              }
            })
          }
        })
      }
      if (this.excludeId) { // 过滤id
        const index = data.findIndex(i => i.id == this.excludeId)
        if (index != -1) {
          data.splice(index, 1)
        }
      }
      data.map(i => {
        // i.label = i.username+'('+i.account+')'
        i.label = i.username
        if (i.id == this.defaultChecked) {
          i.isDisabled = true
        } else {
          i.isDisabled = false
        }
      })

      this.data = data
      if (this.defaultChecked && this.useValue.findIndex(i => i == this.defaultChecked) == -1) { // 存在默认选中则value赋值
        this.useValue.push(this.defaultChecked)
        // this.$emit('input', this.defaultChecked);
      }
      // this.value = Array.from(new Set(this.value)).filter(function (s) {return s && String(s).trim();})

    },

    // 填充部门树
    __fillData (list, pool) {
      console.log(list, pool, '修改树结构')
      for (const user of pool) {
        if (user.id === this.excludeId) {
          continue
        }
        const deptNode = {
          ...user,
          id: user.id,
          label: user.username + '-' + user.account
          // user:JSON.stringify(user)
        }
        list.push(deptNode)
        if (user.children != null && user.children.length > 0) {
          deptNode.children = []
          this.__fillData(deptNode.children, user.children)
          if (deptNode.children.length === 0) {
            deptNode.children = undefined
          }
        }
      }
    },
    inputFun (id) {
      this.$emit('input', id)
      const data = this.data.filter(i => i.id == id)
      this.$emit('row', data[0])
    },
    add (fromData, toData, obj) {
      // 树形穿梭框模式transfer时，返回参数为左侧树移动后数据、右侧树移动后数据、移动的        {keys,nodes,halfKeys,halfNodes}对象

      // 通讯录模式addressList时，返回参数为右侧收件人列表、右侧抄送人列表、右侧密送人列表

      console.log('fromData:', fromData)

      console.log('toData:', toData)

      console.log('obj:', obj)
    },

    // 监听穿梭框组件移除

    remove (fromData, toData, obj) {
      // 树形穿梭框模式transfer时，返回参数为左侧树移动后数据、右侧树移动后数据、移动的{keys,nodes,halfKeys,halfNodes}对象

      // 通讯录模式addressList时，返回参数为右侧收件人列表、右侧抄送人列表、右侧密送人列表

      console.log('fromData:', fromData)

      console.log('toData:', toData)

      console.log('obj:', obj)
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
