<template>
<el-dialog
    class="global-window"
    title="操作记录"
    status-icon
    v-if="visible"
    :visible="visible"
    
    :width="width"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="visible = false"
    :center='true'
    append-to-body
  >
    <div class="window__body">
      <TableLayout  :withBreadcrumb="false">
    
        <template v-slot:table-wrap>
          <el-table
            v-loading="isWorking.search"
            :data="tableData.list"
            stripe
            @selection-change="handleSelectionChange"
            @sort-change="handleSortChange"
          >
            <el-table-column prop="operationUserName" label="操作人" min-width="120px">
              <div slot-scope="{row}">{{row.operationUserName || row.operationNickName}}</div>
            </el-table-column>
            <el-table-column prop="operationTime" label="操作时间" min-width="100px"></el-table-column>
            <el-table-column prop="content" label="操作内容" min-width="100px">
              <div slot-scope="{row}">{{row.content || row.remark}}</div>
            </el-table-column>
          </el-table>
          <pagination @size-change="handleSizeChange" @current-change="handlePageChange" :pagination="tableData.pagination"></pagination>
        </template>
      </TableLayout>
    </div>
  </el-dialog>
  
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import Pagination from '@/components/common/Pagination'
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import { fetchList , getLogList  } from '../../../api/layun/record'
// 操作类型 1商家 2设备安装单 3拉运单 4入库单 5出库单 6出库拉运单 7拉运结算单 9出库结算单
export default {
  name: 'OperationRecord',
  extends: BaseTable,
  components: {  GlobalWindow,Pagination,TableLayout },
  data () {
    return {
      searchForm: {
        basicsDataType:null,
        id:null
      },
      title:'',
      visible:false,
      width:'36%'
    }
  },
  methods: {
    /**
     * @apiType: 对标的api类型 
     * /*
     * 1 基础数据类型 fetchList 1设备 2仓库 3出库商家 4客服数据 5街道办数据 20账号操作记录表 21邀约金操作记录 22充值金操作记录
     * 2 getLogList 操作类型 1商家 2设备安装单 3拉运单 4入库单 5出库单 6出库拉运单 7拉运结算单 9出库结算单
     * 
     * 
     * *
     * 
     * @target: 当前条数据 row
     * @parent: type类型
     */
    open (apiType, target, parent) {
      console.log(apiType, target, parent,'--s-ds');
      this.apiType = apiType
      this.visible = true
      if(apiType==1){
        this.searchForm.id = parent==22?target.userId:target.id
        this.searchForm.basicsDataType = parent
      }
      if(apiType==2){
        this.searchForm.businessId = target.id || target.exId
        this.searchForm.type = parent
      }
      
      this.config({
        api: '/layun/record'
      })
      this.search()
      
    },
    // 页码变更处理
    handlePageChange (pageIndex) {
      console.log('this.searchForm',this.searchForm);
      this.__checkApi()
      this.tableData.pagination.pageIndex = pageIndex || this.tableData.pagination.pageIndex
      this.isWorking.search = true
      let api = this.apiType==1?fetchList:getLogList
      api({
        page: this.tableData.pagination.pageIndex,
        capacity: this.tableData.pagination.pageSize,
        model: this.searchForm,
        sorts: this.tableData.sorts
      })
        .then(data => {
          this.isWorking.search = false
          if(data){
            if(data.types){
              this.tableData.types = data.types
            }
            this.tableData.list = data.records?data.records:[]
            this.tableData.pagination.total = data.total?data.total:0
          }
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          this.isWorking.search = false
          this.$set(this.isWorking,'search',false)
          // this.adjustWidth()
        })
    },
  },
  created () {
    
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables";
$icon-background-color: $primary-color;
.global-window {
  .tip {
    margin-bottom: 12px;
    em {
      font-style: normal;
      color: $primary-color;
      font-weight: bold;
    }
  }
  // 图标
  /deep/ .form-item-icon {
    .el-form-item__content {
      height: 193px;
      overflow-y: auto;
    }
    .el-radio-group {
      background: $icon-background-color;
      padding: 4px;
      .el-radio {
        margin-right: 0;
        color: #fff;
        padding: 6px;
        &.is-checked {
          background: $icon-background-color - 30;
          border-radius: 10px;
        }
        .el-radio__input.is-checked + .el-radio__label {
          color: #fff;
        }
      }
      .el-radio__input {
        display: none;
      }
      .el-radio__label {
        padding-left: 0;
        // element-ui图标
        i {
          font-size: 30px;
        }
        // 自定义图标
        [class^="eva-icon-"], [class*=" eva-icon-"] {
          width: 30px;
          height: 30px;
          background-size: 25px;
          vertical-align: bottom;
        }
      }
      .el-radio--small {
        height: auto;
        padding: 8px;
        margin-left: 0;
      }
    }
  }
}
</style>
