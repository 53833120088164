<template>
  <div>
    <TreeSelect
      :placeholder="placeholder"
      :value="value"
      :data="data"
      :clearable="clearable"
      :append-to-body="appendToBody"
      :inline="inline"
      :multiple="multiple"
      :flat="multiple"
      @input="inputChange"
    />
  </div>
  
</template>

<script>
import TreeSelect from '../../common/TreeSelect.vue'
import { fetchAll } from '../../../api/layun/basicDataManagement/bussinessArea'
export default {
  name: 'RoleSelect',
  components: { TreeSelect },
  props: {
    value: {},
    inline: {
      default: true
    },
    //optionId 只展示这几个id 可供选择
    optionId:{
      default:()=>[]
    },
    selectMode:{ //人员选择框的适用场景
      default: 'search',//changeUser 移交人员  search 搜索
    },
    multiple: {//是否可多选
      default: true
    },
    placeholder: {
      default: '请选择部门'
    },
    showAlert:{
      //是否展示告警信息
      default: false
    },
    // 是否可清空
    clearable: {
      default: false
    },
    appendToBody: {
      default: false
    },
    // 需被排除的部门ID
    excludeId: {
      default:()=>[]
    },

  },
  data () {
    return {
      data: []
    }
  },
  watch: {
    excludeId () {
      this.fetchData()
    },
    optionId () {
      this.fetchData()
    }
  },
  methods: {
    // 获取所有部门
    fetchData () {
      
      fetchAll()
        .then(records => {

          console.log(records,'司机区域');
          this.data = []
          this.__fillData(this.data, records)
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
    },
    // 填充树
    __fillData (list, pool) {
      console.log(list,pool,'----');
      for (const dept of pool) {
        if (this.excludeId.length>0&&this.excludeId.findIndex(i=>i==dept.id)!=-1) {
          continue
        }
        const deptNode = {
          id: dept.id,
          label: dept.regionName,
          item:dept
        }
        if(this.selectMode=='changeUser'){ //传入了默认的
          if(this.optionId.length>0){
            //存在设置的展示树
            let idx = this.optionId.findIndex(i=>i==dept.id)
            if(idx!=-1){
              list.push(deptNode)
            }
          
          }
        
        }
        else{
          list.push(deptNode)
        }

        // list.push(deptNode)
        
      }
    },
    inputChange(value){
      this.$emit('input', value)
      this.$emit('change')

    }
  },
  created () {
    this.fetchData()
  }
}
</script>
