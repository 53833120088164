<template>
  <GlobalWindow
    class="handle-table-dialog"
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-alert type="warning" v-if="form.businessRoleId==2&&title=='账户冻结'" title="将同步冻结所有处于启动状态的子账号"></el-alert>
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item :label="form.businessRoleId==1?'姓名':'管理员姓名'" prop="username">
        {{form.username}}
      </el-form-item>
      <el-form-item :label="form.businessRoleId==1?'账号':'管理员账号'" prop="account">
        {{form.account}}
      </el-form-item>
      <el-form-item label="角色" prop="roleId" v-if="form.businessRoleId==1">
        <div v-if="form.roleName.length>0">
          <el-tag v-for="item in form.roleName" style="margin:0 4px">{{item}}</el-tag>
        </div>
      </el-form-item>
      <el-form-item label="备注" prop="remarks" :rules="[{ required: this.required, message: '请输入备注' }]">
        <el-input v-model="form.remarks" placeholder="请输入备注" v-trim maxlength="200"/>
      </el-form-item>
      
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import {updateUserStatus,updateRoleStatus,updateShopStatus} from '../../../api/system/user'
export default {
  name: 'UserStatusOpera',
  extends: BaseOpera,
  components: {  GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        shopId:null,
        username:'',
        account:'',
        roleId:null,
        roleName:null,
        remarks:'',
        status:0,
        businessRoleId:1,
      },
      required:false,//备注是否必填
      // 验证规则
      rules: {
        remarks: [
          { required: this.required, message: '请输入备注' }
        ],
        // equipmentModel: [
        //   { required: true, message: '请输入型号' }
        // ]
      }
    }
  },
  methods: {
    /**
     * @title: 窗口标题
     * @target: 编辑的菜单对象
     * @parent: 新建时的上级菜单
     */
    open (title, target, parent) {
      this.title = title
      this.visible = true
      // 新建，menu为空时表示新建菜单
      if (target == null) {
        // this.excludeMenuId = null
        this.$nextTick(() => {
          this.$refs.form.resetFields()
          this.form.id = null
        })
        return
      }
      // 编辑
      this.$nextTick(() => {
        console.log(title, target, parent,'--结局');
        // this.excludeMenuId = target.id
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        this.form.status = parent?parent.status:0;
        this.form.businessRoleId = parent?parent.businessRoleId:1;
        this.form.shopRole = parent&&parent.businessRoleId==2?1:null;
        this.form.roleName = [target.roleName]

        this.form.userId = target.id?target.id:null;
        if(this.form.businessRoleId!=1){
          this.form.username = target.adminUserName
          this.form.account = target.adminUserAccount
          this.form.shopId = target.id
          this.form.userId = target.adminId

        }
        if(this.form.status==2){
          let idArr = [target.roleId]
          let nameArr = [target.roleName]
          if(target.children&&target.children.length>0){
           
            target.children.map(item=>{
              idArr.push(item.roleId)
              nameArr.push(item.roleName)
            })
          }

          this.form.roleId = idArr
          this.form.roleName = nameArr


        }
        this.form.remarks = ''
        this.required = this.form.status==0?false:true
      })
    },

    confirm(){
       this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        }
        // 调用新建接口
        this.isWorking = true
        let updateStatus = this.form.businessRoleId==2?updateShopStatus:this.form.status==2?updateUserStatus:updateRoleStatus
        updateStatus(this.form)
          .then((res) => {
            if(res.code==500){
              this.$message.error(res.message);
              return 
            }
            this.visible = false
            this.$tip.apiSuccess(res.data?res.data:'修改成功')
            this.$emit('success')
          })
          .catch(e => {
            this.$tip.apiFailed(e)
          })
          .finally(() => {
            this.isWorking = false
          })
      })
    
    },
  },
  created () {
    // this.config({
    //   api: '/layun/basicDataManagement/equipmentData'
    // })
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables";
$icon-background-color: $primary-color;
.global-window {
  .tip {
    margin-bottom: 12px;
    em {
      font-style: normal;
      color: $primary-color;
      font-weight: bold;
    }
  }
  // 图标
  /deep/ .form-item-icon {
    .el-form-item__content {
      height: 193px;
      overflow-y: auto;
    }
    .el-radio-group {
      background: $icon-background-color;
      padding: 4px;
      .el-radio {
        margin-right: 0;
        color: #fff;
        padding: 6px;
        &.is-checked {
          background: $icon-background-color - 30;
          border-radius: 10px;
        }
        .el-radio__input.is-checked + .el-radio__label {
          color: #fff;
        }
      }
      .el-radio__input {
        display: none;
      }
      .el-radio__label {
        padding-left: 0;
        // element-ui图标
        i {
          font-size: 30px;
        }
        // 自定义图标
        [class^="eva-icon-"], [class*=" eva-icon-"] {
          width: 30px;
          height: 30px;
          background-size: 25px;
          vertical-align: bottom;
        }
      }
      .el-radio--small {
        height: auto;
        padding: 8px;
        margin-left: 0;
      }
    }
  }
}
</style>
