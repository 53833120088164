import request from '@/utils/request'

//新建
export function create (data) {
  return request.post('/business/question/creatQuestion',data)
}

// 查询
export function fetchList (data) {
  return request.post('/business/question/page',data)
}

//详情
export function queryQuestionInfoById (data) {
  return request.get('/business/question/queryQuestionInfoById',{
    params:data
  })
}

//回复
export function creatQuestionReply (data) {
    return request.post('/business/question/creatQuestionReply',data)
}

//删除附件
export function deleteEnclosure (data) {
  return request.get('/business/question/deleteEnclosure',{
    params:data
  })
}

//删除回复
export function deleteFollowUpByQuestionId (data) {
  return request.get('/business/question/deleteRecord',{
    params:data
  })
}

// 删除问题
export function deleteQuestion (data) {
  return request.get('/business/question/delete',{
    params:data
  })
}
