<template>
<div style="margin:20px auto">
  <el-form ref="projectionScreen" :model="projectionScreen" label-width="180px" style="width:1000px;margin-left:74px">
    <el-form-item label="展示项目周期" >
      <el-date-picker class="fixWidth"  type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" v-model="showProjectDayRange" @change="changeDate" disabledDate="" picker-options></el-date-picker> 
      <!-- 至 -->
      <!-- <el-date-picker type="month" value-format="yyyy-MM" placeholder="选择日期" v-model="projectionScreen.projectStatusEndMonth" ></el-date-picker> -->
    </el-form-item>
    <el-form-item label="项目问题展示"  >
      <el-date-picker class="fixWidth" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" v-model="showQuestionDayRange" @change="changeQuestionDate"></el-date-picker> 
      <!-- 至
      <el-date-picker type="month" value-format="yyyy-MM" placeholder="选择日期" v-model="projectionScreen.projectQuestionsEndMonth" ></el-date-picker> -->
      <!-- <el-select v-model="projectionScreen.projectQuestionsOrderType" placeholder="请选择活动区域" style="margin-left:20px">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select> -->
    </el-form-item>

    <!-- <el-form-item label="项目问题展示数据刷新时间">
      <el-select v-model="projectionScreen.projectQuestionsRefreshTime" placeholder="请选择" >
        <el-option v-for="item in refreshTimeOptions" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </el-form-item> -->

    <el-form-item label="项目得分展示"  >
      <!-- <el-date-picker type="month" value-format="yyyy-MM" placeholder="选择日期" v-model="projectionScreen.projectScoreBeginMonth" ></el-date-picker> 至
      <el-date-picker type="month" value-format="yyyy-MM" placeholder="选择日期" v-model="projectionScreen.projectScoreEndMonth" ></el-date-picker> -->
      <el-select class="fixWidth" v-model="projectionScreen.projectScoreOrderType" placeholder="请选择" >
        <el-option label="按得分从高到低" :value="1"></el-option>
        <el-option label="按得分从低到高" :value="2"></el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="项目列表滚动速度">
      <el-input class="fixWidth" placeholder="数字越大，滚动越快" type="number" :maxlength="2" :max='10' :min='0.0' :step="0.1"  v-model="projectionScreen.projectRollingSpeed"></el-input>
      <!-- <p></p> -->
      <!-- <el-select v-model="projectionScreen.projectRollingSpeed" placeholder="请选择" >
        <el-option label="1s" :value="0.5"></el-option>
        <el-option label="2s" :value="0.6"></el-option>
        <el-option label="2s" :value="0.7"></el-option>
        <el-option label="2s" :value="0.8"></el-option>
        <el-option label="2s" :value="0.9"></el-option>
        <el-option label="2s" :value="1.0"></el-option>

      </el-select> -->
    </el-form-item>

    <!-- <el-form-item label="轮播间隔时间">
      <el-select v-model="projectionScreen.leaderRefreshTime" placeholder="请选择" >
        <el-option v-for="item in refreshTimeOptions" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="负责人排序">
      <el-select v-model="projectionScreen.leaderOrderType" placeholder="请选择" >
        <el-option v-for="item in responsibleOptions" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="负责人项目展示排序调整">
      <draggable v-model="sortButtons" :animation="300">
        <transition-group name='oo'>
          <span v-for='item in sortButtons' :key='item' style="margin-right:10px">
            <el-button type='primary'>{{item.name}}</el-button>
          </span>
        </transition-group>
      </draggable>
    </el-form-item> -->
  <el-form-item v-if="showSave">
    <el-button type="primary" @click="onSubmit" :loading="btnLoading">保存</el-button>
  </el-form-item>
</el-form>

</div>
</template>

<script>
import draggable from 'vuedraggable'
import {querySettings,saveSettings} from '@/api/manage/rules'
import {equalObject} from '@/utils/util.js'

import { mapState, mapMutations } from 'vuex'
export default {
  name:'scrules',
  components:{
    draggable
  },
  computed: {
    ...mapState({
      setting: state=>state.setting,
      homePage: state=>state.init.homePage,
    })

  },
  watch:{
    'projectionScreen.projectBeginMonth':{
      handler(val){
        if(val){
          this.showProjectDayRange = [this.projectionScreen.projectBeginMonth,this.projectionScreen.projectEndMonth]
        }
      },
      immediate:true
    },
    'projectionScreen.projectEndMonth':{
      handler(val){
        if(val){
          this.showProjectDayRange = [this.projectionScreen.projectBeginMonth,this.projectionScreen.projectEndMonth]
        }
      },
      immediate:true
    }, 
    'projectionScreen.projectQuestionsBeginMonth':{
      handler(val){
        if(val){
          this.showQuestionDayRange = [this.projectionScreen.projectQuestionsBeginMonth,this.projectionScreen.projectQuestionsEndMonth]
        }
      },
      immediate:true
    },
    'projectionScreen.projectQuestionsEndMonth':{
      handler(val){
        if(val){
          this.showQuestionDayRange = [this.projectionScreen.projectQuestionsBeginMonth,this.projectionScreen.projectQuestionsEndMonth]
        }
      },
      immediate:true
    },
    'projectionScreen':{
      handler(val){
        if(val){
          console.log(val,this.basic);
          console.log(JSON.stringify(val)==JSON.stringify(this.basic));
          if(JSON.stringify(val)!=JSON.stringify(this.basic)){
            this.showSave = true
          }else{
            this.showSave = false
          }

        }
      },
      deep:true
    }

  },
    data() {
      return {
        showProjectDayRange:[],
        showQuestionDayRange:[],
        showSave:false,
        btnLoading:false,
        // sortButtons:[
        //   {name:'进行中'},
        //   {name:'已完成'},
        //   {name:'预启动'},
        //   {name:'已暂停'},
        //   {name:'已取消'},
        //   {name:'已超期'},
        // ],
        // options: [
        //   {value: 1,label: '按问题个数从高到低'}, 
        //   {value: 2,label: '按问题状态(未处理数量)从高到低'}, 
        //   {value: 3,label: '按问题状态(处理中数量)从高到低'}, 
        //   {value: 4,label: '按问题状态(已解决数量)从高到低'}
        // ],
        // value: 1,
        // refreshTimeOptions: [
        //   {value: 1,label: '10s'},
        //   {value: 2,label: '15s'}, 
        //   {value: 3,label: '30s'}
        // ],
        // refreshTime:1,

        // responsibleOptions: [
        //   {value: 1,label: '按项目总数从高到低'},
        //   {value: 2,label: '按项目状态进行中的个数从高到低'}, 
        //   {value: 3,label: '按项目状态已完成的个数从高到低'},
        //   {value: 4,label: '按项目状态已暂停的个数从高到低'},
        //   {value: 5,label: '按项目状态预启动的个数从高到低'}, 
        //   {value: 6,label: '按项目超期的个数从高到低'}
        // ],
        // responsibleProjectShowOptions: [
        //   {value: 1,label: '按项目创建时间从最近倒叙'},
        //   {value: 2,label: '按项目工期开始时间从最近倒叙'}
        // ],
        basic:{},
        projectionScreen: {
          // projectStatusEndMonth:'',
          // projectStatusBeginMonth:'',
          // projectScoreRefreshTime:'',
          // projectScoreOrderType:'',
          // projectScoreEndMonth:'',
          // projectScoreBeginMonth:'',
          // projectQuestionsRefreshTime:'',
          // projectQuestionsOrderType:'',
          // projectQuestionsEndMonth:'',
          // projectQuestionsBeginMonth:'',
          // leaderRefreshTime:'',
          // leaderProjectOrder:'',
          // leaderOrderType:''

          projectBeginMonth: '',
          projectEndMonth: '',
          projectQuestionsBeginMonth: '',
          projectQuestionsEndMonth: '',
          // projectRefreshTime: 2,
          projectScoreOrderType: 1,
          projectRollingSpeed:'',
        }
      }
    },
    mounted(){
      this.querySettings()
    },
    methods: {
      changeDate(value){
        console.log(value);
        this.projectionScreen.projectBeginMonth = value?value[0]:''
        this.projectionScreen.projectEndMonth = value?value[1]:''
      },
      changeQuestionDate(value){
        console.log(value);

        this.projectionScreen.projectQuestionsBeginMonth = value?value[0]:''
        this.projectionScreen.projectQuestionsEndMonth = value?value[1]:''
      },
      onSubmit() {
        this.btnLoading = true
        saveSettings({projectionScreen:this.projectionScreen}).then(res=>{
          this.showSave = false;
          this.$tip.success('保存成功')
        }).finally(()=>{
          this.btnLoading = false
        })
      },
      querySettings(){
        querySettings().then(res=>{
          console.log(res,'00s0d0');
          
          if(res && res.projectionScreen) {
            console.log(res.projectionScreen,'--as-a');
            console.log(Object.keys(res.projectionScreen));
            for (const key in res.projectionScreen) {
              res.projectionScreen[key] = res.projectionScreen[key]?res.projectionScreen[key]:''
              // this.form[key] = target[key]
            }
            this.projectionScreen = res.projectionScreen
            this.basic = JSON.parse(JSON.stringify(res.projectionScreen))
            this.showProjectDayRange=[res.projectionScreen.projectBeginMonth,res.projectionScreen.projectEndMonth]
            this.showQuestionDayRange=[res.projectionScreen.projectQuestionsBeginMonth,res.projectionScreen.projectQuestionsEndMonth]
          }
        }).catch(e => {
          throw e
        })
        .finally(() => {
          this.loading = false
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
.fixWidth{
  width:245px;
}
</style>

