<template>
  <TreeSelect
    :placeholder="placeholder"
    :value="value"
    :data="data"
    :clearable="clearable"
    :append-to-body="appendToBody"
    :inline="inline"
    :multiple="multiple"
    :flat="multiple"
    @input="$emit('input', $event)"
  />
</template>

<script>
import TreeSelect from './TreeSelect'
import { fetchTree } from '@/api/system/department'
export default {
  name: 'DepartmentSelect',
  components: { TreeSelect },
  props: {
    value: {
      default:null,
    },
    inline: {
      default: true
    },
    multiple: {
      default: false
    },
    placeholder: {
      default: '请选择部门'
    },
    // 是否可清空
    clearable: {
      default: false
    },
    appendToBody: {
      default: false
    },
    // 需被排除的部门ID
    excludeId: {},
    companyId:{},//公司id
  },
  data () {
    return {
      data: [],
      records:[],
    }
  },
  watch: {
    excludeId () {
      this.fetchData()
    },
    value:{
      handler(){
      console.log(this.value,'----选择部门',this.records);
      let dept = this.records.filter(i=>i.id==this.value)
      this.data = dept
        // this.fetchData()
      },
      // immediate:true
      // let dept = this.records.filter(i=>i.id==this.value)
      // this.data = []
      // if(dept[0]&&dept[0].children){
      //   this.__fillData(this.data, dept[0].children)
      // }
    },
    companyId(){
      console.log('公司发生了变化');
      this.$emit('input', null)
      let dept = this.records.filter(i=>i.id==this.companyId)
      this.data = []
      if(dept[0]&&dept[0].children){
        this.__fillData(this.data, dept[0].children)
      }
    }
  },
  methods: {
    // 获取所有部门
    fetchData () {
      fetchTree()
        .then(records => {
          this.data = []
          this.records = records

          let dept = records.filter(i=>i.id==this.companyId)
          
          if(dept[0]&&dept[0].children){
            this.__fillData(this.data, dept[0].children)
          }
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
    },
    // 填充部门树
    __fillData (list, pool) {
      console.log(list,pool,'----部门数');
       
    
      for (const dept of pool) {
        if (dept.id === this.excludeId) {
          continue
        }
          const deptNode = {
            id: dept.id,
            label: dept.name
          }
          list.push(deptNode)
          if (dept.children != null && dept.children.length > 0) {
            deptNode.children = []
            this.__fillData(deptNode.children, dept.children)
            if (deptNode.children.length === 0) {
              deptNode.children = undefined
            }
          }
      }
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
