/**
 * 动态加载腾讯地图api函数
 * @param {String} key  key
 */
 export default function loadMap() {
  //需提醒加白名单  目前服务类附加库暂不支持签名&授权 IP 校验


    let key = 'BTJBZ-KLJCD-WI44K-PN5JU-26HBJ-33BFH'
    let sk = 'MMHVf6OAbLOjmJjRC7xkq7cjIRQ3MpnT';//

    key = 'ROKBZ-XMZK3-IHU3N-YIDJ4-AMMPE-EDF2Y'
    return new Promise(function(resolve, reject) {
      if (typeof window.TMap !== 'undefined') {
        resolve(window.TMap)
        return true
      }
      window.onTMapCallback = function() {
        resolve(window.TMap)
      }
      
      let script = document.createElement('script')
      script.type = 'text/javascript'
      script.src =
        'https://map.qq.com/api/gljs?v=1.exp&libraries=service&key=' + key + '&sig='+sk+'&callback=onTMapCallback'
      script.onerror = reject
      document.head.appendChild(script)
    })
  } 