<template>
    <TableLayout :permissions="['basics:outboundmerchant:query']">
      <!-- 搜索表单 -->
      <el-form
        ref="searchForm"
        slot="search-form"
        :model="searchForm"
        label-width="100px"
        inline
      >
        <el-form-item label="分组名称" prop="name">
          <el-input
            v-model="searchForm.name"
            v-trim
            placeholder="请输入分组名称"
            @keypress.enter.native="search"
            style="width: 250px"
          />
        </el-form-item>  
        <section>
          <el-button type="primary" icon="el-icon-search" @click="search"
            >查询</el-button
          >
          <el-button @click="reset">重置</el-button>
        </section>
      </el-form>
  
      <template v-slot:table-wrap>
        <ul
          class="toolbar"
          v-permissions="['basics:outboundmerchant:create']"
          v-if="userInfo.currentMcRoleStatus==0&&currentRole!=roles.treasurer"
        >
          <li v-permissions="['basics:outboundmerchant:create']">
            <el-button
              icon="el-icon-plus"
              type="primary"
              @click="$refs.OperaGroupDataWindow.open('新建分组')"
              >新增</el-button
            >
          </li>
        </ul>
        <el-table
          v-loading="isWorking.search"
          :data="tableData.list"
          :default-sort="{ prop: 'createTime', order: 'descending' }"
          stripe
          @selection-change="handleSelectionChange"
          @sort-change="handleSortChange"
        >
          <el-table-column type="selection" width="55px" align="center"></el-table-column>
          <el-table-column prop="name" label="标签名称" min-width="120px" align="center">
          </el-table-column>
          <!-- <el-table-column prop="labelColour" label="标签颜色" min-width="100px" align="center"></el-table-column>   -->
          <el-table-column prop="labelColour" label="标签颜色" min-width="100px" align="center">
            <div slot-scope="{row}">
                <span :style="{background:row.labelColour,display:'inline-block',padding:'0px 5px',borderRadius:'5px',fontWeight:'bold'}">{{ row.name }}</span>
                
            </div>
          </el-table-column>  
          <el-table-column
            v-if=" containPermissions([ 'basics:outboundmerchant:query', 'basics:outboundmerchant:create', 'basics:outboundmerchant:delete' ]) &&userInfo.currentMcRoleStatus==0 &&currentRole!=roles.treasurer" label="操作" width="270" fixed="right" align="center"
          >
            <div slot-scope="{ row }">
              <el-button :permissions="['basics:outboundmerchant:create']" type="text" icon="el-icon-edit" @click="$refs.OperaGroupDataWindow.open('修改分组',row)">修改</el-button>
              <el-button :permissions="['basics:outboundmerchant:delete']"  type="text" icon="el-icon-edit" @click="deleteById(row)">删除</el-button>
              <!-- <el-button type="text" icon="el-icon-notebook-2" @click="$refs.operaRecordWindow.open(1,row,3)">操作记录</el-button> -->
            </div>
          </el-table-column>
        </el-table>
        <pagination @size-change="handleSizeChange" @current-change="handlePageChange" :pagination="tableData.pagination"></pagination>
      </template>
      <!-- 新建/修改 -->
      <!-- <OperaGroupDataWindow ref="OperaGroupDataWindow" @success="handlePageChange(tableData.pagination.pageIndex)"/> -->
      <OperaGroupDataWindow ref="OperaGroupDataWindow" @success="handlePageChange(tableData.pagination.pageIndex)"/>
      <!-- 操作记录 -->
      <OperaRecordWindow ref="operaRecordWindow"></OperaRecordWindow>
    </TableLayout>
  </template>
  
  <script>
  
  
  import Pagination from "@/components/common/Pagination";
  import TableLayout from "@/layouts/TableLayout";
  import BaseTable from "@/components/base/BaseTable";
  import OperaGroupDataWindow from "../../../components/layun/basicDataManagement/OperaGroupDataWindow.vue";
  import OperaRecordWindow from '../../../components/layun/common/OperaRecordWindow.vue'
  import { mapState } from 'vuex';
  
  export default {
    name: "分组管理",
    extends: BaseTable,
    components: {
      OperaGroupDataWindow,
      TableLayout,
      Pagination,
      OperaRecordWindow,
    },
    data() {
      return {
        // 搜索
        searchForm: {
          name: null,
          chargePeopleNamePhone: null,
        },
      };
    },
    computed: {
      ...mapState({
        userInfo:state=>state.init.userInfo || {},
        currentRole:state=>state.init.userInfo.currentRole,
        roles:state=>state.init.roles
      }),
    },
    created() {
      this.config({
        module: '分组管理',
        api: '/layun/basicDataManagement/groupData',
        // 'field.main': 'account',
        sorts: [{
          property: 'CREATE_TIME',
          direction: 'DESC'
        }]
      })
      this.search()
    },
    methods: {
      handleCommand(command) {
        this.$message("click on item " + command);
      },
      addressChange(value) {
        console.log("地图选点", value);
      },
    },
  };
  </script>
  
  <style scoped lang="scss">
  @import "@/assets/style/variables.scss";
  // 列表头像处理
  .table-column-avatar {
    img {
      width: 48px;
    }
  }
 
  </style>
  