import request from '@/utils/request'
//轮播规则设置

// 查询配置项
export function querySettings () {
  return request.get('/settings/querySettings')
}

// 修改配置项
export function saveSettings (data) {
  return request.post('/settings/saveSettings',data)
}



// 获取图片验证码
export function getCaptcha () {
  return request.get('/common/captcha')
}