import request from '@/utils/request'

// 获取图片验证码
export function getCaptcha () {
  return request.get('/common/captcha')
}

// //新建
// export function create (data) {
//   return request.post('/business/project/create',data)
// }

// 查询
export function expenditure (data) {
  return request.get('/business/project/queryExpenditure',{params:data})
}

// 支出修改
export function updateActual (data) {
  return request.get('/business/project/updateActual',{params:data})
}


// //查询项目详情 展示
// export function projectDetailShow (data) {
//   return request.get('/business/project/show',{
//     params:data
//   })
// }

// //查询项目详情 编辑
// export function projectDetailQuery (data) {
//   return request.get('/business/project/query',{
//     params:data
//   })
// }

// // 项目变更
// export function createModify (data) {
//   return request.post('/business/project/createModify',data)
// }

// // 更新进度和状态  改变项目状态
// export function updateSpeedAndStatus (data) {
//   return request.post('/business/project/updateSpeedAndStatus',data)
// }
