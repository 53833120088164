<template>
<div style="padding:20px ">
    <el-breadcrumb separator="/" style="margin-bottom:20px">
        <el-breadcrumb-item v-for="path in paths" >{{path}}</el-breadcrumb-item>
    </el-breadcrumb>


    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="邀约金总计列表" name="first" >
            <TableLayout  :withBreadcrumb="false" v-if="activeName=='first'">
                
                <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
                <el-form-item label="邀约人" prop="invitation">
                    <el-input v-model="searchForm.invitation" v-trim placeholder="请输入姓名、昵称、手机号" @keypress.enter.native="search" style="width:250px"/>
                </el-form-item>
                <section>
                    <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
                    <el-button @click="reset">重置</el-button>
                </section>
                </el-form>

                <template v-slot:table-wrap>
                    <ul class="toolbar" v-if="userInfo.currentMcRoleStatus==0">
                        <li ><el-button icon="el-icon-download" type="primary" @click="exportTable(exportObj1)">导出</el-button></li>
                    </ul>
                <el-table
                    ref="table"
                    v-loading="isWorking.search"
                    :data="tableData.list"
                    row-key="id" 
                    :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                    stripe
                    @selection-change="handleSelectionChange"
                    @sort-change="handleSortChange"
                >
                    <el-table-column type="selection" width="55px" align="center"></el-table-column>
                    <el-table-column prop="invitationUserPhone" label="邀约人电话" min-width="100px"  align="center" ></el-table-column>
                    <el-table-column prop="invitationUserName" label="邀约人姓名" min-width="100px"  align="center"></el-table-column>
                    <el-table-column prop="invitationNickName" label="邀约人微信昵称" min-width="120px"  align="center"></el-table-column>
                    <el-table-column prop="totalInvitationMoney" label="累计邀约金" min-width="100px" align="center" sortable >
                        <el-button slot-scope="{row}" type="text" @click="pageTurn(1,row,row.totalInvitationMoney)">{{row.totalInvitationMoney}}</el-button>
                    </el-table-column>
                    <el-table-column prop="cashbackInvitationMoney" label="已返现邀约金" min-width="100px" align="center" sortable>
                        <el-button slot-scope="{row}" type="text" @click="pageTurn(2,row,row.cashbackInvitationMoney)">{{row.cashbackInvitationMoney}}</el-button>
                    </el-table-column>
                    <el-table-column prop="stayInvitationMoney" label="待返现邀约金" min-width="100px" align="center" sortable>
                        <el-button slot-scope="{row}" type="text" @click="pageTurn(3,row,row.stayInvitationMoney)">{{row.stayInvitationMoney}}</el-button>
                    </el-table-column>
                    <el-table-column prop="totalRechargeGold" label="累计充值金" min-width="100px" align="center" sortable>
                        <!--  -->
                        <div slot-scope="{row}">
                            <el-button v-if="!row.totalRechargeGold"  type="text" @click="pageTurn(4)">{{row.totalRechargeGold}}</el-button>
                            <el-popover v-else placement="top-start"  trigger="hover" width="400" >
                                <el-table :data="row.detailsEntityList || []" :height="200" :max-height="300">
                                    <el-table-column  property="rechargeAmount" label="充值金额" align="center"></el-table-column>
                                    <el-table-column width="140px" property="rechargeTime" label="操作时间" align="center"></el-table-column>
                                    <el-table-column width="120px" property="userName" label="操作人" align="center"></el-table-column>
                                </el-table>
                                <el-button slot="reference" type="text">{{row.totalRechargeGold == null ? '-' : row.totalRechargeGold}}</el-button>
                            </el-popover>
                        </div>
                    </el-table-column>
                    <el-table-column prop="cashbackRechargeGold" label="已返现充值金" min-width="100px"  align="center" sortable>
                        <span slot-scope="{row}" type="text" >{{row.cashbackRechargeGold}}</span>
                    </el-table-column>
                    <el-table-column prop="stayRechargeGold" label="待返现充值金" min-width="100px"  align="center" sortable>
                        <span slot-scope="{row}" type="text" >{{row.stayRechargeGold}}</span>
                    </el-table-column>
                    <el-table-column prop="cumulativeTotalAmount" label="累计总金额" min-width="100px"  align="center" sortable>
                        <span slot-scope="{row}" type="text" >{{row.cumulativeTotalAmount}}</span>
                    </el-table-column>
                    <el-table-column prop="cumulativeCashbackAmount" label="已返现总金额" min-width="100px"  align="center" sortable >
                        <span slot-scope="{row}" type="text" >{{row.cumulativeCashbackAmount}}</span>
                    </el-table-column>
                    <el-table-column prop="cumulativeStayAmount" label="待返现总金额" min-width="100px"  align="center" sortable>
                        <span slot-scope="{row}" type="text" >{{row.cumulativeStayAmount}}</span>
                    </el-table-column>
                    <div slot="append" v-if="tableData.list.length>0">
                        <div class='sum_footer xiaoji' ref='sum_xiaoji'>
                            <div class='sum_footer_unit'></div>

                            <div class='sum_footer_unit'>小计</div>
                            <div class='sum_footer_unit'></div>
                            <div class='sum_footer_unit'></div>
                            <div class='sum_footer_unit'>{{getXiaoji('totalInvitationMoney')}}</div>
                            <div class='sum_footer_unit'>{{getXiaoji('cashbackInvitationMoney')}}</div>
                            <div class='sum_footer_unit'>{{getXiaoji('stayInvitationMoney')}}</div>
                            <div class='sum_footer_unit'>{{getXiaoji('totalRechargeGold')}}</div>
                            <div class='sum_footer_unit'>{{getXiaoji('cashbackRechargeGold')}}</div>
                            <div class='sum_footer_unit'>{{getXiaoji('stayRechargeGold')}}</div>
                            <div class='sum_footer_unit'>{{getXiaoji('cumulativeTotalAmount')}}</div>
                            <div class='sum_footer_unit'>{{getXiaoji('cumulativeCashbackAmount')}}</div>
                            <div class='sum_footer_unit'>{{getXiaoji('cumulativeStayAmount')}}</div>

                        </div>
                        <div class='sum_footer heji' ref='sum_heji'>
                            <div class='sum_footer_unit'></div>
                            <div class='sum_footer_unit center'>合计</div>
                            <div class='sum_footer_unit'></div>
                            <div class='sum_footer_unit'></div>
                            <div class='sum_footer_unit'>{{sumData&&sumData.totalInvitationMoney}}</div>
                            <div class='sum_footer_unit'>{{sumData&&sumData.totalCashbackInviationMoney}}</div>
                            <div class='sum_footer_unit'>{{sumData&&sumData.totalStayMoney}}</div>
                            <div class='sum_footer_unit'>{{sumData&&sumData.totalRechargeGold}}</div>
                            <div class='sum_footer_unit'>{{sumData&&sumData.totalCashbackRechargeGold}}</div>
                            <div class='sum_footer_unit'>{{sumData&&sumData.totalStayRechargeGold}}</div>
                            <div class='sum_footer_unit'>{{sumData&&sumData.totalCumulativeTotalAmount}}</div>
                            <div class='sum_footer_unit'>{{sumData&&sumData.totalCumulativeCashbackAmount}}</div>
                            <div class='sum_footer_unit'>{{sumData&&sumData.totalCumulativeStayAmount}}</div>
                        </div>
                    </div>
                </el-table>
                <pagination
                    @size-change="handleSizeChange"
                    @current-change="handlePageChange"
                    :pagination="tableData.pagination"
                ></pagination>
                </template>
            </TableLayout>
        </el-tab-pane>
        <el-tab-pane label="邀约金明细列表" name="second">
            <TableLayout  :withBreadcrumb="false" v-if="activeName=='second'">
                
                <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
                    <el-form-item label="商家名称" prop="shopName">
                        <el-input v-model="searchForm.shopName" v-trim placeholder="请输入商家名称" @keypress.enter.native="search" style="width:250px"/>
                    </el-form-item>
                    <el-form-item label="邀约人" prop="invitation">
                        <el-input v-model="searchForm.invitation" v-trim placeholder="请输入姓名、昵称、手机号" @keypress.enter.native="search" style="width:250px"/>
                    </el-form-item>
                    
                    <el-form-item label="邀约金状态" prop="status">
                        <el-select v-model="searchForm.status" placeholder="请选择" style="width:100px">
                            <el-option  key="-1" label="全部" :value="null"  ></el-option>
                            <el-option  key="0" label="待激活" :value="[0]"  ></el-option>
                            <el-option  key="1" label="待返现" :value="[1]"  ></el-option>
                            <el-option  key="2" label="审核中" :value="[2]"  ></el-option>
                            <el-option  key="3" label="已返现" :value="[3]"  ></el-option>
                            <el-option  key="4" label="已驳回" :value="[4]"  ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否核心客户" prop="isCoreUser">
                        <el-select v-model="searchForm.isCoreUser" placeholder="请选择" style="width:100px">
                            <el-option  key="-1" label="全部" :value="null"  ></el-option>
                            <el-option  key="0" label="是" :value="true"  ></el-option>
                            <el-option  key="1" label="否" :value="false"  ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="时间" prop="roleId">
                        <el-date-picker v-model="value2" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="dateChange"
                            :picker-options="pickerOptions" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-form-item>

                    <section>
                        <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
                        <el-button @click="reset">重置</el-button>
                    </section>
                </el-form>

                <template v-slot:table-wrap>
                    <ul class="toolbar" v-if="userInfo.currentMcRoleStatus==0">
                        <!-- <li ><el-button  type="primary" @click="openModel(true,1)" v-loading="isWorking.update">批量修改</el-button></li>
                        <li ><el-button icon="el-icon-plus" type="primary" @click="openModel(true,2)" v-loading="isWorking.verify" v-if="currentRole==roles.manager|| currentRole==roles.newManager || currentRole==-1">批量审核</el-button></li>
                        <li ><el-button icon="el-icon-plus" type="primary" @click="openModel(true,3)" v-loading="isWorking.cashBack">批量返现</el-button></li> -->
                        <li ><el-button icon="el-icon-download" type="primary" @click="exportTable(exportObj2)">导出</el-button></li>
                    </ul>
                <el-table
                    ref="table"
                    v-loading="isWorking.search"
                    :data="tableData.list" border
                    stripe
                    @selection-change="handleSelectionChange"
                    @sort-change="handleSortChange"
                >
                    <el-table-column type="selection" width="55px"></el-table-column>

                    <el-table-column prop="shopName" label="商家名称" min-width="100px" align="center"></el-table-column>
                    <el-table-column prop="adminName" label="管理员" min-width="100px" align="center"></el-table-column>
                    <el-table-column prop="adminAccount" label="账号" min-width="120px" align="center"></el-table-column>
                    <el-table-column prop="invitationUserPhone" label="邀约人电话" min-width="100px" align="center"></el-table-column>
                    <el-table-column prop="invitationNickName" label="邀约人昵称" min-width="100px" align="center"></el-table-column>
                    <el-table-column prop="invitationUserName" label="邀约人姓名" min-width="100px" align="center"></el-table-column>
                    
                    <el-table-column prop="createTime" label="时间" min-width="140px" align="center" sortable></el-table-column>
                    <el-table-column prop="invitationMoney" label="邀约金" min-width="100px" >
                        <div slot-scope="{row}" style="text-align:center">
                            {{row.invitationMoney}}
                        </div>
                    </el-table-column>
                    <el-table-column prop="status" label="邀约金状态" min-width="100px" align="center">
                        <div slot-scope="{row}">
                            {{row.status==0?'待激活':row.status==1?'待返现':row.status==2?'审核中':row.status==3?'已返现':row.status==4?'已驳回':''}}
                        </div>
                    </el-table-column>
                    <el-table-column prop="returnEnclosure" label="附件" min-width="100px" align="center">
                        <Enclosure  v-model="row.returnEnclosure" @input="updateEnclosure(row)" :showAdd="userInfo.currentMcRoleStatus==0" :showDel="userInfo.currentMcRoleStatus==0" slot-scope="{row}" ></Enclosure>
                    </el-table-column>
                    <el-table-column prop="remarks" label="备注" min-width="100px" align="center">
                        <div slot-scope="{row}">
                            <span>{{row.remarks?row.remarks:'/'}}</span>
                            <i class="el-icon-edit" v-if="userInfo.currentMcRoleStatus==0" style="margin-left:4px;color:#2E68EC;" @click="$refs.remarkUpdateWindow.open(row,3)"></i>
                        </div>
                    </el-table-column>
                    <el-table-column
                    v-if="userInfo.currentMcRoleStatus==0"
                    label="操作"
                    width="270"
                    fixed="right"
                    >
                    <div  slot-scope="{row}">
                        <el-button  type="text" icon="el-icon-s-promotion" @click="openModel(false,1,row)" v-if="row.status==0 || row.status==1">修改</el-button>
                        <el-button  type="text" icon="el-icon-edit"  @click="openModel(false,2,row)" v-if="row.status==2&&(currentRole==roles.manager || currentRole==roles.newManager || currentRole==-1)">审核</el-button>
                        <el-button  type="text" icon="el-icon-switch-button" @click="openModel(false,3,row)"  v-if="row.status==1">返现</el-button>
                        <el-button  type="text" icon="el-icon-notebook-2"  @click="$refs.operaRecordWindow.open(1,row,21)">操作记录</el-button>
                    </div>
                    </el-table-column>

                    <div slot="append" v-if="tableData.list.length>0">
                        <div class='sum_footer xiaoji' ref='sum_xiaoji'>
                            <div class='sum_footer_unit' v-for="item in 7"></div>
                            <div class='sum_footer_unit'>小计</div>
                            <div class='sum_footer_unit'>{{getXiaoji('invitationMoney')}}</div>
                            <div class='sum_footer_unit'  v-for="item in 4"></div>

                        </div>
                        <div class='sum_footer heji' ref='sum_heji'>
                            <div class='sum_footer_unit' v-for="item in 7"></div>
                            <div class='sum_footer_unit'>合计</div>
                            <div class='sum_footer_unit'>{{tableData.list[0].totalMoney}}</div>
                            <div class='sum_footer_unit'  v-for="item in 4"></div>
                        </div>
                    </div>
                </el-table>
                <pagination
                    @size-change="handleSizeChange"
                    @current-change="handlePageChange"
                    :pagination="tableData.pagination"
                ></pagination>
                </template>
            </TableLayout>
        </el-tab-pane>
        <el-tab-pane label="充值金明细列表" name="third">
            <TableLayout  :withBreadcrumb="false" v-if="activeName=='third'">
                <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
                <el-form-item label="账号" prop="rechargeGold">
                    <el-input v-model="searchForm.rechargeGold" v-trim placeholder="请输入姓名、昵称、手机号" @keypress.enter.native="search" style="width:250px"/>
                </el-form-item>

                <section>
                    <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
                    <el-button @click="reset">重置</el-button>
                </section>
                </el-form>

                <template v-slot:table-wrap>
                    <ul class="toolbar" v-if="userInfo.currentMcRoleStatus==0">
                        <li><el-button type="primary" @click="$refs.invitationMoneyRechargeWindow.open" v-if="currentRole==roles.manager || currentRole==-1">充值</el-button></li>
                        <li ><el-button icon="el-icon-download" type="primary" @click="exportTable(exportObj3)">导出</el-button></li>
                    </ul>  
                <el-table
                    ref="table"
                    v-loading="isWorking.search"
                    :data="tableData.list"
                    stripe
                    @selection-change="handleSelectionChange"
                    @sort-change="handleSortChange"
                >
                    <el-table-column type="selection" width="55px"></el-table-column>

                    <el-table-column prop="account" label="充值账号" min-width="100px" align="center"></el-table-column>
                    <el-table-column prop="userName" label="充值姓名" min-width="100px" align="center"></el-table-column>

                    <el-table-column prop="nickName" label="微信昵称" min-width="100px" align="center"></el-table-column>

                    <el-table-column prop="shopName" label="商家名称" min-width="120px" align="center">
                        <div slot-scope="{row}">{{row.shopName == null ? '-' : row.shopName}}</div>
                    </el-table-column>
                    <el-table-column prop="totalRechargeGold" label="累计充值金" min-width="120px" align="center" sortable>
                        <el-popover slot-scope="{row}" placement="top-start"  trigger="hover" width="400" >
                            <el-table :data="row.detailsEntityList || []" :height="200" :max-height="300">
                                <el-table-column  property="rechargeAmount" label="充值金额" align="center"></el-table-column>
                                <el-table-column width="140px" property="rechargeTime" label="操作时间" align="center"></el-table-column>
                                <el-table-column width="120px" property="userName" label="操作人" align="center"></el-table-column>
                            </el-table>
                            <el-button slot="reference" type="text">{{row.totalRechargeGold == null ? '-' : row.totalRechargeGold}}</el-button>
                        </el-popover>
                    </el-table-column>
                    <el-table-column prop="cashbackRechargeGold" label="已返现充值金" min-width="120px" align="center" sortable>
                        <div slot-scope="{row}">{{row.cashbackRechargeGold == null ? '-' : row.cashbackRechargeGold}}</div>
                    </el-table-column>
                    <el-table-column prop="stayRechargeGold" label="待返现充值金" min-width="120px" align="center" sortable>
                        <div slot-scope="{row}">{{row.stayRechargeGold == null ? '-' : row.stayRechargeGold}}</div>
                    </el-table-column>

                    <el-table-column
                    v-if="userInfo.currentMcRoleStatus==0"
                    label="操作"
                    align="center"
                    width="270"
                    fixed="right"
                    >
                    <div  slot-scope="{row}" >
                        <el-button  type="text" icon="el-icon-s-promotion" v-if="row.status==1" @click="$refs.casReturnApplyAndExamineWindow.open(3,row)">充值金返现</el-button>
                        <el-button  type="text" icon="el-icon-s-promotion" v-if="row.status==null&&row.stayRechargeGold>0" @click="$refs.casReturnApplyAndExamineWindow.open(1,row)">发起返现申请</el-button>
                        <el-button  type="text" icon="el-icon-s-promotion" v-if="row.status==2&&(currentRole==roles.manager || currentRole==-1)" @click="$refs.casReturnApplyAndExamineWindow.open(2,row)">审核</el-button>
                        <el-button  type="text" icon="el-icon-notebook-2"  @click="$refs.operaRecordWindow.open(1,row,22)">操作记录</el-button>
                    </div>
                    </el-table-column>
                    <div slot="append" v-if="tableData.list.length>0">
                        <div class='sum_footer xiaoji' ref='sum_xiaoji'>
                            <div class='sum_footer_unit' v-for="item in 4"></div>
                            <div class='sum_footer_unit'>小计</div>
                            <div class='sum_footer_unit'>{{getXiaoji('totalRechargeGold')}}</div>
                            <div class='sum_footer_unit'>{{getXiaoji('cashbackRechargeGold')}}</div>
                            <div class='sum_footer_unit'>{{getXiaoji('stayRechargeGold')}}</div>
                            <div class='sum_footer_unit'></div>

                        </div>
                        <div class='sum_footer heji' ref='sum_heji'>
                            <div class='sum_footer_unit' v-for="item in 4"></div>
                            <div class='sum_footer_unit'>合计</div>
                            <div class='sum_footer_unit'>{{tableData.list[0].totalsRecharge}}</div>
                            <div class='sum_footer_unit'>{{tableData.list[0].totalCashbackRecharge}}</div>
                            <div class='sum_footer_unit'>{{tableData.list[0].totalStayRecharge}}</div>
                            <div class='sum_footer_unit'></div>
                        </div>
                    </div>
                </el-table>
                <pagination
                    @size-change="handleSizeChange"
                    @current-change="handlePageChange"
                    :pagination="tableData.pagination"
                ></pagination>
                </template>
            </TableLayout>
        </el-tab-pane>
        <el-tab-pane label="返现记录列表" name="fourth">
            <TableLayout  :withBreadcrumb="false" v-if="activeName=='fourth'">
                <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
                <el-form-item label="返现对象" prop="recurrentObject">
                    <el-input v-model="searchForm.recurrentObject" v-trim placeholder="请输入姓名、昵称、手机号" @keypress.enter.native="search" style="width:250px"/>
                </el-form-item>
                <el-form-item label="返现类型" prop="cashBackType">
                    <el-select v-model="searchForm.cashBackType" placeholder="请选择" style="width:140px">
                        <el-option  key="-1" label="全部" :value="null"  ></el-option>
                        <el-option  key="0" label="邀约金返现" :value="1"  ></el-option>
                        <el-option  key="1" label="充值金返现" :value="2"  ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="时间" prop="keywords">
                    <el-date-picker v-model="value2" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="dateChange"
                        :picker-options="pickerOptions" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>

                <section>
                    <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
                    <el-button @click="reset">重置</el-button>
                </section>
                </el-form>

                <template v-slot:table-wrap>
                    <ul class="toolbar" v-if="userInfo.currentMcRoleStatus==0">
                        <li ><el-button icon="el-icon-download" type="primary" @click="exportTable(exportObj4)">导出</el-button></li>
                    </ul> 
                    <el-table
                        ref="table"
                        v-loading="isWorking.search"
                        :data="tableData.list"
                        stripe
                        @selection-change="handleSelectionChange"
                        @sort-change="handleSortChange"
                    >
                        <el-table-column type="selection" width="55px"></el-table-column>

                        <el-table-column prop="cashReturnNo" label="返现单号" min-width="120px" align="center"></el-table-column>
                        <el-table-column prop="cashBackType" label="返现类型" min-width="100px" align="center">
                            <div slot-scope="{row}">{{row.cashBackType==1?'邀约金返现':row.cashBackType==2?'充值金返现':'-'}}</div>
                        </el-table-column>

                        <el-table-column prop="account" label="返现对象账号" min-width="120px" align="center">
                        <!-- <div slot-scope="{row}">{{row.userName == null ? '-' : row.userName}}</div> -->
                        </el-table-column>
                        <el-table-column prop="userName" label="返现对象姓名" min-width="100px" align="center"></el-table-column>
                        <el-table-column prop="nickName" label="微信昵称" min-width="100px" align="center">
                            <div slot-scope="{row}">{{row.nickName == null ? '-' : row.nickName}}</div>
                        </el-table-column>
                        <el-table-column prop="cashAmount" label="返现金额" min-width="100px" align="center" sortable></el-table-column>
                        <el-table-column prop="cashTime" label="返现时间" min-width="120px" align="center"  sortable></el-table-column>

                        <el-table-column prop="remarks" label="备注" min-width="100px" align="center">
                            <div slot-scope="{row}">
                                <span>{{row.remarks?row.remarks:'/'}}</span>
                                <i class="el-icon-edit" v-if="userInfo.currentMcRoleStatus==0" style="margin-left:4px;color:#2E68EC;" @click="$refs.remarkUpdateWindow.open(row,4)"></i>
                            </div>
                        </el-table-column>

                        <el-table-column prop="returnEnclosure" label="附件" width="270" align="center">
                            <Enclosure slot-scope="{row}" v-model="row.returnEnclosure" :showAdd="userInfo.currentMcRoleStatus==0" :showDel="userInfo.currentMcRoleStatus==0"  @input="updateEnclosure(row)"></Enclosure>
                        </el-table-column>
                        <div slot="append" v-if="tableData.list.length>0">
                            <div class='sum_footer xiaoji' ref='sum_xiaoji'>
                                <div class='sum_footer_unit' v-for="item in 5"></div>
                                <div class='sum_footer_unit'>小计</div>
                                <div class='sum_footer_unit'>{{getXiaoji('cashAmount')}}</div>
                                <div class='sum_footer_unit' v-for="item in 3"></div>
                            </div>
                            <div class='sum_footer heji' ref='sum_heji'>
                                <div class='sum_footer_unit' v-for="item in 5"></div>
                                <div class='sum_footer_unit'>合计</div>
                                <div class='sum_footer_unit'>{{tableData.list[0].totalMoney}}</div>
                                <div class='sum_footer_unit' v-for="item in 3"></div>
                            </div>
                        </div>
                    </el-table>
                    <pagination
                        @size-change="handleSizeChange"
                        @current-change="handlePageChange"
                        :pagination="tableData.pagination"
                    ></pagination>
                </template>
            </TableLayout>
        </el-tab-pane>
    </el-tabs>
    <!-- 修改邀约金、返现邀约金 、审核 -->
    <!-- <UpdateInvitationMoneyWindow ref="updateInvitationMoneyWindow" @success="handlePageChange(tableData.pagination.pageIndex)"></UpdateInvitationMoneyWindow> -->
    <!-- 批量修改 批量审核 批量返现 + 修改邀约金、返现邀约金 、审核  -->
    <BatchUpdateInvitationMoneyWindow  ref="batchUpdateInvitationMoneyWindow" @success="handlePageChange(tableData.pagination.pageIndex)"></BatchUpdateInvitationMoneyWindow>
    <!-- 邀约金充值 -->
    <InvitationMoneyRechargeWindow  ref="invitationMoneyRechargeWindow" @success="handleClick(3)"></InvitationMoneyRechargeWindow>
    <!-- 充值金的 发起返现申请 审核 -->
    <CasReturnApplyAndExamineWindow  ref="casReturnApplyAndExamineWindow" @success="handlePageChange(tableData.pagination.pageIndex)"></CasReturnApplyAndExamineWindow>
    <!-- 填写更新备注 -->
    <RemarkUpdateWindow  ref="remarkUpdateWindow"  @success="handlePageChange(tableData.pagination.pageIndex)"></RemarkUpdateWindow>
    <!-- 操作记录 -->
    <OperaRecordWindow ref="operaRecordWindow"></OperaRecordWindow>

    
</div>

  
</template>

<script>
import Pagination from '@/components/common/Pagination'
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import OperaRecordWindow from '../../../components/layun/common/OperaRecordWindow.vue'
import FileUpload from '../../../components/layun/common/FileUpload.vue'
import Enclosure from '../../../components/layun/common/Enclosure.vue'

import UpdateInvitationMoneyWindow from '../../../components/layun/contractManagement/fund/UpdateInvitationMoneyWindow.vue'
import BatchUpdateInvitationMoneyWindow from '../../../components/layun/contractManagement/fund/BatchUpdateInvitationMoneyWindow.vue'
import InvitationMoneyRechargeWindow from '../../../components/layun/contractManagement/fund/InvitationMoneyRechargeWindow.vue'
import CasReturnApplyAndExamineWindow from '../../../components/layun/contractManagement/fund/CasReturnApplyAndExamineWindow.vue'
import RemarkUpdateWindow from '../../../components/layun/common/RemarkUpdateWindow.vue'

import { fetchBatchList , updateRemarksAndEnclosure , pageSum  } from '../../../api/layun/contractManagement/invitationFundManagement'
import { mapState } from 'vuex'
// const pageThis = this

const filter1 = {
    listType:1,// 1邀约金总计列表 2 邀约金明细列表 3充值金明细列表 4 返现记录列表
    invitation:'', 
    sort:1,
    sortType:0,    
}
const filter2 = {
    listType:2,
    // beInvitedUser: "",
    // invitationUser: "",
    shopName: "",
    invitation:'',     
    status: null,
    isCoreUser:null,
    signedTimeStart:'',
    signedTimeEnd:'',
    sort:1,
    sortType:0,
      
}
const filter3 = {
    listType:3,
    rechargeGold:'',
    sort:1,
    sortType:0,
    // historyShop:null,
    // openPay: null,
    // payStatus: null,
    // sale: "",
    // shopAdmin: "",
    // shopName: "",
    // shopStatus: null
      
}
const filter4 = {
    listType:4,
    recurrentObject:'',
    cashBackType:null,
    signedTimeStart:'',
    signedTimeEnd:'',
    sort:1,
    sortType:0,
      
}

const exportObj1 = {
    tHeader:['邀约人电话','邀约人姓名','邀约人微信昵称','累计邀约金','已返现邀约金','待返现邀约金','累计充值金','已返现充值金','待返现充值金','累计总金额','已返现总金额','待返现总金额'],
    filterVal:[
        {key:'invitationUserPhone'},
        {key:'invitationUserName',},
        {key:'invitationNickName',},
        {key:'totalInvitationMoney',},
        {key:'cashbackInvitationMoney',},
        {key:'stayInvitationMoney',},
        {key:'totalRechargeGold',},
        {key:'cashbackRechargeGold',},
        {key:'stayRechargeGold',},
        {key:'cumulativeTotalAmount',},
        {key:'cumulativeCashbackAmount',},
        {key:'cumulativeStayAmount'},    
    ],
    fileName:'邀约金总计列表',
}
const exportObj2 = {
    tHeader:['商家名称','管理员','账号','邀约人电话','邀约人昵称','邀约人姓名','时间','邀约金','邀约金状态','附件','备注'],
    filterVal:[
        {key:'shopName'},
        {key:'adminName',},
        {key:'adminAccount',},
        {key:'invitationUserPhone',},
        {key:'invitationNickName',},
        {key:'invitationUserName',},
        {key:'createTime',},
        {key:'invitationMoney',},
        {key:'status',type:2,default:'', item:[{value:0,label:'待激活'},{value:1,label:'待返现'},{value:2,label:'审核中'},{value:3,label:'已返现'},{value:4,label:'已驳回'}]},
        {key:'returnEnclosure',},
        {key:'remarks',}, 
    ],
    fileName:'邀约金明细列表',
}
const exportObj3 = {
    tHeader:['充值账号','充值姓名','微信昵称','商家名称','累计充值金','已返现充值金','待返现充值金'],
    filterVal:[
        {key:'account'},
        {key:'userName',},
        {key:'nickName',},
        {key:'shopName',},
        {key:'totalRechargeGold',},
        {key:'cashbackRechargeGold',},
        {key:'stayRechargeGold',},
    ],
    fileName:'充值金明细列表',
}
const exportObj4 = {
    tHeader:['返现单号','返现类型','返现对象账号','返现对象姓名','微信昵称','返现金额','返现时间','备注','附件'],
    filterVal:[
        {key:'cashReturnNo'},
        {key:'cashBackType',type:2,default:'', item:[{value:1,label:'邀约金返现'},{value:2,label:'充值金返现'}]},

        {key:'account',},
        {key:'userName',},
        {key:'nickName',},
        {key:'cashAmount',},
        {key:'remarks',},
        {key:'returnEnclosure',},
    ],
    fileName:'返现记录列表',
}



// import { fetchList } from '@/api/system/user'

export default {
  name: 'invitationFundManagement',
  extends: BaseTable,
  components: {  TableLayout, Pagination, OperaRecordWindow ,  FileUpload , UpdateInvitationMoneyWindow , BatchUpdateInvitationMoneyWindow , InvitationMoneyRechargeWindow , CasReturnApplyAndExamineWindow , Enclosure , RemarkUpdateWindow ,  },
  computed: {
    paths () {
      return this.$route.meta.paths
    },
    ...mapState({
      userInfo:state=>state.init.userInfo || {},
      currentRole :state=>state.init.userInfo.currentRole,
      roles:state=>state.init.roles
    }),
  },

  data () {
    return {
        activeName: 'first',
        // 搜索
        searchForm:{
            ...filter1
        },
        imgList:[],
        exportObj1,
        exportObj2,
        exportObj3,
        exportObj4,
        sumData:null,

        
    }
  },
  watch:{
    'searchForm.roleId':{
      handler(val,old){
        if(val!=old){
          this.search()
        }
      }
    }
  },
  created () {
    this.config({
      module: '账号',
      api: '/layun/contractManagement/invitationFundManagement',
      'field.main': 'account',
      sorts: []
    })
    this.search()
  },
  mounted(){
    this.adjustWidth()
    window.addEventListener('resize', this.adjustWidth.bind(this))
  },
  methods:{
    dateChange(date,type){
      this.searchForm.signedTimeStart  = date?date[0]:null
      this.searchForm.signedTimeEnd = date?date[1]:null
    },
    search(){
        this.handlePageChange(1)
        if(this.searchForm.listType==1){
            this.pageSum();
        }
        
    },
    pageSum(){
        this.sumData = null
        pageSum({
            model:{...this.searchForm,listType:null}
        }).then((res) => {
            this.sumData = res
        }).catch(() => {  
            this.$message.error('总计查询失败')   
        }).finally(()=>{
        });
    },
    // 排序
    handleSortChange (sortData) {
      this.tableData.sorts = []
        //   sort 邀约金排序 1倒叙 2正序
      	//sortType 邀约金排序字段 
        let arr = [
            {value:0,prop:'',tit:'默认'},
            {value:1,prop:'totalInvitationMoney',tit:'累计邀约金'},
            {value:2,prop:'cashbackInvitationMoney',tit:'已返现邀约金'},
            {value:3,prop:'stayInvitationMoney',tit:'待返现邀约金'},
            {value:4,prop:'totalRechargeGold',tit:'累计充值金'},
            {value:5,prop:'cashbackRechargeGold',tit:'已返现充值金'},
            {value:6,prop:'stayRechargeGold',tit:'待返现充值金'},
            {value:7,prop:'cumulativeTotalAmount',tit:'累计总金额'},
            {value:8,prop:'cumulativeCashbackAmount',tit:'已返现总金额'},
            {value:9,prop:'cumulativeStayAmount',tit:'待返现总金额'},
            {value:10,prop:'createTime',tit:'邀约金返现时间'},
            {value:11,prop:'cashAmount',tit:'返现金额'},
            {value:10,prop:'cashTime',tit:'返现时间'},
        ]
      let param = {
        sort:1,
        sortType:0,
      }
      if (sortData.order != null) {
          param.sort = sortData.order === 'descending' ? 1 : 2
          let index =  arr.findIndex(i=>i.prop==sortData.prop)
          param.sortType = index==-1?0:arr[index].value
          this.searchForm = {
            ...this.searchForm,
            ...param
          }
      }
      this.handlePageChange()
    },
    
    

    getSummaries(param) {
    console.log(param,this.activeName,'-s-s--s-ssss');

      let sumColumns = []
      if(this.activeName=='first'){
        sumColumns =['saleUserName','saleUserAccount']
      }
    
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        console.log(column,index);
        if (index === 0) {
          sums[index] = '小计';
          return;
        }
        let idx = sumColumns.findIndex(i=>i==column.property)
        if(idx==-1){
          sums[index] = ''
        }else{
          const values = data.map(item => {
            return  item[column.property]
          });
          // console.log(values,'此列的数据');
          sums[index] = values.reduce((prev, curr) => {
            console.log(prev,curr);
            const value = curr?Number(curr):0;
            return prev + value;
          }, 0);
          sums[index] += ' 元';
        }
      });
      console.log(sums);
      return sums;
    },

    handleClick(tab, event) {
        console.log(tab.name, this.activeName);
        this.searchForm.listType = 1;
        this.resetForm(this.activeName=='first'?1:this.activeName=='second'?2:this.activeName=='third'?3:4)
        this.resetTable();
        this.search()
    },
    resetForm(type){
        let filter = type==1?filter1:type==2?filter2:type==3?filter3:filter4
        this.searchForm = JSON.parse(JSON.stringify(filter))
        this.searchForm.listType = type
    },
    reset(){
        this.resetForm(this.activeName=='first'?1:this.activeName=='second'?2:this.activeName=='third'?3:4)
        this.value2 = ''
        this.search()
    },
    resetTable(){
        this.tableData={
            selectedRows: [],
            sorts:[],
            list:[],
            pagination: {
                pageIndex: 1,
                pageSize: 10,
                total: 0
            }
        }
        this.value2 = ''
        this.isWorking= {
            search: false,
            delete: false,
            export: false
        }

    },
    /*
    ** openModel 按钮弹框
        @isBatch:是否批量操作
        @type:1修改2审核3返现  //  0待激活,1待返现,2审核中,3已返现,4已驳回(弃用)
        @value:当前行的数据  批量无此按钮
    */
   openModel(isBatch,type,value){
        let arr = [
            {type:0,value:'占位',status:[],remark:'占位',},
            {type:1,value:'update',status:[0,1],remark:'修改',},
            {type:2,value:'verify',status:[2],remark:'审核',},
            {type:3,value:'cashBack',status:[1],remark:'返现',},
        ]
        if(isBatch){
            // //筛选出同一管理员id的状态数据

            if(this.tableData.selectedRows.length==0){
                return this.$message.error('请至少选择一条数据')
            }
            if(this.tableData.selectedRows.length!=this.tableData.selectedRows.filter(i=>i.adminAccount==this.tableData.selectedRows[0].adminAccount).length){
                return this.$message.error('只能批量处理同一商家管理员的数据！')
            }

            
            // let status = type==1?[0,1]:type==2?[2]:type==3?[1]:[]
            this.isWorking[arr[type].value] = true
            fetchBatchList({
                capacity: 1000,
                model: {
                    adminAccount:this.tableData.selectedRows[0].adminAccount,
                    status:arr[type].status
                },
                page: 1,
                sort:[]
            }) 
             .then(data=>{
                console.log(data,'__----');
                if(data.records.length>0){
                    this.$refs.batchUpdateInvitationMoneyWindow.open(isBatch,type,data.records)
                }else{
                    return this.$message.error(`没有可批量${arr[type].remark}的数据!!`)
                }
            }).catch(e=>{
                console.log(e);
                this.$tip.apiFailed(e,false)
            }).finally((res)=>{
                this.isWorking[arr[type].value] = false
                
            })

            //
        
        }else{
            this.$refs.batchUpdateInvitationMoneyWindow.open(isBatch,type,value)
        }
   
   
   },
   //更新附件
   updateEnclosure(row){
        console.log(row,'--');
        console.log({
            enclosure:row.enclosure,
            id:row.id,
            type:this.activeName=='second'?1:this.activeName=='fourth'?2:'',// 1邀约金备注 2返现记录备注
        })
        
        this.isWorking.search = true   

        updateRemarksAndEnclosure({
            enclosure:row.enclosure || [],
            id:row.id,
            type:this.activeName=='second'?1:this.activeName=='fourth'?2:'',// 1邀约金备注 2返现记录备注
        }).then(res=>{
        
        }).catch(e=>{
            console.log(e);
            this.$tip.apiFailed(e,false)
        }).finally((res)=>{
            this.isWorking.search = false   
        })
   },
   //页面跳转
   /*
        @row:当前行信息
        @type:类型
        @money:金额 为0不跳转
   */
   pageTurn(type,row,money){
        /*    type  
            1累计邀约金：带链接，点击可查看该邀约人邀约金列表 
            2 已返还金额：带链接，点击可查看该邀约人已返邀约金列表 
            3 待返还金额：带链接，点击可查看该邀约人待返邀约金列表 
            4 累计充值金：带链接，点击查看该账号的充值列表
        */
        if(!money){
            return 
        }
        this.activeName = 'second'
        this.resetForm(this.activeName=='first'?1:this.activeName=='second'?2:3)
        this.resetTable();
        this.searchForm.listType = 2
        this.searchForm.invitation = String(row.invitationUserPhone)

        if(type==2){
            this.searchForm.status = 3
        }
        if(type==3){
            this.searchForm.status = 1
        }
        // pageThis.created();//切换导致的数据加载问题

        this.search()
   
   }
    
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";
// 列表头像处理
.table-column-avatar {
  img {
    width: 48px;
  }
}


</style>
