import request from '@/utils/request'

// 获取图片验证码
export function getCaptcha () {
  return request.get('/common/captcha')
}

// 根据密码登录
export function loginByPassword (data) {
  // return request.post('/system/mobileLogin',data)
  return request.post('/system/login', data)
}

// 登出
export function logout (data) {
  return request.post('/system/logout', data)
}

// 修改密码
export function updatePwd (data) {
  return request.post('/system/updatePwd', data)
}

// 获取已登录的用户信息
export function getUserInfo () {
  console.log('hahahha');
  return request.get('/system/getUserInfo')
 
}

//获取验证码
export function verificationCode (data) {
  return request.post('/system/verificationCode', data)
}

//验证码校验 
export function checkVerificationCode (data) {
  return request.post('/system/user/verificationCode', data)
}

// 新增备用手机号
export function saveBakMobile (data) {
  return request.post('/system/user/saveBakMobile', data)
}

//修改备用手机号
export function updateBakMobile (data) {
  return request.post('/system/user/updateBakMobile', data)
}


//忘记密码-修改密码
export function forgotPwd (data) {
  return request.post('/system/resetPwd', data)
}
