<template>
  <GlobalWindow
    class="handle-table-dialog"
    :title="title"
    :visible.sync="visible"
    :withFooter="false"
    :confirm-working="isConfirm"
    :width="'850px'"
  >
    <div  v-if="!isBatch">
        <el-form :model="form" ref="form" :rules="rules">
            <el-form-item label="金额" prop="curMoney" :rules="[{required:true,message:'请输入金额'}]">
                <el-input v-model="form.curMoney" type="number" placeholder="请输入金额" v-trim maxlength="200" v-if="type==1"/>
                <span v-else>{{form.curMoney}}</span>

            </el-form-item>
            <el-form-item label="备注" prop="remarks" v-if="type==2">
                <el-input v-model.trim="form.remarks" placeholder="驳回时备注为必填"></el-input>
            </el-form-item>
            <el-form-item label="凭证" prop="" v-if="type==3">
                <Enclosure v-model='form.enclosure' :size="80"></Enclosure>
            </el-form-item>
            
            <div style="display:flex;justify-content: center;">
                <el-button type="primary" @click="confirm(true)" :loading="isConfirm&&verifyStatus==true">{{type==1?'修改':type==2?'同意':type==3?'返现':'占位'}}</el-button>
                <el-button type="primary" @click="confirm(false)" v-if="type==2" :loading="isConfirm&&verifyStatus==false">驳回</el-button>
                <el-button  @click="visible = false" v-if="type!=2">取消</el-button>
            </div>
        </el-form>
    </div>
    <div  v-if="isBatch">
        <h3 style="margin:20px 0;">管理员：<span v-if="tableData.list.length>0"> {{tableData.list[0].adminName}}/{{tableData.list[0].adminAccount}}</span>
        </h3>
        <div class="update" v-if="type==1">
            <el-table
                ref="update"
                v-loading="searchLoading"
                :data="tableData.list"
                stripe
            >
                <el-table-column prop="shopName" label="商家名称" min-width="100px" align="center"></el-table-column>
                <el-table-column prop="curMoney" label="邀约金" min-width="100px" align="center">
                    <div  slot-scope="{row}" >
                        <el-input v-model='row.curMoney' type='number'></el-input>
                    </div>
                </el-table-column>
            </el-table>
            <div class="btns">
                <el-button  type="primary" @click="confirm" :loading="isConfirm">修改</el-button>
                <el-button  @click="visible = false">取消</el-button>
            </div>
        </div>
        <div class="examine"  v-if="type==2">
            <el-table
                ref="examine"
                v-loading="searchLoading"
                :data="tableData.list"
                @selection-change="handleSelectionChange"
                stripe
            >
                <el-table-column type="selection" width="55px" align="center"></el-table-column>
                <el-table-column prop="shopName" label="商家名称" min-width="100px" align="center"></el-table-column>
                <el-table-column prop="curMoney" label="邀约金" min-width="100px" align="center">
                    <div  slot-scope="{row}" >
                        <span class="preNum">{{row.invitationMoney}}</span>
                        <span class="newNum">{{row.curMoney}}</span>
                    </div>
                </el-table-column>
                <el-table-column prop="nickName" label="状态" min-width="80px" align="center">
                    <div  slot-scope="{row}" >
                        {{row.status==0?'待激活':row.status==1?'待返现':row.status==2?'审核中':row.status==3?'已返现':row.status==4?'已驳回':''}}
                    </div>
                </el-table-column>
                <el-table-column prop="remarks" label="备注" min-width="80px" align="center">
                    <div  slot-scope="{row}" >
                        <el-input v-model.trim='row.remarks'></el-input>
                    </div>
                </el-table-column>
                <el-table-column
                
                label="审核"
                width="270"
                align="center"
                >
                <div  slot-scope="{row}" >
                    <el-button  type="primary" @click="confirm(true,row)" :loading="isConfirm&&verifyStatus==true&&singleValue&&singleValue.id==row.id">同意</el-button>
                    <el-button  @click="confirm(false,row)" :loading="isConfirm&&verifyStatus==false&&singleValue&&singleValue.id==row.id">驳回</el-button>
                </div>
                </el-table-column>
            </el-table>
            <div class="btns">
                <el-button  type="primary" @click="confirm(true)" :loading="isConfirm&&verifyStatus==true&&!singleValue">{{tableData.selectedRows.length==tableData.list.length?'全部':''}}同意</el-button>
                <el-button  @click="confirm(false)" :loading="isConfirm&&verifyStatus==false&&!singleValue">{{tableData.selectedRows.length==tableData.list.length?'全部':''}}驳回</el-button>
            </div>

        </div>
        <div class="cashback"  v-if="type==3">
            <el-table
                ref="cashback"
                v-loading="searchLoading"
                :data="tableData.list"
                @selection-change="handleSelectionChange"
                stripe
            >
                <el-table-column type="selection" width="55px" align="center"></el-table-column>
                <el-table-column prop="shopName" label="商家名称" min-width="100px" align="center"></el-table-column>
                <el-table-column prop="curMoney" label="邀约金" min-width="100px" align="center">
                    <div  slot-scope="{row}" >
                        <span class="preNum">{{row.invitationMoney}}</span>
                        <span class="newNum">{{row.curMoney}}</span>
                    </div>
                </el-table-column>
                <el-table-column prop="nickName" label="状态" min-width="80px" align="center">
                    <div  slot-scope="{row}" >
                        {{row.status==0?'待激活':row.status==1?'待返现':row.status==2?'审核中':row.status==3?'已返现':row.status==4?'已驳回':''}}
                    </div>
                </el-table-column>
                
                <el-table-column prop="nickName" label="附件" min-width="80px" align="center">
                    <div  slot-scope="{row}" >
                        <Enclosure v-model="row.enclosure"></Enclosure>
                    </div>
                </el-table-column>
                
                <el-table-column
                
                label="操作"
                width="270"
                align="center"
                >
                <div  slot-scope="{row}" >
                    <el-button  type="primary" @click="confirm(null,row)" :loading="isConfirm&&singleValue&&singleValue.id==row.id">返现</el-button>
                    <!-- <el-button  >上传附件</el-button> -->
                </div>
                </el-table-column>
            </el-table>
            <div class="btns">
                <el-button  type="primary" @click="confirm" :loading="isConfirm&&verifyStatus==undefined&&!singleValue"> {{tableData.selectedRows.length==tableData.list.length?'全部':''}}返现</el-button>
                <el-button  @click="visible = false">取消</el-button>
            </div>

        </div>

    </div>
    
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import Enclosure from '../../../layun/common/Enclosure.vue'
import BaseTable from '@/components/base/BaseTable'

import {updateById , verifyById , cashBackById } from '../../../../api/layun/contractManagement/invitationFundManagement'

export default {
  name: 'updateInvitationMoney',
  extends: BaseTable,
  components: {  GlobalWindow , Enclosure , },
  data () {
    
    return {
        title:'',
        isBatch:false,
        type:1,
        form:{
            curMoney:'',
            
        },
        visible:false,
        reasonVisible:false,
        searchLoading:false,
        tableData:{
            list:[{},{},{},{},{},{},]
        },
        verifyStatus:null,// loading condition
        singleValue:null,// loading condition
        isConfirm:null,//loading condition
    }
  },
  methods: {
    /**
     * 
     *   @isBatch:是否批量操作
        @type:1修改2审核3返现
        @value:当前行/adminId的数据
    */
    async open(isBatch,type,value) {
      console.log(isBatch,type,value,'_____target');
      this.title =(isBatch?'批量':'') + (type==1?'修改':type==2?'审核':type==3?'返现':'') +'邀约金'
      this.isBatch = isBatch
      this.type = type

      if(isBatch){
        value.forEach(i=>{
            i.curMoney = i.updateInvitationMoney?i.updateInvitationMoney:i.invitationMoney
        })
        this.tableData.list = value
      }else{
        this.form.id = value.id   
        this.form.curMoney = value.updateInvitationMoney?value.updateInvitationMoney:value.invitationMoney
        this.form.userId = value.invitationUserId
      }
      

      this.visible = true
    },
    //确认按钮
    /**
     * @verifyStatus 审核状态 true通过 false拒绝 
     * @singleValue 批量状态下 点击单个按钮操作
     * @type 1 修改 2 审核 3 返现
    */
    confirm(verifyStatus,singleValue){
        this.verifyStatus = verifyStatus //loading判断
        this.singleValue = singleValue //loading判断

        let api = ['占位',updateById,verifyById,cashBackById]
        let allRemark = true;//假设备注已填
        let param = []
        if(this.isBatch){
            if(this.type==1){
                this.tableData.list.map(i=>{
                    param.push({
                        id: i.id,
                        invitationMoney: Number(i.curMoney),
                        
                    })
                })
            
            }else if(this.tableData.selectedRows.length==0&&!singleValue){
                return this.$message.error('请至少选择一条数据')
            }

            if(this.type==2){
                if(singleValue){
                    if(verifyStatus==false&&singleValue.remarks.length==0){
                        return this.$message.error('驳回需填写备注')
                    }
                    param.push({
                        id: singleValue.id,
                        status:verifyStatus?1:2,
                        remarks:singleValue.remarks,
                        invitationMoney: Number(singleValue.curMoney),
                    })
                }else{
                    
                    this.tableData.selectedRows.map(i=>{
                        if(i.remarks.length==0){
                            allRemark = false
                        }
                        param.push({
                            id: i.id,
                            status:verifyStatus?1:2,
                            remarks:i.remarks,
                            invitationMoney: Number(i.curMoney),
                        })
                    })

                    if(allRemark==false&&verifyStatus==false){
                        return this.$message.error('存在备注未填写')
                    }
                    
                }
                
            }
            if(this.type==3){
                if(singleValue){
                    param.push({
                        id: singleValue.id,
                        enclosure:singleValue.enclosure,
                        invitationMoney: Number(singleValue.curMoney),
                    })
                }else{
                    this.tableData.selectedRows.map(i=>{
                        param.push({
                            id: i.id,
                            enclosure:i.enclosure,
                            invitationMoney: Number(i.curMoney),
                        })
                    })
                }
                
            }
        
        }else{
            param = [{
                ...this.form,
                invitationMoney: Number(this.form.curMoney),
                status:this.type==2?verifyStatus?1:2:null,
            }]
        }
        this.isConfirm = true
        api[this.type]({moneyPriceDtos:param,userId:param[0].userId}).then(data=>{
            this.$tip.apiSuccess(`${this.type==1?'修改':this.type==2?'审核':this.type==3?'返现':''}成功`)
            if(singleValue){
                this.tableData.list = this.tableData.list.filter(i=>i.id!=singleValue.id)
                this.tableData.selectedRows = this.tableData.selectedRows.filter(i=>i.id!=singleValue.id)
                if(this.tableData.list.length==0){
                    this.visible = false
                    this.$emit('update:visible', false)
                    this.close();
                }
            }else{
                this.visible = false
                this.$emit('update:visible', false)
                this.$emit('success')
            }
            
        }).catch(e=>{
            this.$tip.apiFailed(e,false)
        }).finally((res)=>{
            this.isConfirm = false
            
        })

    
    
    },
    close(){
        this.visible = false
        this.$emit('update:visible', false)
        this.$emit('success')
    },
    //驳回
    refuse(){
    
    },
  },
  created () {
    // this.config({
    //   api: '/layun/contractManagement/signedManagement', 
    // })
    
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables";
$icon-background-color: $primary-color;
.btns{
    text-align: center;
    margin-top:10px;
}
.preNum{
    text-decoration: line-through;
    margin-right:4px;
    
}
.newNum{
    color: red;
}

</style>
