<template>
    <GlobalWindow
      class="handle-table-dialog"
      :title="title"
      :visible.sync="visible"
      :confirm-working="isWorking"
      @confirm="confirm"
    >
      
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item label="分组名称" prop="name" required>
          <el-input v-model="form.name" placeholder="请输入分组名称" v-trim maxlength="50"/>
        </el-form-item>
        <el-form-item label="分组标签颜色" prop="labelColour" required>
            <el-color-picker v-model="form.labelColour"></el-color-picker>
        </el-form-item>        
      </el-form>
    </GlobalWindow>
  </template>
  
  <script>
  import BaseOpera from '@/components/base/BaseOpera'
  import GlobalWindow from '@/components/common/GlobalWindow'
  import { checkMobile,} from '@/utils/form'
  export default {
    name: 'OperaMenuWindow',
    extends: BaseOpera,
    components: {  GlobalWindow },
    data () {
      return {
        // 表单数据
        form: {
          id: null,
          name:'',
          labelColour:'',
        },
        // 验证规则
        rules: {
          
          name: [
            { required: true, message: '请输入分组名称' },
          ],
          labelColour: [
            { required: true, message: '请选择分组颜色' },
          ],
        }
      }
    },
    methods: {
      /**
       * @title: 窗口标题
       * @target: 编辑的菜单对象
       * @parent: 新建时的上级菜单
       */
      open (title, target, parent) {
        this.title = title
        this.visible = true
        // 新建，menu为空时表示新建菜单
        if (target == null) {
          // this.excludeMenuId = null
          this.$nextTick(() => {
            this.$refs.form.resetFields()
            this.form.id = null
          })
          return
        }
        // 编辑
        this.$nextTick(() => {
          // this.excludeMenuId = target.id
          for (const key in this.form) {
            this.form[key] = target[key]
          }
        })
      },
      addressChange(value) {
        console.log("地图选点", value);
        this.form.outboundMerchantAddress = value.address
        if(value.addressComponents &&value.addressIds){
          this.form.provinceId = value.addressIds.provinceId
          this.form.cityId = value.addressIds.cityId
          this.form.districtId = value.addressIds.districtId
          this.form.provinceName = value.addressIds.provinceName
          this.form.cityName = value.addressIds.cityName
          this.form.districtName = value.addressIds.districtName
        }
        if(value.addrPoint){
          this.form.latitude = value.addrPoint.lat
          this.form.longitude = value.addrPoint.lng
        }
      },
      methodChange(value){
        if(value==2){
          this.form.settlementPeriod = null;
        }
      }
    },
    created () {
      this.config({
        api: '/layun/basicDataManagement/groupData'
      })
    }
  }
  </script>
  
  <style scoped lang="scss">
  @import "@/assets/style/variables";
  $icon-background-color: $primary-color;
  .global-window {
    .tip {
      margin-bottom: 12px;
      em {
        font-style: normal;
        color: $primary-color;
        font-weight: bold;
      }
    }
    // 图标
    /deep/ .form-item-icon {
      .el-form-item__content {
        height: 193px;
        overflow-y: auto;
      }
      .el-radio-group {
        background: $icon-background-color;
        padding: 4px;
        .el-radio {
          margin-right: 0;
          color: #fff;
          padding: 6px;
          &.is-checked {
            background: $icon-background-color - 30;
            border-radius: 10px;
          }
          .el-radio__input.is-checked + .el-radio__label {
            color: #fff;
          }
        }
        .el-radio__input {
          display: none;
        }
        .el-radio__label {
          padding-left: 0;
          // element-ui图标
          i {
            font-size: 30px;
          }
          // 自定义图标
          [class^="eva-icon-"], [class*=" eva-icon-"] {
            width: 30px;
            height: 30px;
            background-size: 25px;
            vertical-align: bottom;
          }
        }
        .el-radio--small {
          height: auto;
          padding: 8px;
          margin-left: 0;
        }
      }
    }
  }
  </style>
  