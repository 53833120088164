<template>
  <GlobalWindow
    class="handle-table-dialog"
    :title="title"
    :visible.sync="visible"
    :withFooter="false"
    :confirm-working="isWorking"
    :loading="dialogLoading"
  >
    <el-form :model="form" ref="form" :rules="rules" >

      <ImportHistoryShop v-if="form.id==null&&form.historyShop"  v-model='uploadFile' :max="1"  limitType='xlsx' @input="fileChange" ></ImportHistoryShop> 
      <el-alert type="warning" v-if="!updateMaintained">如果需要修改信息可联系公司后勤或销售进行更改</el-alert>
      <el-alert type="warning" v-if="updateMaintained&&isMaintained">一旦修改，同步修改邀约编辑信息</el-alert> 

      <el-form-item label="账号" prop="account" required >
        <el-input v-model.trim="form.account" placeholder="请输入账号" :disabled="form.id || (type==4) || (!form.id&&!updateMaintained) || !accountEditable" @input="changeUser" @visible="visibleChange"></el-input>
      </el-form-item>

      <el-form-item label="管理员姓名" prop="userName" :required="true">
        <el-input v-model.trim="form.userName" :disabled="!updateMaintained || !userNameEditable" placeholder="请输入管理员姓名" v-trim maxlength="200"/>
      </el-form-item>

      <el-form-item label="商家名称" prop="name" :required="formRequired.name">
        <el-input v-model.trim="form.name" :disabled="!updateMaintained" placeholder="请输入商家名称" v-trim maxlength="200"/>
      </el-form-item>
      <el-form-item label="分组" prop="shopLabelId" :required="formRequired.shopLabelId">
        <el-select :disabled="!updateMaintained" v-model="form.shopLabelId" filterable class="w240" clearable  @change="changeLandAgency">
          <el-option v-for="(item) in allGroupData" :label="item.name" :key="item.id * 1" :value="item.id +''"
             ></el-option>
          </el-select>
      </el-form-item>

      <el-form-item label="统一社会信用代码" prop="creditCode" :required="formRequired.creditCode">
        <el-input v-model.trim="form.creditCode" :disabled="!updateMaintained" placeholder="请输入统一社会信用代码" v-trim maxlength="200" />
      </el-form-item>
      <el-form-item label="所属销售" prop="saleUserId" >
        <SaleUserSelect v-model="form.saleUserId" :disabled="!updateMaintained || currentRole==roles.sale" placeholder="请选择销售" :clearable="true"  style="display:block"></SaleUserSelect>
      </el-form-item>
      <el-form-item label="标签" prop="saleUserId" >
        <SaleUserSelect v-model="form.saleUserId" :disabled="!updateMaintained || currentRole==roles.sale" placeholder="请选择销售" :clearable="true"  style="display:block"></SaleUserSelect>
      </el-form-item>

      <el-form-item label="协议价格" prop="settPrice" v-if="type==2" :rules="[{ required: false, message: '请输入协议价格' },]">
        <el-input  placeholder="请输入" v-model="form.settPrice" type="number" :max="currentRole==roles.sale?4:null"   style="width:300px;">
          <template slot="append">元/千克</template>
        </el-input>
      </el-form-item>
      <el-form-item label="结算方式" prop="settType" v-if="type==2" :rules="[{ required: false, message: '请选择结算方式' },]">
        <el-radio-group v-model="form.settType">
          <el-radio :label="1">现结</el-radio>
          <el-radio :label="2">周期结算</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="结算周期" prop="settCycle" v-if="type==2&&form.settType==2" :rules="[{ required: form.settType==2, message: '请输入结算周期' },]">
        <el-input  placeholder="请输入" v-model="form.settCycle" type="number"  style="width:300px;" required>
          <template slot="append">天</template>
        </el-input> 
      </el-form-item>


      <el-form-item label="是否开通现金支付" prop="openPay" required>
        <el-switch v-model="form.openPay"  :disabled="!openPayChangeable || !updateMaintained" style="display:inline-block;"></el-switch>
      </el-form-item>
      <el-form-item label="商家地址" prop="address"  :rules="[{ required: formRequired.address, message: '请输入商家地址' }]" >
        <TMapSelect v-if='visible' :visible="visible" :disabled="!updateMaintained" v-model="form.addressT" :latitude="form.latitude" :longitude="form.longitude" :isAdd="form.id==null?true:false"  @change="addressChange"  style="width:100%;height:100%">

        </TMapSelect>
      </el-form-item>
      <el-form-item label="业务区域" prop="address"  v-if="form.latitude">
        <span v-if="form.areaCode">{{form.areaName}}</span>
        <div v-else>
          <el-tag  type="danger" style="width:auto">异常</el-tag>
          <!-- //7 管理员 8 新增管理员 9 后勤 10 库管 11销售 12 司机 13财务 -->
          <el-alert type="warning" v-if="(currentRole==roles.manager || currentRole==roles.newManager || currentRole==roles.logistics || currentRole==roles.sale || currentRole==-1)">基础信息中无此业务区域， 请前往业务区域基础数据中进行管理，然后再补充商家信息</el-alert>
          <el-alert type="warning" v-else>该商家地址所属业务区域不存在，请联系后勤管理业务区域基础数据后完成再补充商家地址信息</el-alert>
        </div>
      </el-form-item>
      <el-form-item label="所属街道办" prop="streetOfficeCode" v-if="form.latitude">
        <StreetSelect v-model="form.streetOfficeCode" :disabled="!updateMaintained" :filter="nameObj" :clearable="true"></StreetSelect>
      </el-form-item>
      <el-form-item label="合同编号" prop="contractNo" :rules="[{ required: type==1, message: '请输入合同编号' },]">
        <el-input v-model.trim="form.contractNo" :disabled="!updateMaintained" placeholder="请输入合同编号" v-trim maxlength="200"/>
        <!-- <el-alert type="warning">合同编号重复，重复商家名称：xxx。商家名称带连接可以点开查看/修改</el-alert> -->
      </el-form-item>
      <template  v-if="!form.id ">
        <el-form-item label="邀约人"  >
          {{$store.state.init.userInfo.username}}/{{$store.state.init.userInfo.account}}
        </el-form-item>
        <el-form-item label="邀约人昵称" v-if="$store.state.init.userInfo.nickName">
          {{$store.state.init.userInfo.nickName}}
        </el-form-item>
      </template>
      

      <div style="display:flex;justify-content: center;" v-if="showBtn">
          <template v-if="type==0">
            <el-button type="primary" @click="confirm" :loading="isWorking">发送邀约</el-button>
          </template>
          <template v-if="type==1">
            <el-button type="primary" @click="confirm"  :loading="isWorking">完成</el-button>
          </template>
          <template v-if="type==2">
            <el-button type="primary" @click="confirm"  :loading="isWorking">确认</el-button>
            <el-button  @click="close">取消</el-button>
          </template>
          <template v-if="type==3">
            <el-button type="primary" @click="confirm"  :loading="isWorking">保存</el-button>
          </template>
          <template v-if="type==5">
            <el-button type="primary" @click="confirm(null)"  :loading="isWorking">发送邀约</el-button>
            <!-- <el-button type="primary" @click="confirm({sendInvitation:false})"  :loading="isWorking">保存为预备商家</el-button> -->
          </template>
          <template v-if="type==4">
            <el-button type="primary" @click="confirm"  :loading="isWorking">发送邀约</el-button>
          </template>
      </div>
      
    </el-form>
  </GlobalWindow>
</template>

<script>

import { checkMobile} from '../../../utils/form'
import {fetchList} from '../../../api/layun/basicDataManagement/bussinessArea'
import { importHistoryShop } from '../../../api/layun/accountManagement/shop'
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import SaleUserSelect from '../../../components/layun/common/SaleUserSelect.vue'
import StreetSelect from '../../../components/layun/common/StreetSelect.vue'
import TMapSelect from '../../../components/layun/common/TMapSelect.vue'
// import UserSelect from '../../common/UserSelect.vue';//别的userSelect
import UserSelect from '../../../components/layun/common/AccountInput.vue';

import FileUpload from '../../common/FileUpload.vue'
import ImportHistoryShop from '../../../components/layun/common/historyShop.vue'

import {selectSystemAllUser,handoverRoles,isRepeatCreditCode} from '../../../api/system/user'
import {getGroupData} from "../../../api/layun/basicDataManagement/groupData"
import { mapState, Store } from 'vuex'
const modalBaseDataList = [
  {type:0,title:'新增商家/预备商家',remark:'新增商家/预备商家',query:null,save:null},
  {type:1,title:'新增历史商家',remark:'新增历史商家',query:null,save:null},
  {type:2,title:'补充/修改信息',remark:'补充/修改信息_商家管理员列表',query:null,save:null},
  {type:3,title:'编辑邀约',remark:'编辑邀约_预备商家列表 || 编辑邀约_预备商家列表_累计受邀次数',query:null,save:null},
  {type:4,title:'编辑邀约',remark:'编辑邀约信息&发起邀约',query:null,save:null},
  {type:5,title:'新增邀约',remark:'新增_邀约管理',query:null,save:null},
  {type:6,title:'补充/修改信息',remark:'补充/修改信息_签约商家列表',query:null,save:null},//别的组件
]
export default {
  name: 'MerchantOpera',
  extends: BaseOpera,
  components: {  GlobalWindow ,SaleUserSelect , TMapSelect , StreetSelect , UserSelect , FileUpload , ImportHistoryShop , },
  computed:{
  ...mapState({
    currentRole :state=>state.init.userInfo.currentRole,
    roles:state=>state.init.roles
  })
  
  },
  data () {
    var validBizLicenseKey =  (rule, value, callback)=> {
      this.validCode = true
      if (value == null || String(value).trim() === '') {
        callback()
        return
      }
      // if (!/(^(?:(?![IOZSV])[\dA-Z]){2}\d{6}(?:(?![IOZSV])[\dA-Z]){10}$)|(^\d{15}$)/.test(value)) {
      //   this.validCode = false
      //   callback(new Error('统一社会信用代码不正确'))
      //   return
      // }
      if(value.length!=15 &&  value.length!=18){
        this.validCode = false
        callback(new Error('统一社会信用代码不正确'))
        return
      }
      
      
      else{
        if(this.baseCreditCode==value){
          callback()
          return
        }

        isRepeatCreditCode(value).then(res=>{
          console.log(res,'--resououiiio--');
          if(res){
            callback()
            return
          }else{
            callback(new Error('统一社会信用代码重复'))
            return
          }
        }).catch(err=>{
          console.log('err',err);
          this.validCode = false
          callback(new Error('统一社会信用代码远程校验失败'))
        })
      
      
      }
    }
    return {
      // 表单数据
      
      form: {
        id: null,
        userName:'',
        account:'',
        userId:'',//是否是企业内部人员
        name:'',
        creditCode:'',
        shopLabelId:'',
        saleUserId:'',
        openPay:false,
        address:'',
        addressName:'',
        latitude:0,
        longitude:0,
        streetOfficeCode:'',
        areaCode:'',
        contractNo:'',
        invitationUserId:'',
        historyShop:false,
        shopType:2,//1 商家 2预备商家
        sendInvitation:true,
        addressT:'',//总的地址
        addressAres:'',
        settPrice:0,
        settType:0,
        settCycle:0,
      },
      baseCreditCode:'',
      validCode:true,//校验creditCode
      openPayChangeable:true,
      nameObj:{
        provinceName:'',
        cityName:'',
        districtName:''
      },
      formRequired:{},//必填
      formShow:{},//展示项
      // 验证规则
      rules: {
        account: [
          { required: true, message: '请输入手机号' },
          { validator: checkMobile,trigger: 'change' }
        ],
        userName:[
          { required: true, message: '请输入名称' },
        ],
        name:[{ required: true, message: '请输入商家名称' },],
        creditCode:[
          { required: this.formRequired&&this.formRequired.creditCode, message: '请输入统一社会信用代码' },
          {validator:validBizLicenseKey},
          // ,trigger:'blur'
        ]
        // :rules="[{ required: formRequired.creditCode, message: '请输入统一社会信用代码' },{validator:validBizLicenseKey},]"
      },
      target:null,
      parent:null,
      userOptions:[],//
      uploadFile:'',//上传文件的地址
      type:0,//来自于 哪个按钮
      updateMaintained:true,//是否可更新维护信息
      isMaintained:false,//是否回填了维护信息
      userNameEditable:true,//商家管理员姓名是否可编辑
      accountEditable:true,//账号是否可修改（新增其它商家邀约）
      showBtn:true,//是否展示保存按钮
      dialogLoading:false,//系统内账号列表查询
      originMap:true,//修改补充信息第一步
      allGroupData:[]
    }
  },
  watch:{
  },
  methods: {
    getGroupData(){
      var params = {"page":1,"capacity":200,"model":{"name":null,"chargePeopleNamePhone":null},"sorts":[{"property":"CREATE_TIME","direction":"DESC"}]}
      getGroupData(params).then(data=>{
        this.allGroupData = data.records || []
      })
    },
    open(title,target,parent) {
      this.showBtn  = true
      this.visible = true
      this.updateMaintained = true
      this.userNameEditable = true
      this.accountEditable = true
      this.originMap = true
      this.baseCreditCode = ''
      this.validCode = true
      
      
      this.formRequired = {};
      this.userOptions = [];//账号部分 清空
      this.form = {
        id: null,
        userName:'',
        account:'',
        userId:'',//是否是企业内部人员
        name:'',
        creditCode:'',
        shopLabelId:'',
        saleUserId:'',
        openPay:false,
        address:'',
        addressName:'',
        latitude:0,
        longitude:0,
        streetOfficeCode:'',
        areaCode:'',
        contractNo:'',
        invitationUserId:'',
        historyShop:false,
        shopType:2,//1 商家 2预备商家
        sendInvitation:true,
        addressT:'',//总的地址
        addressAres:'',
        settPrice:0,
        settType:0,
        settCycle:0,
      }
      
      if(parent){
        this.title = modalBaseDataList[parent.type].title
        this.type = parent.type || 0
      }
      
      // this.title = title
      
      // this.nameRequired = false
      this.openPayChangeable = true
      
      // 新建，menu为空时表示新建菜单
      if (target == null) {
        this.selectSystemAllUser()
        this.$nextTick(() => {
          // this.$refs.form.resetFields()
          this.form.id = null
          
          this.form.historyShop = parent.historyShop
          this.form.sendInvitation = !parent.historyShop
          this.form.shopType = parent.historyShop?1:2
          // 如果是销售则自动带入
          // let currentRole = this.$store.state.init.userInfo.currentRole;
          if(this.currentRole == this.roles.sale){ 
            this.form.saleUserId = this.$store.state.init.userInfo.id;
          }
          if(this.type==1){
            //历史商家 签合同所需字段为必填
            this.formRequired.userId = true
            this.formRequired.name = true
            this.formRequired.creditCode = true
            this.formRequired.address = true
          }
        })
        return
      }
      // 编辑
      this.$nextTick(() => {
        for (const key in this.form) {
          this.form[key] = target[key]
          this.form.shopLabelId = target.shopLabelId && target.shopLabelId > 0 ? target.shopLabelId : '';
        }
        if( this.type == 2 ){
          this.userNameEditable = !target.isPartTimeBusiness
          this.openPayChangeable = target.openPay?false:true
          this.form.userName = target.adminUserName
          this.form.account = target.adminUserAccount
          
          this.form.userId = target.adminId
          this.form.sendInvitation = false
          if(target.adminId&&target.adminUserName!='游客'){
            this.formRequired.userName = true
          }
          this.form.addressT = target.address+target.addressName
          this.form.addressAres = target.addressAres
          this.form.shopType = 1
          this.form.settPrice = target.settPrice
          this.form.settType = target.settType
          this.form.settCycle = target.settCycle
        }else if( this.type == 3 ){
          this.form.id = target.id;
          this.form.userName = target.beInvitedUserName;
          this.form.account = target.beInvitedPhone;
          this.form.userId = target.beInvitedUserId;
          this.form.name = target.shopName;
          this.form.creditCode = target.creditCode;
          this.form.shopLabelId = target.shopLabelId && target.shopLabelId > 0 ? target.shopLabelId : '';
          
          this.form.openPay = target.openPay || false;
          this.form.address = target.address;
          this.form.addressName = target.addressName;
          this.form.addressAres = target.addressAres
          this.form.latitude = target.latitude;
          this.form.longitude = target.longitude;
          this.form.saleUserId = target.saleUserId;
          this.form.streetOfficeCode = target.streetOfficeCode;
          this.form.areaCode = target.areaCode;
          this.form.contractNo = target.contractNo;
          this.form.historyShop = target.historyShop;
          this.form.shopType = 2;
          this.form.addressT = '';
          this.form.sendInvitation = false
        }else if( this.type== 4){
          this.form.id = null
          this.form.userId = target.id
          this.form.invitationUserId = this.$store.state.init.userInfo.id;
          this.form.historyShop = false;
          this.form.sendInvitation = true;
          this.form.shopType = 3
          this.form.openPay = false
        }
        this.baseCreditCode = target.creditCode || '';
      })
    },
    async changeUser(value){
      this.form.userId = null;
      this.formRequired.userName = false
      this.userNameEditable = true
      this.accountEditable = true
      this.showBtn = true
      if (!/^1\d{10}$/.test(value)) {
        return 
      }
      var findItem = await this.selectUserByAllUser(value)
      if(findItem){
        this.form.userName = findItem.userName
        this.form.userId = findItem.id;
        if(findItem.businessRoleId.findIndex(i=>i==1)!=-1){
          this.userNameEditable = false //存在美程账户 姓名不可编辑
        }
        this.formRequired.userName = findItem.userName=='游客'?false:true
        if(findItem.disabled){
          this.showBtn = false
          return this.$message.error('该账号为美程实业工作人员，无法发起邀约！如有特殊情况，请联系美程实业后勤特殊处理！');
        }
        this.visibleChange();
      }



      // let idx = this.userOptions.findIndex(i=>i.account==value)
     
      // if(idx!=-1){
      //   this.form.userName = this.userOptions[idx].userName
      //   this.form.userId = this.userOptions[idx].id;
      //   if(this.userOptions[idx].businessRoleId.findIndex(i=>i==1)!=-1){
      //     this.userNameEditable = false //存在美程账户 姓名不可编辑
      //   }
      //   this.formRequired.userName = this.userOptions[idx].userName=='游客'?false:true
      //   if(this.userOptions[idx].disabled){
      //     this.showBtn = false
      //     return this.$message.error('该账号为美程实业工作人员，无法发起邀约！如有特殊情况，请联系美程实业后勤特殊处理！');
      //   }
      //   this.visibleChange();
      // }
    },
    
    async visibleChange(){
      if(this.form.id==null&&this.form.historyShop==false){
        // 1 美程 2商家 3预备 4游客
        // isShopUser:是否是商家 isPreShop:是否是预备商家
        var findItem = await this.selectUserByAllUser(this.form.account)
           let isShopUser = findItem ? findItem.businessRoleId.findIndex(ii=>ii==2)!=-1 : -1
           let isPreShop = findItem ? findItem.businessRoleId.findIndex(ii=>ii==3)!=-1 : -1


        // let isShopUser = this.userOptions.findIndex(i=>i.id==this.form.userId&& i.businessRoleId && i.businessRoleId.findIndex(ii=>ii==2)!=-1)
        // let isPreShop = this.userOptions.findIndex(i=>i.id==this.form.userId&& i.businessRoleId && i.businessRoleId.findIndex(ii=>ii==3)!=-1)

        // console.log('index____isShopUser,isPreShop',isShopUser,isPreShop,this.userOptions[isPreShop].businessShopEntitiy);
      
        if(isShopUser!=-1){
          // let shopName = this.userOptions[isShopUser].businessShopEntitiy.filter(i=>i.shopType==1).map(i=>i.name)
          let shopName = findItem.businessShopEntitiy.filter(i=>i.shopType==1).map(i=>i.name)
          this.$confirm(`该账号【${shopName.join('、')}】已与美程实业建立合作关系，请确认是否发送其它商家名称的邀约?`, '提示', {
            confirmButtonText: '新增其它商家邀约',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            console.log('签合同所需字段为必填');
            //签合同所需字段为必填
            this.formRequired = {}
            this.formRequired.userId = true
            this.formRequired.name = true
            this.formRequired.creditCode = true
            this.formRequired.address = true
            this.userNameEditable = false
            this.accountEditable = false
            console.log(this.formRequired);
            
          }).catch(() => {     
            this.close() 
          }); 
        }

        if(isPreShop!=-1){ //预备商家列表里存在该商户

        //当前这个人 有没有给这个账号发过邀约 人还没接受邀约
        // let curIndex = this.userOptions[isPreShop].businessShopEntitiy.findIndex(i=>i.userId==this.form.userId&&i.invitationUserId==this.$store.state.init.userInfo.id)
        let curIndex = findItem.businessShopEntitiy.findIndex(i=>i.userId==this.form.userId&&i.invitationUserId==this.$store.state.init.userInfo.id)
        console.log('index____curIndex',curIndex);

          this.form.sendInvitation = curIndex!=-1?false:true;//已经发过邀约了 就不发了

          let target = null;//回显的信息
          
          if(findItem.businessShopEntitiy[0].isMaintained){
            //已维护信息 谁可改 谁不可改 
            // let currentRole = this.$store.state.init.userInfo.currentRole;
            //  管理员 新增管理员 销售、后勤  7,8,11,9
            this.updateMaintained  = (this.currentRole==this.roles.manager || this.currentRole==this.roles.newManager || this.currentRole==this.roles.logistics || this.currentRole==this.roles.sale || this.currentRole==-1 )&& (this.type==0 || this.type==5 ) // 有权限
            this.isMaintained = true
            
            target = findItem.businessShopEntitiy[0];//已维护信息
          
          }else{
            if(curIndex!=-1){
              target = findItem.businessShopEntitiy[curIndex];//回显当前人填过的信息
            }
          }
          console.log(target,'__--__--__target');
          if(target){
            //回显信息
            this.form.name = target.name;
            this.form.creditCode = target.creditCode; 
            this.form.shopLabelId = target.shopLabelId && target.shopLabelId > 0 ? target.shopLabelId : '';        
          
            this.form.openPay = target.openPay || false;
            this.form.address = target.address;
            this.form.addressName = target.addressName;
            this.form.latitude = target.latitude;
            this.form.longitude = target.longitude;
            this.form.saleUserId = target.saleUserId;
            this.form.streetOfficeCode = target.streetOfficeCode;
            this.form.areaCode = target.areaCode;
            this.form.contractNo = target.contractNo;
            this.form.historyShop = false;
            this.form.shopType = 2;
            this.form.addressT = '';
          }

          

          
        }

      }
    
    },
    selectUserByAllUser(account){
      return new Promise((suc,err)=>{
        selectSystemAllUser({account}).then(data=>{
          console.log(data,'---')
          if(data && data.length){
            data[0].disabled = data[0].businessRoleId&&data[0].businessRoleId.findIndex(i=>i==1)!=-1;//存在企业内账号即为disable用户
          }
          suc(data && data.length ? data[0] : null)
        })

      })      
    },
    selectSystemAllUser(){ //获取美程账号列表
      return
      this.dialogLoading = true
      selectSystemAllUser({
      }).then(res=>{
      console.log(res,'--->');
      if(res&&res.length>0&&this.form.id==null&&this.form.historyShop==false){
        res.forEach(i=>{
          i.disabled = i.businessRoleId&&i.businessRoleId.findIndex(i=>i==1)!=-1;//存在企业内账号即为disable用户

        })
      
      }
        
        this.userOptions = res || []
      }).finally(()=>{
        this.dialogLoading = false
      })
    },
    addressChange(value) {
      console.log("新增商家的地图选点", value,value.addressObj);
      if(!this.originMap){ //修改补充信息 地图选点变化后 this.type==2&&
        this.form.streetOfficeCode = null
      }
      this.originMap = false
      
      //业务区域先置空
      this.form.areaCode = ''
      this.form.areaName = ''
      if(value.clear){
        this.form.address = ''
        this.form.addressName = ''
        this.form.addressT = ''
        this.form.addressAres = ''
        // this.form.provinceId = null
        // this.form.cityId = null
        // this.form.districtId = null
        // this.form.provinceName = null 
        // this.form.cityName = null
        // this.form.districtName = null
        this.nameObj = {
            provinceName:'',
            cityName:'',
            districtName:''
        }
        this.form.latitude = null
        this.form.longitude = null

        return 
      }
      
      this.$nextTick(()=>{
        if(value.addressObj){
          this.form.address = value.addressObj.address;
          this.form.addressName = value.addressObj.addressName;
          this.form.addressT = value.addressObj.address+value.addressObj.addressName
        }
        
        if(value.addressIds){
          this.nameObj = {
            provinceName:value.addressIds.provinceName,
            cityName:value.addressIds.cityName,
            districtName:value.addressIds.districtName
          }
          this.form.addressAres = value.addressIds.cityName+value.addressIds.districtName
          this.getBussinessArea(value.addressIds)
        }
        
        if(value.addrPoint){
          this.form.latitude = value.addrPoint.lat
          this.form.longitude = value.addrPoint.lng
        }
      
      
      })
      
    },
    getBussinessArea(value){
      //根据省市区名称查相关的业务区域 回填信息
      fetchList({
        capacity: 500,
        model: {
          provinceId:value.provinceId,
          cityId:value.cityId,
          districtId:value.districtId
        },
        page: 1,
      }).then(res=>{
      console.log(res,'--区域的结果');
      this.form.areaCode = res.records.length>0?res.records[0].id:''
      this.form.areaName = res.records.length>0?res.records[0].regionName:''
      
      })
      
    },
    fileChange(val){
      console.log(val,'-------ImportHistoryShop---------');
    },
    confirm (obj,message,fixedError = false) {
      //校验合同编号是否重复
      if(this.form.contractNo){
        
      }
      

      if(this.type==3 || this.type==5){
        this.form.sendInvitation = obj?false:true
        if(this.type==5){
          this.form.invitationUserId = this.$store.state.init.userInfo.id;
        }else{
          this.form.invitationUserId = obj?this.form.invitationUserId:this.$store.state.init.userInfo.id;
        }
        
      }

      if(this.type==2){
        if(!this.form.areaCode){
        let message = (this.currentRole==this.roles.manager || this.currentRole==this.roles.newManager || this.currentRole==this.roles.logistics || this.currentRole==this.roles.sale || this.currentRole==-1)?'基础信息中无此业务区域， 请前往业务区域基础数据中进行管理，然后再补充商家信息':'该商家地址所属业务区域不存在，请联系后勤管理业务区域基础数据后完成再补充商家地址信息'
          return this.$message.error(message)
        }

        if(!this.form.streetOfficeCode){
          return this.$message.error('请先选择街道办')
        }
      
      
      }
      if(!this.validCode){
        return this.$message.error('统一社会信用代码校验失败')
      }
      if (this.form.id == null || this.form.id === '') {
         this.__confirmCreate(message,fixedError)
        return
      }
      this.__confirmEdit(message,fixedError)
    },
    // 确认新建
     async __confirmCreate (message,fixedError) {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        }
        // 调用新建接口
        this.isWorking = true
        this.api.create(this.form)
          .then((data) => {
            console.log(data,'--新增历史商家、预备商家完成');
            
            this.$tip.apiSuccess(message?message:'新建成功')
            
            if(this.form.historyShop&&data){
            //历史商家
              this.$confirm(`新增历史商家成功，请前往完善签约信息，之后系统将自动生成该商家的战略合作协议`, '提示', {
                confirmButtonText: '前往',
                cancelButtonText: '暂不处理',
                type: 'warning'
              }).then(() => {
                this.$router.push({
                  path:'/layun/contractManagement/signedManagement',
                  query:{
                    adminUserNameAccount:this.form.account,
                    shopName:this.form.name,
                    contractNo:this.form.contractNo
                  }
                })
              }).catch(() => {    
                this.visible = false
                this.$emit('success') 
                
              }); 
            
            }else{
              this.visible = false
              this.$emit('success')

            }
          })
          .catch(e => {
            this.$tip.apiFailed(e,fixedError)
          })
          .finally(() => {
            this.isWorking = false
          })
         
      })
    },
    // 确认修改
    __confirmEdit (message,fixedError) {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        }
        // 调用新建接口
        this.isWorking = true
        this.api.updateById(this.form)
          .then(() => {
            this.visible = false
            this.$tip.apiSuccess(message?message:'修改成功')
            this.$emit('success')
          })
          .catch(e => {
            this.$tip.apiFailed(e,fixedError)
          })
          .finally(() => {
            this.isWorking = false
          })
      })
    },
    close(){
      this.$emit('update:visible', false)
      this.visible = false
    }
  },
  created () {
    this.config({
      api: '/layun/accountManagement/shop'
    })
    //获取所有的标签
    this.getGroupData()
    
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables";
$icon-background-color: $primary-color;
.global-window {
  .tip {
    margin-bottom: 12px;
    em {
      font-style: normal;
      color: $primary-color;
      font-weight: bold;
    }
  }
  // // 图标
  // /deep/ .form-item-icon {
  //   .el-form-item__content {
  //     height: 193px;
  //     overflow-y: auto;
  //   }
  //   .el-radio-group {
  //     background: $icon-background-color;
  //     padding: 4px;
  //     .el-radio {
  //       margin-right: 0;
  //       color: #fff;
  //       padding: 6px;
  //       &.is-checked {
  //         background: $icon-background-color - 30;
  //         border-radius: 10px;
  //       }
  //       .el-radio__input.is-checked + .el-radio__label {
  //         color: #fff;
  //       }
  //     }
  //     .el-radio__input {
  //       display: none;
  //     }
  //     .el-radio__label {
  //       padding-left: 0;
  //       // element-ui图标
  //       i {
  //         font-size: 30px;
  //       }
  //       // 自定义图标
  //       [class^="eva-icon-"], [class*=" eva-icon-"] {
  //         width: 30px;
  //         height: 30px;
  //         background-size: 25px;
  //         vertical-align: bottom;
  //       }
  //     }
  //     .el-radio--small {
  //       height: auto;
  //       padding: 8px;
  //       margin-left: 0;
  //     }
  //   }
  // }
}
</style>
