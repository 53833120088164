<template>
  <GlobalWindow
    class="handle-table-dialog"
    title="邀约金"
    :visible.sync="visible"
    :withFooter="false"
    :confirm-working="isWorking"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="金额" prop="money" required>
        <el-input v-model="form.money" type="number" placeholder="请输入商家名称" v-trim maxlength="200"/>
      </el-form-item>
      <el-form-item label="凭证" prop="" >
        <ImageUpload v-model='form.pic'></ImageUpload>
      </el-form-item>
      
      <div style="display:flex;justify-content: center;">
          <el-button type="primary" @click="confirm">修改</el-button>
          <el-button type="primary" @click="confirm">确认</el-button>
          <el-button  @click="visible = false">取消</el-button>
      </div>
      
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import ImageUpload from '../../../common/ImageUpload.vue'


export default {
  name: 'updateInvitationMoney',
  extends: BaseOpera,
  components: {  GlobalWindow , ImageUpload , },
  data () {
    
    return {
      form:{
      },
      visible:false
    }
  },
  methods: {
    async open(target,parent) {
      console.log(target,'_____target');
      this.visible = true
     
    },
  },
  created () {
    // this.config({
    //   api: '/layun/contractManagement/signedManagement', 
    // })
    
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables";
$icon-background-color: $primary-color;

</style>
