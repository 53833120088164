
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import VueClipboard from 'vue-clipboard2'
import directives from './directives'
import filters from './filters'
import plugins from './plugins'
import { mapState, mapMutations } from 'vuex'
import { fetchMenuTree } from './api/system/menu'
import {querySettings} from './api/common';//或
import scroll from 'vue-seamless-scroll'
import './assets/style/element-variables.scss'
import '../src/assets/iconfont/iconfont'



Vue.use(scroll)






Vue.config.productionTip = false
Vue.use(ElementUI, {
  size: 'small'
})
Vue.use(VueClipboard)
Vue.use(directives)
Vue.use(filters)
Vue.use(plugins)

new Vue({
  data: {
    loading: false
  },
  router,
  store,
  computed: {
    ...mapState({
      userInfo: state=>state.init.userInfo,
      homePage: state=>state.init.homePage,
    })

  },
  watch: {
    async userInfo () {
      if (this.userInfo == null) {
        return
      }
      // this.$store.dispatch('querySettings') // 不调接口的写法
      await this.initRoutes()
      
    }
  },
  methods: {
    ...mapMutations(['switchCollapseMenu', 'setHomePage','setSetting']),

    // 初始化本地配置
    initLocalConfig () {
      // 菜单状态配置
      const menuStatus = window.localStorage.getItem('MENU_STATUS')
      if (menuStatus != null) {
        this.switchCollapseMenu(menuStatus === 'true')
      }
    },
    // 初始化路由
    async initRoutes () {
      console.log('接口方法',this.loading,this.userInfo == null);
      if (this.loading || this.userInfo == null) {
        
        return
      }
      
      this.loading = true
      // 重置菜单
      this.$store.commit('resetMenus')
      // 获取菜单
      const storeMenus = this.$store.state.init.menuData.list;
      console.log('---,----',storeMenus);
      console.log(storeMenus,'---------菜单');
      
      if (storeMenus.length > 0 && this.homePage == null) {
        console.log('重置默认页');
        console.log('重置默认页',storeMenus);
        console.log('重置默认页',storeMenus[0].children[0]);


        this.setHomePage(storeMenus[1].children[0])
      }
      console.log('接口调用前','接口绕过登陆');
      await fetchMenuTree()
        .then(menus => {
          this.__afterGetMenus(menus,storeMenus);
        })
        .catch(e => {
          throw e
        })
        .finally(() => {
          this.loading = false
        })
    },
    //菜单路由获取之后
    __afterGetMenus(menus,storeMenus){  
      //添加菜单
      storeMenus.push.apply(storeMenus, menus)
      // 添加路由
      this.__addRouters(storeMenus)
      // this.__addRouters([storeMenus[1]])

      // 404
      router.addRoute({
        path: '*',
        redirect: '/not-found'
      })
      // 首页
      router.addRoute({
        path: '/',
        redirect: this.homePage?this.homePage.url:'/'
      })
      

      // //项目详情
      // router.addRoute('layout', {
      //   path: '/detail',
      //   name: '项目详情',
      //   meta: {
      //     title: '项目详情',
      //     // paths: [...parents.map(p => p.label), route.label]
      //   },
      //   component: () => import('@/views' + '/bussiness/projectDetail')
      // })
      // 路由加载完成后，如果访问的是/，跳转至动态识别的首页
      console.log(this.$route.path,'-----------当前页面',window.location.hash );
      let hashUrl = window.location.hash.substring(1,(window.location.hash.length))
      console.log(hashUrl,'---hashUrl');
      let path = this.$route.path
      if (path === '/') {
        this.$router.push(this.homePage.url)
      }
    },


    // 新建路由
    __addRouters (routes, parents = []) {
      if (routes == null || routes.length === 0) {
        return
      }

      const rs = router.getRoutes()
      for (const route of routes) {

        const parentsDump = JSON.parse(JSON.stringify(parents))
        parentsDump.push(route)
        // console.log(route,'---报错前');
        if (route&&(route.url == null || route.url === '')) {
          this.__addRouters(route.children, parentsDump)
          continue
        }
        if (rs.findIndex(r => r.path === route.url) > -1) {
          this.__addRouters(route.children, parentsDump)
          continue
        }
        if (this.homePage == null && route.url=='/layun/accountManagement/systemAccountList' ) {
          console.log(route,'------------设置默认页');
          this.setHomePage(route)
        }
        router.addRoute('layout', {
          path: route.url,
          name: route.label,
          meta: {
            title: route.label,
            paths: [...parents.map(p => p.label), route.label]
          },
          component: () => import('@/views' + route.url)
        })
        this.__addRouters(route.children, parentsDump)
      }

    }
  },
  async created () {
    console.log('创建期-----------,,,',this.$route.path,window.location);
    
    if (this.userInfo == null) {
      return
    }
    // this.$store.dispatch('querySettings') // 跳过登录
    await this.initRoutes().catch(() => {})
    
    
  },
  mounted () {
    this.initLocalConfig()
  },
  render: h => h(App)
}).$mount('#app')
