import request from '@/utils/request'

// 查询
export function fetchList (data) {
  return request.post('/basics/outboundmerchant/page', data)
}
// 新建
export function create (data) {
  return request.post('/basics/outboundmerchant/create', data, {
    trim: true
  })
}

// 修改
export function updateById (data) {
  return request.post('/basics/outboundmerchant/create', data, {
    trim: true
  })
}

// 删除
export function deleteById (id) {
  return request.get(`/basics/outboundmerchant/delete/${id}`)
}
