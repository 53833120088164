<template>
  <TableLayout :permissions="['system:user:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="80px" inline>
      <el-form-item label="账号/姓名" prop="keywords">
        <el-input v-model="searchForm.keywords" v-trim placeholder="请输入账号(手机号)/姓名搜索" @keypress.enter.native="search" style="width:250px"/>
      </el-form-item>
      <el-form-item label="角色" prop="roleId">
        <role-select  v-model="searchForm.roleId" placeholder="请选择角色" :inline="true" selectMode="search" @change="search" clearable ></role-select>
      </el-form-item>

      <section>
        <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>

    <template v-slot:table-wrap>
      <ul class="toolbar" v-if="userInfo.currentMcRoleStatus==0&&(currentRole==roles.manager || currentRole == roles.newManager || currentRole==-1)" v-permissions="['system:user:create']">
        <li v-permissions="['system:user:create']"><el-button icon="el-icon-plus" type="primary" @click="$refs.operaUserWindow.open('新建用户',null,businessRoleId)">新建</el-button></li>
        <li ><el-button icon="el-icon-download" type="primary" @click="exportTable">导出</el-button></li>

      </ul>
      <!-- :default-sort = "{prop: 'createTime', order: 'descending'}"  :tree-props="{children: 'children', hasChildren: 'hasChildren'}" -->
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        :rowKey="(row)=>row.id"
        
        stripe
        @selection-change="handleSelectionChange"
        @sort-change="handleSortChange"
      >
        <el-table-column type="selection" width="55px" :selectable="(row)=>Object.keys(row).findIndex(i=>i=='children')!=-1">
          <!-- avatar -->
        </el-table-column>

        <el-table-column prop="account" label="账号" min-width="100px" align="center" ></el-table-column>
        <el-table-column prop="username" label="姓名" min-width="100px" align="center" ></el-table-column>

        <el-table-column prop="roleName" label="角色类型" min-width="120px" align="center" >
          <div slot-scope="{row}">
            <div v-if="row.isTransfer" style="width:22px;height:22px;border-radius:50%;color:red;border:1px solid red;font-size:12px;display:inline-flex;align-items:center;justify-content:center;padding-top:3px;">移</div>
            {{row.roleName == null ? '暂无角色' : row.roleName}}
          </div>
        </el-table-column>
        <el-table-column prop="roleName" label="业务区域" min-width="150px" :show-overflow-tooltip="true" align="center" >
          <div slot-scope="{row}">{{(row.basicsRegionDto == null || row.basicsRegionDto.length==0) ? '-' :row.basicsRegionDto.map(i=>i.regionName).join(',')}}</div>
        </el-table-column>
        <el-table-column prop="createTime" label="新增时间" min-width="100px" align="center" ></el-table-column>
        <el-table-column prop="remarks" label="备注" min-width="100px" align="center" ></el-table-column>
        <el-table-column prop="status" label="状态" min-width="100px" align="center" >
          <div slot-scope="{row}">{{row.status == 0 ? '正常' :row.status == 1 ? '冻结'+(row.isTransfer?'(已移交)':'') :row.status == 2 ? '禁用' : 'Error'}}</div>
        </el-table-column>

        <el-table-column
          v-if="containPermissions(['system:user:update','system:user:delete'])"
          label="操作"
          width="270"
          align="center" 
          fixed="right"
        >
          <div  slot-scope="{row}" v-if="row&&userInfo.currentMcRoleStatus==0&&(currentRole==roles.manager || currentRole == roles.newManager || currentRole==-1)">
            <!-- row.avatar 是第一个身份的按钮信息 &&   curUser=='新增管理员'&&row.roleId!='新增管理员&&row.roleId!='管理员' !(userInfo.currentRole==8&&(row.roleId==8 || row.roleId==7))   &&   -->
            <div v-permissions="['system:user:update']">
              <el-button  type="text" icon="el-icon-edit" @click="$refs.operaUserWindow.open('编辑用户',row,businessRoleId)" v-if="row.edit" >编辑</el-button>
              <el-button  type="text" icon="el-icon-share"  @click="$refs.operaRoleChangeWindow.open('账户移交',row,businessRoleId)"  v-if="row.change">移交</el-button>
              <el-button  type="text" icon="el-icon-switch-button"  @click="$refs.operaUserStatusWindow.open('账户禁用',row,{businessRoleId:1,status:2})"  v-if="row.delete" style="color:red">禁用</el-button>

              <el-button  type="text" icon="el-icon-moon"  @click="$refs.operaUserStatusWindow.open('账户冻结',row,{businessRoleId:1,status:1})" v-if="row.freeze">冻结</el-button>
              <el-button  type="text" icon="el-icon-switch-button"  @click="$refs.operaUserStatusWindow.open('账户启用',row,{businessRoleId:1,status:0})" style="color:green" v-if="row.unfreeze">解冻</el-button>
              <el-button  type="text" icon="el-icon-notebook-2" @click="$refs.operaRecordWindow.open(1,row,20)" >操作记录</el-button>
            </div>
            
          </div>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaUserWindow ref="operaUserWindow" @success="handlePageChange(tableData.pagination.pageIndex)"/>
    <!-- 账号移交 -->
    <OperaRoleChangeWindow ref="operaRoleChangeWindow"  @success="handlePageChange(tableData.pagination.pageIndex)"> </OperaRoleChangeWindow>
    <!-- 账号-角色状态改变 -->
    <OperaUserStatusWindow ref="operaUserStatusWindow"  @success="handlePageChange(tableData.pagination.pageIndex)"></OperaUserStatusWindow>
    <!-- 操作记录 -->
    <OperaRecordWindow ref="operaRecordWindow"></OperaRecordWindow>
    
  </TableLayout>
</template>

<script>
import Pagination from '@/components/common/Pagination'
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
// import OperaUserWindow from '@/components/system/user/OperaUserWindow'
import OperaUserWindow from '../../../components/layun/user/OperaUserWindow.vue'
import OperaRoleChangeWindow from '../../../components/layun/accountManagement/OperaRoleChangeWindow.vue'
import OperaUserStatusWindow from '../../../components/layun/accountManagement/OperaUserStatusWindow.vue'
import OperaRecordWindow from '../../../components/layun/common/OperaRecordWindow.vue'


import RoleConfigWindow from '@/components/system/user/RoleConfigWindow'
import ResetPwdWindow from '@/components/system/user/ResetPwdWindow'
import DepartmentSelect from '@/components/common/DepartmentSelect'
import PositionSelect from '@/components/common/PositionSelect'
import CompanySelect from "@/components/common/CompanySelect"
import RoleSelect from '@/components/common/RoleSelect'
import { mapState } from 'vuex'
// import { fetchList } from '@/api/system/user'

export default {
  name: 'MeichengUserList',
  extends: BaseTable,
  components: { PositionSelect, DepartmentSelect, ResetPwdWindow, RoleConfigWindow, OperaUserWindow, OperaRoleChangeWindow ,OperaUserStatusWindow ,OperaRecordWindow , TableLayout, Pagination ,CompanySelect,RoleSelect },
  data () {
    return {
      // 搜索
      searchForm: {
        keywords:null,
        roleId:null,
        businessRoleId:1
      },
      businessRoleId:1,//1美程 2商家 3 预备商家 4游客
    }
  },
  computed:{
    ...mapState({
      userInfo:state=>state.init.userInfo || {},
      currentRole:state=>state.init.userInfo.currentRole,
      roles:state=>state.init.roles
    }),

  },
  watch:{
    // 'searchForm.roleId':{
    //   handler(val,old){
    //     if(val!=old){
    //       this.search()
    //     }
    //   }
    // }
  },
  created () {
    this.config({
      module: '账号',
      api: '/system/user',
      'field.main': 'account',
      sorts: [{
        property: 'CREATE_TIME',
        direction: 'DESC'
      }]
    })
    this.search()
  },
  methods:{
    // showOpera(row){
    //   let newManagerId = this.roles.newManager;
    //   let managerId = this.roles.manager;
    //   let status = row.status;//0 正常 1 冻结 2禁用
    //   //是否可以进行编辑操作
      
    //   console.log('object',Object.keys(row).findIndex(i=>i=='children'),row.id);
    //   let isMain = Object.keys(row).findIndex(i=>i=='children')==-1?false:true;
    //   if(isMain &&row.children&&row.children.length>0){//主角色  且存在多个角色
    //       let list = [] ;////需要比较的字段都在这里
    //       list.push({
    //         id:row.roleId,
    //         status:row.status,
    //         isTransfer:row.isTransfer,
    //         name:row.roleName,
    //       })
    //       row.children.map(item=>{
    //         list.push({
    //           id:item.roleId,
    //           name:item.roleName,
    //           status:item.status,
    //           isTransfer:item.isTransfer
    //         })
    //       })
          
    //       // let result1 = !(this.userInfo.currentRole==newManagerId&&(list.filter(i=>i.id==newManagerId).length>0 || list.filter(i=>i.id==managerId).length>0)) || this.userInfo.currentRole==managerId; //角色中是否存在当前用户的 操作状态  true  可操作 false 不可操作;
          
    //       let result1 = this.userInfo.currentRole==managerId?true:!(this.userInfo.currentRole==newManagerId&&(list.filter(i=>i.id==newManagerId).length>0 || list.filter(i=>i.id==managerId).length>0))
    //       let result2 = !(this.userInfo.currentRole==newManagerId&&(row.roleId==newManagerId || row.roleId==managerId) ); //当前角色是否是当前用户的 操作状态  true  可操作 false 不可操作;
    //       // 是否每一个角色都不可操作(新增管理员不可编辑新增管理员和管理员)
    //       let result3 = !(
    //         this.userInfo.currentRole==newManagerId && 
    //         ( list.filter(i=>(i.id==newManagerId || i.id==managerId )).length==list.length )
            
    //       );
    //       let noChangeRole = list.filter(i=>(i.status==2|| (i.status==1&&i.isTransfer))); //所有被冻结的 被移交的角色
    //       let changeAbleRole = list.filter(i=>(
    //           (i.status==0 || (i.status==1&&!i.isTransfer))
    //         )
    //       )
    //       if(this.userInfo.currentRole==newManagerId){
    //         changeAbleRole = changeAbleRole.filter(i=>(i.id!=newManagerId && i.id!=managerId ))
    //       }

    //       // console.log('noChangeRole',noChangeRole,noChangeRole.length!=list.length);
    //       // console.log('changeAbleRole',changeAbleRole);


    //       return {
    //         edit:result1&&noChangeRole.lengt!=list.length,
    //         change:result3&&changeAbleRole.length>0,   // 冻结可移交
    //         delete:result1&&(status!=2),
    //         freeze:result2&&status==0,
    //         unfreeze:result2&&status==1&&!row.isTransfer,
    //       }   
    //   }else{ //单角色 或 多个角色的其中之一
    //       let result2 = !(this.userInfo.currentRole==newManagerId&&(row.roleId==newManagerId || row.roleId==managerId)); //当前角色是否是当前用户的 操作状态  true  可操作 false 不可操作;
    //       let isSingleRole = isMain?true:false
    //       // console.log('单角色 或 多个角色的其中之一',result2,isSingleRole);
    //       return {
    //         edit:isSingleRole?result2&&status!=2:false,
    //         change:isSingleRole?result2&&status!=2:false,
    //         delete:isSingleRole?result2&&status!=2:false,
    //         freeze:result2&&status==0,
    //         unfreeze:result2&&status==1&&!row.isTransfer,
    //       }
    //   }
    // },

    exportTable(obj) {
      if (this.tableData.selectedRows.length == 0) {
        this.$message.warning("至少选择一条");
        return;
      }
      const tHeader = ['账号','姓名','角色类型[状态]','业务区域','新增时间','备注']
      const filterVal = ['account','username','roleName','basicsRegionDto','createTime','remarks']
      const fileName = '美程企业账号列表'
       
      import("@/vendor/Export2Excel").then((excel) => {
        const data = this.formatData(filterVal,this.tableData.selectedRows)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: (fileName || '表格'),
          autoWidth: true,
          bookType: "xlsx",
        });
      });
    },
    //格式化数据
    formatData(filterVal,list){
      return list.map((v) =>
        filterVal.map((j) => {
          console.log(j,'___',v[j]);
          switch (j) {
            case "roleName":
              let value = ''
              if(v.children&&v.children.length>0){
                value = v.roleName+'['+(v.status == 0 ? '正常' :v.status == 1 ? '冻结'+(v.isTransfer?'(已移交)':'') :v.status == 2 ? '禁用' : 'Error')+']'
                v.children.map(i=>{
                  value = value +','+i.roleName+'['+(i.status == 0 ? '正常' :i.status == 1 ? '冻结'+(i.isTransfer?'(已移交)':'') :i.status == 2 ? '禁用' : 'Error')+']'
                })
              }else{
                value = v.roleName+'['+(v.status == 0 ? '正常' :v.status == 1 ? '冻结'+(v.isTransfer?'(已移交)':'') :v.status == 2 ? '禁用' : 'Error')+']'
              }
              return value;
            case "basicsRegionDto":
              return v[j]&&v[j].regionName || "-";
            default:
              return v[j] || "/";
          }
          
          
        })
      );
    
    },

    handlePageChange (pageIndex) {
    console.log('表单查询-',pageIndex);
      this.__checkApi()
      this.tableData.pagination.pageIndex = pageIndex || this.tableData.pagination.pageIndex
      this.isWorking.search = true
      console.log('表单查询',pageIndex);
      this.tableData.list = []
      this.api.fetchList({
        page: this.tableData.pagination.pageIndex,
        capacity: this.tableData.pagination.pageSize,
        model: this.searchForm,
        sorts: this.tableData.sorts
      })
        .then(data => {
          console.log(data,'---------页码变更');
          
          this.$set(this.isWorking,'search',false)
          if(data){
            let currentRole = this.currentRole;
            let roles = this.roles;
            data.records.forEach((i,index)=>{
              i.tableIndex = index+'_TABLE';
              let curRoles = [i.roleId]
              //编辑 移交 禁用 都在主帐号上
              // row.status;//0 正常 1 冻结 2禁用
              if(i.children){
                i.children.forEach((item)=>{
                  curRoles.push(item.roleId)
                  item.edit = false
                  item.change = false
                  item.delete = false
                  item.freeze = item.status==0?true:false; //下方的条件也得判断
                  item.unfreeze = item.status==1?true:false
                })
              }
              /**
               * 1、超管可对美程企业的管理员账号进行管理（新增、移交（更换账号），美程管理员账号没有冻结、禁用）。
               * 2、管理员自己可以对账号进行移交
               * 3、新增管理员不能新增新增管理员，不能对管理员、新增管理员账号进行账号管理(冻结解冻根据角色来)。
               * 4、编辑权归管理员、新增管理员，其它有角色的仅为查看权
              */

              if(currentRole!=roles.manager&&currentRole!=roles.newManager&&currentRole!=-1){ // 4
              console.log('不是新增管理员也不是管理员也不是超管');
                  i.edit = false
                  i.change = false
                  i.delete = false
                  i.freeze = false
                  i.unfreeze = false
              }else{
                //默认 manager newManager  -1  都可以操作
                i.edit = true
                i.change = true
                i.delete = i.status!=2?true:false
                i.freeze = i.status==0?true:false;
                i.unfreeze = i.status==1?true:false;

                let idx = curRoles.findIndex(r=>(r==roles.newManager || r==roles.manager))
                if(currentRole==roles.newManager&&idx!=-1){ // 3
                  console.log('新增管理员遇见了同级和上级');

                  i.edit = false
                  i.change = false
                  i.delete = false
                  i.freeze = ( i.roleId==roles.newManager || i.roleId==roles.manager)?false:i.freeze
                  i.unfreeze = ( i.roleId==roles.newManager || i.roleId==roles.manager)?false:i.unfreeze

                  i.children&&i.children.forEach(item=>{
                    item.freeze = ( item.roleId==roles.newManager || item.roleId==roles.manager)?false:item.freeze
                    item.unfreeze = ( item.roleId==roles.newManager || item.roleId==roles.manager)?false:item.unfreeze
                  })
                }
                if(currentRole==roles.manager && curRoles.findIndex(r=>(r==roles.manager))!=-1){ //2 1 管理员自己可以对账号进行移交
                console.log('管理员操作自己');
                  i.edit = false
                  i.delete = false

                  i.freeze = i.roleId==roles.manager?false:i.freeze
                  i.unfreeze = i.roleId==roles.manager?false:i.unfreeze

                  i.children&&i.children.forEach(item=>{
                    item.freeze = item.roleId==roles.manager?false:item.freeze
                    item.unfreeze = item.roleId==roles.manager?false:item.unfreeze
                  })
                }

                if(currentRole==-1 && curRoles.findIndex(r=>(r==roles.manager))!=-1){ // 1
                  console.log('超管来了');
                  i.edit = false
                  i.delete = false
                  i.freeze = i.roleId==roles.manager?false:i.freeze
                  i.unfreeze = i.roleId==roles.manager?false:i.unfreeze

                  i.children&&i.children.forEach(item=>{
                    item.freeze = item.roleId==roles.manager?false:item.freeze
                    item.unfreeze = item.roleId==roles.manager?false:item.unfreeze
                  })
                }
              
              }
            })
            this.tableData.list = data.records?data.records:[]
            this.tableData.pagination.total = data.total?data.total:0
          }
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          
          setTimeout(()=>{
            this.adjustWidth()
            this.isWorking.search = false
          this.$set(this.isWorking,'search',false)
          },20)
          
        })
    },












    
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";
// 列表头像处理
.table-column-avatar {
  img {
    width: 48px;
  }
}
</style>
