import request from '@/utils/request'

export function fetchList(data){
  //总的项目资料查询
  return request.post('/business/project/queryPageEnclosures',data)
}

//上传纪要资料
export function saveEnclosure (data) {
  return request.post('/business/project/saveMinutes',data)
}


// 查询纪要列表
export function fetchListSummary (data) {
  return request.get('/business/project/queryMinutes',{params:data})
}
// 删除纪要
export function deleteEnclosureById (data) {
  return request.get('/business/project/deleteMinutes',{params:data})
}



//上传图片资料
export function savePicture (data) {
  return request.post('/business/project/savePicture',data)
}

// 查询图片列表
export function fetchListPicture (data) {
  return request.get('/business/project/queryPicture',{params:data})
}
// 删除图片
export function deletePictureById (data) {
  return request.get('/business/project/deletePicture',{params:data})
}