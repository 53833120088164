<template>
<div style="padding:20px ">
    <el-breadcrumb separator="/" style="margin-bottom:20px">
        <el-breadcrumb-item v-for="(path,index) in paths" :key="index" >{{path}}</el-breadcrumb-item>
    </el-breadcrumb>

    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="商家结算总计列表" name="first" >
            <TableLayout  :withBreadcrumb="false" v-if="activeName=='first'">
                <!-- 搜索表单 -->
                <el-form ref="searchFormCur" slot="search-form" :model="searchFormCur" label-width="100px" inline>
                
                <!-- <el-form-item label="商家名称" prop="shopSearch">
                    <el-input v-model="searchFormCur.shopSearch" v-trim placeholder="请输入商家名称" @keypress.enter.native="search" style="width:250px"/>
                </el-form-item> -->
                <el-form-item label="商家" prop="shopSearch">
                    <el-input v-model="searchFormCur.shopSearch" v-trim placeholder="请输入姓名/手机号/商家名称" @keypress.enter.native="search" style="width:250px"/>
                </el-form-item>
                <el-form-item label="协议价格" >
                    <div>
                        <el-input v-model="searchFormCur.settPriceStart" type="number" v-trim placeholder="最小价格" @keypress.enter.native="search" style="width:100px" /> 至 
                        <el-input v-model="searchFormCur.settPriceEnd" type="number" v-trim placeholder="最大价格" @keypress.enter.native="search" style="width:100px"/>
                    </div>
                </el-form-item>
                <el-form-item label="分组" prop="shopLabelIds" >                     
                    <el-select v-model="searchFormCur.shopLabelIds" class="w250" remote clearable  multiple filterable >
                        <el-option  v-for="(item,index) in allGroupData" :value="item.id" :label="item.name" :key="'searchFromUnitId'+index"></el-option>
                        
                    </el-select>
                </el-form-item>
                <section>
                    <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
                    <el-button @click="reset">重置</el-button>
                </section>
                </el-form>

                <template v-slot:table-wrap>
                <ul class="toolbar" v-if="userInfo.currentMcRoleStatus==0">
                    <li ><el-button icon="el-icon-download" type="primary" @click="exportTable(exportObj1)">导出</el-button></li>
                </ul>
                <el-table
                    ref="table"
                    v-loading="isWorking.search"
                    :data="tableData.list"
                    stripe
                    @selection-change="handleSelectionChange"
                    @sort-change="handleSortChange"
                >
                    <el-table-column type="selection" width="55px" align="center"></el-table-column>

                    <el-table-column prop="shopName" label="商家名称" min-width="100px"  align="center">
                        <el-button slot-scope="{row}" type="text"  @click="$refs.shopDetail.open(1,row)">{{row.shopName}}</el-button>
                    </el-table-column>
                     <el-table-column prop="shopLabelName" label="分组" width="50px" align="center" show-overflow-tooltip  >
                        <div slot-scope="{row}">
                            <span :style="{background:row.shopLabelColour,display:'inline-block',padding:'0px 5px',borderRadius:'5px',fontWeight:'bold'}">{{ row.shopLabelName }}</span>
                          
                        </div>
                    </el-table-column>
                    <el-table-column prop="adminId" label="管理员" min-width="150px"  align="center">
                        <div slot-scope="{row}">{{row.adminName}}/{{row.adminAccount}}</div>
                    </el-table-column>

                    <el-table-column prop="role" label="协议价格" min-width="120px" align="center">
                        <div slot-scope="{row}">{{row.settPrice?(row.settPrice+'元/千克'):'-'}}</div>
                    </el-table-column>
                    <el-table-column prop="settType" label="结算方式" min-width="100px" align="center">
                        <div slot-scope="{row}">{{row.settType==2?`周期结算(${row.settCycle}天)`:row.settType==1?'现结':'-'}}</div>
                    </el-table-column>
                    <el-table-column prop="transportNum" label="拉运结算总单数" min-width="100px" align="center" sortable>
                        <el-button slot-scope="{row}"  type="text" @click="orderNumDetail(row)">{{row.transportNum}}</el-button>
                    </el-table-column>
                    <el-table-column prop="totalWeight" label="累计拉运总重量" min-width="100px" align="center" sortable>
                        <span slot-scope="{row}"  type="text">{{row.totalWeight || 0}}</span>
                    </el-table-column>
                    <el-table-column prop="payableSettTotalPrice" label="应结算总金额" min-width="100px" align="center" sortable>
                        <span slot-scope="{row}"  type="text">{{row.payableSettTotalPrice || 0}}</span>
                    </el-table-column>
                    <el-table-column prop="unSettTotalPrice" label="待结算金额" min-width="100px" align="center" sortable>
                        <span slot-scope="{row}"  type="text">{{row.unSettTotalPrice || 0}}</span>
                    </el-table-column>
                    <el-table-column prop="settTotalPrice" label="已结算金额" min-width="100px" align="center" sortable>
                        <span slot-scope="{row}"  type="text">{{row.settTotalPrice || 0}}</span>
                    </el-table-column>
                    
                </el-table>
                <pagination
                    @size-change="handleSizeChange"
                    @current-change="handlePageChange"
                    :pagination="tableData.pagination"
                ></pagination>
                </template>
            </TableLayout>
        </el-tab-pane>
        <el-tab-pane label="拉运结算明细列表" name="second">
            <TableLayout  :withBreadcrumb="false" v-if="activeName=='second'">
                <!-- 搜索表单 -->
                <el-form ref="searchFormCur" slot="search-form" :model="searchFormCur" label-width="100px" inline>
                <el-form-item label="商家" prop="shopSearch">
                    <el-input v-model="searchFormCur.shopSearch" v-trim placeholder="请输入姓名、昵称、手机号、商家名称" @keypress.enter.native="search" style="width:250px"/>
                </el-form-item>
                <!-- <el-form-item label="商家名称" prop="shopSearch">
                    <el-input v-model="searchFormCur.shopSearch" v-trim placeholder="请输入商家名称" @keypress.enter.native="search" style="width:250px"/>
                </el-form-item> -->
                <el-form-item label="司机" prop="driverSearch">
                    <el-input v-model="searchFormCur.driverSearch" v-trim placeholder="请输入姓名/手机号" @keypress.enter.native="search" style="width:250px"/>
                </el-form-item>
                <el-form-item label="拉运时间" >
                    <el-date-picker v-model="value2" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="dateChange($event,1)"
                        :picker-options="pickerOptions" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="拉运状态" prop="status">
                    <el-select v-model="searchFormCur.status" placeholder="请选择" style="width:100px" :popper-append-to-body="false">
                        <el-option  :key="key" :label="item.label" :value="item.status" v-for="(item,key) in lyStatus" ></el-option>

                    </el-select>
                </el-form-item>
                <el-form-item label="结算方式" prop="settType">
                    <el-select v-model="searchFormCur.settType" placeholder="请选择" style="width:100px" :popper-append-to-body="false">
                        <el-option  key="-1" label="全部" :value="null"  ></el-option>
                        <el-option  key="1" label="现结" :value="1"  ></el-option>
                        <el-option  key="2" label="周期结算" :value="2"  ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="结算状态" prop="settStatus">
                    <el-select v-model="searchFormCur.settStatus" placeholder="请选择" style="width:100px" :popper-append-to-body="false">
                        <el-option  :key="key" :label="item.label" :value="item.status" v-for="(item,key) in lySettleStatus" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="拉运单编号" prop="orderNos">
                    <el-input v-model="searchFormCur.orderNos" v-trim placeholder="多个编号以','分开" @keypress.enter.native="search" style="width:250px"/>
                </el-form-item>
                <el-form-item label="分组" prop="shopLabelIds" >                     
                    <el-select v-model="searchFormCur.shopLabelIds" class="w250" remote clearable  multiple filterable >
                        <el-option  v-for="(item,index) in allGroupData" :value="item.id" :label="item.name" :key="'searchFromUnitId'+index"></el-option>
                        
                    </el-select>
                </el-form-item>

                <section>
                    <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
                    <el-button @click="reset">重置</el-button>
                </section>
                </el-form>

                <template v-slot:table-wrap>
                <ul class="toolbar"  v-if="userInfo.currentMcRoleStatus==0">
                    <li ><el-button icon="el-icon-plus" type="primary" @click="$refs.chooseNumbersWindow.open(1,null)">生成结算单</el-button></li>
                    <li ><el-button icon="el-icon-download" type="primary" @click="exportTable(exportObj2)">导出</el-button></li>
                </ul>
                <el-table
                    ref="table"
                    v-loading="isWorking.search"
                    :data="tableData.list"
                    stripe
                    @selection-change="handleSelectionChange"
                    @sort-change="handleSortChange"
                >
                    <el-table-column type="selection" width="55px" align="center"></el-table-column>

                    <el-table-column prop="orderNo" label="拉运单编号" min-width="160px" align="center"></el-table-column>
                    <el-table-column prop="transportTime" label="拉运日期" min-width="100px" align="center"></el-table-column>

                    <el-table-column prop="shopName" label="商家名称" min-width="120px" align="center">
                        <el-button slot-scope="{row}" type="text"  @click="$refs.shopDetail.open(1,row)">{{row.shopName}}</el-button>
                    </el-table-column>
                    <el-table-column prop="shopLabelName" label="分组" width="50px" align="center" show-overflow-tooltip  >
                        <div slot-scope="{row}">
                            <span :style="{background:row.shopLabelColour,display:'inline-block',padding:'0px 5px',borderRadius:'5px',fontWeight:'bold'}">{{ row.shopLabelName }}</span>
                          
                        </div>
                    </el-table-column>
                    <el-table-column prop="adminUserId" label="商家管理员" min-width="160px" align="center">
                        <div slot-scope="{row}">{{row.adminUserName}}/{{row.adminUserAccount}}</div>
                    </el-table-column>
                    <!-- <el-table-column prop="createTime1" label="电话" min-width="100px"></el-table-column> -->
                    <el-table-column prop="driverInfos" label="司机" min-width="160px" align="center">
                        <div slot-scope="{row}">
                            <div v-if="row.driverInfos" v-for="item in row.driverInfos">{{item.driverUserName}}/{{item.driverUserAccount}}</div>
                            <span v-else>-</span>
                        </div>
                    </el-table-column>
                    <el-table-column prop="settPrice" label="协议价格" min-width="100px" align="center">
                        <div slot-scope="{row}">{{row.settPrice}}元/千克</div>
                    </el-table-column>
                    <el-table-column prop="settWeight" label="拉运重量" min-width="100px" align="center">
                        <div slot-scope="{row}">{{row.settWeight}}千克</div>
                    </el-table-column>

                    <el-table-column prop="status" label="拉运状态" min-width="100px" align="center">
                        <!-- 0待核验 2已完成 4已取消 -->
                        <div slot-scope="{row}">{{lyStatus.findIndex(i=>i.status==row.status)==-1?'异常状态':lyStatus[lyStatus.findIndex(i=>i.status==row.status)].label}}</div>
                    </el-table-column>
                    <el-table-column prop="settType" label="结算方式" min-width="100px" align="center">
                        <div slot-scope="{row}">{{row.settType==2?`周期结算(${row.settCycle}天)`:row.settType==1?'现结':'-'}}</div>
                    </el-table-column>

                    <el-table-column prop="settTotalPrice" label="拉运结算总金额" min-width="120px" align="center"></el-table-column>
                    <el-table-column prop="settStatus" label="结算状态" min-width="100px" align="center">
                        <!-- <div slot-scope="{row}">{{row.settStatus==0?'待结算':row.settStatus==1?'结算中':row.settStatus==2?'已结算':''}}</div> -->
                        <div slot-scope="{row}">{{lySettleStatus.filter(i=>i.status==row.settStatus)[0].label || '-'}}</div>
                    </el-table-column>
                    <el-table-column prop="settRemark" label="备注" min-width="100px" align="center">
                        <div slot-scope="{row}">
                            <span>{{row.remark?row.remark:'/'}}</span>
                            <i class="el-icon-edit" v-if="userInfo.currentMcRoleStatus==0" style="margin-left:4px;color:#2E68EC;" @click="$refs.remarkUpdateWindow.open(row,5)"></i>
                        </div>
                    </el-table-column>
                    <el-table-column
                    label="操作"
                    width="270"
                    fixed="right"
                    v-if="userInfo.currentMcRoleStatus==0"
                    >
                    <div  slot-scope="{row}" >
                        <!-- 所有现结的，结算中或者结算已完成但未入库的时候，会出现编辑按钮。 结算已完成但未入库的时候，只能编辑结算总金额，不能编辑结算方式。  -->
                        <!-- 周期结算的未结算/待结算/结算中会出现编辑按钮。结算中的不能编辑结算方式，只能编辑结算总金额。 -->
                            <!-- 5确认入库 editSettlement   true;false  -->
                        <!-- editSettlement.open  @type: 1 拉运单 2  出库单 
                                                * @status: 1明细单非结算单,2
                                                * @settTypeEditable: true 可编辑结算方式 false 不可编辑结算方式 
                                                * @row:具体的修改字段 默认值
                         -->
                        <el-button v-if="row.settType==1&&(row.settStatus==1 || row.settStatus==2&&row.status&&row.status<5)" @click="$refs.editSettlement.open(1,1,row.settStatus==2?false:true,row)" type="text" icon="el-icon-edit">编辑结算</el-button>
                        
                        <el-button v-if="row.settType==2&&row.settStatus!=2"  @click="$refs.editSettlement.open(1,1,row.settStatus==1?false:true,row)"   type="text" icon="el-icon-edit">编辑结算</el-button>

                        <el-button  type="text" icon="el-icon-s-claim" v-if="row.sureSett==1" @click="$refs.statementWindow.open(1,true,[row])">生成结算单</el-button>

                        <el-button  type="text" icon="el-icon-notebook-2"  @click="$refs.operaRecordWindow.open(2,row,3)">操作记录</el-button>
                    </div>
                    </el-table-column>
                    <div slot="append" v-if="tableData.list.length>0">
                        <div class='sum_footer xiaoji' ref='sum_xiaoji'>
                            <div class='sum_footer_unit' v-for="item in 9"></div>
                            <div class='sum_footer_unit'>小计</div>
                            <div class='sum_footer_unit'>{{getXiaoji('settTotalPrice')}}</div>
                            <div class='sum_footer_unit' v-for="item in 3"></div>
                        </div>
                        <div class='sum_footer heji' ref='sum_heji'>
                            <div class='sum_footer_unit' v-for="item in 9"></div>
                            <div class='sum_footer_unit'>合计</div>
                            <div class='sum_footer_unit'>{{sumData2&&sumData2.settTotalPrice}}</div>
                            <div class='sum_footer_unit' v-for="item in 3"></div>
                        </div>
                    </div>
                </el-table>
                <pagination
                    @size-change="handleSizeChange"
                    @current-change="handlePageChange"
                    :pagination="tableData.pagination"
                ></pagination>
                </template>
            </TableLayout>
        </el-tab-pane>
        <el-tab-pane label="结算单列表" name="third">
            <TableLayout  :withBreadcrumb="false" v-if="activeName=='third'">
                <!-- 搜索表单 -->
                <el-form ref="searchFormCur" slot="search-form" :model="searchFormCur" label-width="100px" inline>
                    <el-form-item label="结算单号" prop="settNos">
                        <el-input v-model="searchFormCur.settNos" v-trim placeholder="多个单号以','隔开" @keypress.enter.native="search" style="width:250px"/>
                    </el-form-item>
                    <el-form-item label="拉运单号" prop="transportNos">
                        <el-input v-model="searchFormCur.transportNos" v-trim placeholder="多个单号以','隔开" @keypress.enter.native="search" style="width:250px"/>
                    </el-form-item>
                    <el-form-item label="商家名称" prop="shopSearch">
                        <el-input v-model="searchFormCur.shopSearch" v-trim placeholder="请输入商家名称" @keypress.enter.native="search" style="width:250px"/>
                    </el-form-item>
                    <el-form-item label="商家管理员" prop="shopSearch">
                        <el-input v-model="searchFormCur.shopSearch" v-trim placeholder="请输入姓名、手机号" @keypress.enter.native="search" style="width:250px"/>
                    </el-form-item>
                    <el-form-item label="结算生成时间" prop="keywords">
                        <el-date-picker v-model="value1" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="dateChange($event,2)"
                            :picker-options="pickerOptions" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="结算完成时间" prop="keywords">
                        <el-date-picker v-model="value2" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="dateChange($event,3)"
                            :picker-options="pickerOptions" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="结算方式" prop="settType">
                        <el-select v-model="searchFormCur.settType" placeholder="请选择" style="width:100px" :popper-append-to-body="false">
                            <el-option  key="-1" label="全部" :value="null"  ></el-option>
                            <el-option  key="1" label="现结" :value="1"  ></el-option>
                            <el-option  key="2" label="周期结算" :value="2"  ></el-option>
                        </el-select>
                    </el-form-item>
                    <!-- <el-form-item label="入库状态" prop="warehousingStatus">
                        <el-select v-model="searchFormCur.warehousingStatus" placeholder="请选择" style="width:100px" :popper-append-to-body="false">
                            <el-option  key="-1" label="全部" :value="null"  ></el-option>
                            <el-option  key="0" label="待核验" :value="0"  ></el-option>
                            <el-option  key="2" label="已完成" :value="2"  ></el-option>
                            <el-option  key="4" label="已取消" :value="4"  ></el-option>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item label="是否异常" prop="abnormalSett">
                        <el-select v-model="searchFormCur.abnormalSett" placeholder="请选择" style="width:100px" :popper-append-to-body="false">
                            <el-option  key="-1" label="全部" :value="null"  ></el-option>
                            <el-option  key="1" label="是" :value="true"  ></el-option>
                            <el-option  key="2" label="否" :value="false"  ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="异常类型" prop="abnormalSettType">
                        <el-select v-model="searchFormCur.abnormalSettType" placeholder="请选择" style="width:100px" :popper-append-to-body="false">
                            <el-option  key="-1" label="全部" :value="null"  ></el-option>
                            <el-option  key="1" label="取消" :value="1"  ></el-option>
                            <el-option  key="2" label="变更" :value="2"  ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="分组" prop="shopLabelIds" >                     
                    <el-select v-model="searchFormCur.shopLabelIds" class="w250" remote clearable  multiple filterable >
                        <el-option  v-for="(item,index) in allGroupData" :value="item.id" :label="item.name" :key="'searchFromUnitId'+index"></el-option>
                        
                    </el-select>
                </el-form-item>

                    <section>
                        <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
                        <el-button @click="reset">重置</el-button>
                    </section>
                </el-form>

                <template v-slot:table-wrap>
                    <ul class="toolbar"  v-if="userInfo.currentMcRoleStatus==0">
                        <li ><el-button icon="el-icon-download" type="primary" @click="exportTable(exportObj3)">导出</el-button></li>
                    </ul>
                <el-table
                    ref="table"
                    v-loading="isWorking.search"
                    :data="tableData.list"
                    stripe
                    @selection-change="handleSelectionChange"
                    @sort-change="handleSortChange"
                >
                    <el-table-column type="selection" width="55px" align="center"></el-table-column>

                    <el-table-column prop="orderNo" label="结算编号" min-width="200px" align="center">
                        <div slot-scope="{row}">
                            
                            <img v-if="row.abnormalSett" style="width:14px;" src="../../../assets/images/abnormalOrder.png" alt="">
                            <a  >{{row.orderNo}}</a>
                            </div>
                    </el-table-column>
                    <el-table-column prop="createTime" label="生成结算时间" min-width="140px" align="center"></el-table-column>
                    <el-table-column prop="torderNos" label="拉运单" min-width="160px" align="center">
                    <div slot-scope="{row}">
                        <div v-if="row.torderNos.length<=2">
                            {{row.torderNos.join(',')}}
                        </div>
                        <el-popover v-else placement="top-start" width="200" trigger="hover">
                            <div v-for="i in row.torderNos">
                                {{i}}
                            </div>
                            <div type="text" slot="reference">
                                <span>{{row.torderNos.filter((i,index)=>index<=1).join(',')}}</span>,
                                <span>...</span>
                            </div>
                        </el-popover>    
                    </div>
                    </el-table-column>
                    <el-table-column prop="shopName" label="商家名称" min-width="150px" align="center">
                        <el-button slot-scope="{row}" type="text"  @click="$refs.shopDetail.open(1,row)">{{row.shopName}}</el-button>
                    </el-table-column>
                    <el-table-column prop="shopLabelName" label="分组" width="50px" align="center" show-overflow-tooltip  >
                        <div slot-scope="{row}">
                            <span :style="{background:row.shopLabelColour,display:'inline-block',padding:'0px 5px',borderRadius:'5px',fontWeight:'bold'}">{{ row.shopLabelName }}</span>
                          
                        </div>
                    </el-table-column>
                    <el-table-column prop="adminId" label="商家管理员" min-width="140px" align="center">
                        <div slot-scope="{row}">{{row.adminName}}/{{row.adminAccount}}</div>
                    </el-table-column>
                    <el-table-column prop="settType" label="结算方式" min-width="100px" align="center">
                        <div slot-scope="{row}">{{row.settType==2?`周期结算`:row.settType==1?'现结':'-'}}</div>
                        <!-- (${row.settCycle || ''}天) -->
                    </el-table-column>
                    
                    <el-table-column prop="tstatus" label="入库状态" min-width="100px" align="center">
                        <div slot-scope="{row}">{{row.tstatus?lyStatus.filter(i=>i.status==row.tstatus)[0].label:'-'}}</div>
                    </el-table-column>
                    <el-table-column prop="settTotalPrice" label="结算单总金额" min-width="100px" align="center"></el-table-column>
                    <el-table-column prop="status" label="结算状态" min-width="100px" align="center">
                        <div slot-scope="{row}">{{row.status==0?'已生成(结算中)':row.status==2?'已完成':row.status==5?'已作废':row.status==6?'异常结算待处理':row.status==7?'异常处理完成':''}}</div>
                    </el-table-column>
                    <el-table-column prop="successTime" label="完成结算时间" min-width="140px" align="center"></el-table-column>
                    <el-table-column prop="enclosures" label="附件" min-width="100px" align="center">
                        <Enclosure  slot-scope="{row}" v-model="row.enclosures" :showAdd="userInfo.currentMcRoleStatus==0" :showDel="userInfo.currentMcRoleStatus==0" @input="updateEnclosure(row)"></Enclosure>
                    </el-table-column>
                    <el-table-column prop="remark" label="备注" min-width="100px"  align="center">
                        <div slot-scope="{row}">
                            <span>{{row.remark?row.remark:'/'}}</span>
                            <i class="el-icon-edit" v-if="userInfo.currentMcRoleStatus==0" style="margin-left:4px;color:#2E68EC;" @click="$refs.remarkUpdateWindow.open(row,6)"></i>
                        </div>
                    </el-table-column>
                    <el-table-column
                    label="操作"
                    width="270"
                    fixed="right"
                    v-if="userInfo.currentMcRoleStatus==0"
                    >
                    <div  slot-scope="{row}" >
                        <!-- true 可编辑结算方式 false 不可编辑结算方式 -->
                        <!-- 现结的 结算中或者结算已完成但未入库的时候，会出现编辑按钮 结算已完成但未入库的时候，只能编辑结算总金额，不能编辑结算方式 -->
                        <el-button  type="text" icon="el-icon-edit" v-if="row.settType==1&&(row.status==0 || row.status==2&&row.tstatus&&row.tstatus<5)"  @click="$refs.editSettlement.open(1,2,row.status==2?false:true,row)" >编辑结算</el-button>
                        <!-- 周期结算 未结算/待结算/结算中会出现编辑按钮。结算中的不能编辑结算方式 -->
                        <el-button  type="text" icon="el-icon-edit" v-if="row.settType==2&&row.status!=2"  @click="$refs.editSettlement.open(1,2,false,row)" >编辑结算</el-button>
                        <el-button  type="text" icon="el-icon-s-claim" v-if="row.status!=6&&row.status!=2"  @click="$refs.statementWindow.open(1,false,row)" >完成结算</el-button>
                        <el-button  type="text" icon="el-icon-s-claim" v-if="row.status==0&&row.settType==2" @click="VoidOrder">作废</el-button>
                        <!--作废 只会出现在结算中状态下的周期结算单中 -->
                        <el-button v-if="row.abnormalSett"  type="text" icon="el-icon-s-claim" @click="$refs.settlementExceptionWindow.open(1,row)">处理异常</el-button>
                        <el-button  type="text" icon="el-icon-notebook-2"  @click="$refs.operaRecordWindow.open(2,row,7)">操作记录</el-button>
                    </div>
                    </el-table-column>
                    <div slot="append" v-if="tableData.list.length>0">
                        <div class='sum_footer xiaoji' ref='sum_xiaoji'>
                            <div class='sum_footer_unit' v-for="item in 8"></div>
                            <div class='sum_footer_unit'>小计</div>
                            <div class='sum_footer_unit'>{{getXiaoji('settTotalPrice')}}</div>
                            <div class='sum_footer_unit' v-for="item in 5"></div>
                        </div>
                        <div class='sum_footer heji' ref='sum_heji'>
                            <div class='sum_footer_unit' v-for="item in 8"></div>
                            <div class='sum_footer_unit'>合计</div>
                            <div class='sum_footer_unit'>{{sumData3&&sumData3.settTotalPrice}}</div>
                            <div class='sum_footer_unit' v-for="item in 5"></div>
                        </div>
                    </div>
                </el-table>
                <pagination
                    @size-change="handleSizeChange"
                    @current-change="handlePageChange"
                    :pagination="tableData.pagination"
                ></pagination>
                </template>
            </TableLayout>
        </el-tab-pane>
    </el-tabs>
    <!-- 拉运总单数明细 -->
    <el-dialog :width="'240px'"  :visible.sync="orderNumDetailVisible" v-if="orderNumDetailData" @close="orderNumDetailData=null;orderNumDetailVisible=false">
        <h3 >拉运单总数：{{orderNumDetailData.row.transportNum}}</h3>
        
        <div class="line"><span>未结算拉运单数</span><el-link type="primary" @click="pageTurn(-1)">{{orderNumDetailData.unSettNum}}</el-link></div>
        <div class="line"><span>待结算拉运单数</span><el-link type="primary" @click="pageTurn(0)">{{orderNumDetailData.staySettNum}}</el-link></div>
        <div class="line"><span>结算中拉运单数</span><el-link type="primary" @click="pageTurn(1)">{{orderNumDetailData.inSettNum}}</el-link></div>
        <div class="line"><span>已结算拉运单数</span><el-link type="primary" @click="pageTurn(2)">{{orderNumDetailData.settNum}}</el-link></div>
    </el-dialog>
    
    <!-- 选择单号并生成结算单（拉运结算、出库结算） -->
    <ChooseNumbersWindow types='1' ref="chooseNumbersWindow" @success="(value)=>{this.$refs.statementWindow.open(1,true,value)}"></ChooseNumbersWindow>
    <!-- 编辑、完成 结算单 -->
    <StatementWindow ref="statementWindow" @success="handlePageChange(tableData.pagination.pageIndex)"></StatementWindow>
    <!-- 签约商家详情、出库商家详情 -->
    <ShopDetail  ref="shopDetail" ></ShopDetail>
    <!-- 编辑结算 拉运、出库 -->
    <EditSettlement  ref="editSettlement" @success="handlePageChange(tableData.pagination.pageIndex)"></EditSettlement>
    <!-- 结算异常 -->
    <SettlementExceptionWindow  ref="settlementExceptionWindow" @success="handlePageChange(tableData.pagination.pageIndex)"></SettlementExceptionWindow>
    <!-- 备注 -->
    <RemarkUpdateWindow ref="remarkUpdateWindow" @success="handlePageChange(tableData.pagination.pageIndex)"></RemarkUpdateWindow>
    <!-- 操作记录 -->
    <OperaRecordWindow ref="operaRecordWindow"></OperaRecordWindow>
    


</div>

  
</template>

<script>
import Pagination from '@/components/common/Pagination'
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import ChooseNumbersWindow from '../../../components/layun/financialManagement/ChooseNumbersWindow.vue'
import StatementWindow from '../../../components/layun/financialManagement/StatementWindow.vue'
import ShopDetail from '../../../components/layun/financialManagement/ShopDetail.vue'
import Enclosure from '../../../components/layun/common/Enclosure.vue'
import RemarkUpdateWindow from '../../../components/layun/common/RemarkUpdateWindow.vue'
import EditSettlement from '../../../components/layun/financialManagement/EditSettlement.vue'
import SettlementExceptionWindow from '../../../components/layun/financialManagement/SettlementExceptionWindow.vue'
import OperaRecordWindow from '../../../components/layun/common/OperaRecordWindow.vue'
import {getGroupData} from "../../../api/layun/basicDataManagement/groupData"
import {VoidOrder , fetchAllList ,orderNumDetail , uploadEnclosures , } from '../../../api/layun/financialManagement/transportationSettlement'
import { mapState } from 'vuex'



const filter1 = {
    listType:1,// 1商家结算总计列表 2 拉运结算明细列表 3结算单列表
    settPriceStart: null,
    settPriceEnd:null,
    shopSearch: null,// 商家筛选 名称 姓名 手机号  缺 商家名称的筛选
    sort: "", // 1倒序 2正序
    sortType: 0, // 0默认 1拉运结算总单数 2累计拉运总重量 3应结算总金额 4待结算金额 5已结算金额
     
}
const filter2 = {
    listType:2,
    driverSearch: "",
    isBatchSett: null,//是否批量生成拉运结算单
    orderNos: null,//拉运单编号
    settStatus: null, // 结算状态 0待结算 1结算中 2已结算
    settType: null, // 结算方式 1现结 2周期
    shopSearch: "",
    status: null, // 拉运单状态
    transportTimeEnd: "", // 拉运日期
    transportTimeStart: ""
      
}
const filter3 = {
    listType:3,
    abnormalSett: null, // 是否是异常结算单
    abnormalSettType: null, // 异常结算类型 1取消 2变更
    settNos: null,
    settSuccessTimeEnd: "", // 结算完成时间
    settSuccessTimeStart: "",
    settTimeEnd: "", //结算生成时间
    settTimeStart: "",
    settType: null, // 结算方式 1现结 2周期
    shopSearch: "",
    status: null,
    transportNos: null, // 拉运单号
    warehousingStatus: null, // 入库状态
      
}
const exportObj1 = {
        fileName:'商家结算总计列表（拉运）',
        tHeader:['商家名称','管理员姓名','管理员账号','协议价格','结算方式','结算周期','拉运结算总单数','累计拉运总重量(kg)','应结算总金额（￥）','待结算金额（￥）','已结算金额（￥）'],
        filterVal:['shopName','adminName','adminAccount','settPrice','settType','settCycle','transportNum','totalWeight','payableSettTotalPrice','unSettTotalPrice','settTotalPrice',],
        
      }
 const exportObj2={
        fileName:'拉运结算明细列表',
        tHeader:['拉运单编号','拉运日期','商家名称','商家管理员','司机','协议价格','拉运重量','拉运状态','结算方式','拉运结算总金额','结算状态','备注'],
        filterVal:['orderNo','transportTime','adminUserId','driverInfos','settPrice','settWeight','status','settType','settTotalPrice','settStatus','settRemark'],
      }
const exportObj3={
       fileName:'结算单列表（拉运）',
        tHeader:['结算编号','是否异常结算','生成结算时间','拉运单','商家名称','商家管理员','结算方式','入库状态','结算单总金额','结算状态','完成结算时间','附件','备注'],
        filterVal:['orderNo','abnormalSett','createTime','torderNos','shopName','adminId','settType','tstatus','settTotalPrice','status','successTime','enclosures','remark'],
      }

export default {
  name: 'InputSettlement',
  extends: BaseTable,
  components: { TableLayout , Pagination , ChooseNumbersWindow , StatementWindow , ShopDetail , EditSettlement , SettlementExceptionWindow , Enclosure , RemarkUpdateWindow , OperaRecordWindow ,  },
  computed: {
    paths () {
      return this.$route.meta.paths
    },
    ...mapState({
      userInfo:state=>state.init.userInfo || {},
      currentRole:state=>state.init.userInfo.currentRole,
      roles:state=>state.init.roles
    }),
  },
  data () {
    return {
        exportObj1,exportObj2,exportObj3,
        activeName: 'first',
      // 搜索
      searchFormCur: {
        ...filter1
      },
      searchForm:{
        ...filter1
      },
      sumData:null,//总计
      sumData2:null,
      sumData3:null,
      lyStatus:[
        {status:null,label:'全部'},
        {status:0,label:'待委派'},
        {status:2,label:'拉运中'},
        {status:3,label:'待入库'},
        {status:4,label:'待核验'},
        {status:5,label:'确认入库'},
        {status:6,label:'拉运中-待确认'},
        {status:7,label:'拉运中-调增拒绝'},
        {status:8,label:'已取消'},
        {status:9,label:'取消中-待司机确认'},
        {status:10,label:'拉运中-变更待审核'},
      ],
      lySettleStatus:[
        {status:null,label:'全部'},
        {status:-1,label:'未结算'},
        {status:0,label:'待结算'},
        {status:1,label:'结算中'},
        {status:2,label:'已结算'},
        {status:4,label:'已取消'},

      ],
      orderNumDetailVisible:false,//拉运结算单数明细弹窗
      orderNumDetailData:null,
      allGroupData:[]
    }
  },
  watch:{
    'searchFormCur.roleId':{
      handler(val,old){
        if(val!=old){
          this.search()
        }
      }
    }
  },
  created () {
    this.getGroupData()
    this.config({
      module: '单号',
      api: '/layun/financialManagement/transportationSettlement',
      sorts: []
    })
    this.search()
   
  },
  mounted(){
    this.adjustWidth()
    window.addEventListener('resize', this.adjustWidth.bind(this))
  },
  methods:{
    getGroupData(){
      var params = {"page":1,"capacity":200,"model":{"name":null,"chargePeopleNamePhone":null},"sorts":[{"property":"CREATE_TIME","direction":"DESC"}]}
      getGroupData(params).then(data=>{
        this.allGroupData = data.records || []
      })
    },
    search(){
        let settPriceStart = this.searchFormCur.settPriceStart
        let settPriceEnd = this.searchFormCur.settPriceEnd
        let priceStart = Number(settPriceStart)&&Number(settPriceEnd)?Number(settPriceStart)>Number(settPriceEnd)?Number(settPriceEnd):Number(settPriceStart):settPriceStart
        let priceEnd = Number(settPriceStart)&&Number(settPriceEnd)?Number(settPriceStart)>Number(settPriceEnd)?Number(settPriceStart):Number(settPriceEnd):settPriceEnd
        this.searchFormCur.settPriceStart = priceStart
        this.searchFormCur.settPriceEnd = priceEnd

        let form = {
            ...this.searchFormCur,
            // settPriceStart:priceStart,
            // settPriceEnd:priceEnd,
        }
        console.log(form);
        for(let key in form){
            let arr = ['orderNos','settNos','transportNos'];//将这些参数改成数组
            let idx = arr.findIndex(i=>i==key)
            if(idx!=-1){
                let Nos = form[arr[idx]]?form[arr[idx]].split('，').join(',').split(','):null;
                form[arr[idx]] = Nos || null
            }
        }
        this.searchForm = form
        this.handlePageChange(1)
        this.pageSum();
    },
    pageSum(){ //总计
        this.sumData2 = null
        this.sumData3 = null
        fetchAllList({
            model:{...this.searchForm,listType:null},
            listType:this.searchForm.listType
        }).then((res) => {
        console.log(res);
            this['sumData'+this.searchForm.listType] = res
        }).catch((err) => {  
        console.log(err);
            this.$message.error('总计查询失败')   
        }).finally(()=>{
        });

    
    },
    // 排序
    handleSortChange (sortData) {
      this.tableData.sorts = []
      console.log('sortData.prop',sortData.prop);
        //   sort 邀约金排序 1倒叙 2正序
      	//sortType 邀约金排序字段 0默认 1拉运结算总单数 2累计拉运总重量 3应结算总金额 4待结算金额 5已结算金额
        let arr = [
            {value:0,prop:'',tit:'默认'},
            {value:1,prop:'transportNum',tit:'拉运结算总单数'},
            {value:2,prop:'totalWeight',tit:'累计拉运总重量'},
            {value:3,prop:'payableSettTotalPrice',tit:'应结算总金额'},
            {value:4,prop:'unSettTotalPrice',tit:'待结算金额'},
            {value:5,prop:'settTotalPrice',tit:'已结算金额'},
        ]
      let param = {
        sort:1,
        sortType:0,
      }
      if (sortData.order != null) {
          param.sort = sortData.order === 'descending' ? 1 : 2
          let index =  arr.findIndex(i=>i.prop==sortData.prop)
          param.sortType = index==-1?0:arr[index].value
          this.searchFormCur = {
            ...this.searchFormCur,
            ...param
          }
      }
      this.handlePageChange()
    },
    handleClick(tab, event) {
        console.log(tab.name, this.activeName);
        this.searchFormCur.listType = 1;
        this.resetForm(this.activeName=='first'?1:this.activeName=='second'?2:3)
        this.resetTable();
        this.search()
    },
    resetForm(type){
        let filter = type==1?filter1:type==2?filter2:filter3
        this.searchFormCur = JSON.parse(JSON.stringify(filter))
        this.searchFormCur.listType = type
        this.value2 = '';
        this.value1 = '';
    },
    reset(){
        this.resetForm(this.activeName=='first'?1:this.activeName=='second'?2:3)
        this.search()
    },
    resetTable(){
        this.tableData={
            selectedRows: [],
            sorts:[],
            list:[],
            pagination: {
                pageIndex: 1,
                pageSize: 10,
                total: 0
            }
        }
        
        this.isWorking= {
            search: false,
            delete: false,
            export: false
        }

    },
    dateChange(date,type){
        
        if(type==1){ //拉运时间
            this.searchFormCur.transportTimeStart = date?date[0]:null
            this.searchFormCur.transportTimeEnd = date?date[1]:null
        }else if(type==2){ //结算生成时间
            this.searchFormCur.settTimeStart = date?date[0]:null
            this.searchFormCur.settTimeEnd = date?date[1]:null
        }else if(type==3){ //结算完成时间
            this.searchFormCur.settSuccessTimeStart = date?date[0]:null
            this.searchFormCur.settSuccessTimeEnd = date?date[1]:null
        }
    },
    //作废拉运单
    VoidOrder(row){
        this.$confirm(`是否作废当前拉运结算单?`, '提示', {
            confirmButtonText: '作废',
            cancelButtonText: '取消',
            
            type: 'warning',
            beforeClose: (action, instance, done) => {
                if (action === 'confirm') {
                instance.confirmButtonLoading = true;
                instance.confirmButtonText = '执行中...';
                VoidOrder({settId:row.id}).then(() => {
                    done();
                    this.search()
                }).catch(() => {  
                    this.$message.error('作废失败')   
                }).finally(()=>{
                    instance.confirmButtonLoading = false;
                });
                
                } else {
                    done();
                }
            }
          }).then(() => {
             
            
          }).catch(() => {     
            
          }); 
    
    
    },
    orderNumDetail(row){
        console.log(row);
        this.orderNumDetailVisible = true
        orderNumDetail({shopId:row.shopId}).then((res) => {
            this.orderNumDetailData = res
            this.orderNumDetailData.row = row
        }).catch(() => {  
            this.$message.error('单数详情获取失败')   
        }).finally(()=>{
        });
    
    },
    pageTurn(status){
    
        this.activeName = 'second'
        this.searchFormCur.listType = 2;
        this.resetForm(this.activeName=='first'?1:this.activeName=='second'?2:3)
        this.resetTable();
        this.searchFormCur.shopId = this.orderNumDetailData.row.shopId
        this.searchFormCur.shopSearch = this.orderNumDetailData.row.shopName

        this.searchFormCur.settStatus = status
        this.orderNumDetailVisible = false
        this.orderNumDetailData = null
        this.search()
    },
    //更新附件
    updateEnclosure(row){
        console.log(row,'--');
        console.log({
            enclosures:row.enclosures,
            remark:row.remark,
            settId:row.id,
            
        })
        
        this.isWorking.search = true   
        uploadEnclosures({
            enclosures:row.enclosures || [],
            remark:row.remark,
            settId:row.id,
            
        }).then(res=>{
        
        }).catch(e=>{
            console.log(e);
            this.$tip.apiFailed(e,false)
        }).finally((res)=>{
            this.isWorking.search = false   
        })
    },
    exportTable(obj) {
      if (this.tableData.selectedRows.length == 0) {
        this.$message.warning("至少选择一条");
        return;
      }
      import("@/vendor/Export2Excel").then((excel) => {
        const data = this.formatData(obj.filterVal,this.tableData.selectedRows)
        excel.export_json_to_excel({
          header: obj.tHeader,
          data,
          filename: (obj.fileName || '表格'),
          autoWidth: true,
          bookType: "xlsx",
        });
      });
    },
    //格式化数据
    formatData(filterVal,list){
      return list.map((v) =>
        filterVal.map((j) => {
          console.log(j,'___',v[j]);
          switch (j) {
            case "settPrice":
                return v[j]?`${v[j]}元/千克`:'';
            case "settType":
                return v[j]==1?'现结':v[j]==2?'周期结算':'';
            case "settCycle":
                return v.settType==2&&v[j]?`${v[j]}天`:'';
            case "adminUserId":
                return `${v.adminUserName}/${v.adminUserAccount}`;

            case "driverInfos":
                let value = []
                if(v[j]&&v[j].length>0){
                    v[j].map(i=>{
                        value.push(`${i.driverUserName}/${i.driverUserAccount}`)
                    })    
                }
                return value.join(',');
            case "status":
                let valueStatus = '';
                if(this.activeName=='second'){
                    let idx = this.lyStatus.findIndex(i=>i.status&&i.status==v[j])
                    valueStatus = v[j]!=null&&idx!=-1?this.lyStatus[idx].label:''
                }
                if(this.activeName=='third'){
                    valueStatus = v[j]==0?'已生成(结算中)':v[j]==2?'已完成':v[j]==5?'已作废':v[j]==6?'异常结算待处理':v[j]==7?'异常处理完成':''
                }
                return valueStatus;
            case "settStatus":
                let idx = this.lySettleStatus.findIndex(i=>i.status&&i.status==v[j])
                return v[j]!=null&&idx!=-1?this.lySettleStatus[idx].label:'';
            case "abnormalSett":
                return v[j]?'是':'否';
            case "adminId":
                return `${v.adminName}/${v.adminAccount}`;
            case "tstatus":
                let tstatusIdx = this.lyStatus.findIndex(i=>i.status&&i.status==v[j])
                return v[j]!=null&&tstatusIdx!=-1?this.lyStatus[tstatusIdx].label:'';
            default:
              return v[j] || "/";
          }
          
          
        })
      );
    
    },
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";
// 列表头像处理
.table-column-avatar {
  img {
    width: 48px;
  }
}
.line{
    display: flex;
    margin: 10px 10px 10px 0;
    span{
        margin-right: 10px;
    }
}
</style>
