<template>
  <el-dialog
    class="global-window"
    :title="title"
    status-icon
    :visible.sync="visible"
    
    :width="width"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="close"
    :center='true'
    append-to-body
  >
    <div class="window__body" v-loading="loading">
      <slot></slot>
    </div>
    <div v-if="withFooter" class="window__footer">
      <slot name="footer">
        <el-button @click="confirm" :loading="confirmWorking" type="primary">{{confirmText}}</el-button>
        <el-button @click="close">{{cancelText}}</el-button>
      </slot>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'GlobalWindow',
  props: {
    width: {
      type: String,
      default: '36%'
    },
    loading:{
      type: Boolean,
      default: false
    },
    // 是否包含底部操作
    withFooter: {
      type: Boolean,
      default: true
    },
    // 确认按钮loading状态
    confirmWorking: {
      type: Boolean,
      default: false
    },
    // 标题
    title: {
      type: String,
      default: ''
    },
    // 是否展示
    visible: {
      type: Boolean,
      required: false
    },
    confirmText:{
      default:'确定'
    },
    cancelText:{
      default:'取消'
    }
  },
  methods: {
    confirm () {
      this.$emit('confirm')
    },
    close () {
      this.$emit('update:visible', false)
    },
    keyupSubmit(){
    document.onkeydown = (e) => {
      let _key = window.event.keyCode;
      //!this.clickState是防止用户重复点击回车 // 27是esc
      // console.log(_key,'---点击了按键',this.visible);
      if(this.visible&&_key==27){
        this.close();
      
      }
      if (_key === 13&&!this.clickState) {
      this.login();
      }
    };
    
    },
  },
  created(){
    this.keyupSubmit();
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";
// 输入框高度
$input-height: 32px;
.global-window {
  // 头部
  /deep/ .el-dialog__header {
    // border-bottom: 1px solid #eee;
  }
  // 内容
  /deep/ .el-dialog__body {
    padding: 0;
  }
  /deep/ .window__body {
    max-height: 550px;
    overflow-y: auto;
    padding: 12px 16px;
    &::-webkit-scrollbar {
        width: 0px;
        height: 1px;
    }
    // 标签
    .el-form-item__label {
      float: none;
    }
    // 元素宽度为100%
    .el-form-item__content > *{
      width: 100%;
    }
  }
  // 尾部
  /deep/ .window__footer {
    user-select: none;
    border-top: 1px solid #eee;
    height: 60px;
    line-height: 60px;
    text-align: center;
  }
}
</style>
