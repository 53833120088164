<template>
  <TableLayout :permissions="['basics:region:query']">
    <!-- 搜索表单 -->
    <div slot="search-form">
      <el-form ref="searchForm" :model="searchForm" label-width="100px" inline>
        <el-form-item label="区域名称" prop="regionName">
          <el-input
            v-model="searchForm.regionName"
            v-trim
            placeholder="请输入区域名称"
            @keypress.enter.native="search"
            style="width:250px"
          />
        </el-form-item>
        <el-form-item label="省市区" prop="areaArr">
          <CitySelect
            v-model="searchForm.areaArr"
            :checkStrictly="true"
            :clearable="true"
            @change="cityChange"
          ></CitySelect>
        </el-form-item>

        <section>
          <el-button type="primary" icon="el-icon-search" @click="search"
            >查询</el-button
          >
          <el-button @click="reset">重置</el-button>
        </section>
      </el-form>
    </div>

    <template v-slot:table-wrap>
      <ul
        class="toolbar"
        v-permissions="['basics:region:create']"
        v-if="userInfo.currentMcRoleStatus==0&&currentRole!=roles.treasurer"
      >
        <li v-permissions="['basics:region:create']">
          <el-button
            icon="el-icon-plus"
            type="primary"
            @click="$refs.operaBussinessAreaWindow.open('新建业务区域')"
            >新增</el-button
          >
        </li>
        
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        :default-sort="{ prop: 'createTime', order: 'descending' }"
        stripe
        @selection-change="handleSelectionChange"
        @sort-change="handleSortChange"
      >
        <el-table-column prop="id" label="区域ID" min-width="40px" align="center" ></el-table-column>
        <el-table-column prop="regionName" label="区域名称" align="center" ></el-table-column>
        <el-table-column prop="levelPathName" label="所属区域" min-width="400px" :show-overflow-tooltip="true" align="center" >
          <div slot-scope="{row}">
            {{row.levelPathName&&row.levelPathName.length>0 ? row.levelPathName.join(' ; ') : '-'}}
          </div>
        </el-table-column>

        <el-table-column
          v-if="userInfo.currentMcRoleStatus==0&&currentRole!=roles.treasurer"
          align="center" 
          label="操作"
          width="270"
          fixed="right"
        >
          <div slot-scope="{ row }" v-if="isAdmin || row.id !== userInfo.id">
            <el-button  type="text" icon="el-icon-edit" @click="$refs.operaBussinessAreaWindow.open('修改业务区域', row)" :permissions="['basics:region:update']">修改</el-button>
            <el-button type="text" icon="el-icon-notebook-2" @click="$refs.operaRecordWindow.open(1, row, 6)">操作记录</el-button
            >
          </div>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaBussinessAreaWindow
      ref="operaBussinessAreaWindow"
      @success="handlePageChange(tableData.pagination.pageIndex)"
    />
    <!-- 操作记录 -->
    <OperaRecordWindow ref="operaRecordWindow"></OperaRecordWindow>
  </TableLayout>
</template>

<script>
import Pagination from '@/components/common/Pagination';
import TableLayout from '@/layouts/TableLayout';
import BaseTable from '@/components/base/BaseTable';
import OperaBussinessAreaWindow from '../../../components/layun/basicDataManagement/OperaBussinessAreaWindow.vue';
import CitySelect from '../../../components/layun/common/CitySelect.vue';
import HotCity from '../../../components/layun/basicDataManagement/OperaHotCity.vue';

import OperaRecordWindow from '../../../components/layun/common/OperaRecordWindow.vue';
import { mapState } from 'vuex';

// import { fetchList } from '@/api/system/user'

export default {
  name: 'BussinessArea',
  extends: BaseTable,
  components: {
    TableLayout,
    Pagination,
    OperaBussinessAreaWindow,
    CitySelect,
    OperaRecordWindow,
    HotCity,
  },
  data() {
    return {
      // 搜索
      searchForm: {
        regionName: null,
        areaArr: [15419, 36243],
        provinceId: 15419,
        cityId: 36243,
        provinces: '15419,36243',
      },
    };
  },
  computed: {
    ...mapState({
      userInfo:state=>state.init.userInfo || {},
      currentRole:state=>state.init.userInfo.currentRole,
      roles:state=>state.init.roles
    }),
  },
  created() {
    this.config({
      module: '街道办',
      api: '/layun/basicDataManagement/bussinessArea',
      // 'field.main': 'account',
      // sorts: [
      //   {
      //     property: 'CREATE_TIME',
      //     direction: 'DESC',
      //   },
      // ],
    });
    this.search();
  },
  methods: {
    handleCommand(command) {
      this.$message('click on item ' + command);
    },
    hotCitySuccess(ss){
      console.log(ss,'--ds-s-d-sdssss');
      this.$refs.hotCity.handlePageChange(1)
    },
    handleClick(value) {
      console.log(value);
      let arr = []
      if(value.provinceId){
        arr.push(value.provinceId)
      }
      if(value.cityId){
        arr.push(value.cityId)
      }
      if(value.districtId){
        arr.push(value.districtId)
      }

      let str = arr.join(',')

      this.searchForm.areaArr =arr
      this.searchForm.provinces = str
      this.search()
     
    },
    cityChange(value) {
      console.log(value, '--数据');
      this.searchForm = {
        ...this.searchForm,
        ...value.form,
        provinces: value.value.join(','),
      };
      this.search();
    },
    reset() {
      this.$refs.searchForm.resetFields();
      this.searchForm.adCode = '';
      this.searchForm.cityId = '';
      this.searchForm.cityName = '';
      this.searchForm.districtId = '';
      this.searchForm.districtName = '';
      this.searchForm.provinceId = '';
      this.searchForm.provinceName = '';
      this.searchForm.provinces = '';
      this.searchForm.areaArr = [];

      this.search();
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/style/variables.scss';
// 列表头像处理
.table-column-avatar {
  img {
    width: 48px;
  }
}
</style>
