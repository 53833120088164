import request from '@/utils/request'
// 出库结算管理

// 查询
export function fetchList (data) {
  console.log(data,'--transport');
  if(data.model.listType==1){
    return request.post('/business/exWarehouse/sett/shopSettPage', data) 
  }else if(data.model.listType==2){
    return request.post('/business/exWarehouse/getExWarehouseSettList', data) 
  }else if (data.model.listType==3){
   return request.post('/business/exWarehouse/sett/exSettPage', data) 
  }else{
    return this.$message.info('参数有一丢丢小问题')
   }
}

// 查询-总计
export function fetchAllList (data) {
  console.log(data,'--transport');
  if(data.listType==1){
    
  }else if(data.listType==2){
    return request.post('/business/exWarehouse/getExWarehouseSettTotal', data) 
  }else if (data.listType==3){
   return request.post('/business/exWarehouse/sett/exSettTotal', data) 
  }else{
    return this.$message.info('参数有一丢丢小问题')
   }
}

// 总计tab 拉运结算总单数明细 
export function orderNumDetail (data) {
  return request.get('/business/exWarehouse/sett/getExSettDetail', {params:data})
}

//生成出库结算单

export function createStatement (data) {
  return request.post('/business/exWarehouse/sett/generateSett', data)
}

// 作废出库结算单
export function VoidOrder (data) {
  return request.post('/business/exWarehouse/sett/nullifySett', data)
}

//完成出库结算单
export function CompleteOrder (data) {
  return request.post('/business/exWarehouse/sett/completeSett', data)
}

//获取结算单详情
export function getInfo (data) {
  return request.post('/business/exWarehouse/sett/getInfo', data)
}

// //处理异常结算单 不存在
// export function operaAbnormal (data) {
//   
// }

//编辑结算  
// 1 明细列表
export function changeSettPrice (data) {
  return request.post('/business/exWarehouse/updateSett', data)
}
// 2 结算单列表
export function editSettDto (data) {
  
  return request.post('/business/exWarehouse/sett/editSett', data)
}

//更新列表附件
export function uploadEnclosures (data) {
  
  return request.post('/business/exWarehouse/sett/uploadEnclosures', data)
}

//修改备注 出库结算单 
export function updateRemark (data) {
  return request.post('/business/exWarehouse/updateRemark', data)
}
// 出库明细 拉运明细
export function updateRemarkMX (data) {
  return request.post('/business/transport/updateRemark', data)
}