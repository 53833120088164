import request from '@/utils/request'

// 获取图片验证码
export function getCaptcha () {
  return request.get('/common/captcha')
}

// 查询
export function fetchList (data) {
  return request.post('/business/project/page4Modify',data)
}

//项目变更详情 展示
export function projectDetailShow (data) {
  return request.get('/business/project/queryModify',{
    params:data
  })
}

//项目变更 删除
export function deleteModify (data) {
  return request.get('/business/project/deleteModify',{
    params:data
  })
}

// //查询项目详情 编辑
// export function projectDetailQuery (data) {
//   return request.get('/business/project/query',{
//     params:data
//   })
// }

// // 项目变更
// export function createModify (data) {
//   return request.post('/business/project/createModify',data)
// }

// // 更新进度和状态  改变项目状态
// export function updateSpeedAndStatus (data) {
//   return request.post('/business/project/updateSpeedAndStatus',data)
// }
