<template>
  <GlobalWindow
    class="handle-table-dialog"
    title="编辑结算"
    :width="'800px'"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirmC"
  >
    <el-container class="tab" >
        <el-aside class="left">编辑结算</el-aside>
        <el-main class="right">
            <div class="top">
                <div class="item">结算单总金额</div>
                <div class="item">结算方式 </div>
            </div>
            <div class="bottom">
                <div class="item">{{totalPrice}}</div>
                <div class="item"  style="display: flex;flex-direction: column;align-items: center;">
                    <el-radio-group v-model="form.settType" :disabled="!settTypeEditable" style="margin-bottom:10px;">
                        <el-radio :label="1">现结</el-radio>
                        <el-radio :label="2">周期结算</el-radio>
                    </el-radio-group>
                    <el-input  placeholder="" v-model="form.settCycle" type="number"  :disabled="!settTypeEditable" v-if="form.settType==2&&settTypeEditable" style="width:130px;" required>
                        <template slot="append">天</template>
                    </el-input> 
                </div>
            </div>
        </el-main>
    </el-container>

    <el-container class="tab">
        <el-header class="titleTop" >结算明细</el-header>
        <el-main class="right" >
            <el-table
                v-if="type==1"
                :loading="pageLoading"
                ref="chooseNumbers"
                :data="list"
                stripe
            >
                <el-table-column prop="orderNo" label="拉运单编号" min-width="120px" align="center"></el-table-column>
                <el-table-column prop="driverInfos" label="司机" min-width="140px" align="center">
                    <div slot-scope="{row}">
                        <div v-if="row.driverInfos" v-for="item in row.driverInfos">{{item.driverUserName}}/{{item.driverUserAccount}}</div>
                        <span v-else>-</span>
                    </div>
                </el-table-column>
                <el-table-column prop="settWeight" label="总拉运重量" min-width="100px" align="center"></el-table-column>
                <el-table-column prop="settPrice" label="协议价格" min-width="80px" align="center">
                    <div  slot-scope="{row}" >
                        {{row.settPrice?row.settPrice+'元/千克':'-'}}
                    </div>
                </el-table-column>
                <el-table-column prop="settTotalPrice" label="拉运结算总金额" min-width="110px" align="center">
                    <div  slot-scope="{row}" >
                        <el-input type="number" v-model="row.settTotalPrice">
                            <div slot="append">元</div>
                        </el-input>
                    </div>
                </el-table-column>
            </el-table>

            <el-table
                v-if="type==2"
                ref="out"
                v-loading="search"
                :data="list"
                stripe
            >
                <el-table-column prop="orderNo" label="出库单编号" min-width="100px" align="center"></el-table-column>
                <el-table-column prop="settVerificationWeight" label="核验重量" min-width="100px" align="center"></el-table-column>
                <el-table-column prop="settPrice" label="协议价格" min-width="80px" align="center">
                    <div  slot-scope="{row}" >
                        {{row.settPrice?row.settPrice+'元/千克':'-'}}
                    </div>
                </el-table-column>
                <el-table-column prop="settTotalPrice" label="出库结算金额" min-width="110px" align="center">
                    <div  slot-scope="{row}" >
                        <el-input type="number" v-model="row.settTotalPrice">
                            <div slot="append">元</div>
                        </el-input>
                    </div>
                </el-table-column>
            </el-table>
        </el-main>
    </el-container>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import {updateUserStatus,updateRoleStatus} from '../../../api/system/user'
import StreetSelect from '../../../components/layun/common/StreetSelect.vue'
import SaleUserSelect from '../../../components/layun/common/SaleUserSelect.vue'
import {editSettDto as editSettDtoLY,getInfo as getInfoLY,changeSettPrice as changeSettPriceLY} from '../../../api/layun/financialManagement/transportationSettlement'
import {editSettDto as editSettDtoCK,getInfo as getInfoCK,changeSettPrice as changeSettPriceCK} from '../../../api/layun/financialManagement/warehousingSettlement'



export default {
  name: 'OperaSignedListChangeWindow',
  extends: BaseOpera,
  components: {  GlobalWindow , StreetSelect , SaleUserSelect , },
  data () {
    return {
      // 表单数据
      form: {
        settType:1,
        settCycle:0,
      },
      type:1,//
      title:'',
      visible:false,
      list:[],
      pageLoading:false,
      
    }
  },
  computed:{
    totalPrice(){
        if(this.type==1){
          return  this.list.reduce(function (total, item) {
                return total + Number(item.settTotalPrice);
            }, 0);
        }else{
            return  this.list.reduce(function (total, item) {
                return total +  Number(item.settTotalPrice);
            }, 0);
        }   
    },
    // totalWeight(){
    //     if(this.type==1){
    //         return this.list.reduce(function (total, item) {
    //             return total + item.settWeight;
    //         }, 0);
    //     }else{
    //         return this.list.reduce(function (total, item) {
    //             return total + item.settWeight;
    //         }, 0);
    //     }   
    // },
    baseTotalPrice(){ //未曾改变过的价格
        if(this.type==1){
          return  this.baseValue.reduce(function (total, item) {
                return total + Number(item.settTotalPrice);
            }, 0);
        }else{
            return  this.baseValue.reduce(function (total, item) {
                return total +  Number(item.settTotalPrice);
            }, 0);
        }
    }
  
  },
  methods: {
    /**
     * @type: 1 拉运单 2  出库单 
     * @status: 1明细单非结算单,2
     * @settTypeEditable: true 可编辑结算方式 false 不可编辑结算方式 
     * @row:具体的修改字段 默认值
     */
    open (type,status,settTypeEditable,row) {
    console.log('弹窗基础数据',type,status,settTypeEditable,row);
      let api = type==1?getInfoLY:getInfoCK
      this.settTypeEditable = settTypeEditable
      this.status = status
      this.type = type 
      this.row = row
      this.visible = true  
      this.form.settType = row.settType
      this.form.settCycle = row.settCycle

      if(type==1&&status==1 || type==2&&status==1){
        this.list = [{...row}]
        this.baseValue = JSON.parse(JSON.stringify([row]))
      }else  if(type==1&&status==2 || type==2&&status==2){ //结算单表（有存在多条拉运单的可能性）
        //获取结算单详情后回显
        this.pageLoading = true;
        api({settId:row.id}).then(res=>{
            console.log(res,'-sds-d-s-d-s---');
            this.remark = res.remark
            this.enclosures = res.enclosures || []
            let curValue = res.detailList || []
            curValue.forEach(i=>{
                i.shopName = res.shopName
                i.adminUserName =  res.receiveAdminName
                i.adminUserAccount =  res.receiveAdminAccount
                i.settWeight = i.settTotalWeight
                i.settId = row.id
            })
            this.list = curValue
            this.baseValue = JSON.parse(JSON.stringify(curValue))
        })
        .catch(e => {
            this.$tip.apiFailed(e,false)
        })
        .finally(() => {
            this.pageLoading = false
        })
      }
      
    },
    //确认前
    confirmC(){
      let param = {}
      let api = null;//接口api

      if(this.settTypeEditable && this.form.settType==2 && this.form.settCycle<0){
        return this.$message.error('结算周期天数必填')
      }

      //结算列表编辑结算
      if(this.type==1&&this.status==1){

        param.settTotalPrice = this.totalPrice
        param.settType = this.form.settType
        param.settCycle = Number(this.form.settCycle)
        param.transportId = this.row.id
        param.settSuccess = this.totalPrice==0?true:false,
        param.costs = [this.totalPrice-this.row.settTotalPrice]

        api = changeSettPriceLY
      
      }

      //结算单编辑结算
      if(this.type==1&&this.status==2){

        param.settTotalPrice = this.totalPrice
        param.settType = this.form.settType
        param.settCycle = Number(this.form.settCycle)
        param.settId = this.row.id
        param.details = []
        this.list.map((item,index)=>{
            param.details.push({
                isChange:item.settTotalPrice!=this.baseValue[index].settTotalPrice,
                transportId: item.id,
                tsettTotalPrice: Number(item.settTotalPrice) || 0
            })
        })
        api = editSettDtoLY
      
      }



      //拉运单编辑结算
      if(this.type==2&&this.status==1){

        param.settTotalPrice = this.totalPrice
        param.settType = this.form.settType
        param.settCycle = Number(this.form.settCycle)
        param.exId = this.row.exId
        // param.settSuccess = this.totalPrice==0?true:false,
        param.costs = [this.totalPrice-this.row.settTotalPrice]

        api = changeSettPriceCK
      
      }

      //拉运结算单编辑结算
      if(this.type==2&&this.status==2){

        param.settTotalPrice = this.totalPrice
        param.settType = this.form.settType
        param.settCycle = Number(this.form.settCycle)
        param.settId = this.row.id
        param.details = []
        this.list.map((item,index)=>{
            param.details.push({
                isChange:item.settTotalPrice!=this.baseValue[index].settTotalPrice,
                exId: item.id,
                tsettTotalPrice: Number(item.settTotalPrice) || 0
            })
        })
        api = editSettDtoCK
      
      }




      
        // param.receiveAdminId = this.baseValue[0].adminUserId
        // param.receiveId = this.baseValue[0].shopId
        // param.settTotalPrice = this.totalPrice
        // param.settTotalWeight = this.totalWeight
        // param.settType = 2
        // param.details = []
        // this.list.map((item,index)=>{
        //     param.details.push({
        //         isChange:item.settTotalPrice!=this.baseValue[index].settTotalPrice,
        //         transportId: item.id,
        //         tsettTotalPrice: Number(item.settTotalPrice) || 0
        //     })
        // })

        console.log('编辑结算',param);
        
        this.isWorking = true
        api(param).then(res=>{
        console.log('完成结算');
          //  this.$tip.apiSuccessed('编辑完成')
           this.$message.success('编辑完成')
           this.visible = false
           this.$emit('success')
        })
        .catch(e => {
            this.$tip.apiFailed(e,false)
        })
        .finally(() => {
            this.isWorking = false
        })
    
    }

  
  },
  created () {
    this.config({
      api: '/layun/contractManagement/signedManagement', 
    })
    
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables";
$icon-background-color: $primary-color;
.global-window {
  .tip {
    margin-bottom: 12px;
    em {
      font-style: normal;
      color: $primary-color;
      font-weight: bold;
    }
  }
  // 图标
  /deep/ .form-item-icon {
    .el-form-item__content {
      height: 193px;
      overflow-y: auto;
    }
    .el-radio-group {
      background: $icon-background-color;
      padding: 4px;
      .el-radio {
        margin-right: 0;
        color: #fff;
        padding: 6px;
        &.is-checked {
          background: $icon-background-color - 30;
          border-radius: 10px;
        }
        .el-radio__input.is-checked + .el-radio__label {
          color: #fff;
        }
      }
      .el-radio__input {
        display: none;
      }
      .el-radio__label {
        padding-left: 0;
        // element-ui图标
        i {
          font-size: 30px;
        }
        // 自定义图标
        [class^="eva-icon-"], [class*=" eva-icon-"] {
          width: 30px;
          height: 30px;
          background-size: 25px;
          vertical-align: bottom;
        }
      }
      .el-radio--small {
        height: auto;
        padding: 8px;
        margin-left: 0;
      }
    }
  }
}
.btns{
    text-align: center;
    margin: 20px 0;
}
.line{
    display: flex;
    align-items: center;
    margin:20px 0;
    .label{
        min-width: 80px;
        flex-shrink: 0;
        text-align: right;
        margin-right: 20px;
    }
    .content{
        flex: 1;
    }
}
.tab{
    display: table;
    width:100%;
    background: $primary-color;
    vertical-align: middle;
    margin: 30px 0;

    .left{
        width: 100px !important;
        height: 100%;
        color: white;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
    }
    .titleTop{
        color: white;
        text-align: center;
        line-height: 60px;
        width: 100%;
    }
    .right{
        display: table-cell;
        width: 100%;
        
        background: #fff;
        display: table;
        vertical-align: middle;
        padding: 0;
        .item{
            display: table-cell;
            min-width: 200px;
            border-bottom: 1px solid #ccc;
            vertical-align: middle;
            text-align: center;
            padding: 20px 0;
        }

        .top{
            display: table-row;
            font-weight: bold;
        }
        .bottom{
            display: table-row;
        }
    }


}
</style>
