<template>
  <GlobalWindow
    class="handle-table-dialog"
    title="账号移交"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules" v-loading="dialogLoading">
      <el-form-item label="原账号" >
        {{form.originalName+'/'+form.originalAccount}}
      </el-form-item>
      <el-form-item label="商家名称" prop="shopName" required>
        {{form.shopName}}
      </el-form-item>
      <el-form-item label="移交账号" prop="account" required>
        <!-- <UserSelect v-model="form.newUserId" :optionId="userOptions" :optionKey="{id:['id'],label:['account']}" selectMode='changeUser' :allow-create="true" :filterable="true" :clearable="true"  :multiple="false" placeholder="请选择移交账号" :inline="false" :showAlert="false" @change="changeUser"></UserSelect> -->
        <el-input v-model.trim="form.account" placeholder="请输入账号"  @input="changeUser" ></el-input>
      </el-form-item>
      <el-form-item label="新账号姓名" prop="userName" required>
        <el-input v-model="form.userName" v-trim placeholder="请输入新账号姓名" :disabled='!userNameEditable' />
      </el-form-item>
      <el-form-item label="请确认是否需要更换收款码" v-if="form.openPay">
        <ImageUpload  v-model='form.payCode'></ImageUpload>
        <el-alert title="点击图片可上传新的二维码" type="warning"></el-alert>
      </el-form-item>
      <el-form-item label="备注" prop="remarks" :required="required" >
        <el-input v-model="form.remarks" v-trim placeholder="请输入备注" />
      </el-form-item>
      
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import ImageUpload from '../../common/ImageUpload.vue'
import UserSelect from '../../common/UserSelect.vue';//别的userSelect
import {selectSystemAllUser , shopAdminAccountChange } from '../../../api/system/user'
import { mapState, Store } from 'vuex'
import {checkMobile} from '../../../utils/form'

export default {
  name: 'HandoverRolesOpera',
  extends: BaseOpera,
  components: {  GlobalWindow  , UserSelect , ImageUpload },
  computed:{
    ...mapState({
      userInfo:state=>state.init.userInfo
    })
  },
  data () {
    return {
      // 表单数据
      form: {
        shopId:'',
        shopAdminId:'',
        userName:'',//新账号名称
        account:'',//新账号
        conerNewAccount:'',//新账号
        newUserId:'',
        shopName:'',
        payCode:'',
        
        
        originalAccount:'',
        userId: 0,//旧账号
        originalName: "",//旧名字
        businessRoleId:2,
        remarks:'',
        

      },
      userNameEditable:true,
      required:false,
      optionId:[],//可移交角色的展示
      userOptions:[],//可选的移交账号
      targetRoleArr:[],//targetRoles
      shopNames:[],// belong to original ID's treasure
      dialogLoading:false,//页面数据加载中
      // 验证规则
      rules: {
        roleId: [
          { required: true, message: '请选择用户角色' }
        ],
        account: [
          { required: true, message: '请输入新账号' },
          {validator:checkMobile}
        ],
        userName:[
          { required: true, message: '请输入新账号姓名' }
        ],
        remarks:[
          { required: true, message: '请输入备注' }
        ]
      }
    }
  },

  methods: {
    
    open (title, target, parent) {
      console.log(target,'-----新建');
      
      this.title = title
      this.visible = true
      this.userNameEditable  = true
      this.selectSystemAllUser();
      // 编辑
      this.$nextTick(() => {
        // for (const key in this.form) {
        //   this.form[key] = target[key]
        // }
        this.form.shopId = target.id
        this.form.userId = target.adminId
        this.form.originalName = target.adminUserName
        this.form.originalAccount = target.adminUserAccount
        this.form.shopName = target.name
        this.form.payCode = target.payCode
        this.form.openPay = target.openPay
        this.form.basePayCode = target.payCode
        this.form.remarks = target.remarks
        setTimeout(() => {
          this.$refs.form.clearValidate()
          this.required = true
        }, 30);
        
      })
    },
    selectUserByAllUser(account){
      return new Promise((suc,err)=>{
        selectSystemAllUser({account}).then(data=>{
          console.log(data,'---')
          if(data && data.length){
            data[0].disabled = data[0].businessRoleId&&data[0].businessRoleId.findIndex(i=>i==1)!=-1;//存在企业内账号即为disable用户
          }
          suc(data && data.length ? data[0] : null)
        })

      })      
    },
    selectSystemAllUser(){ //获取系统内账号列表
      return
      this.dialogLoading = true
      selectSystemAllUser({
      }).then(res=>{
        this.userOptions = res || []
      }).finally(()=>{
        this.dialogLoading = false
      })
    },
    async changeUser(value){
      this.form.account = value
      this.userNameEditable  = true
      if (!/^1\d{10}$/.test(value)) {
        return 
      }
      var findItem = await this.selectUserByAllUser(value)
    if(findItem){
        this.form.userName = findItem.userName
        this.userNameEditable  = findItem.userName?false:true
      }
      // let idx = this.userOptions.findIndex(i=>i.account==value)
      // if(idx!=-1){
      //   this.form.userName = this.userOptions[idx].userName
      //   this.userNameEditable  = this.userOptions[idx].userName?false:true
      // }
    },

    confirm(){
      if(this.form.newUserId==this.form.account){
        //新账号  手机号的校验
        if (!/^1\d{10}$/.test(this.form.account)) {
          return this.$message.error('手机号码格式不正确')
        }
      }
       this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        }
        // 调用新建接口
        this.isWorking = true
        shopAdminAccountChange({
          userId: this.form.userId,
          shopId:this.form.shopId,
          userName:this.form.userName,
          account:this.form.account,//targetAccount no ID
          // businessRoleId:2,
          
          isPayCodeChange:!this.form.openPay?false:this.form.payCode!=this.form.basePayCode?true:false,
          payCode:this.form.payCode,
          remarks:this.form.remarks,
          openPay:this.form.openPay
        })
          .then((res) => {
            console.log(res,'--移交成功的返回');
            if(res.code==500){
              this.$message.error(res.message);
              return 
            }
            this.visible = false
            this.$tip.apiSuccess(res.data?res.data:'移交成功')
            this.$emit('success')
          })
          .catch(e => {
            this.$tip.apiFailed(e)
          })
          .finally(() => {
            this.isWorking = false
          })
      })
    
    },

  },
  created () {
    // this.config({
    //   api: '/layun/basicDataManagement/equipmentData'
    // })
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables";
$icon-background-color: $primary-color;
.global-window {
  .tip {
    margin-bottom: 12px;
    em {
      font-style: normal;
      color: $primary-color;
      font-weight: bold;
    }
  }
  // 图标
  /deep/ .form-item-icon {
    .el-form-item__content {
      height: 193px;
      overflow-y: auto;
    }
    .el-radio-group {
      background: $icon-background-color;
      padding: 4px;
      .el-radio {
        margin-right: 0;
        color: #fff;
        padding: 6px;
        &.is-checked {
          background: $icon-background-color - 30;
          border-radius: 10px;
        }
        .el-radio__input.is-checked + .el-radio__label {
          color: #fff;
        }
      }
      .el-radio__input {
        display: none;
      }
      .el-radio__label {
        padding-left: 0;
        // element-ui图标
        i {
          font-size: 30px;
        }
        // 自定义图标
        [class^="eva-icon-"], [class*=" eva-icon-"] {
          width: 30px;
          height: 30px;
          background-size: 25px;
          vertical-align: bottom;
        }
      }
      .el-radio--small {
        height: auto;
        padding: 8px;
        margin-left: 0;
      }
    }
  }
}
.scrollBox{
  max-height: 100px;
  background: #dec;
  padding:10px;
  overflow-y: auto;
}
</style>
