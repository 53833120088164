<template>
  <TableLayout :permissions="['system:user:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="80px" inline>
      <el-form-item label="关键字" prop="keywords">
        <el-input v-model="searchForm.keywords" v-trim placeholder="按账号(手机号)、姓名、关联公司搜索" @keypress.enter.native="search" style="width:250px"/>
      </el-form-item>
      <el-form-item label="角色" prop="roleId">
        <role-select  v-model="searchForm.roleId" placeholder="请选择角色" :inline="true" clearable @change="search" :excludeId="1"></role-select>
        <!-- <el-select  class="fixWidth" v-model="searchForm.roleId" clearable  @change="search">
          <el-option :key='0' :value="0" label="预启动"/>
          <el-option :key='1' :value="1" label="进行中"/>
          <el-option :key='2' :value="2" label="已暂停"/>
          <el-option :key='3' :value="3" label="已完成"/>
          <el-option :key='4' :value="4" label="已取消"/>
        </el-select> -->
      </el-form-item>

      <!-- <el-form-item label="关联公司" prop="rootDeptId">
        <CompanySelect v-model="form.companyId" placeholder="请选择用户关联子公司" :inline="false" clearable/>
      </el-form-item> -->

      <section>
        <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['system:user:create', 'system:user:delete']">
        <li v-permissions="['system:user:create']"><el-button icon="el-icon-plus" type="primary" @click="$refs.operaUserWindow.open('新建用户')">新建</el-button></li>
        <li v-permissions="['system:user:delete']"><el-button icon="el-icon-delete" @click="deleteByIdInBatch">删除</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
        @sort-change="handleSortChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="avatar" label="头像" width="80px" class-name="table-column-avatar" fixed="left">
          <div slot-scope="{row}">
            <img :src="row.avatar == null ? '/static/avatar/man.png' : row.avatar">
          </div>
        </el-table-column>
        <el-table-column prop="account" label="账号" min-width="100px" fixed="left"></el-table-column>
        <el-table-column prop="username" label="姓名" min-width="100px" fixed="left"></el-table-column>
        <el-table-column prop="role" label="角色" min-width="120px">
          <div slot-scope="{row}">{{row.roleName == null ? '暂无角色' : row.roleName}}</div>
        </el-table-column>
        <el-table-column prop="company" label="关联公司" min-width="120px">
          <div slot-scope="{row}">{{row.departmentName == null ? '无' : row.departmentName}}</div>
        </el-table-column>
        <!-- <el-table-column prop="department" label="部门" min-width="120px">
          <div slot-scope="{row}">{{row.department == null ? '无' : row.department.name}}</div>
        </el-table-column> -->
        <el-table-column prop="createTime" label="创建时间" min-width="100px" fixed="left"></el-table-column>
        <el-table-column
          v-if="containPermissions(['system:user:update', 'system:user:createUserRole', 'system:user:resetPwd', 'system:user:delete'])"
          label="操作"
          width="270"
          fixed="right"
        >
        <!-- v-if="isAdmin || (row.id !== userInfo.id && row.roles.findIndex(r => r.code === adminCode) === -1)" -->
          <div  slot-scope="{row}" v-if="isAdmin || (row.id !== userInfo.id)">

            <el-button type="text" icon="el-icon-edit" @click="$refs.operaUserWindow.open('编辑用户', row)" v-permissions="['system:user:update']">编辑</el-button>
            <!-- <el-button type="text" icon="el-icon-s-custom" @click="$refs.roleConfigWindow.open(row)" v-permissions="['system:user:createUserRole']">配置角色</el-button> -->
            <el-button type="text" @click="$refs.resetPwdWindow.open(row)" v-permissions="['system:user:resetPwd']">重置密码</el-button>
            <el-button v-if="!row.fixed" type="text" icon="el-icon-delete" @click="deleteById(row)" v-permissions="['system:user:delete']">删除</el-button>
          </div>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaUserWindow ref="operaUserWindow" @success="handlePageChange(tableData.pagination.pageIndex)"/>
    <!-- 配置角色 -->
    <!-- <RoleConfigWindow ref="roleConfigWindow" @success="handlePageChange(tableData.pagination.pageIndex)"/> -->
    <!-- 重置密码 -->
    <ResetPwdWindow ref="resetPwdWindow"/>
  </TableLayout>
</template>

<script>
import Pagination from '@/components/common/Pagination'
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import OperaUserWindow from '@/components/system/user/OperaUserWindow'
import RoleConfigWindow from '@/components/system/user/RoleConfigWindow'
import ResetPwdWindow from '@/components/system/user/ResetPwdWindow'
import DepartmentSelect from '@/components/common/DepartmentSelect'
import PositionSelect from '@/components/common/PositionSelect'
import CompanySelect from "@/components/common/CompanySelect"
import RoleSelect from '../../components/common/RoleSelect'
// import { fetchList } from '@/api/system/user'

export default {
  name: 'ManageUser',
  extends: BaseTable,
  components: { PositionSelect, DepartmentSelect, ResetPwdWindow, RoleConfigWindow, OperaUserWindow, TableLayout, Pagination ,CompanySelect,RoleSelect },
  data () {
    return {
      // 搜索
      searchForm: {
        keywords:null,
        roleId:null
      }
    }
  },
  watch:{
    'searchForm.roleId':{
      handler(val,old){
        if(val!=old){
          this.search()
        }
      }
    }
  },
  created () {
    this.config({
      module: '账号',
      api: '/system/user',
      'field.main': 'account',
      sorts: [{
        property: 'CREATE_TIME',
        direction: 'DESC'
      }]
    })
    this.search()
  },
  methods:{
    // // 查询数据
    // handlePageChange () {
    //   this.tableData.list.splice(0, this.tableData.list.length)
    //   this.isWorking.search = true
    //   fetchList()
    //     .then(records => {
    //       this.tableData.list = records
    //     })
    //     .catch(e => {
    //       this.$tip.apiFailed(e)
    //     })
    //     .finally(() => {
    //       this.isWorking.search = false
    //     })
    // }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";
// 列表头像处理
.table-column-avatar {
  img {
    width: 48px;
  }
}
</style>
