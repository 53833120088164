<template>
  <GlobalWindow
    class="handle-table-dialog"
    title="补充/修改信息"
    :visible.sync="visible"
    :withFooter="false"
    :confirm-working="isWorking"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="商家名称" prop="name" required>
        <el-input v-model="form.name" placeholder="请输入商家名称" v-trim maxlength="200"/>
      </el-form-item>
      <el-form-item label="账号" prop="userId" required >
        <UserSelect v-if="visible" v-model="form.userId" :optionId="userOptions" :optionKey="{id:['id'],label:['account']}" selectMode='changeUser' :allow-create="true" :filterable="true" :clearable="true" :disabled="true" :multiple="false" placeholder="请选择/新增账号" :inline="false" :showAlert="false" @change="changeUser"></UserSelect>
      </el-form-item>

      <el-form-item label="管理员姓名" prop="userName" required>
        <el-input v-model="form.userName" placeholder="请输入管理员姓名" :disabled="!userNameEditable" v-trim maxlength="200"/>
      </el-form-item>

      
      <el-form-item label="邀约人">
        {{form.invitationUserName}}
      </el-form-item>
      <el-form-item label="所属销售" prop="saleUserId" >
        <SaleUserSelect v-model="form.saleUserId" placeholder="请选择销售" :clearable="true" :disabled="currentRole==roles.sale"></SaleUserSelect>
      </el-form-item>
      <el-form-item label="协议价格" prop="settPrice" >
        <el-input v-model="form.settPrice" placeholder="请输入协议价格" type="number" v-trim :max="currentRole==roles.sale?4:null">
          <template slot="append">元/千克</template>
        </el-input>
      </el-form-item>
      <el-form-item label="结算方式" prop="settType"  :rules="[{ required: false, message: '请选择结算方式' },]">
        <el-radio-group v-model="form.settType">
          <el-radio :label="1">现结</el-radio>
          <el-radio :label="2">周期结算</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="结算周期" prop="settCycle" v-if="form.settType==2" :rules="[{ required: form.settType==2, message: '请输入结算周期' },]">
        <el-input  placeholder="请输入" v-model="form.settCycle" type="number"  style="width:300px;" required>
          <template slot="append">天</template>
        </el-input> 
      </el-form-item>
      <el-form-item label="商家地址" prop="address" required>
        <TMapSelect v-if='visible' v-model="form.addressT" :latitude="form.latitude" :longitude="form.longitude" :isAdd="form.id==null?true:false"  @change="addressChange" >

        </TMapSelect>
      </el-form-item>
      <el-form-item label="业务区域" prop="address"  v-if="form.latitude">
        <span v-if="form.areaCode">{{form.areaName}}</span>
        <div v-else>
          <el-tag  type="danger" style="width:auto">异常</el-tag>
          <el-alert type="warning" v-if="(currentRole==roles.manager || currentRole==roles.newManager || currentRole==roles.logistics || currentRole==roles.sale || currentRole==-1)">基础信息中无此业务区域， 请前往业务区域基础数据中进行管理，然后再补充商家信息</el-alert>
          <el-alert type="warning" v-else>该商家地址所属业务区域不存在，请联系后勤管理业务区域基础数据后完成再补充商家地址信息</el-alert>
        </div>
      </el-form-item>
      <el-form-item label="所属街道办" prop="streetOfficeCode" v-if="form.latitude">
        <StreetSelect v-if="form.latitude" v-model="form.streetOfficeCode" :filter="nameObj" :clearable="true" :isAdd="false"></StreetSelect>
      </el-form-item>
      <el-form-item label="合同编号" prop="contractNo" :required="type==1">
        <el-input v-model="form.contractNo" placeholder="请输入合同编号" v-trim maxlength="200"/>
        <!-- <el-alert type="warning">合同编号重复，重复商家名称：xxx。商家名称带连接可以点开查看/修改</el-alert> -->
      </el-form-item>
      <div style="display:flex;justify-content: center;">
          <el-button type="primary" @click="confirmC" :loading="isWorking">确认</el-button>
          <el-button  @click="visible = false">取消</el-button>
      </div>
      
    </el-form>
  </GlobalWindow>
</template>

<script>
import  loadTMap from '../../../utils/loadTMap'
import { checkMobile, checkEmail,checkeRoleId,validBizLicenseKey } from '../../../utils/form'
import {fetchList} from '../../../api/layun/basicDataManagement/bussinessArea'
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import SaleUserSelect from '../../../components/layun/common/SaleUserSelect.vue'
import StreetSelect from '../../../components/layun/common/StreetSelect.vue'
import TMapSelect from '../../../components/layun/common/TMapSelect.vue'
import UserSelect from '../../common/UserSelect.vue';//别的userSelect
import FileUpload from '../../common/FileUpload.vue'
import {selectSystemAllUser,handoverRoles} from '../../../api/system/user'
import { mapState } from 'vuex'
const modalBaseDataList = [
  // {type:0,title:'新增商家/预备商家',remark:'新增商家/预备商家',query:null,save:null},
  // {type:1,title:'新增历史商家',remark:'新增历史商家',query:null,save:null},
  // {type:2,title:'补充/修改信息',remark:'补充/修改信息_商家管理员列表',query:null,save:null},
  // {type:3,title:'编辑邀约',remark:'编辑邀约_预备商家列表 || 编辑邀约_预备商家列表_累计受邀次数',query:null,save:null},
  // {type:4,title:'编辑邀约',remark:'编辑邀约信息&发起邀约',query:null,save:null},
  // {type:5,title:'新增邀约',remark:'新增_邀约管理',query:null,save:null},
  {type:6,title:'补充/修改信息',remark:'补充/修改信息_签约商家列表',query:null,save:null},
]
export default {
  name: 'editSignItem',
  extends: BaseOpera,
  components: {  GlobalWindow ,SaleUserSelect , TMapSelect , StreetSelect , UserSelect , FileUpload },
  computed:{
  ...mapState({
    currentRole :state=>state.init.userInfo.currentRole,
    roles:state=>state.init.roles
  })
  },
  data () {
    return {
      // 表单数据
      
      form: {
        id:null,
        userId:null,
        userName:'',
        address:'',
        addressName:'',
        areaCode:null,
        areaName:'',
        contractNo:'',
        latitude:0,
        longitude:0,
        name:'',
        saleUserId:0,
        settCycle:0,
        settPrice:null,
        settType:null,
        streetOfficeCode:0,
        addressAres:'',

        addressT:'',//总的地址
        invitationUserName:'',
      },
      originMap:true,//修改补充信息第一步
      userNameEditable:true,//姓名是否可编辑
      nameObj:{
        provinceName:'',
        cityName:'',
        districtName:''
      },
      // 验证规则
      rules: {
        account: [
          { required: true, message: '请输入手机号' },
          { validator: checkMobile,trigger: 'blur' }
        ],
        saleUserId:[
          { required: false, message: '请选择所属销售' },
        ],
      },
      target:null,
      parent:null,
      userOptions:[],//
      uploadFile:'',//上传文件的地址
      type:0,//来自于 哪个按钮
    }
  },
  methods: {
    async open(target,parent) {
      console.log(target,'_____target');
      // await loadTMap('ROKBZ-XMZK3-IHU3N-YIDJ4-AMMPE-EDF2Y')
      this.userNameEditable = !target.isPartTimeBusiness
      this.originMap = true
      this.userOptions = [{
        id:target.adminId,
        userName:target.adminUserName,
        account:target.adminUserAccount
      }]
      this.visible = true
      this.$nextTick(() => {
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        this.form.userId = target.adminId
        this.form.userName = target.adminUserName
        this.form.name = target.shopName
        this.form.invitationUserName = target.invitationUserName
        this.form.addressT = target.address+target.addressName
        this.form.latitude = target.latitude
        this.form.longitude = target.longitude
        this.form.streetOfficeCode = target.streetOfficeCode  
        this.form.addressAres = target.addressAres
      })
    },
    changeUser(value){
      console.log('value--changeUser',value);
      this.targetRoleArr = []
      this.form.account = null
      this.form.userId = null
      this.form.userName = ''
      this.nameRequired = false
      if(value){
        let idx = this.userOptions.findIndex(i=>i.id==value)
        if(idx!=-1){
          this.form.account = this.userOptions[idx].account
          this.form.userName = this.userOptions[idx].userName
          this.nameRequired = this.userOptions[idx].userName=='游客'?false:true
          this.form.userId = value
        }else{
          //创建的新的用户
          this.form.account = value
          this.form.userId = value
        }
      }
    
    },
    selectSystemAllUser(){ //获取美程账号列表
      return
      this.dialogLoading = true
      selectSystemAllUser({}).then(res=>{
        this.userOptions = res || []
      }).finally(()=>{
        this.dialogLoading = false
      })
    },
    addressChange(value) {
      console.log("新增商家的地图选点", value,value.addressObj);
      if(!this.originMap){ //修改补充信息 地图选点变化后 this.type==2&&
        this.form.streetOfficeCode = null
      }
      this.originMap = false
      //业务区域先置空
      this.form.areaCode = ''
      this.form.areaName = ''
      if(value.clear){
        this.form.address = ''
        this.form.addressName = ''
        this.form.addressT = ''
        this.form.streetOfficeCode = ''
        this.form.addressAres = ''
        this.nameObj = {
            provinceName:'',
            cityName:'',
            districtName:''
        }
        this.form.latitude = null
        this.form.longitude = null

        return 
      }
      
      this.$nextTick(()=>{
        if(value.addressObj){
          this.form.address = value.addressObj.address;
          this.form.addressName = value.addressObj.addressName;
          this.form.addressT = value.addressObj.address+value.addressObj.addressName
        }
        
        if(value.addressIds){
          this.nameObj = {
            provinceName:value.addressIds.provinceName,
            cityName:value.addressIds.cityName,
            districtName:value.addressIds.districtName
          }
          this.form.addressAres = value.addressIds.cityName+value.addressIds.districtName
          this.getBussinessArea(value.addressIds)
        }
        
        if(value.addrPoint){
          this.form.latitude = value.addrPoint.lat
          this.form.longitude = value.addrPoint.lng
        }
      
      
      })
      
    },
    getBussinessArea(value){
      //根据省市区名称查相关的业务区域 回填信息
      fetchList({
        capacity: 500,
        model: {
          provinceId:value.provinceId,
          cityId:value.cityId,
          districtId:value.districtId
        },
        page: 1,
      }).then(res=>{
        console.log(res,'--区域的结果');
        this.form.areaCode = res.records.length>0?res.records[0].id:''
        this.form.areaName = res.records.length>0?res.records[0].regionName:''
      
      })
    },
    confirmC(){
      if(this.form.settPrice && this.form.settPrice>4&&this.currentRole==this.roles.sale){
        return this.$message.error('协议价格不能超过4元')
      }
      if(!this.form.areaCode){
      let message = (this.currentRole==this.roles.manager || this.currentRole==this.roles.newManager || this.currentRole==this.roles.logistics || this.currentRole==this.roles.sale || this.currentRole==-1)?'基础信息中无此业务区域， 请前往业务区域基础数据中进行管理，然后再补充商家信息':'该商家地址所属业务区域不存在，请联系后勤管理业务区域基础数据后完成再补充商家地址信息'
        return this.$message.error(message)
      }

      if(!this.form.streetOfficeCode){
        return this.$message.error('请先选择街道办')
      }


      this.confirm()
      
    },
  },
  created () {
    this.config({
      api: '/layun/contractManagement/signedManagement', 
    })
    
  },
  mounted(){
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables";
$icon-background-color: $primary-color;
.global-window {
  .tip {
    margin-bottom: 12px;
    em {
      font-style: normal;
      color: $primary-color;
      font-weight: bold;
    }
  }
  // // 图标
  // /deep/ .form-item-icon {
  //   .el-form-item__content {
  //     height: 193px;
  //     overflow-y: auto;
  //   }
  //   .el-radio-group {
  //     background: $icon-background-color;
  //     padding: 4px;
  //     .el-radio {
  //       margin-right: 0;
  //       color: #fff;
  //       padding: 6px;
  //       &.is-checked {
  //         background: $icon-background-color - 30;
  //         border-radius: 10px;
  //       }
  //       .el-radio__input.is-checked + .el-radio__label {
  //         color: #fff;
  //       }
  //     }
  //     .el-radio__input {
  //       display: none;
  //     }
  //     .el-radio__label {
  //       padding-left: 0;
  //       // element-ui图标
  //       i {
  //         font-size: 30px;
  //       }
  //       // 自定义图标
  //       [class^="eva-icon-"], [class*=" eva-icon-"] {
  //         width: 30px;
  //         height: 30px;
  //         background-size: 25px;
  //         vertical-align: bottom;
  //       }
  //     }
  //     .el-radio--small {
  //       height: auto;
  //       padding: 8px;
  //       margin-left: 0;
  //     }
  //   }
  // }
}
</style>
