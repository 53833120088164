<template>
  <TableLayout :permissions="['basics:customerservice:query']">


    <template v-slot:table-wrap>
        <!-- <ul class="toolbar" v-permissions="['system:user:create', 'system:user:delete']">
            <li v-permissions="['system:user:create']"><el-button icon="el-icon-plus" type="primary" @click="$refs.operaUserWindow.open('新建用户')">新增</el-button></li>
        </ul> -->
      <el-table
        
        border
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
        @sort-change="handleSortChange"
      >
        <el-table-column prop="customerServicePhone" label="电话" min-width="120px" align="center">
        </el-table-column>

        <el-table-column
          v-if="containPermissions(['basics:customerservice:query', 'basics:customerservice:update'])&&userInfo.currentMcRoleStatus==0&&currentRole!=roles.treasurer"
          label="操作"
          align="center"
          fixed="right"
        >
          <div  slot-scope="{row}">
            <el-button  type="text" icon="el-icon-edit" :permissions="['basics:customerservice:update']"  @click="$refs.OperaServicePhoneWindow.open('修改电话',row)">修改</el-button>
            <el-button  type="text" icon="el-icon-notebook-2" @click="$refs.operaRecordWindow.open(1,row,4)">操作记录</el-button>
          </div>
        </el-table-column>
      </el-table>

    </template>
    <!-- 新建/修改 -->
    <OperaServicePhoneWindow ref="OperaServicePhoneWindow" @success="handlePageChange(tableData.pagination.pageIndex)"/>
    <!-- 操作记录 -->
    <OperaRecordWindow ref="operaRecordWindow"></OperaRecordWindow>
  </TableLayout>
</template>

<script>
import Pagination from '@/components/common/Pagination'
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import OperaServicePhoneWindow from '../../../components/layun/basicDataManagement/OperaServicePhoneWindow.vue'
import OperaRecordWindow from '../../../components/layun/common/OperaRecordWindow.vue'
import { mapState } from 'vuex'

// import { fetchList } from '@/api/system/user'

export default {
  name: 'ManageUser',
  extends: BaseTable,
  components: {TableLayout, Pagination , OperaServicePhoneWindow,OperaRecordWindow  },
  data () {
    return {
      // 搜索
      searchForm: {
        keywords:'',
        roleId:''
      }
    }
  },
  computed: {
    ...mapState({
      userInfo:state=>state.init.userInfo || {},
      currentRole:state=>state.init.userInfo.currentRole,
      roles:state=>state.init.roles
    }),
  },
  created () {
    this.config({
      module: '设备数据',
      api: '/layun/basicDataManagement/serviceData',
      // 'field.main': 'account',
      sorts: [{
        property: 'CREATE_TIME',
        direction: 'DESC'
      }]
    })
    this.search()
  },
  methods:{
        handleCommand(command) {
            this.$message('click on item ' + command);
        }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";
// 列表头像处理
.table-column-avatar {
  img {
    width: 48px;
  }
}
</style>
