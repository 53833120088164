import request from '@/utils/request'

// 查询
export function fetchList (data) {
  console.log(data,'--shop');
  if(data.model.listType==1){
    return request.post('/business/shop/page', data) 
  }else if(data.model.listType==2){
    return request.post('/business/shop/preShopPage', data) 
  }else if (data.model.listType==3){
   return request.post('/business/shop/touristPage', data) 
  }else{
    return this.$message.info('参数有一丢丢小问题')
   }
}

// 新建
export function create (data) {
  return request.post('/business/shop/create', data, {
    trim: true
  })
}

// 修改
export function updateById (data) {
  return request.post('/business/shop/updateById', data, {
    trim: true
  })
}

// 删除
export function deleteById (id) {
  return request.get(`/business/shop/deleteById/${id}`)
}

//查询 店铺下的员工列表
export function fetchStaffsList(data){ 
  //接口需替换
  return request.post('/system/user/selectShopSelf', data)
}

// 导入历史商家
export function importHistoryShop(data){ 
  return request.post('/business/shop/importHistoryShop', data)
}


//修改付款码验证状态
export function updatePayCodeVerStatus(data){ 
  return request.post('/business/shop/payStatus', data)
}

//修改付款码 
export function updateSonCollectionCode(data){ 
  return request.post('/business/shop/updateSonCollectionCode', data)
}

//预备商家列表 累计受邀次数 列表
export function beInvitedTotalRecordList(data){ 
  return request.post('/business/shop/invitedRecord', data)
}

// 预备商家列表 累计受邀次数 列表的批量开启,批量关闭,全部开启,全部关闭邀约
export function changeInvitationStatusInBatch(data){ 
  return request.post('/business/shop/batchStatus', data)
}

// 把商家移入回收站
export function removeToRecycle(data){ 
  return request.post('/business/shop/removeToRecycle', data)
}

// 把商家移出回收站
export function removeFromRecycle(data){ 
  return request.post('/business/shop/removeFromRecycle', data)
}



