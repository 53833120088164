<template>
<!-- 在用 -->
  <GlobalWindow

    :title="title"
    :visible.sync="visible"
    :with-footer="false"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <div class="top">
          <div class="admin">
            管理员:{{adminUserName}}/{{adminUserAccount}}
          </div>
          <div class="list">
            <div class="item" v-for="item in shopList">
              <div class="block">商家名称：{{item.shopName}}</div>
              <div class="block">所属销售：{{item.saleUserName}} / {{item.saleUserAccount}}</div>
              <div class="block">合同编号：<el-link type="primary">{{item.contractNo}}-1</el-link></div>
            </div>
          </div>
      </div>
      <el-form-item label="选择设备押金费用收取方式"  required >
        <el-radio-group v-model="form.equipmentDeposit" style="margin-bottom:10px;" @change="form.setIsBarrel = 1;form.price2=null">
          <el-radio :label="1">小型干湿分离型油水分离器</el-radio>
          <el-radio :label="2">异型、大型干湿分离型油水分离器</el-radio>
        </el-radio-group>
        <el-radio-group v-model="form.setIsBarrel" v-if="form.equipmentDeposit==1" @change="form['price'+(form.setIsBarrel==1?'2':'1')]=null">
          <el-radio :label="1" >
            <el-input placeholder="请输入" v-model="form.price1"  type="number" :disabled="form.setIsBarrel==1?false:true">
              <template slot="append">元/台</template>
            </el-input>
          </el-radio>
          <el-radio :label="2" style="margin-top:10px">
            <el-input placeholder="请输入" v-model="form.price2" type="number" :disabled="form.setIsBarrel==2?false:true">
              <template slot="append">元/桶（50KG/桶）/台</template>
            </el-input></el-radio>
        </el-radio-group>

        <el-input  placeholder="请输入"  v-if="form.equipmentDeposit==2"  type="number" v-model="form.price1" style="width:300px;">
          <template slot="append">元/台</template>
        </el-input> 
      </el-form-item>
      
      <el-form-item label="合同另约内容" prop="contractContent">
        <el-input type="textarea" v-model="form.contractContent" placeholder="请输入合同另约内容" v-trim maxlength="200"/>
      </el-form-item>
      <el-form-item label="合同起止日期" required>
        <div>
          <el-date-picker v-model="form.startTime" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" :picker-options="startDateOpt" /> 至
          <el-date-picker v-model="form.endTime" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" :pickerOptions="endDateOpt" ></el-date-picker> 
          <el-checkbox v-model="form.longTerm" style="margin-left:10px">长期</el-checkbox>
        </div>
        
      </el-form-item>
    
      <!-- <el-form-item > -->
        <el-button type="primary" @click="send(2)" :loading="isWorking&&isSave==2">保存并发送</el-button>
        <el-button type="primary" @click="send(1)" :loading="isWorking&&isSave==1">保存</el-button>
        <el-button type="" @click="$emit('update:visible', false);visible = false">取消</el-button>

      <!-- </el-form-item> -->
    </el-form>
  </GlobalWindow>
</template>

<script>
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import GlobalWindow from '@/components/common/GlobalWindow'
import Pagination from '@/components/common/Pagination'
import { createCoreAgreement,editCoreAgreement } from '../../../api/layun/contractManagement/signedManagement'
export default {
  name: 'SupplementalAgreementWindow',
  extends: BaseTable,
  components: { Pagination, GlobalWindow, TableLayout  ,  },
  data () {
    return {
      shopId: null,
      shopName: '',
      visible: false,
      title:'',
      
      form:{
        id:null,
        contractContent:'',//合同另约内容
        equipmentDeposit:'',//方式
        price1:'',
        price2:'',
        setIsBarrel:1,//1 台 2  桶
        startTime:'',
        endTime:'',
        longTerm:'',
        deposit:'',//具体现金金额
      },
      shopList:[],
      adminUserName:'',
      adminUserAccount:'',
      adminUserId:null,
      startDateOpt:{
          disabledDate(time) {
            return time.getTime() > Date.now();
          },
      },
      endDateOpt: {
          disabledDate(time) {
            return Date.now()>time.getTime()
          },
      },
      rules:{},
      isWorking:false
      
    }
  },
  watch:{
    'form.longTerm'(val){
      if(val){
        this.form.endTime = ''
      }
    },
    'form.endTime'(val){
      if(val){
        this.form.longTerm = false 
      }
    }
  
  },
  methods: {
  
    open (title, row) {
      this.title = title;
      this.visible = true;
      this.adminUserName = row.adminUserName
      this.adminUserAccount = row.adminUserAccount
      this.adminUserId = row.adminUserId
      this.shopList = row.shopList
      this.form = {
        id:null,
        contractContent:'',//合同另约内容
        equipmentDeposit:'',//方式
        price1:'',
        price2:'',
        setIsBarrel:1,//1 台 2  桶
        startTime:'',
        endTime:'',
        longTerm:'',
        deposit:'',//具体现金金额
      }

      console.log(row,'____---___-___-_--------');

      if(row.editable){
        for (const key in this.form) {
          this.form[key] = row.editable[key]
        }
        this.form.id = row.editable.id
      }
      // this.search()
    },
    send(isSave){
      console.log(this.shopList);
      this.isSave = isSave
      console.log({
        contractContent: this.form.contractContent,
        deposit: Number(this.form.setIsBarrel==1?this.form.price1:this.form.price2),
        endTime: this.form.endTime,
        equipmentDeposit: this.form.equipmentDeposit,
        id: null,
        longTerm: this.form.longTerm,
        setIsBarrel: this.form.setIsBarrel,
        startTime: this.form.startTime,
        isSave:isSave,
        adminId:this.adminUserId,
        shopIds:this.shopList.map(i=>i.shopId)
      })
      // return 
      let api = [createCoreAgreement,editCoreAgreement]
      api[this.form.id?1:0]({
        contractContent: this.form.contractContent,
        deposit: Number(this.form.setIsBarrel==1?this.form.price1:this.form.price2),
        endTime: this.form.endTime,
        equipmentDeposit: this.form.equipmentDeposit,
        id: this.form.id,
        longTerm: this.form.longTerm?1:0,
        setIsBarrel: this.form.setIsBarrel,
        startTime: this.form.startTime,
        isSave:isSave,
        adminId:this.adminUserId,
        shopIds:this.shopList.map(i=>i.shopId)
      }).then((message) => {
        this.visible = false
        this.$tip.apiSuccess(message?message:'新建成功')
        this.$emit('success')
      })
      .catch(e => {
        this.$tip.apiFailed(e,false)
      })
      .finally(() => {
        this.isWorking = false
      })
    
    },





  }
}
</script>

<style scoped lang="scss">
.position-user-window {
  /deep/ .table-search-form {
    padding: 0;
  }
  /deep/ .window__body {
    background: #f7f7f7;
    .table-content {
      padding: 0;
      .table-wrap {
        padding: 0;
      }
    }
  }
  .toolbar {
    margin-top: 10px;
    padding: 6px 12px;
    background: #fff;
    font-size: 13px;
    label {
      margin-left: 8px;
      vertical-align: middle;
      color: #999;
    }
  }
  // 列表头像处理
  .table-column-avatar {
    img {
      width: 48px;
    }
  }
  // 列表角色处理
  .table-column-role {
    ul {
      li {
        display: inline-block;
        background: #eee;
        border-radius: 3px;
        padding: 0 3px;
        margin-right: 3px;
      }
    }
  }
}
.top{
  .admin{
  font-weight: bold;
  font-size: large;
  
  
  }
  .list{
    margin: 20px;
    display: flex;
    flex-direction: column;

  
    .item{
      display: flex;
      justify-content: space-around;
      margin: 8px 0;
    
    }
    .block{
    
    }  
  }




}
</style>
