<template>
  <GlobalWindow
    class="handle-table-dialog"
    :title="title"
    :visible.sync="visible"
    :withFooter="false"
    :confirm-working="isWorking"
  >
  <el-form :model="form" ref="form">
      <el-form-item label="关联拉运单" prop="torderNo" required>
        <div>
          <el-tag v-for="item in row.torderNos" style="margin:20px 10px ">{{item}}</el-tag>
        </div>
      </el-form-item>
      <el-form-item label="备注" prop="remark" :rules="[{required:true,message:'请输入备注'}]">
        <el-input v-model.trim="remark"  placeholder="请输入备注" v-trim  />
      </el-form-item>
      <el-form-item label="附件" prop="enclosure" >
        <Enclosure v-model="enclosure" :size="80"></Enclosure>
      </el-form-item>
      <div style="display:flex;justify-content: center;">
          <el-button type="primary" @click="confirm(3)" :loading="isWorking&&status==3"  v-if="row.abnormalSettType==2&&row.abnormalType==1">已支付差额</el-button>
          <template v-else>
            <el-button type="primary" @click="confirm(1)" :loading="isWorking&&status==1" >追回</el-button>
            <el-button type="primary" @click="confirm(2)" :loading="isWorking&&status==2" >对冲</el-button>
            <el-button  @click="visible = false"  >取消</el-button>
          </template>
      </div>  
    </el-form>
    
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import Enclosure from '../../../components/layun/common/Enclosure.vue'
import {operaAbnormal} from '../../../api/layun/financialManagement/transportationSettlement'


export default {
  name: 'SettlementException',
  extends: BaseOpera,
  components: {  GlobalWindow  , Enclosure , },
  data () {
    
    return {
      title:'',
      form:{
      },
      visible:false,
      search:false,
      tableData:{
      list:[1,2,3,4,5,6]
      },
      type:1,//
      row:null,
      remark:'',//备注
      enclosure:[],
      status:null,//点击按钮的时候点了哪一个
    }
  },
  methods: {
    /*
      @type 1 拉运 2 出库
      @row  当前行的数据
    */
    async open(type,row) {
        console.log(type,row,'_____target');
        this.isWorking = false;
        this.type = type
        this.row = row
        if(type==1){
          this.title = row.abnormalSettType==1?'处理取消拉运单-结算异常':row.abnormalSettType==2?'处理变更拉运单-结算异常':'异常'
        }else{
        
        }

        this.visible = true
    },
    confirm(status){
      this.status = status
      if(this.remark.trim().length==0){
        return this.$message.error('备注必填')
      }
      let param = {
        settId:this.row.id,
        remark:this.remark.trim(),
        enclosure:this.enclosure,
        treatmentMethod:status,
        transportIds:this.row.torderNos,
      }

      console.log('确认的返回值',param);
      
      this.isWorking = true
      // return 
      operaAbnormal(param).then(() => {
            this.visible = false
            this.$tip.apiSuccess('异常结算处理完成')
            this.$emit('success')
        })
        .catch(e => {
            this.$tip.apiFailed(e,false)
        })
        .finally(() => {
            this.isWorking = false
            this.status = null
        })
    
    
    }
  },
  created () {
    // this.config({
    //   api: '/layun/contractManagement/signedManagement', 
    // })
    
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables";
$icon-background-color: $primary-color;

</style>
