//街道办数据信息
import request from '@/utils/request'
// 查询
export function fetchList (data) {
    return request.post('/basics/area/page', data)
}


export function fetchStreetList (data) {
  return request.post('/basics/area/page', data)
}

// 新建
export function create (data) {
  return request.post('/basics/area/create', data, {
    trim: true
  })
}

// 修改
export function updateById (data) {
  return request.post('/basics/area/updateById', data, {
    trim: true
  })
}
// 根据省市区汉字名称 查相应的街道办名称
export function selectSubName (data) {
  return request.post('/basics/area/selectarea', data, {
    trim: true
  })
}
