<template>
  <GlobalWindow
    class="handle-table-dialog"
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm(null,true)" 
  >
  <!-- @confirm="confirm(null,true)" message==null  fixedError = true -->
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="业务区域名称" prop="regionName">
        <el-input v-model="form.regionName" placeholder="请输入业务区域名称" v-trim maxlength="200"/>
      </el-form-item>
      <el-form-item label="省市区" prop="basicsRegionMapVo" required>
        <CitySelect v-model="form.basicsRegionMapVo" :fixedArr="fixedArr" v-if="visible" @change="cityChange" :multiple='true' :checkStrictly="true" :clearable="true"></CitySelect>
        <el-alert style="margin:10px 0" title="至少选到市" type="warning"></el-alert>
      </el-form-item>  
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import CitySelect from '../../../components/layun/common/CitySelect.vue'
import { fetchAll} from '../../../api/layun/basicDataManagement/bussinessArea'
export default {
  name: 'OperaBussinessAreaWindow',
  extends: BaseOpera,
  components: {  GlobalWindow ,CitySelect },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        regionName:'',
        basicsRegionMapVo:[],
      },
      fixedArr:[],//固定的 不可去掉disable的数据
      errorMessage:'',
      // 验证规则
      rules: {
        regionName: [
          { required: true, message: '请输入区域名称' }
        ],
        basicsRegionMapVo: [
          { required: true, message: '请输入市区' }
        ]
      }
    }
  },
  methods: {
    /**
     * @title: 窗口标题
     * @target: 编辑的菜单对象
     * @parent: 新建时的上级菜单
     */
    async open (title, target, parent) {
      console.log(title, target, parent);
      this.errorMessage = ''
      this.fixedArr = []
      this.form.basicsRegionMapVo= []
      this.title = title
      this.visible = true
      let path = []
      target&&target.levelPath.map(element => {
        let i = element.slice(1,-1);//去头去尾
        element = i.split(', ').map(Number)
        path.push(element)
        
      });
      
      this.$nextTick(() => {
        // this.$refs.form.resetFields()
        this.form.id = null
        this.form.regionName = ''
        this.form.basicsRegionMapVo= []
        if (target) {
          for (const key in this.form) {
            this.form[key] = target[key]
          }
          this.form.basicsRegionMapVo = path
          
        }
        this.getFixedData()  
      })
      
      
      
    },
    cityChange(value){
      console.log(value,'--change数据');
      this.form = {
        ...this.form,
        ...value.form,
        // basicsRegionMapVo:value.operateData,//选全市的时候 筛出全部的区县数据
      }
      // this.operateData = value.operateData
    },
    async getFixedData(){
      let fixedArr  = []
      await fetchAll().then(data=>{
        console.log(data,'0-0-');
        if(data){
          data.map(target=>{
            console.log(this.form.id,target);
            if(this.form.id!=target.id){
              let path = []
              target&&target.levelPath.map(element => {
                let i = element.slice(1,-1);//去头去尾
                element = '-1,46834,'+i.split(', ').map(Number).join(',')
                path.push(element)
                
              });
              fixedArr.push(...path)
            }
            
          
          })
          this.fixedArr = fixedArr
          console.log(fixedArr,'---变化fixedArr');
        }
      })
    }
  },
  created () {
    this.config({
      api: '/layun/basicDataManagement/bussinessArea'
    })
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables";
$icon-background-color: $primary-color;
.global-window {
  .tip {
    margin-bottom: 12px;
    em {
      font-style: normal;
      color: $primary-color;
      font-weight: bold;
    }
  }
  // 图标
  /deep/ .form-item-icon {
    .el-form-item__content {
      height: 193px;
      overflow-y: auto;
    }
    .el-radio-group {
      background: $icon-background-color;
      padding: 4px;
      .el-radio {
        margin-right: 0;
        color: #fff;
        padding: 6px;
        &.is-checked {
          background: $icon-background-color - 30;
          border-radius: 10px;
        }
        .el-radio__input.is-checked + .el-radio__label {
          color: #fff;
        }
      }
      .el-radio__input {
        display: none;
      }
      .el-radio__label {
        padding-left: 0;
        // element-ui图标
        i {
          font-size: 30px;
        }
        // 自定义图标
        [class^="eva-icon-"], [class*=" eva-icon-"] {
          width: 30px;
          height: 30px;
          background-size: 25px;
          vertical-align: bottom;
        }
      }
      .el-radio--small {
        height: auto;
        padding: 8px;
        margin-left: 0;
      }
    }
  }
}
</style>
