<template>
  <GlobalWindow
    class="handle-table-dialog"
    title="邀约金充值"
    :visible.sync="visible"
    :withFooter="false"
    :confirm-working="isWorking"
    :loading="dialogLoading"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="充值账号" prop="account" required>
        <el-input v-model="form.account"  placeholder="请输入手机号" v-trim maxlength="200" @input="changeUser"/>
      </el-form-item>
      <el-form-item label="姓名" prop="userName" >
        <el-input v-model="form.userName" placeholder="请输入姓名" :disabled="!userNameEditable" v-trim maxlength="200"/>
      </el-form-item>
      <el-form-item label="充值金额" prop="rechargeAmount" required>
        <el-input v-model="form.rechargeAmount" type="number" placeholder="请输入金额" v-trim />
      </el-form-item>
      <!-- <el-form-item label="凭证" prop="" >
        <ImageUpload v-model='form.pic'></ImageUpload>
      </el-form-item> -->
      
      <div style="display:flex;justify-content: center;">
          <el-button type="primary" @click="confirm">确认</el-button>
          <el-button  @click="visible = false">取消</el-button>
      </div>  
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import ImageUpload from '../../../common/ImageUpload.vue'
import { checkMobile,} from '../../../../utils/form'
import {selectSystemAllUser} from '../../../../api/system/user'
import {create} from '../../../../api/layun/contractManagement/invitationFundManagement'
export default {
  name: 'updateInvitationMoney',
  extends: BaseOpera,
  components: {  GlobalWindow , ImageUpload , },
  data () {
    
    return {
      form:{
        account:null,
        userName:null,
        rechargeAmount:null,
      },
      rules:{
        account: [
          { required: true, message: '请输入手机号' },
          { validator: checkMobile,trigger: 'change' }
        ],
        rechargeAmount: [
          { required: true, message: '请输入充值金额' },
        ],
      },
      visible:false,
      userOptions:[],
      userNameEditable:true,//名称是否可编辑
      dialogLoading:false,//page loading
    }
  },
  methods: {
    async open(target,parent) {
        console.log(target,'_____target');
        this.userNameEditable = true
        this.$nextTick(() => {
          this.$refs.form.resetFields()
        })

        this.visible = true
        this.selectSystemAllUser();
    },
    async changeUser(value){
      console.log('',value);
      this.userNameEditable = true
      if (!/^1\d{10}$/.test(value)) {
        return 
      }

      var findItem = await this.selectUserByAllUser(value)      
     
      if(findItem){
        this.userNameEditable = findItem.userName=='游客'?true:false;
        this.form.userName = findItem.userName
      }
      // let idx = this.userOptions.findIndex(i=>i.account==value)
      // if(idx!=-1){
      //   this.userNameEditable = this.userOptions[idx].userName=='游客'?true:false;
      //   this.form.userName = this.userOptions[idx].userName
      // }
    },
    selectUserByAllUser(account){
      return new Promise((suc,err)=>{
        selectSystemAllUser({account}).then(data=>{
          console.log(data,'---')
          if(data && data.length){
            data[0].disabled = data[0].businessRoleId&&data[0].businessRoleId.findIndex(i=>i==1)!=-1;//存在企业内账号即为disable用户
          }
          suc(data && data.length ? data[0] : null)
        })

      })      
    },
    selectSystemAllUser(){ //获取美程账号列表\
      return
      this.dialogLoading = true
      selectSystemAllUser({
      }).then(res=>{
        this.userOptions = res || []
      }).finally(()=>{
        this.dialogLoading = false
      })
    },
    confirm(){
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        }
        // 调用新建接口
        this.isWorking = true
        create(this.form)
          .then((message) => {
            this.visible = false
            this.$tip.apiSuccess(message?message:'新建成功')
            this.$emit('success')
          })
          .catch(e => {
            this.$tip.apiFailed(e,false)
          })
          .finally(() => {
            this.isWorking = false
          })
         
      })
    
    }
  },
  created () {
    // this.config({
    //   module: '邀约金充值',
    //   api: '/layun/contractManagement/invitationFundManagement',
    // })
    
  },
  
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables";
$icon-background-color: $primary-color;

</style>
