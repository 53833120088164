<template>
    <div>
        <div class="formBox" v-loading="pageLoading">
            <el-form ref="form" :model="form" label-position="right" :label-width='labelWidth' :rules="rules" >
                <div style="margin-left:30px;clear:both;padding-top:20px">
                    <el-form-item label="司机抢单数量上限" style="display:inline-block;width:60%;margin-right:0;" prop="grabNum">
                        <el-input v-model="form.grabNum"></el-input>
                    </el-form-item>
                   
                   

                   
                        <el-form-item label="" style="display:inline-block;width:60%;margin-right:0;margin-top:50px" >
                            <el-button @click="save" type="primary">保存</el-button>
                    </el-form-item>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
import { uploadImage } from '@/api/common'
import { getShopInfo   ,updateShopInfo  } from "@/api/layun/basicDataManagement/serviceData"
export default {
    name: "contractConfig",
    data() {
        return {
            pageLoading:false,
            labelWidth: '150px',
            rules: {
                shopName: [
                    { required: true, message: '请输入信息', trigger: 'blur' },
                ],
           



              
              
            },
            form: {
                grabNum :0,
             
            }
        }
    },
    mounted() {
        this.getShopInfo  ()
    },
    methods: {
        beforeUpload(file) {
            return true
        },
        updateQrCode(options) {
            console.log(options, '上传文件')
            let formData = new FormData();

            formData.append('file', options.file, new Date().getTime() + '.jpg');
            let size = options.file.size
            uploadImage(formData).then((data) => {
                console.log(data, '成功')
                this.form.shopLogo = data


            }).catch(e => {

            }).finally(() => {

            })
        },
        getShopInfo  () {
            getShopInfo().then(data => {
                console.log(data, '--')
                if (data) {
                    this.form = JSON.parse(JSON.stringify(data))
                    
                }
            })
        },
        save() {
            //校验必填信息
            console.log('1')
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.pageLoading = true 
                    var  {grabNum,
                     } = this.form
                    var params = {
                        grabNum
                    }
                    updateShopInfo(params).then(data=>{
                        this.pageLoading = false
                        this.$message({ type: 'success', message: `保存成功!` })
                    }).catch(err=>{
                        this.$message({ type: 'error', message: `保存失败!` })
                        this.pageLoading = false
                    })
                  
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.width350 {
    width: 350px;
}

.formBox {
    width: 90%;
    margin: 50px auto;
}
</style>