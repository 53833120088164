import request from '@/utils/request'
// 拉运结算管理

// 查询
export function fetchList (data) {
  console.log(data,'--transport');
  if(data.model.listType==1){
    return request.post('/business/transport/sett/shopSettPage', data) 
  }else if(data.model.listType==2){
    return request.post('/business/transport/sett/transportSettList', data) 
  }else if (data.model.listType==3){
   return request.post('/business/transport/sett/transportSettPage', data) 
  }else{
    return this.$message.info('参数有一丢丢小问题')
   }
}

// 查询-总计
export function fetchAllList (data) {
  console.log(data,'--transport');
  if(data.listType==1){
    
  }else if(data.listType==2){
    return request.post('/business/transport/sett/getTransportSettTotal', data) 
  }else if (data.listType==3){
   return request.post('/business/transport/sett/transportSettTotal', data) 
  }else{
    return this.$message.info('参数有一丢丢小问题')
   }
}

// 总计tab 拉运结算总单数明细 
export function orderNumDetail (data) {
  return request.get('/business/transport/sett/getTransportSettDetail', {params:data})
}

//生成拉运结算单

export function createStatement (data) {
  return request.post('/business/transport/sett/generateSett', data)
}

// 作废拉运结算单
export function VoidOrder (data) {
  return request.post('/business/transport/sett/nullifySett', data)
}

//完成拉运结算单
export function CompleteOrder (data) {
  return request.post('/business/transport/sett/completeSett', data)
}

//获取结算单详情
export function getInfo (data) {
  return request.post('/business/transport/sett/getInfo', data)
}

//处理异常结算单
export function operaAbnormal (data) {
  return request.post('/business/transport/sett/treatmentAbnormalSett', data)
}

//编辑结算  
// 1 明细列表
export function changeSettPrice (data) {
  return request.post('/business/transport/changeOrder', data)
}
// 2 结算单列表
export function editSettDto (data) {
  return request.post('/business/transport/sett/editSettDto', data)
}

//更新列表附件
export function uploadEnclosures (data) {
  return request.post('/business/transport/sett/uploadEnclosures', data)
}

//修改备注 
export function updateRemark (data) {
  return request.post('/business/transport/sett/updateRemark', data)
}