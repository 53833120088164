import request from '@/utils/request'

// 查询
export function query (data) {
  return request.get('/system/user/queryById')
}
//修改信息
export function updateBasics (data) {
  return request.post('/system/user/updateBasics', data)
}

//绑定微信
export function bindingWeChat (data) {
  return request.post('/system/bindingWeChat', data)
}

//更换头像
export function updateAvatar (data) {
  return request.get('/system/user/updateAvatar', {params:data})
}
//更换手机号
export function resetAccount (data) {
  return request.post('/system/user/resetAccount', data)
}