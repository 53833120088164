import vPermissions from './v-permissions'
import vRoles from './v-roles'
import vTrim from './v-trim'
import vButtons from './v-buttons'

export default {
  install (Vue) {
    // 角色控制指令
    Vue.directive('roles', vRoles)
    // 权限控制指令
    Vue.directive('permissions', vPermissions)
    // 自动去空指令
    Vue.directive('trim', vTrim)
    
    //操作按钮展示指令
    Vue.directive('buttons', vButtons)
  }
}
