<template>
<!-- 在用 -->
  <GlobalWindow
    class="position-user-window"
    width="90%"
    title="关联商家"
    :visible.sync="visible"
    :with-footer="false"
  >
    <TableLayout :with-breadcrumb="false">
      <!-- 搜索表单 -->
      <template v-slot:table-wrap v-if="row">
        <h2  style="margin:0px 20px;padding:20px 0;">管理员：{{row.adminUserName}}/{{row.adminUserAccount}}</h2>
        <ul class="toolbar" v-if="userInfo.currentMcRoleStatus==0&&currentRole!=roles.treasurer">
          <li ><el-button icon="el-icon-download" type="primary" @click="exportTable(exportObj1)">导出</el-button></li>
        </ul>
        <el-table
                    v-loading="isWorking.search"
                    :data="tableData.list"
                    row-key="id"
                    :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                    
                    stripe
                    @selection-change="handleSelectionChange"
                    @sort-change="handleSortChange"
                >
                    <el-table-column type="selection" width="55px"></el-table-column>

                    <el-table-column prop="adminUserAccount" label="账号" min-width="140px" align="center">
                        <div slot-scope="{row}">
                            <div v-if="row.isTransfer" style="width:22px;height:22px;border-radius:50%;color:red;border:1px solid red;font-size:12px;display:inline-flex;align-items:center;justify-content:center;padding-top:3px;">移</div>
                         <!-- <img v-if="row.historyShop" src="../../../assets/images/historyShop.png" alt="" srcset="" style="width:18px;height:16px;margin-right:2px;">  -->
                        {{row.adminUserAccount == null ? '-' : row.adminUserAccount}}
                        </div>
                    </el-table-column>
                    <el-table-column prop="adminUserName" label="管理员姓名" min-width="100px"  align="center" ></el-table-column>

                    <el-table-column prop="name" label="商家名称" min-width="220px" align="center" :show-overflow-tooltip="true">
                        <div slot-scope="{row}" class="position:relative">
                            <!-- <div style="position:absolute;top:1px;"> -->
                                <img v-if="row.isCoreUser" src="../../../assets/images/isCore.png" alt="" srcset="" style="width:18px;height:18px;margin-right:2px;">
                                <img v-if="row.historyShop" src="../../../assets/images/historyShop.png" alt="" srcset="" style="width:18px;height:16px;margin-right:4px;">
                                <img v-if="row.multiShop" src="../../../assets/images/multiShop.png" alt="" srcset=""  style="width:18px;height:18px;margin-right:4px;cursor:pointer;" @click="$refs.linkedShopListWindow.open(row)"> 
                                <img v-if="row.isPartTimeBusiness" src="../../../assets/images/isPartTime.png" alt="" srcset=""  style="width:18px;height:18px;margin-right:4px;">
                            <!-- </div> -->
                            <span >{{row.name == null ? '-' : row.name}}</span>
                        </div>
                    </el-table-column>
                    <el-table-column prop="createTime" label="新增时间" min-width="90px" align="center" ></el-table-column>
                    <el-table-column prop="saleUserName" label="所属销售" min-width="100px"  align="center" >
                        <div slot-scope="{row}">
                            <span>{{row.saleUserName || '待分配'}}</span>   
                        </div>
                    </el-table-column>
                    <el-table-column prop="saleUserAccount" label="销售电话" min-width="100px" align="center"  ></el-table-column>
                    <el-table-column prop="openPay" label="现金支付开关" min-width="100px" align="center"  >
                        <div slot-scope="{row,$index,store}">
                            <el-switch v-model="row.openPay"  :disabled="row.openPay || userInfo.currentMcRoleStatus==1 || currentRole==roles.treasurer" @change="updateStatus(row,$index,store)"></el-switch>
                        </div>
                    </el-table-column>
                    <el-table-column prop="payCode" label="收款码" min-width="100px" align="center"  >
                        <div slot-scope="{row}" style="display:flex;" v-if="row.openPay&&row.payCode">
                            <el-image 
                                style="width: 40px; height: 40px"
                                :src="row.payCode" 
                                :preview-src-list="[row.payCode]">
                            </el-image>
                            <div style="display:flex;flex-direction:column;margin-left:2px;cursor:pointer" @click="row.payStatus==1&&userInfo.currentMcRoleStatus==0?$refs.operaVerifyPayCodeWindow.open('收款码验证',row):''">
                                <i :class="row.payStatus==1?'el-icon-question':row.payStatus==2?'el-icon-check':row.payStatus==3?'el-icon-close':'el-icon-minus'" :style="{color:row.payStatus==1?'#F59A23':row.payStatus==2?'green':row.payStatus==3?'red':'','font-size':'28px'}" ></i>
                                <span>{{row.payStatus==1?'待验证':row.payStatus==2?'已验证':row.payStatus==3?'无效码':'待上传'}}</span>
                            </div>
                        </div>
                        <div v-else>-</div>
                        
                    </el-table-column>
                    <el-table-column prop="status" label="状态" min-width="80px" align="center"  >
                        <div slot-scope="{row}">
                            <span :style="{color:row.status==0?'green':row.status==1?'#F59A23':row.status==2?'red':''}" >{{row.status==0?'已启用':row.status==1?'已冻结':row.status==2?'已禁用':''}}</span>
                            
                            <!-- <i class="el-icon-edit" style="margin-left:4px;color:#2E68EC;" v-if="row.status!=null&&userInfo.currentMcRoleStatus==0" @click="$refs.operaUserStatusWindow.open('账户冻结',row,{businessRoleId:2,status:row.status==0?1:0})"></i> -->
                        </div>
                    </el-table-column>
                    <el-table-column prop="remarks" label="备注" min-width="100px" >
                        <div slot-scope="{row}">
                            <span>{{row.remarks || '/'}}</span>
                            <!-- <i class="el-icon-edit" style="margin-left:4px;color:#2E68EC;" @click="$refs.remarkUpdateWindow.open(row,0)"></i> -->
                        </div>
                    </el-table-column>
                    <el-table-column prop="address" label="地址" min-width="150px" :show-overflow-tooltip="true" align="center" >
                        <div slot-scope="{row}">{{(row.address?row.address:'')+(row.addressName?row.addressName:'')}}</div>
                    </el-table-column>
                    <el-table-column prop="areaName" label="业务区域" min-width="150px" :show-overflow-tooltip="true" align="center" >
                        <div slot-scope="{row}">
                            <span v-if="row.areaName">{{row.areaName}}</span>
                            <el-tag v-else type="danger">异常</el-tag>
                        </div>
                    </el-table-column>
                    <el-table-column
                    v-if="userInfo.currentMcRoleStatus==0&&currentRole!=roles.treasurer"
                    label="操作"
                    width="270"
                    fixed="right"
                    >
                    <div  slot-scope="{row}" >
                        <el-button  type="text" icon="el-icon-edit" @click="$refs.operaMerchantWindow.open('补充/修改信息',row,{historyShop:row.historyShop,type:2})">补充/修改信息</el-button>
                        <el-dropdown >
                            <el-button type="text" >
                                <i class=" el-icon-more"></i>更多操作
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="a" v-if="row.status==0"><el-button  type="text" icon="el-icon-moon" @click="$refs.operaUserStatusWindow.open('账户冻结',row,{businessRoleId:2,status:1})">冻结</el-button></el-dropdown-item>
                                <el-dropdown-item command="a"  v-if="row.status==1"><el-button  type="text" icon="el-icon-switch-button" style="color:green" @click="$refs.operaUserStatusWindow.open('账户解冻',row,{businessRoleId:2,status:0})">解冻</el-button></el-dropdown-item>
                                <el-dropdown-item command="a"><el-button  type="text" icon="el-icon-share" @click="$refs.operaShopAdminChangeWindow.open('移交账号',row,2)">账号移交</el-button></el-dropdown-item>
                                <el-dropdown-item command="a" v-if="row.isSelfAccount" ><el-button  type="text" icon="el-icon-view" @click="$refs.shopStaffsWindow.open(row.id,row.name)">查看子账号</el-button></el-dropdown-item>

                            </el-dropdown-menu>
                        </el-dropdown>
                        <el-button  type="text" icon="el-icon-notebook-2" @click="$refs.operaRecordWindow.open(2,row,1)">操作记录</el-button>
                    </div>
                    </el-table-column>
                </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
      <!-- 新建/修改 -->
      <OperaMerchantWindow ref="operaMerchantWindow" @success="handlePageChange(tableData.pagination.pageIndex)"/>
      <!-- 冻结解冻   -->
      <OperaUserStatusWindow ref="operaUserStatusWindow" @success="handlePageChange(tableData.pagination.pageIndex)"></OperaUserStatusWindow>
      <!-- 移交 -->
      <OperaShopAdminChangeWindow  ref="operaShopAdminChangeWindow" @success="handlePageChange(tableData.pagination.pageIndex)"></OperaShopAdminChangeWindow>
      <!-- 验证收款码 -->
      <OperaVerifyPayCodeWindow   ref="operaVerifyPayCodeWindow" width="400px" @success="handlePageChange(tableData.pagination.pageIndex)"></OperaVerifyPayCodeWindow>
      <!-- 操作记录 -->
      <OperaRecordWindow ref="operaRecordWindow"></OperaRecordWindow>
      <!-- 查看当前店铺子账号 -->
      <ShopStaffsWindow  ref="shopStaffsWindow"></ShopStaffsWindow>
      <!-- 邀约次数明细 -->
      <InviterListWindow ref="inviterListWindow"></InviterListWindow>
      <!-- 填写/更新备注 -->
      <RemarkUpdateWindow  ref="remarkUpdateWindow"  @success="handlePageChange(tableData.pagination.pageIndex)"></RemarkUpdateWindow>
      </template>
    </TableLayout>
  </GlobalWindow>
</template>

<script>
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import GlobalWindow from '@/components/common/GlobalWindow'
import Pagination from '@/components/common/Pagination'
import OperaMerchantWindow from '../accountManagement/OperaMerchantWindow.vue'
import OperaUserStatusWindow from '../accountManagement/OperaUserStatusWindow.vue'
import OperaShopAdminChangeWindow from '../accountManagement/OperaShopAdminChangeWindow.vue'
import OperaVerifyPayCodeWindow from '../accountManagement/OperaVerifyPayCodeWindow.vue'
import OperaRecordWindow from '../common/OperaRecordWindow.vue'
import ShopStaffsWindow from '../accountManagement/ShopStaffsWindow.vue'
import InviterListWindow from '../accountManagement/InviterListWindow.vue'
import RemarkUpdateWindow from '../common/RemarkUpdateWindow.vue'

import {updateById} from '../../../api/layun/accountManagement/shop'
import { mapState } from 'vuex'
export default {
  name: 'LinkedShopListWindow',
  extends: BaseTable,
   components: {  GlobalWindow , TableLayout, Pagination,OperaMerchantWindow,OperaUserStatusWindow ,  OperaShopAdminChangeWindow ,OperaVerifyPayCodeWindow , OperaRecordWindow , ShopStaffsWindow , InviterListWindow , RemarkUpdateWindow , },
  data () {
    return {
      row:null,
      visible: false,
      // 仅查看当前店铺
      onlyCurrentDept: true,
      // 搜索表单
      searchForm: {
        listType:1,
        shopAdmin:''
      },
      exportObj1:{
        tHeader:['账号','管理员姓名','商家名称','新增时间','所属销售','销售电话','现金支付','收款码','收款码状态','状态','备注','地址','业务区域','是否历史商家','是否关联商家','是否兼职商家'],
        filterVal:[
            {key:'adminUserAccount'},
            {key:'adminUserName',},
            {key:'name',},
            {key:'createTime',},
            {key:'saleUserName',},
            {key:'saleUserAccount',},
            {key:'openPay',type:2,item:[{value:true,label:'开'},{value:false,label:'关'}]},
            {key:'payCode',},
            {key:'payStatus',type:2,item:[{value:1,label:'待验证'},{value:2,label:'已验证'},{value:3,label:'无效码'}]},
            {key:'status',type:2,item:[{value:0,label:'已启用'},{value:1,label:'已冻结'},{value:2,label:'已禁用'},{value:3,label:''}]},
            {key:'remarks',},
            {key:'address',type:3,item:['address','addressName']},
            {key:'areaName', default:'异常'},
            {key:'historyShop',type:2,default:'否' ,item:[{value:true,label:'是'},{value:false,label:'否'}]},
            {key:'multiShop',type:2,default:'否' ,item:[{value:true,label:'是'},{value:false,label:'否'}]},
            {key:'isPartTimeBusiness',type:2,default:'否' ,item:[{value:true,label:'是'},{value:false,label:'否'}]},  
        ],
        fileName:'商家管理员列表',
      },
    }
  },
  computed:{
    ...mapState({
      userInfo:state=>state.init.userInfo || {},
      currentRole:state=>state.init.userInfo.currentRole,
      roles:state=>state.init.roles
    }),
  },
  methods: {
    // 打开查看人员窗口
    open (target) {
      this.row = target
      this.searchForm.shopAdmin = target.adminUserAccount
      this.visible = true
      // this.config({
      //   api: '/layun/contractManagement/signedManagement', 
      // })
      this.search()
    },
    updateStatus(row,index,store){
        console.log(row,index);
        this.tableData.list[index].openPay = false
        let form = {
        id: row.id,
        userName:row.adminUserName,
        account:row.adminUserAccount,
        userId:row.adminId,//是否是企业内部人员
        name:row.name,
        creditCode:row.creditCode,
        saleUserId:row.saleUserId,
        openPay:true,
        address:row.address,
        addressName:row.addressName,
        latitude:row.latitude,
        longitude:row.longitude,
        streetOfficeCode:row.streetOfficeCode,
        areaCode:row.areaCode,
        contractNo:row.contractNo,
        invitationUserId:'',
        historyShop:row.historyShop,
        shopType:row.shopType,
        sendInvitation:false,
      }
        

        this.$confirm('即将开启现金支付权限，一旦开启则不可关闭,是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
        this.isWorking.search = true
            updateById(form)
            .then(() => {
                this.visible = false
                this.$tip.apiSuccess('修改成功')
                this.handlePageChange(this.tableData.pagination.pageIndex)
            })
            .catch(e => {
                this.$tip.apiFailed(e,false)
            })
            .finally(() => {
                this.isWorking.search = false
                
            })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });       
        });
    },
    
  },
  created () {
    this.config({
      api: '/layun/accountManagement/shop',
    })
  },
}
</script>

<style scoped lang="scss">
.position-user-window {
  /deep/ .table-search-form {
    padding: 0;
  }
  /deep/ .window__body {
    background: #f7f7f7;
    .table-content {
      padding: 0;
      .table-wrap {
        padding: 0;
      }
    }
  }
  .toolbar {
    margin-top: 10px;
    padding: 6px 12px;
    background: #fff;
    font-size: 13px;
    label {
      margin-left: 8px;
      vertical-align: middle;
      color: #999;
    }
  }
  // 列表头像处理
  .table-column-avatar {
    img {
      width: 48px;
    }
  }
  // 列表角色处理
  .table-column-role {
    ul {
      li {
        display: inline-block;
        background: #eee;
        border-radius: 3px;
        padding: 0 3px;
        margin-right: 3px;
      }
    }
  }
}
</style>
