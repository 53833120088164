import request from '@/utils/request'

// 查询
export function fetchList (data) {
  return request.post('/basics/customerservice/page', data,)
}


// 修改
export function updateById (data) {
  return request.post('/basics/customerservice/updateById', data, {
    trim: true
  })
}

// 更新企业信息
export function updateShopInfo (data) {
  return request.post('/basics/platform/updateShopInfo', data,)
}

// 查询企业信息
export function getShopInfo (data) {
  return request.post('/basics/platform/getShopInfo', data,)
}