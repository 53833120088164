<template lang="">
    <div style="display:flex;align-items:center;flex-wrap:wrap">
       <div v-for="(item,index) in value" class="pictures">
            <!-- <img src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" alt="" srcset=""> -->
            <el-image class="imag" :style="{width:size+'px',height:'auto',maxHeight:size+'px'}"   :src="item"  :preview-src-list="value"></el-image>
            <i class="el-icon-close closeIcon" v-if="showDel" @click="remove(index)"></i>
        </div>
        <el-upload
            v-if="(!value || value.length!=limit)&&showAdd"
            style="border:1px solid #ccc;"
            :style="{width:size-2+'px',height:size-2+'px'}" 
            class="upload-demo"
            action=""
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :http-request="customUpload"
            v-loading="imgLoading"
            element-loading-spinner="el-icon-loading"
            :limit="limit"
            >
                <i class="el-icon-plus" :style="{fontSize:size/4+'px',padding:'100%',marginLeft:'-100%'}" ></i>
        </el-upload>
    </div>
</template>
<script>
import {uploadImage} from '../../../api/common'

export default {
    name: 'enclosure',
    props:{
        size:{
            type:Number,
            default:30
        },
        limit:{ //附件上传数量限制
            type:Number,
            default:5,
        },
        value:{
            type:Array,
            default:()=>{
                return []
            }
        },
        isRealDel:{ //移除是否真实删除（调接口的那种）默认否
            type:Boolean,
            default:false,
        },
        showDel:{ //是否展示删除按钮
            type:Boolean,
            default:true,
        },
        showAdd:{ //是否展示新增上传的按钮
            type:Boolean,
            default:true,
        }
    
    },
    data() {
      return {
        fileList: [{name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, {name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}],
        list:[
            'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100',
            'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
        ],
        imgLoading:false
      };
    },
    watch:{
        value:{
        handler(){
            this.imageUrl = ''
            if(this.value){
                this.imageUrl = this.value
            }
        },
        immediate:true
        }
    },
    methods: {
      handleAvatarSuccess(res, file) {
        // this.imageUrl = URL.createObjectURL(file.raw);
      },
      beforeAvatarUpload(file) {
        console.log(file,'上传的图片');
        // const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 5;
        const fileTypes = [".jpg", ".png","jpeg"];
        const filePath = file.name;
        //当括号里面的值为0、空字符、false 、null 、undefined的时候就相当于false
        if(filePath){
            var isNext = false;
            var fileEnd = filePath.substring(filePath.indexOf("."));
            for (var i = 0; i < fileTypes.length; i++) {
                if (fileTypes[i] == fileEnd) {
                    isNext = true;
                    break;
                }
            }
            if (!isNext){
                this.$message.error('图片后缀应为.jpg,.png');
                file.value = "";
                return false;
            }
        }else {
            this.$message.error('图片异常');
        }

        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 5MB!');
        }
        return filePath && isLt2M;
      },
      customUpload(option){

        let self = this
        let formData = new FormData();
        this.imgLoading = true
        formData.append('file',option.file);
        uploadImage(formData).then((res) => {
          console.log(res,'---图片')
          let path = this.value? [
            ...this.value,
            res
          ]:[res]
          this.$emit('input', path)
          this.imageUrl = path
        }).catch(e=>{
        console.log(e);
          this.$message.error('上传图片失败');
        }).finally(()=>{
          this.imgLoading = false

        })
      },
      remove(idx){
        if(this.isRealDel){
            return this.$emit('del', idx)
        }
        let path = this.value.filter((i,index)=>index!=idx)
        this.$emit('input', path)
      
      }
    }
    
}
</script>
<style lang="scss" scoped>
    .pictures{
        position:relative;
        display:inline-block;
        margin-right:10px;
        .image{
            width: 30px;
            height: 30px;
        }

        .closeIcon{
            position:absolute;
            top:-2px;
            right:-10px;
            cursor:pointer;
            &:hover{
                color:red
            }
        }
    }
    .upload-demo{
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
    .upload-demo /deep/.el-upload-list{
    display: none;
    }
</style>