<template>
<div style="padding:20px ">
    <el-breadcrumb separator="/" style="margin-bottom:20px">
        <el-breadcrumb-item v-for="(path,index) in paths" :key="index" >{{path}}</el-breadcrumb-item>
    </el-breadcrumb>

    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="商家结算总计列表" name="first" >
            <TableLayout  :withBreadcrumb="false" v-if="activeName=='first'">
                <!-- 搜索表单 -->
                <el-form ref="searchFormCur" slot="search-form" :model="searchFormCur" label-width="100px" inline>
                
                    <el-form-item label="商家" prop="shopSearch">
                        <el-input v-model="searchFormCur.shopSearch" v-trim placeholder="请输入商家名称/姓名/手机号" @keypress.enter.native="search" style="width:250px"/>
                    </el-form-item>
                    <el-form-item label="协议价格" >
                        <div>
                            <el-input v-model="searchFormCur.settPriceStart" type="number" v-trim placeholder="最小价格" @keypress.enter.native="search" style="width:100px" /> 至 
                            <el-input v-model="searchFormCur.settPriceEnd" type="number" v-trim placeholder="最大价格" @keypress.enter.native="search" style="width:100px"/>
                        </div>
                    </el-form-item>
                    <section>
                        <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
                        <el-button @click="reset">重置</el-button>
                    </section>
                </el-form>

                <template v-slot:table-wrap>
                <ul class="toolbar"   v-if="userInfo.currentMcRoleStatus==0">
                    <li ><el-button icon="el-icon-download" type="primary" @click="exportTable(exportObj1)">导出</el-button></li>
                </ul>
                <el-table
                    v-loading="isWorking.search"
                    :data="tableData.list"
                    stripe
                    @selection-change="handleSelectionChange"
                    @sort-change="handleSortChange"
                >
                    <el-table-column type="selection" width="55px" align="center"></el-table-column>
                    <el-table-column prop="shopName" label="商家名称" min-width="120px" align="center">
                        <el-button slot-scope="{row}" type="text"  @click="$refs.shopDetail.open(2,row)">{{row.shopName}}</el-button>
                    </el-table-column>
                    <el-table-column prop="adminId" label="负责人" min-width="100px" align="center">
                        <div slot-scope="{row}">{{row.adminName}}/{{row.adminAccount}}</div>
                    </el-table-column>
                    <el-table-column prop="settPrice" label="协议价格" min-width="100px" align="center">
                        <div slot-scope="{row}">{{row.settPrice?`${row.settPrice}元/千克`:'-'}}</div>
                    </el-table-column>
                    <el-table-column prop="settType" label="结算方式" min-width="100px" align="center">
                        <div slot-scope="{row}">{{row.settType==2?`周期结算(${row.settCycle || ''}天)`:row.settType==1?'现结':'-'}}</div>
                    </el-table-column>
                    <el-table-column prop="exWarehousingNum" label="出库结算总单数" min-width="100px" align="center">
                        <el-button slot-scope="{row}"  type="text" @click="orderNumDetail(row)">{{row.exWarehousingNum}}</el-button>

                    </el-table-column>
                    <el-table-column prop="exWarehousingTotalWeight" label="累计出库总重量" min-width="100px" align="center" sortable>
                        <span  slot-scope="{row}" type="text">{{row.exWarehousingTotalWeight || 0}}</span>
                    </el-table-column>
                    <el-table-column prop="payableSettTotalPrice" label="应结算总金额" min-width="100px" align="center">
                        <span  slot-scope="{row}" type="text">{{row.payableSettTotalPrice || 0}}</span>
                    </el-table-column>

                    <el-table-column prop="unSettTotalPrice" label="待结算金额" min-width="100px" align="center" sortable>
                        <span  slot-scope="{row}" type="text">{{row.unSettTotalPrice || 0}}</span>
                    </el-table-column>
                    <el-table-column prop="settTotalPrice" label="已结算金额" min-width="100px" align="center" sortable>
                        <span  slot-scope="{row}" type="text">{{row.settTotalPrice || 0}}</span>
                    </el-table-column>
                </el-table>
                <pagination
                    @size-change="handleSizeChange"
                    @current-change="handlePageChange"
                    :pagination="tableData.pagination"
                ></pagination>
                </template>
            </TableLayout>
        </el-tab-pane>
        <el-tab-pane label="出库结算明细列表" name="second">
            <TableLayout  :withBreadcrumb="false" v-if="activeName=='second'">
                <!-- 搜索表单 -->
                <el-form ref="searchFormCur" slot="search-form" :model="searchFormCur" label-width="100px" inline>
                    <el-form-item label="出库单编号" prop="orderNos">
                        <el-input v-model="searchFormCur.orderNos" v-trim placeholder="多个编号以','分开" @keypress.enter.native="search" style="width:250px"/>
                    </el-form-item>
                    <!-- <el-form-item label="商家管理员" prop="shopSearch">
                        <el-input v-model="searchFormCur.shopSearch" v-trim placeholder="请输入姓名、手机号" @keypress.enter.native="search" style="width:250px"/>
                    </el-form-item> -->
                    <el-form-item label="商家" prop="shopSearch">
                        <el-input v-model="searchFormCur.shopSearch" v-trim placeholder="请输入商家名称、姓名、手机号" @keypress.enter.native="search" style="width:250px"/>
                    </el-form-item>
                    
                    <el-form-item label="出库日期" prop="roleId">
                        <el-date-picker v-model="value2" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="dateChange($event,1)"
                            :picker-options="pickerOptions" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="出库状态" prop="status">
                        <el-select v-model="searchFormCur.status" placeholder="请选择" style="width:100px">
                            <el-option  key="-1" label="全部" :value="null"  ></el-option>
                            <el-option  key="0" label="待核验" :value="0"  ></el-option>
                            <el-option  key="2" label="已完成" :value="2"  ></el-option>
                            <el-option  key="4" label="已取消" :value="4"  ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="结算方式" prop="settType">
                        <el-select v-model="searchFormCur.settType" placeholder="请选择" style="width:100px">
                            <el-option  key="-1" label="全部" :value="null"  ></el-option>
                            <el-option  key="1" label="现结" :value="1"  ></el-option>
                            <el-option  key="2" label="周期结算" :value="2"  ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="结算状态" prop="settStatus">
                        <!-- 0已生成(结算中) 2已完成 5已作废 6异常结算待处理 -->
                        <el-select v-model="searchFormCur.settStatus" placeholder="请选择" style="width:100px">
                            <el-option  key="-1" label="全部" :value="null"  ></el-option>
                            <el-option  key="0" label="待结算" :value="0"  ></el-option>
                            <el-option  key="1" label="结算中" :value="1"  ></el-option>
                            <el-option  key="2" label="已结算" :value="2"  ></el-option>
                        </el-select>
                    </el-form-item>

                    <section>
                        <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
                        <el-button @click="reset">重置</el-button>
                    </section>
                </el-form>

                <template v-slot:table-wrap>
                <ul class="toolbar" v-if="userInfo.currentMcRoleStatus==0">
                    <li ><el-button icon="el-icon-plus" type="primary" @click="$refs.chooseNumbersWindow.open(2,null)">生成结算单</el-button></li>
                    <li ><el-button icon="el-icon-download" type="primary" @click="exportTable(exportObj2)">导出</el-button></li>
                </ul>
                <el-table
                    ref="table"
                    v-loading="isWorking.search"
                    :data="tableData.list"
                    stripe
                    @selection-change="handleSelectionChange"
                    @sort-change="handleSortChange"
                >
                    <el-table-column type="selection" width="55px"></el-table-column>

                    <el-table-column prop="orderNo" label="出库单编号" min-width="160px" align="center"></el-table-column>
                    <el-table-column prop="createTime" label="出库日期" min-width="140px" align="center"></el-table-column>

                    <el-table-column prop="shopId" label="商家名称" min-width="120px" align="center">
                        <el-button slot-scope="{row}" type="text"  @click="$refs.shopDetail.open(2,row)">{{row.shopName}}</el-button>
                    </el-table-column>
                    <el-table-column prop="adminUserId" label="商家负责人" min-width="140px" align="center">
                        <span slot-scope="{row}" type="text">{{row.adminUserName}}/{{row.adminUserAccount}}</span>
                    </el-table-column>
                    <el-table-column prop="settPrice" label="协议价格" min-width="100px" align="center">
                        <span slot-scope="{row}" type="text">{{row.settPrice?`${row.settPrice}元/千克`:'-'}}</span>
                    </el-table-column>
                    <el-table-column prop="settInitWeight" label="出库初始重量" min-width="100px" align="center">
                        <span slot-scope="{row}" type="text">{{row.settInitWeight?`${row.settInitWeight}/千克`:'-'}}</span>
                    </el-table-column>
                    <el-table-column prop="settVerificationWeight" label="核验重量" min-width="100px" align="center">
                        <span slot-scope="{row}" type="text">{{row.settVerificationWeight?`${row.settVerificationWeight}/千克`:'-'}}</span>
                    </el-table-column>
                    <el-table-column prop="status" label="出库状态" min-width="100px" align="center">
                        <span slot-scope="{row}" type="text">{{row.status==0?'待核验':row.status==2?'已完成':row.status==4?'已取消':''}}</span>
                    </el-table-column>
                    <el-table-column prop="settType" label="结算方式" min-width="140px" align="center">
                        <span slot-scope="{row}" type="text">{{row.settType==1?'现结':row.settType==2?`周期结算（${row.settCycle}天）`:'-'}}</span>

                    </el-table-column>
                    <el-table-column prop="settTotalPrice" label="出库结算金额" min-width="100px"  align="center"></el-table-column>
                    <el-table-column prop="settStatus" label="结算状态" min-width="100px" align="center">
                        <!-- 0已生成(结算中) 2已完成 5已作废 6异常结算待处理 -->
                        <span slot-scope="{row}" type="text">{{row.settStatus==0?'待结算':row.settStatus==1?'结算中':row.settStatus==2?'已结算':''}}</span>
                    </el-table-column>
                    <el-table-column prop="remark" label="备注" min-width="100px" align="center">
                        <div slot-scope="{row}">
                            <span>{{row.remark?row.remark:'/'}}</span>
                            <i class="el-icon-edit" v-if="userInfo.currentMcRoleStatus==0" style="margin-left:4px;color:#2E68EC;" @click="$refs.remarkUpdateWindow.open({id:row.exId},7)"></i>
                        </div>
                    </el-table-column>

                    <el-table-column
                    v-if="userInfo.currentMcRoleStatus==0"
                    label="操作"
                    width="270"
                    fixed="right"
                    >
                    <div  slot-scope="{row}">
                        <el-button  type="text" icon="el-icon-edit" @click="$refs.editSettlement.open(2,1,false,row)">编辑结算</el-button>
                        <el-button  type="text" icon="el-icon-edit" @click="$refs.statementWindow.open(2,true,[row])">生成结算单</el-button>
                        <el-button  type="text" icon="el-icon-notebook-2"  @click="$refs.operaRecordWindow.open(2,row,5)">操作记录</el-button>
                    </div>
                    </el-table-column>
                    <div slot="append" v-if="tableData.list.length>0">
                        <div class='sum_footer xiaoji' ref='sum_xiaoji'>
                            <div class='sum_footer_unit' v-for="item in 5"></div>
                            <div class='sum_footer_unit'>小计</div>
                            <div class='sum_footer_unit'>{{getXiaoji('settInitWeight')}}</div>
                            <div class='sum_footer_unit'>{{getXiaoji('settVerificationWeight')}}</div>
                            <div class='sum_footer_unit' v-for="item in 2"></div>
                            <div class='sum_footer_unit'>{{getXiaoji('settTotalPrice')}}</div>
                            <div class='sum_footer_unit' v-for="item in 3"></div>

                        </div>
                        <div class='sum_footer heji' ref='sum_heji'>
                            <div class='sum_footer_unit' v-for="item in 5"></div>
                            <div class='sum_footer_unit'>合计</div>
                            <div class='sum_footer_unit'>{{sumData2&&sumData2.settInitWeight}}</div>
                            <div class='sum_footer_unit'>{{sumData2&&sumData2.settVerificationWeight}}</div>
                            <div class='sum_footer_unit' v-for="item in 2"></div>
                            <div class='sum_footer_unit'>{{sumData2&&sumData2.settTotalPrice}}</div>
                            <div class='sum_footer_unit' v-for="item in 3"></div>
                        </div>
                    </div>
                </el-table>
                <pagination
                    @size-change="handleSizeChange"
                    @current-change="handlePageChange"
                    :pagination="tableData.pagination"
                ></pagination>
                </template>
            </TableLayout>
        </el-tab-pane>
        <el-tab-pane label="结算单列表" name="third">
            <TableLayout  :withBreadcrumb="false" v-if="activeName=='third'">
                <!-- 搜索表单 -->
                <el-form ref="searchFormCur" slot="search-form" :model="searchFormCur" label-width="100px" inline>
                    <el-form-item label="结算单号" prop="settNos">
                        <el-input v-model="searchFormCur.settNos" v-trim placeholder="多个单号以','隔开" @keypress.enter.native="search" style="width:250px"/>
                    </el-form-item>
                    <el-form-item label="出库单号" prop="exNos">
                        <el-input v-model="searchFormCur.exNos" v-trim placeholder="多个单号以','隔开" @keypress.enter.native="search" style="width:250px"/>
                    </el-form-item>
                    <el-form-item label="商家" prop="shopSearch">
                        <el-input v-model="searchFormCur.shopSearch" v-trim placeholder="请输入商家名称、姓名、手机号" @keypress.enter.native="search" style="width:250px"/>
                    </el-form-item>
                    <!-- <el-form-item label="商家负责人" prop="shopSearch">
                        <el-input v-model="searchFormCur.shopSearch" v-trim placeholder="请输入姓名、手机号" @keypress.enter.native="search" style="width:250px"/>
                    </el-form-item> -->
                    <el-form-item label="出库状态" prop="warehousingStatus">
                        <el-select v-model="searchFormCur.warehousingStatus" placeholder="请选择" style="width:100px">
                            <el-option  key="-1" label="全部" :value="null"  ></el-option>
                            <el-option  key="0" label="待核验" :value="0"  ></el-option>
                            <el-option  key="2" label="已完成" :value="2"  ></el-option>
                            <el-option  key="4" label="已取消" :value="4"  ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="结算方式" prop="settType">
                        <el-select v-model="searchFormCur.settType" placeholder="请选择" style="width:100px">
                            <el-option  key="-1" label="全部" :value="null"  ></el-option>
                            <el-option  key="1" label="现结" :value="1"  ></el-option>
                            <el-option  key="2" label="周期结算" :value="2"  ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="结算状态" prop="status">
                        <el-select v-model="searchFormCur.status" placeholder="请选择" style="width:100px">
                            
                            <el-option  key="-1" label="全部" :value="null"  ></el-option>
                            <el-option  key="0" label="结算中" :value="0"  ></el-option>
                            <el-option  key="1" label="已完成" :value="2"  ></el-option>
                            <el-option  key="2" label="已作废" :value="5"  ></el-option>
                            <el-option  key="2" label="异常结算待处理" :value="6"  ></el-option>

                        </el-select>
                    </el-form-item>
                    <el-form-item label="结算生成时间" >
                        <el-date-picker v-model="value1" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="dateChange($event,2)"
                            :picker-options="pickerOptions" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="结算完成时间" >
                        <el-date-picker v-model="value2" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="dateChange($event,3)"
                            :picker-options="pickerOptions" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-form-item>

                    <section>
                        <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
                        <el-button @click="reset">重置</el-button>
                    </section>
                </el-form>

                <template v-slot:table-wrap>
                <ul class="toolbar"  v-if="userInfo.currentMcRoleStatus==0">
                    <li ><el-button icon="el-icon-download" type="primary" @click="exportTable(exportObj3)">导出</el-button></li>
                </ul>
                <el-table
                    ref="table"
                    v-loading="isWorking.search"
                    :data="tableData.list"
                    stripe
                    @selection-change="handleSelectionChange"
                    @sort-change="handleSortChange"
                >
                    <el-table-column type="selection" width="55px"></el-table-column>

                    <el-table-column prop="orderNo" label="结算编号" min-width="180px" align="center"></el-table-column>
                    <el-table-column prop="createTime" label="生成结算单时间" min-width="140px" align="center"></el-table-column>
                    <el-table-column prop="torderNos" label="出库单" min-width="166px" align="center">
                        <div slot-scope="{row}">
                            <div v-if="row.torderNos.length<=2">
                                {{row.torderNos.join(',')}}
                            </div>
                            <el-popover v-else placement="top-start" width="200" trigger="hover">
                                <div v-for="i in row.torderNos">
                                    {{i}}
                                </div>
                                <div type="text" slot="reference">
                                    <span>{{row.torderNos.filter((i,index)=>index<=1).join(',')}}</span>,
                                    <span>...</span>
                                </div>
                            </el-popover>    
                        </div>
                    </el-table-column>


                    <el-table-column prop="shopName" label="商家名称" min-width="120px" align="center">
                        <el-button slot-scope="{row}" type="text"  @click="$refs.shopDetail.open(2,row)">{{row.shopName}}</el-button>
                    </el-table-column>
                    <el-table-column prop="adminName" label="商家负责人" min-width="160px" align="center">
                        <div slot-scope="{row}">{{row.adminName}}/{{row.adminAccount}}</div>
                    </el-table-column>
                    <el-table-column prop="settType" label="结算方式" min-width="100px"  align="center">
                        <div slot-scope="{row}">{{row.settType==2?`周期结算`:row.settType==1?'现结':'-'}}</div>
                    </el-table-column>
                    <el-table-column prop="warehousingStatus" label="出库状态" min-width="100px"  align="center">
                        <span slot-scope="{row}" type="text">{{row.warehousingStatus==0?'待核验':row.warehousingStatus==2?'已完成':row.warehousingStatus==4?'已取消':'-'}}</span>
                    </el-table-column>


                    <el-table-column prop="settTotalPrice" label="结算单总金额" min-width="100px" align="center"></el-table-column>
                    <el-table-column prop="status" label="结算状态" min-width="100px" align="center">
                        
                        <span slot-scope="{row}" type="text">{{row.status==0?'结算中':row.status==2?'已完成':row.status==5?'已作废':row.status==6?'异常结算待处理':''}}</span>
                    </el-table-column>
                    <el-table-column prop="successTime" label="完成结算时间" min-width="140px" align="center"></el-table-column>
                    <el-table-column prop="enclosures" label="附件" min-width="100px" >
                        <Enclosure  slot-scope="{row}" v-model="row.enclosures" :showAdd="userInfo.currentMcRoleStatus==0" :showDel="userInfo.currentMcRoleStatus==0"  @input="updateEnclosure(row)"></Enclosure>
                    </el-table-column>
                    <el-table-column prop="remark" label="备注" min-width="100px" align="center">
                        <div slot-scope="{row}">
                            <span>{{row.remark?row.remark:'/'}}</span>
                            <i class="el-icon-edit" v-if="userInfo.currentMcRoleStatus==0" style="margin-left:4px;color:#2E68EC;" @click="$refs.remarkUpdateWindow.open({id:row.id},8)"></i>
                        </div>
                    </el-table-column>

                    <el-table-column
                    v-if="userInfo.currentMcRoleStatus==0"
                    label="操作"
                    width="270"
                    fixed="right"
                    >
                    <div  slot-scope="{row}" >
                        <el-button  type="text" icon="el-icon-s-finance"  @click="$refs.editSettlement.open(2,2,false,row)">编辑结算</el-button>
                        <el-button  type="text" icon="el-icon-edit"  @click="$refs.statementWindow.open(2,false,row)">完成结算</el-button>
                        <el-button  type="text" icon="el-icon-edit" @click="VoidOrder(row)">作废</el-button>
                        <el-button  type="text" icon="el-icon-notebook-2"  @click="$refs.operaRecordWindow.open(2,row,9)">操作记录</el-button>
                    </div>
                    </el-table-column>
                    <div slot="append" v-if="tableData.list.length>0">
                        <div class='sum_footer xiaoji' ref='sum_xiaoji'>
                            <div class='sum_footer_unit' v-for="item in 7"></div>
                            <div class='sum_footer_unit'>小计</div>
                            <div class='sum_footer_unit'>{{getXiaoji('settTotalPrice')}}</div>
                            <div class='sum_footer_unit' v-for="item in 5"></div>
                        </div>
                        <div class='sum_footer heji' ref='sum_heji'>
                            <div class='sum_footer_unit' v-for="item in 7"></div>
                            <div class='sum_footer_unit'>合计</div>
                            <div class='sum_footer_unit'>{{sumData3&&sumData3.settTotalPrice}}</div>
                            <div class='sum_footer_unit' v-for="item in 5"></div>
                        </div>
                    </div>

                </el-table>
                <pagination
                    @size-change="handleSizeChange"
                    @current-change="handlePageChange"
                    :pagination="tableData.pagination"
                ></pagination>
                </template>
            </TableLayout>
        </el-tab-pane>
    </el-tabs>

    <!-- 拉运总单数明细 -->
    <el-dialog :width="'240px'"  :visible.sync="orderNumDetailVisible" v-if="orderNumDetailData" @close="orderNumDetailData=null;orderNumDetailVisible=false">
        <h3 >拉运单总数：{{orderNumDetailData.row.transportNum}}</h3>
        
        <div class="line"><span>未结算拉运单数</span><el-link type="primary" @click="pageTurn(-1)">{{orderNumDetailData.unSettNum}}</el-link></div>
        <div class="line"><span>待结算拉运单数</span><el-link type="primary" @click="pageTurn(0)">{{orderNumDetailData.staySettNum}}</el-link></div>
        <div class="line"><span>结算中拉运单数</span><el-link type="primary" @click="pageTurn(1)">{{orderNumDetailData.inSettNum}}</el-link></div>
        <div class="line"><span>已结算拉运单数</span><el-link type="primary" @click="pageTurn(2)">{{orderNumDetailData.settNum}}</el-link></div>
    </el-dialog>
    <!-- 选择单号并生成结算单（拉运结算、出库结算） -->
    <ChooseNumbersWindow types='1' ref="chooseNumbersWindow" @success="(value)=>{this.$refs.statementWindow.open(2,true,value)}"></ChooseNumbersWindow>
    <!-- 编辑、完成 结算单 -->
    <StatementWindow ref="statementWindow" @success="handlePageChange(tableData.pagination.pageIndex)"></StatementWindow>
    <!-- 签约商家详情、出库商家详情 -->
    <ShopDetail  ref="shopDetail" ></ShopDetail>
    <!-- 编辑结算 拉运、出库 -->
    <EditSettlement  ref="editSettlement" @success="handlePageChange(tableData.pagination.pageIndex)"></EditSettlement>
    <!-- 结算异常 -->
    <SettlementExceptionWindow  ref="settlementExceptionWindow" @success="handlePageChange(tableData.pagination.pageIndex)"></SettlementExceptionWindow>
    <!-- 备注 -->
    <RemarkUpdateWindow ref="remarkUpdateWindow" @success="handlePageChange(tableData.pagination.pageIndex)"></RemarkUpdateWindow>
    <!-- 操作记录 -->
    <OperaRecordWindow ref="operaRecordWindow"></OperaRecordWindow>

</div>

  
</template>

<script>
import Pagination from '@/components/common/Pagination'
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'

import ShopDetail from '../../../components/layun/financialManagement/ShopDetail.vue'
import ChooseNumbersWindow from '../../../components/layun/financialManagement/ChooseNumbersWindow.vue'
import StatementWindow from '../../../components/layun/financialManagement/StatementWindow.vue'
import Enclosure from '../../../components/layun/common/Enclosure.vue'
import RemarkUpdateWindow from '../../../components/layun/common/RemarkUpdateWindow.vue'
import EditSettlement from '../../../components/layun/financialManagement/EditSettlement.vue'
import SettlementExceptionWindow from '../../../components/layun/financialManagement/SettlementExceptionWindow.vue'
import OperaRecordWindow from '../../../components/layun/common/OperaRecordWindow.vue'

import {VoidOrder ,fetchList, fetchAllList ,orderNumDetail , uploadEnclosures ,} from '../../../api/layun/financialManagement/warehousingSettlement'
import { mapState } from 'vuex'


const filter1 = {
    listType:1,// 1商家结算总计列表 2 出库结算明细列表 3结算单列表
    settPriceStart: null,
    settPriceEnd:null,
    shopSearch: null,// 商家筛选 名称 姓名 手机号  缺 商家名称的筛选
    sort: "", // 1倒序 2正序
    sortType: 0, // 0默认 1拉运结算总单数 2累计拉运总重量 3应结算总金额 4待结算金额 5已结算金额
     
}
const filter2 = {
    listType:2,
    sort:1,
    exTimeEnd: "", // 出库日期
    exTimeStart: "",
    isBatchSett: null, //是否批量生成出库结算单
    orderNos: null,//出库单编号
    settStatus: null,//结算状态 0待结算 1结算中 2已结算
    settType: null,//结算方式 1现结 2周期
    shopSearch: "",
    status: null,//0待核验 2已完成 4已取消
      
}
const filter3 = {
    listType:3,
    exNos: null,//出库单号
    settNos: null,//结算单号
    settSuccessTimeEnd: "", //结算完成时间
    settSuccessTimeStart: "",
    settTimeEnd: "", // 结算生成时间
    settTimeStart: "",
    settType: null, //结算方式 1现结 2周期
    shopSearch: "",
    status: null, //结算状态
    warehousingStatus: null,//出库状态
      
}
const exportObj1 = {
        fileName:'商家结算总计列表（出库）',
        tHeader:['商家名称','负责人','协议价格','结算方式','结算周期','出库结算总单数','累计出库总重量(kg)','应结算总金额（￥）','待结算金额（￥）','已结算金额（￥）'],
        filterVal:['shopName','adminId','settPrice','settType','settCycle','exWarehousingNum','exWarehousingTotalWeight','payableSettTotalPrice','unSettTotalPrice','settTotalPrice',],  
      }
 const exportObj2={
        fileName:'出库结算明细列表',
        tHeader:['出库单编号','出库日期','商家名称','商家负责人','协议价格','出库初始重量','核验重量','出库状态','结算方式','出库结算金额','结算状态','备注'],
        filterVal:['orderNo','createTime','shopName','adminUserId','settPrice','settInitWeight','settVerificationWeight','status','settType','settTotalPrice','settStatus','remark'],
      }
const exportObj3={
       fileName:'结算单列表（出库）',
        tHeader:['结算编号','生成结算时间','出库单','商家名称','商家管理员','结算方式','出库状态','结算单总金额','结算状态','完成结算时间','附件','备注'],
        filterVal:['orderNo','createTime','torderNos','shopName','adminId','settType','出库状态','settTotalPrice','status','successTime','enclosures','remark'],
      }

export default {
  name: 'OutputSettlement',
  extends: BaseTable,
  components: { TableLayout, Pagination , ShopDetail , ChooseNumbersWindow , StatementWindow , Enclosure , RemarkUpdateWindow , EditSettlement , SettlementExceptionWindow , OperaRecordWindow , },
  computed: {
    paths () {
      return this.$route.meta.paths
    },
    ...mapState({
      userInfo:state=>state.init.userInfo || {},
      currentRole:state=>state.init.userInfo.currentRole,
      roles:state=>state.init.roles
    }),
  },
  data () {
    return {
        activeName: 'first',
      // 搜索
      searchFormCur: {
        ...filter1
      },
      searchForm: {
        ...filter1
      },
      orderNumDetailVisible:false,
      orderNumDetailData:null,
    }
  },
  watch:{
    'searchFormCur.roleId':{
      handler(val,old){
        if(val!=old){
          this.search()
        }
      }
    }
  },
  created () {
    this.config({
      module: '单号',
      api: '/layun/financialManagement/warehousingSettlement',
      sorts: []
    })
    this.search()
  },
  mounted(){
    this.adjustWidth()
    window.addEventListener('resize', this.adjustWidth.bind(this))
  },
  methods:{
  search(){
        let settPriceStart = this.searchFormCur.settPriceStart
        let settPriceEnd = this.searchFormCur.settPriceEnd
        let priceStart = Number(settPriceStart)&&Number(settPriceEnd)?Number(settPriceStart)>Number(settPriceEnd)?Number(settPriceEnd):Number(settPriceStart):settPriceStart
        let priceEnd = Number(settPriceStart)&&Number(settPriceEnd)?Number(settPriceStart)>Number(settPriceEnd)?Number(settPriceStart):Number(settPriceStart):settPriceEnd
        this.searchFormCur.settPriceStart = priceStart
        this.searchFormCur.settPriceEnd = priceEnd

        let form = {
            ...this.searchFormCur,
            settPriceStart:priceStart,
            settPriceEnd:priceEnd,
        }
        console.log(form);
        for(let key in form){
            let arr = ['orderNos','settNos','exNos'];//将这些参数改成数组
            let idx = arr.findIndex(i=>i==key)
            console.log(idx,'-');
            if(idx!=-1){
                let Nos = form[arr[idx]]?form[arr[idx]].split('，').join(',').split(','):null;
                form[arr[idx]] = Nos || null
            }
        }
        this.searchForm = form
        this.handlePageChange(1)
        if(form.listType!=1){
            this.pageSum();
        }
        
    },
    pageSum(){ //总计
        this.sumData2 = null
        this.sumData3 = null
        fetchAllList({
            model:{...this.searchForm,listType:null},
            listType:this.searchForm.listType
        }).then((res) => {
            this['sumData'+this.searchForm.listType] = res
        }).catch(() => {  
            this.$message.error('总计查询失败')   
        }).finally(()=>{
        });

    
    },
    // 排序
    handleSortChange (sortData) {
      this.tableData.sorts = []
      console.log('sortData.prop',sortData.prop);
        //   sort 邀约金排序 1倒叙 2正序
      	//sortType 0默认 1拉运结算总单数 2累计拉运总重量 3应结算总金额 4待结算金额 5已结算金额
        let arr = [
            {value:0,prop:'',tit:'默认'},
            {value:1,prop:'transportNum',tit:'拉运结算总单数'},
            {value:2,prop:'totalWeight',tit:'累计拉运总重量'},
            {value:3,prop:'payableSettTotalPrice',tit:'应结算总金额'},
            {value:4,prop:'unSettTotalPrice',tit:'待结算金额'},
            {value:5,prop:'settTotalPrice',tit:'已结算金额'},
        ]
      let param = {
        sort:1,
        sortType:0,
      }
      if (sortData.order != null) {
          param.sort = sortData.order === 'descending' ? 1 : 2
          let index =  arr.findIndex(i=>i.prop==sortData.prop)
          param.sortType = index==-1?0:arr[index].value
          this.searchFormCur = {
            ...this.searchFormCur,
            ...param
          }
      }
      this.handlePageChange()
    },
    handleClick(tab, event) {
        console.log(tab.name, this.activeName);
        this.searchFormCur.listType = 1;
        this.resetForm(this.activeName=='first'?1:this.activeName=='second'?2:3)
        this.resetTable();
        this.search()
    },
    resetForm(type){
        let filter = type==1?filter1:type==2?filter2:filter3
        this.searchFormCur = JSON.parse(JSON.stringify(filter))
        this.searchFormCur.listType = type
        this.value2 = ''
        this.value1 = ''
    },
    reset(){
        this.resetForm(this.activeName=='first'?1:this.activeName=='second'?2:3)
        this.search()
    },
    resetTable(){
        this.tableData={
            selectedRows: [],
            sorts:[],
            list:[],
            pagination: {
                pageIndex: 1,
                pageSize: 10,
                total: 0
            }
        }
        this.isWorking= {
            search: false,
            delete: false,
            export: false
        }

    },
    dateChange(date,type){
        
        if(type==1){ //出库日期
            this.searchFormCur.exTimeStart = date?date[0]:null
            this.searchFormCur.exTimeEnd = date?date[1]:null
        }else if(type==2){ //结算生成时间
            this.searchFormCur.settTimeStart = date?date[0]:null
            this.searchFormCur.settTimeEnd = date?date[1]:null
        }else if(type==3){ //结算完成时间
            this.searchFormCur.settSuccessTimeStart = date?date[0]:null
            this.searchFormCur.settSuccessTimeEnd = date?date[1]:null
        }
    },
    //作废拉运单
    VoidOrder(row){
        this.$confirm(`是否作废当前拉运结算单?`, '提示', {
            confirmButtonText: '作废',
            cancelButtonText: '取消',
            type: 'warning',
            beforeClose: (action, instance, done) => {
                if (action === 'confirm') {
                instance.confirmButtonLoading = true;
                instance.confirmButtonText = '执行中...';
                VoidOrder({settId:row.id}).then(() => {
                    done();
                    this.search()
                }).catch(() => {  
                    this.$message.error('作废失败')   
                    instance.confirmButtonLoading = false;
                    instance.confirmButtonText = '作废';
                }).finally(()=>{
                    instance.confirmButtonLoading = false;
                });
                
                } else {
                    done();
                }
            }
          }).then(() => {
             
            
          }).catch(() => {     
            
          }); 
    
    
    },
    orderNumDetail(row){
        console.log(row);
        this.orderNumDetailVisible = true
        orderNumDetail({shopId:row.shopId}).then((res) => {
            this.orderNumDetailData = res
            this.orderNumDetailData.row = row
        }).catch(() => {  
            this.$message.error('单数详情获取失败')   
        }).finally(()=>{
        });
    
    },
    pageTurn(status){
    
        this.activeName = 'second'
        this.searchFormCur.listType = 2;
        this.resetForm(this.activeName=='first'?1:this.activeName=='second'?2:3)
        this.resetTable();
        this.searchFormCur.shopId = this.orderNumDetailData.row.shopId
        this.searchFormCur.shopSearch = this.orderNumDetailData.row.shopName

        this.searchFormCur.settStatus = status
        this.orderNumDetailVisible = false
        this.orderNumDetailData = null
        this.search()
    },
    //更新附件
   updateEnclosure(row){
        console.log(row,'--');
        console.log({
            enclosures:row.enclosures,
            remark:row.remark,
            settId:row.id,
            
        })
        
        this.isWorking.search = true   
        uploadEnclosures({
            enclosures:row.enclosures || [],
            remark:row.remark,
            settId:row.id,
            
        }).then(res=>{
        
        }).catch(e=>{
            console.log(e);
            this.$tip.apiFailed(e,false)
        }).finally((res)=>{
            this.isWorking.search = false   
        })
   
   
   },
   exportTable(obj) {
      if (this.tableData.selectedRows.length == 0) {
        this.$message.warning("至少选择一条");
        return;
      }
      import("@/vendor/Export2Excel").then((excel) => {
        const data = this.formatData(obj.filterVal,this.tableData.selectedRows)
        excel.export_json_to_excel({
          header: obj.tHeader,
          data,
          filename: (obj.fileName || '表格'),
          autoWidth: true,
          bookType: "xlsx",
        });
      });
    },
    //格式化数据
    formatData(filterVal,list){
      return list.map((v) =>
        filterVal.map((j) => {
          console.log(j,'___',v[j]);
          switch (j) {
            case "settPrice":
                return v[j]?`${v[j]}元/千克`:'';
            case "settType":
                return v[j]==1?'现结':v[j]==2?'周期结算':'';
            case "settCycle":
                return v.settType==2&&v[j]?`${v[j]}天`:'';
            case "adminUserId":
                return `${v.adminUserName}/${v.adminUserAccount}`;

            case "driverInfos":
                let value = []
                if(v[j]&&v[j].length>0){
                    v[j].map(i=>{
                        value.push(`${i.driverUserName}/${i.driverUserAccount}`)
                    })    
                }
                return value.join(',');
            case "status":
                let valueStatus = '';
                if(this.activeName=='second'){ // 出库结算明细列表  出库状态
                    // let idx = this.lyStatus.findIndex(i=>i.status&&i.status==v[j])
                    // valueStatus = v[j]!=null&&idx!=-1?this.lyStatus[idx].label:''
                    valueStatus = v[j]==0?'待核验':v[j]==2?'已完成':v[j]==4?'已取消':''
                }
                if(this.activeName=='third'){ // 结算单列表  结算状态？？？
                    valueStatus = v[j]==0?'已生成(结算中)':v[j]==2?'已完成':v[j]==5?'已作废':v[j]==6?'异常结算待处理':v[j]==7?'异常处理完成':''
                }
                return valueStatus;
            case "settStatus":
                let idx = this.lySettleStatus.findIndex(i=>i.status&&i.status==v[j])
                return v[j]!=null&&idx!=-1?this.lySettleStatus[idx].label:'';
            case "abnormalSett":
                return v[j]?'是':'否';
            case "adminId":
                return `${v.adminName}/${v.adminAccount}`;
            case "tstatus":
                let tstatusIdx = this.lyStatus.findIndex(i=>i.status&&i.status==v[j])
                return v[j]!=null&&tstatusIdx!=-1?this.lyStatus[tstatusIdx].label:'';
            default:
              return v[j] || "/";
          }
          
          
        })
      );
    
    },
    
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";
// 列表头像处理
.table-column-avatar {
  img {
    width: 48px;
  }
}
</style>
