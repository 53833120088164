import Vue from 'vue'
import VueRouter from 'vue-router'
import AppLayout from '@/layouts/AppLayout'
import { getUserInfo } from '@/api/system/common'
import { fetchAll } from '@/api/system/role'
const Login = () => import('@/views/login')
const ForgotPassword = () => import('@/views/forgotPassword')
const WechatLoading = () => import('@/views/wechatLoading')
const WechatBinding = () => import('@/views/wechatBinding')
const ErrorNoPermissions = () => import('@/views/no-permissions')
const Error404 = () => import('@/views/not-found')


Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  routes: [
    // 登录
    {
      name: 'login',
      path: '/login',
      component: Login
    },
    {
      name:'forgotPassword',
      path:'/forgotPassword',
      component:ForgotPassword
    },

    {
      name: 'loading',
      path: '/loading',
      component: WechatLoading
    },
    {
      name: 'binding',
      path: '/binding',
      component: WechatBinding
    },
    // {
    //   name: 'test2',
    //   path: '/test2',
    //   component: test2
    // },
    // 无权限
    {
      name: 'no-permissions',
      path: '/no-permissions',
      component: ErrorNoPermissions
    },
    // 404
    {
      name: 'not-found',
      path: '/not-found',
      component: Error404
    },
    // 内容页（动态加载）
    {
      name: 'layout',
      path: '',
      component: AppLayout,
      children: []
    }
  ]
})
router.beforeEach((to, from, next) => {
  // 无权访问&404页面可直接访问  登录页也可直接访问
  if (to.name === 'no-permissions' || to.name === 'not-found'  || to.name === 'forgotPassword' || to.name === 'login' || to.name === 'loading') {
    next()
    return
  }
  let userInfo = router.app.$options.store.state.init.userInfo

  // console.log(router.app.$options.store.state.userInfo,'登陆人信息',userInfo,router.app);
  if (userInfo != null) {
    // 如果用户不存在权限
    if (userInfo.permissions.length === 0) {
      next({ name: 'no-permissions' })
      return
    }
    next()
    return
  }

  // 非登录页面，且无用户信息
  getUserInfo()
  .then(userInfo => {
    // 如果用户不存在权限
    if (userInfo.permissions.length === 0) {
      next({ name: 'no-permissions' })
      return
    }
    // 已登录，存储userInfo
    
    router.app.$store.commit('setUserInfo', userInfo)
    next()
  })
  .catch(e => {
    // 未登录，跳转至登录页
    // next()
    next({ name: 'login' })
  })

  fetchAll().then(records => {
    console.log(records,'用户角色');
    router.app.$store.commit('setRoles', records)
  })
  .catch(e => {
    this.$tip.apiFailed(e)
  })
})

export default router
