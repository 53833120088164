<template>
  <GlobalWindow
    class="handle-table-dialog"
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="商家名称" prop="businessName" required>
        <el-input v-model="form.businessName" placeholder="请输入商家名称" v-trim maxlength="50"/>
      </el-form-item>
      <el-form-item label="负责人" prop="chargePeopleName" required>
        <el-input v-model="form.chargePeopleName" placeholder="请输入负责人姓名" v-trim maxlength="50"/>
      </el-form-item>
      <el-form-item label="负责人手机" prop="chargePeoplePhone" required>
        <el-input v-model="form.chargePeoplePhone" placeholder="请输入手机号" v-trim maxlength="50"/>
      </el-form-item>
      <el-form-item label="地址" prop="outboundMerchantAddress" required>
        <TMapSelect  v-if="visible"   v-model="form.outboundMerchantAddress" :latitude="form.latitude" :longitude="form.longitude" :isAdd="form.id==null?true:false"  @change="addressChange" @blur="addressChange"></TMapSelect>

      </el-form-item>
      <el-form-item label="结算方式" prop="settlementMethod" >
        <el-select  class="fixWidth" v-model="form.settlementMethod" clearable @change="methodChange" >
          <el-option :key='0' :value="1" label="定期结算"/>
          <el-option :key='1' :value="2" label="现结"/>
        </el-select>
      </el-form-item>
      <el-form-item label="结算周期" prop="settlementPeriod" v-if="form.settlementMethod==1">
        <el-input v-model="form.settlementPeriod" type="number"  placeholder="请输入结算周期(天)" v-trim maxlength="50" >
          <template slot="append">天</template>
        </el-input>
      </el-form-item>
      <el-form-item label="协议价格" prop="agreementPrice" >
        <!-- <el-input-number v-model="num" :precision="2" :step="0.1"  placeholder="请输入价格"></el-input-number> -->
        <el-input v-model="form.agreementPrice" type="number"  placeholder="请输入价格" v-trim maxlength="50" :min="0">
          <template slot="append">元/千克</template>
        </el-input>
      </el-form-item>
      
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import TMapSelect from '../../../components/layun/common/TMapSelect.vue'
import { checkMobile,} from '@/utils/form'
export default {
  name: 'OperaMenuWindow',
  extends: BaseOpera,
  components: {  GlobalWindow,TMapSelect },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        businessName:'',
        chargePeopleName:'',
        chargePeoplePhone:'',
        latitude:0,
        longitude:0,
        outboundMerchantAddress:'',
        settlementMethod:'',//结算方式 1定期结算 2现结
        settlementPeriod:'',//结算周期
        agreementPrice:'',
      },
      // 验证规则
      rules: {
        
        businessName: [
          { required: true, message: '请输入商家名称' },
        ],
        chargePeopleName: [
          { required: true, message: '请输入负责人名称' },
        ],
        chargePeoplePhone: [
          { required: true, message: '请输入负责人电话' },
          { validator: checkMobile,trigger: 'blur' }
        ],

        outboundMerchantAddress: [
          { required: true, message: '请输入出库商家地址' },
        ],
        // settlementMethod: [
        //   { required: true, message: '请选择结算方式' },
        // ],
        // settlementPeriod: [
        //   { required: true, message: '请输入结算周期' },
        // ],
        // agreementPrice: [
        //   { required: true, message: '请输入协议价格/千克' },
        // ],
      }
    }
  },
  methods: {
    /**
     * @title: 窗口标题
     * @target: 编辑的菜单对象
     * @parent: 新建时的上级菜单
     */
    open (title, target, parent) {
      this.title = title
      this.visible = true
      // 新建，menu为空时表示新建菜单
      if (target == null) {
        // this.excludeMenuId = null
        this.$nextTick(() => {
          this.$refs.form.resetFields()
          this.form.id = null
        })
        return
      }
      // 编辑
      this.$nextTick(() => {
        // this.excludeMenuId = target.id
        for (const key in this.form) {
          this.form[key] = target[key]
        }
      })
    },
    addressChange(value) {
      console.log("地图选点", value);
      this.form.outboundMerchantAddress = value.address
      if(value.addressComponents &&value.addressIds){
        this.form.provinceId = value.addressIds.provinceId
        this.form.cityId = value.addressIds.cityId
        this.form.districtId = value.addressIds.districtId
        this.form.provinceName = value.addressIds.provinceName
        this.form.cityName = value.addressIds.cityName
        this.form.districtName = value.addressIds.districtName
      }
      if(value.addrPoint){
        this.form.latitude = value.addrPoint.lat
        this.form.longitude = value.addrPoint.lng
      }
    },
    methodChange(value){
      if(value==2){
        this.form.settlementPeriod = null;
      }
    }
  },
  created () {
    this.config({
      api: '/layun/basicDataManagement/outboundMerchant'
    })
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables";
$icon-background-color: $primary-color;
.global-window {
  .tip {
    margin-bottom: 12px;
    em {
      font-style: normal;
      color: $primary-color;
      font-weight: bold;
    }
  }
  // 图标
  /deep/ .form-item-icon {
    .el-form-item__content {
      height: 193px;
      overflow-y: auto;
    }
    .el-radio-group {
      background: $icon-background-color;
      padding: 4px;
      .el-radio {
        margin-right: 0;
        color: #fff;
        padding: 6px;
        &.is-checked {
          background: $icon-background-color - 30;
          border-radius: 10px;
        }
        .el-radio__input.is-checked + .el-radio__label {
          color: #fff;
        }
      }
      .el-radio__input {
        display: none;
      }
      .el-radio__label {
        padding-left: 0;
        // element-ui图标
        i {
          font-size: 30px;
        }
        // 自定义图标
        [class^="eva-icon-"], [class*=" eva-icon-"] {
          width: 30px;
          height: 30px;
          background-size: 25px;
          vertical-align: bottom;
        }
      }
      .el-radio--small {
        height: auto;
        padding: 8px;
        margin-left: 0;
      }
    }
  }
}
</style>
