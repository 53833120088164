<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="上级部门" v-if='form.type==1'>
        <div> {{form.parentName}}</div>
        
        <!-- <DepartmentSelect v-if="visible" ref="departmentSelect" placeholder="请选择上级部门" v-model="form.parentId"  :exclude-id="excludeDeptId" :inline="false"/> -->
      </el-form-item>
      <el-form-item :label="form.type==1?'部门编码':'公司编码'" prop="code" required>
        <el-input v-model="form.code" placeholder="请输入部门编码" v-trim maxlength="50"/>
      </el-form-item>
      <el-form-item :label="form.type==1?'部门名称':'公司名称'" prop="name" required>
        <el-input v-model="form.name" placeholder="请输入部门名称" v-trim maxlength="500"/>
      </el-form-item>
      <el-form-item label="部门领导" prop="leaderId" v-if='form.type==1'>
        <LeaderSelect v-if="visible" ref="leaderSelect" placeholder="请选择部门领导" v-model="form.leaderId" :exclude-id="excludeDeptId" :inline="false"/>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import DepartmentSelect from '@/components/common/DepartmentSelect'
import LeaderSelect from '@/components/common/LeaderSelect'
import { checkMobile, checkEmail } from '@/utils/form'
export default {
  name: 'OperaDepartmentWindow',
  extends: BaseOpera,
  components: { DepartmentSelect,LeaderSelect, GlobalWindow },
  data () {
    return {
      // 需排除选择的部门ID
      excludeDeptId: null,
      // 表单数据
      form: {
        id: null,
        parentId: null,
        leaderId:null,
        code: '',
        name: '',
        type:0,
        parentName:null,
        // phone: '',
        // email: ''
      },
      // 验证规则
      rules: {
        code: [
          { required: true, message: '请输入部门编码' }
        ],
        name: [
          { required: true, message: '请输入部门名称' }
        ],
        phone: [
          { validator: checkMobile }
        ],
        email: [
          { validator: checkEmail }
        ]
      }
    }
  },
  methods: {
    /**
     * @title 窗口标题
     * @target 编辑的部门对象
     * @parent 新建时的上级部门对象
     * @departmentList 部门列表
     */
    open (title, target, parent) {
      console.log('------target-----',parent);
      this.title = title
      if(title=='新建公司'){
        this.form.type=0
      }
      if(title=='新建下级部门'){
        this.form.type=1
      }
      this.visible = true
      // 新建
      if (target == null) {
        this.excludeDeptId = null
        this.$nextTick(() => {
          this.$refs.form.resetFields()
          this.form.id = null
          this.form.parentId = parent == null ? null : parent.id
          this.form.parentName = parent == null ? null : parent.name
        })
        return
      }
      // 编辑
      this.$nextTick(() => {
        this.excludeDeptId = target.id
        console.log(target,'----部门编辑');
        for (const key in this.form) {
          this.form[key] = target[key]
        }
      })
    }
  },
  created () {
    this.config({
      api: '/system/department'
    })
  }
}
</script>
