import request from '@/utils/request'

// 获取图片验证码
export function getCaptcha () {
  return request.get('/common/captcha')
}

//进度概要查询 
export function querySpeedOfProgress (data) {
  return request.get('/business/project/querySpeedOfProgress',{
    params:data
  })
}
// 首页待办接口查询
export function fetchList (data) {
  if(data.model.type==1){
    //项目
    let params = {
      ...data,
      model:{
        ...data.model,
        status:data.model.status==''?[0,1,2,3,4,17]:data.model.status,
        deleted:1,
        showDeleted:true
      }
    }
    return request.post('/business/project/page',params)
  }else if(data.model.type==2){
    //任务
    let params = {
      ...data,
      model:{
        deleted:1,
        ...data.model,
        status:data.model.status==''?[1,2,3,13,14,17]:data.model.status
      }
    }
    return request.post('/business/task/page',params)
  }else if(data.model.type==3){
    //里程碑
    let params = {
      ...data,
      model:{
        ...data.model,
        status:data.model.status==''?[8,9,17]:data.model.status,
        deleted:1,
      }
    }
    return request.post('/business/milepost/page',params)
  }else if(data.model.type==4){
    //问题
    let params = {
      ...data,
      model:{
        deleted:1,
        ...data.model,
        status:data.model.status==''?[10,11,12,17]:data.model.status
      }
    }
    return request.post('/business/question/page',params)
  }else{
    return false
  }
  
}