import request from '@/utils/request'

// 查询
export function fetchList (data) {
  return request.post('/basics/outboundmerchant/pagesdata', data)
}

//操作记录
export function getLogList (data) {
  return request.post('/business/log/page', data)
}