<template>
  <TableLayout >
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="80px" inline>
      <el-form-item label="受邀人" prop="beInvitedUser">
        <el-input v-model="searchForm.beInvitedUser" v-trim placeholder="请输入姓名/手机号" @keypress.enter.native="search" style="width:250px"/>
      </el-form-item>
      <el-form-item label="邀约人" prop="invitationUser">
        <el-input v-model="searchForm.invitationUser" v-trim placeholder="请输入姓名/手机号" @keypress.enter.native="search" style="width:250px"/>
      </el-form-item>
      <el-form-item label="商家名称" prop="shopName">
        <el-input v-model="searchForm.shopName" v-trim placeholder="请输入商家名称" @keypress.enter.native="search" style="width:250px"/>
      </el-form-item>
      <el-form-item label="签约状态" prop="status">
          <el-select v-model="searchForm.status" placeholder="请选择" style="width:100px">
              <el-option  key="-1" label="全部" :value="''"  ></el-option>
              <el-option  key="0" label="待签约" :value="0"  ></el-option>
              <el-option  key="1" label="已签约" :value="1"  ></el-option>
              <el-option  key="2" label="已拒绝" :value="2"  ></el-option>
              <el-option  key="3" label="已关闭" :value="3"  ></el-option>

          </el-select>
      </el-form-item>

      <section>
        <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>

    <template v-slot:table-wrap>
      <ul class="toolbar" v-if="userInfo.currentMcRoleStatus==0&&currentRole!=roles.treasurer">
        <li><el-button type="primary" @click="$refs.operaMerchantWindow.open('新增邀约',null,{historyShop:false,type:5})">新增</el-button></li>
        
        <li ><el-button icon="el-icon-download" type="primary" @click="exportTable(exportObj)">导出</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        
        stripe
        @selection-change="handleSelectionChange"
        @sort-change="handleSortChange"
      >
        <el-table-column type="selection" width="55px" align="center"></el-table-column>

        <el-table-column prop="id" label="ID" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="invitationUserPhone" label="邀约人手机" min-width="100px" align="center"></el-table-column>

        <el-table-column prop="invitationNickName" label="邀约人昵称" min-width="120px" align="center">
          <div slot-scope="{row}">{{row.invitationNickName || '-'}}</div>
        </el-table-column>
        <el-table-column prop="invitationUserName" label="邀约人姓名" min-width="100px" align="center">
          <div slot-scope="{row}">{{row.invitationUserName || '-'}}</div>
        </el-table-column>
        <el-table-column prop="beInvitedPhone" label="受邀人手机" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="beInvitedName" label="受邀人昵称" min-width="100px" align="center">
          <div slot-scope="{row}">{{row.beInvitedName || '-'}}</div>
        </el-table-column>
        <el-table-column prop="beInvitedUserName" label="受邀人姓名" min-width="100px" align="center">
          <div slot-scope="{row}">{{row.beInvitedUserName || '-'}}</div>
        </el-table-column>
        <el-table-column prop="shopName" label="商家名称" min-width="100px" align="center">
          <div slot-scope="{row}">{{row.shopName || '-'}}</div>
        </el-table-column>
        <el-table-column prop="createTime" label="发起时间" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="invitationStatuses" label="签约状态" min-width="100px" align="center">
          <!-- 0待签约 1已签约 2已拒绝 3已关闭 -->
          <div slot-scope="{row}">{{row.invitationStatuses==0?'待签约':row.invitationStatuses==1?'已签约':row.invitationStatuses==2?'已拒绝':row.invitationStatuses==3?'已关闭':'异常状态'}}</div>
        </el-table-column>
        <el-table-column prop="open" label="邀约开关" min-width="100px" align="center">
          <div slot-scope="{row,$index,store}">
            <el-switch  :disabled="userInfo.currentMcRoleStatus==1 || currentRole==roles.treasurer || row.invitationStatuses==1" :value="row.invitationStatus" @change="changeInvitationStatusInBatch(row.invitationStatus==1?6:5,row)"></el-switch>
          </div>
        </el-table-column>

        <el-table-column
          v-if="userInfo.currentMcRoleStatus==0&&currentRole!=roles.treasurer"
          label="操作记录" align="center"
          width="270"
          fixed="right"
        >
          <div  slot-scope="{row}" >
            <el-button  type="text" icon="el-icon-notebook-2" @click="$refs.operaRecordWindow.open(2,{id:row.shopId,},1)">操作记录</el-button>
          </div>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>

    <!-- 新建/修改 -->
    <OperaMerchantWindow ref="operaMerchantWindow" @success="handlePageChange(tableData.pagination.pageIndex)"/>
    <!-- 操作记录 -->
    <OperaRecordWindow ref="operaRecordWindow"></OperaRecordWindow>
  </TableLayout>
</template>

<script>
import Pagination from '@/components/common/Pagination'
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import OperaMerchantWindow from '../../../components/layun/accountManagement/OperaMerchantWindow.vue'
import OperaRecordWindow from '../../../components/layun/common/OperaRecordWindow.vue'

import { changeInvitationStatusInBatch } from '../../../api/layun/accountManagement/shop'
import { mapState } from 'vuex'


export default {
  name: 'ManageUser',
  extends: BaseTable,
  components: { TableLayout, Pagination , OperaMerchantWindow , OperaRecordWindow ,  },
  data () {
    return {
      // 搜索
      searchForm: {
        beInvitedUser: "",
        invitationUser: "",
        shopName: "",
        status: null,//invitationStatuses
      },
      exportObj:{
        tHeader:['ID','邀约人手机','邀约人昵称','邀约人姓名','受邀人手机','受邀人昵称','受邀人姓名','商家名称','发起时间','签约状态','邀约开关'],
        filterVal:[
          {key:'id'},
          {key:'invitationUserPhone',},
          {key:'invitationNickName',},
          {key:'invitationUserName',},
          {key:'beInvitedPhone',},
          {key:'beInvitedName',},
          {key:'beInvitedUserName',},
          {key:'shopName',},
          {key:'createTime',},
          {key:'invitationStatuses',type:2,default:'状态异常', item:[{value:0,label:'待签约'},{value:1,label:'已签约'},{value:2,label:'已拒绝'},{value:3,label:'已关闭'}]},
          {key:'invitationStatus',type:2,default:'关', item:[{value:true,label:'开'},{value:false,label:'关'}]},
        ],
        fileName:'邀约管理',
      
      }
    }
  },
  watch:{
    // 'searchForm.roleId':{
    //   handler(val,old){
    //     if(val!=old){
    //       this.search()
    //     }
    //   }
    // }
  },
  computed:{
    ...mapState({
        userInfo:state=>state.init.userInfo || {},
        currentRole:state=>state.init.userInfo.currentRole,
        roles:state=>state.init.roles
    })
  
  },
  created () {
  
    this.config({
      api: '/layun/contractManagement/invitationManagement', 
    })
    this.search()
  },
  methods:{
      updateStatus(row,index,store){
          console.log(row,index);
          
          

          this.$confirm('是否关闭邀约?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
          this.isWorking.search = true
              updateById(form)
              .then(() => {
                  this.visible = false
                  this.$tip.apiSuccess('修改成功')
                  this.handlePageChange(this.tableData.pagination.pageIndex)
              })
              .catch(e => {
                  this.$tip.apiFailed(e,false)
              })
              .finally(() => {
                  this.isWorking.search = false
                  
              })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            });       
          });
      },
      // 开启关闭（单个、批量、全部）
    changeInvitationStatusInBatch(type,row){
    console.log(type,row);
      // 1 批量开启,2 批量关闭,3 全部开启,4 全部关闭邀约	 5单个开启 6 单个关闭
      if( this.tableData.selectedRows.length === 0 && ( type == 1 || type == 2 )){
        this.$tip.warning('请至少选择一条数据')
        return
      }
      if(type == 1  || type == 2){
        console.log(this.tableData.selectedRows,'___this.tableData.selectedRows');
      
      }
      let id = null;// batch id 
      let message = `确认吗`
      if( type == 1 || type == 2){
        message = `${type==1?'开启':'关闭'}已选中的 ${this.tableData.selectedRows.length} 条邀约吗?`
        id = this.tableData.selectedRows.map(row => row.id)
      }else if( type == 3 || type == 4){
        message = `${type==3?'开启':'关闭'}全部邀约?`
      }else{
        message = `${type==5?'开启':'关闭'}当前邀约吗?`
        id = [row.id]
      }
      this.$confirm(message)
        .then(() => {
          this.batchLoading = true
          changeInvitationStatusInBatch({
            adminId:row.beInvitedUserId,
            batchType:type==5?1:type==6?2:type,
            invitationId:id
          })
            .then(() => {
              this.$tip.apiSuccess((type==1 || type==3 || type==5)?'开启成功':'关闭成功')
              this.handlePageChange (this.tableData.pagination.pageIndex)
            })
            .catch(e => {
              this.$tip.apiFailed(e)
            })
            .finally(() => {
              this.batchLoading = false
            })
        })
        .catch(() => {})
    
    },
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";
// 列表头像处理
.table-column-avatar {
  img {
    width: 48px;
  }
}
</style>
