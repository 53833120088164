export default {
  inserted: function (el, binding, vnode) {
    console.log(el.parentNode,'-ASAS');
    const configPermissions = binding.value
    console.log(configPermissions,'值');//[null, 14]
    if (!(configPermissions instanceof Array)) {
      throw new Error('v-buttons的值必须为一个数组')
    }
    if (binding.value == [] || binding.value.length<2) {
      el.parentNode && el.parentNode.removeChild(el)
      return false
    }
    let current = binding.value.pop()
    let list = binding.value
    if(list.findIndex(i=>i===current)===-1){
      // console.log('对比了下 没有权限');
      el.parentNode && el.parentNode.removeChild(el)
      return false
    }else{
      el.parentNode && el.parentNode.appendChild(el)
      return 
    }
  },
  unbind(el, binding,){
    el.remove()
  },
  updated: function (el, binding, vnode) {
    console.log(el.parentNode,'QQQQQQ-a-s-a-s-');
    const configPermissions = binding.value
    const configOldPermissions = binding.oldValue
    console.log('新老数据对比',configPermissions,configOldPermissions,el.parentNode);
    // if(configPermissions==configOldPermissions){
    //   return 
    // }
    // el.inserted()
    if (!(configPermissions instanceof Array)) {
      throw new Error('v-buttons的值必须为一个数组')
    }
    if (binding.value == [] || binding.value.length<2) {
      el.remove()
      return 
    }
    let current = binding.value.pop()
    let list = binding.value
    console.log(current,list,'两份数据',el,el.parentNode);
    let node = el.parentNode
    if(list.findIndex(i=>i===current)!=-1){
      console.log('添加元素');
      node && node.appendChild(el)
      console.log(node,'-a-s-a-s-');
      return 
    }
    else{
      el.remove()
      return 
    }
  }
}
