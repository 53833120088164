import Vue from 'vue'
import Vuex from 'vuex'
import init from './init'


Vue.use(Vuex)

// const state = {
//   // 登录用户信息
//   userInfo: {"id":1,"username":"admin","realname":"伊娃","avatar":"/avatar/man.png","birthday":null,"sex":"1",
//   "roles":["admin"],"permissions":["system:permission:query","system:permission:create","system:permission:update","system:permission:delete",
//   "system:menu:query","system:menu:create","system:menu:update","system:menu:delete","system:menu:sort","system:role:query","system:role:create",
//   "system:role:update","system:role:delete","system:role:createRoleMenu","system:role:createRolePermission","system:user:query","system:user:create",
//   "system:user:update","system:user:delete","system:user:createUserRole","system:user:resetPwd","system:monitor:query","system:department:create","system:department:delete",
//   "system:department:update","system:department:queryUsers","system:department:query","system:position:create","system:position:delete","system:position:update","system:position:query",
//   "system:position:queryUsers","system:dict:create","system:dict:delete","system:dict:update","system:dict:query","system:traceLog:query","system:loginLog:query","system:location:create",
//   "system:location:delete","system:location:update","system:location:query","system:datapermission:create","system:datapermission:delete","system:datapermission:update","system:datapermission:query",
  
  
//   "system:test:query","system:test:create","system:test:update","system:test:delete","system:test:createUserRole","system:test:resetPwd",

// ]},

export default new Vuex.Store({
  modules: { init ,},
  state:{},
  mutations:{},
  actions:{},
  getters:{}

})
