<template>
  <div>
    <TreeSelect
      :placeholder="placeholder"
      :value="value"
      :data="data"
      :clearable="clearable"
      :append-to-body="appendToBody"
      :inline="inline"
      :multiple="multiple"
      :flat="multiple"
      @input="inputChange"
    />
    <el-alert v-if="showAlert"
    style="margin-top:6px"
    type="warning"
    :closable="false">
      <span slot="title">
        司机和其它角色一律不可同时存在；财务和其它角色一律不可同时存在；
        <!-- 销售和财务、司机以及库管不可同时存在 -->
      </span>
  </el-alert>


  </div>
  
</template>

<script>
import TreeSelect from './TreeSelect'
import { fetchAll } from '@/api/system/role'
export default {
  name: 'RoleSelect',
  components: { TreeSelect },
  props: {
    value: {},
    inline: {
      default: true
    },
    //optionId 只展示这几个id 可供选择
    optionId:{
      default:()=>[]
    },
    selectMode:{ //人员选择框的适用场景
      default: 'search',//changeUser 移交人员  search 搜索
    },
    multiple: {//是否可多选
      default: false
    },
    placeholder: {
      default: '请选择部门'
    },
    showAlert:{
      //是否展示告警信息
      default: false
    },
    // 是否可清空
    clearable: {
      default: false
    },
    appendToBody: {
      default: false
    },
    // 需被排除的部门ID
    excludeId: {
      default:()=>[]
    },

  },
  data () {
    return {
      data: []
    }
  },
  watch: {
    excludeId () {
      this.fetchData()
    },
    optionId () {
      this.fetchData()
    }
  },
  methods: {
    // 获取所有部门
    fetchData () {
        this.data = []
        this.__fillData(this.data, this.$store.state.init.roles.roleArr)
      // fetchAll()
      //   .then(records => {

      //     console.log(records,'用户角色');
      //     this.data = []
      //     this.__fillData(this.data, records)
      //   })
      //   .catch(e => {
      //     this.$tip.apiFailed(e)
      //   })
    },
    // 填充树
    __fillData (list, pool) {
      console.log(list,pool,'----');
      for (const dept of pool) {
        if (this.excludeId.length>0&&this.excludeId.findIndex(i=>i==dept.id)!=-1) {
          continue
        }
        const deptNode = {
          id: dept.id,
          label: dept.name,
          item:dept
        }
        if(this.selectMode=='changeUser'){ //传入了默认的
          if(this.optionId.length>0){
            //存在设置的展示树
            let idx = this.optionId.findIndex(i=>i==dept.id)
            if(idx!=-1){
              list.push(deptNode)
            }
          
          }
        
        }
        else{
          list.push(deptNode)
        }

        // list.push(deptNode)
        
      }
    },
    inputChange(value){
      this.$emit('input', value)
      this.$emit('change')

    }
  },
  created () {
    this.fetchData()
  }
}
</script>
