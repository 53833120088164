import { render, staticRenderFns } from "./LinkedShopListWindow.vue?vue&type=template&id=6b940f0a&scoped=true"
import script from "./LinkedShopListWindow.vue?vue&type=script&lang=js"
export * from "./LinkedShopListWindow.vue?vue&type=script&lang=js"
import style0 from "./LinkedShopListWindow.vue?vue&type=style&index=0&id=6b940f0a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b940f0a",
  null
  
)

export default component.exports