<template>
  <!-- 新建/修改 -->
  <GlobalWindow
    :title="title"
    v-if="visible"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
    :loading="pageLoading"
    
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="手机号码" prop="account" required>
        <span v-if="form.id">{{form.account}}</span>
        <el-input v-else v-model.trim="form.account" placeholder="请输入手机号码/账号"  v-trim maxlength="11" @input="accountChange"/>
      </el-form-item>
      <el-form-item label="姓名" prop="username" required>
        <el-input v-model="form.username" placeholder="请输入姓名" v-trim maxlength="50" :disabled="!userNameEditable" />
      </el-form-item>
      <el-form-item label="角色" prop="roleId" required>
        <RoleSelect v-if="visible" key="addRole" v-model="form.roleId" :multiple="true" placeholder="请选择用户角色" :inline="false" :showAlert="true" :excludeId="excludeId"  clearable/>
      </el-form-item>
      <el-form-item label="业务区域" prop="regionIds" v-if="form.roleId==roles.driver"  :required="true" style="margin-bottom:60px">
        <DriverAreaSelect v-model="form.regionIds" placeholder="请选择区域" :inline="false" clearable/>
      </el-form-item>
      <!-- //司机的业务区域 -->
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import DepartmentSelect from '@/components/common/DepartmentSelect'
import PositionSelect from '@/components/common/PositionSelect'
import { checkMobile, checkEmail,checkeRoleId } from '../../../utils/form'
import CompanySelect from '../../common/CompanySelect'
import DriverAreaSelect from '../../layun/common/DriverAreaSelect.vue'
import RoleSelect from '../../common/RoleSelect'
import ImageUpload from '../../common/ImageUpload'
import { mapState } from 'vuex'
import {selectSystemAllUser,fetchList} from '../../../api/system/user'




export default {
  name: 'OperaUserWindow',
  extends: BaseOpera,
  components: { PositionSelect, DepartmentSelect, GlobalWindow,DriverAreaSelect,RoleSelect,ImageUpload,},
  computed:{
    ...mapState({
      userInfo:state=>state.init.userInfo,
      currentRole:state=>state.init.userInfo.currentRole,
      roles:state=>state.init.roles
    }),
    excludeId(){
      let newManagerId = this.roles.newManager;
      let managerId = this.roles.manager;
      if(this.userInfo.currentRole==managerId && this.form.roleId&&this.form.roleId.findIndex(i=>i==managerId)!=-1){
        return []
      }
      if(this.userInfo.currentRole==managerId){
        return [managerId]
      }
      
      if(this.userInfo.currentRole==newManagerId){
        return [managerId,newManagerId]
      }
      return []
    }
  },
  data () {
    return {
      visible:false,
      // 表单数据
      form: {
        id: null,
        account: '',
        regionIds:null,
        avatar: "",
        code: "--",//唯一编码(后台使用)
        companyId: null,
        password: "",
        roleId: null,
        username: "",

      },
      pageLoading:false,//页面加载
      // 验证规则
      rules: {
        account:[
        { required: true, message: '请输入手机号码' },
          { validator: checkMobile,trigger: 'blur' }
          // {validator:}
        ],
        username: [
          { required: true, message: '请输入用户名' }
        ],
        realname: [
          { required: true, message: '请输入姓名' }
        ],
        password: [
          { required: true, message: '请输入密码' }
        ],
        sex: [
          { required: true, message: '请选择用户性别' }
        ],
        mobile: [
          { validator: checkMobile }
        ],
        roleId:[
          { required: true, message: '请选择角色类型' },
          {validator:checkeRoleId},
        ],
        regionIds:[
          { required: true, message: '司机必须选区域' }
        ],
        email: [
          { validator: checkEmail }
        ]
      },
      userOptions:[],//系统内用户
    }
  },
  watch:{
    'form.account':{
      handler(val,olv){
          if(val.length==11&&!this.form.id){
            this.form.password = val.substring(5,11)
          }
      }
    }
  },
  methods: {
    /**
     * @title 窗口标题
     * @target 编辑的用户对象
     */
    open (title, target,obj) {
    console.log(title, target,obj,'-----user');
      this.title = title
      this.visible = true
      this.userNameEditable = true
      // 新建
      if (target == null) {
        this.selectSystemAllUser()
        this.$nextTick(() => {
          this.form= {
            id: null,
            account: null,
            avatar: "",
            code: "--",//唯一编码(后台使用)
            companyId: null,
            password: "",
            roleId: null,
            regionIds:null,
            username: "",
          }
          this.$refs.form.resetFields()
          this.form.businessRoleId = obj
        })
        return
      }
      // 编辑
      this.$nextTick(() => { 
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        let idArr = target.roleId?[target.roleId]:[]
        if(target.children&&target.children.length>0){
          target.children.map(item=>{
            idArr.push(item.roleId)
          })
          
        }
        this.form.roleId = idArr
        this.form.regionIds = target.basicsRegionDto&&target.basicsRegionDto.length>0?target.basicsRegionDto.map(i=>i.id):[];
      })
    },
    async accountChange(value){
      console.log('新增用户',value);
      this.userNameEditable = true
      if (!/^1\d{10}$/.test(value)) {
        return 
      }
      var findItem = await this.selectUserByAllUser(value)
      if(findItem){
        this.form.username = findItem.userName || ''
        this.userNameEditable =this.form.username?false:true


      // let idx = this.userOptions.findIndex(i=>i.account==value)
      // if(idx!=-1){
      //   this.form.username = this.userOptions[idx].userName || ''
      //   this.userNameEditable =this.form.username?false:true

        if(findItem.businessRoleId.findIndex(i=>i==1)!=-1){
         //前往编辑
         this.$msgbox({
          title: '提示',
          message:'该账号已在美程企业账号列表中，是否前往编辑？',
          showCancelButton: true,
          confirmButtonText: '前往编辑',
          cancelButtonText: '取消',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = '执行中...';
              fetchList({
                capacity: 10,
                model: {keywords: value, roleId: null, businessRoleId: 1},
                page: 1,
                sorts: [],
              }).then(res=>{
                console.log(res,'--结果');
                if(res.records&&res.records.length>0){
                  
                  if(this.currentRole==this.roles.manager || this.currentRole==-1){

                  }
                  
                  if(this.currentRole==this.roles.newManager){ //新增管理员
                    let editRoles = [res.records[0].roleId];//编辑用户的身份
                    if(res.records[0].children){
                      res.records[0].children.map(item=>{
                        editRoles.push(item.roleId)
                      })
                    }
                    let idx = editRoles.findIndex(i=>(i==this.roles.manager || i==this.roles.newManager))
                    if(idx!=-1){
                      instance.confirmButtonText = '执行失败，无权限...';
                      this.$message.error('新增管理员不可编辑新增管理员或管理员')
                      this.visible = false;
                      done();
                      return 
                    }
                    
                  }


                  this.open('编辑用户', res.records[0],1)
                }
                done();
              }).finally(()=>{
                instance.confirmButtonLoading = false;
              })
            } else {
              done();
            }
          }
        }).then(action => {
          // this.$message({
          //   type: 'info',
          //   message: 'action: ' + action
          // });
        });
        //  this.$confirm(`该账号已在美程企业账号列表中，是否前往编辑？`, '提示', {
        //     confirmButtonText: '前往编辑',
        //     cancelButtonText: '取消',
        //     type: 'warning'
        //   }).then(() => {

        //     fetchList({
        //     capacity: 10,
        //     model: {keywords: value, roleId: null, businessRoleId: 1},
        //     page: 1,
        //     sorts: [],
        //     }).then(res=>{
            
            
        //     })
            
        //   }).catch(() => {     
        //     this.close() 
        //   }); 
        
        }
      
      }


    },
    selectUserByAllUser(account){
      return new Promise((suc,err)=>{
        selectSystemAllUser({account}).then(data=>{
          console.log(data,'---')
          if(data && data.length && this.form.id==null&&this.form.historyShop==false){
            data[0].disabled = data[0].businessRoleId&&data[0].businessRoleId.findIndex(i=>i==1)!=-1;//存在企业内账号即为disable用户
          }
          suc(data && data.length ? data[0] : null)
        })

      })      
    },
    selectSystemAllUser(){ //获取美程账号列表
      return 
      this.pageLoading = true
      selectSystemAllUser({
      }).then(res=>{
      console.log(res,'--->');
      if(res&&res.length>0&&this.form.id==null&&this.form.historyShop==false){
        res.forEach(i=>{
          i.disabled = i.businessRoleId&&i.businessRoleId.findIndex(i=>i==1)!=-1;//存在企业内账号即为disable用户
        })
      
      }
        
        this.userOptions = res || []
      }).finally(()=>{
        this.pageLoading = false
      })
    },
   
  },
  created () {
    this.config({
      api: '/system/user'
    })
  },
  beforeDestroy:()=>{
    
  }
}
</script>

<style lang="scss" scoped>

.global-window {
  /deep/ .el-date-editor {
    width: 100%;
  }
  /deep/.window__body{
    // overflow-y:inherit !important;
    overflow: visible  !important;
  }
  // 表单头像处理
  /deep/ .form-item-avatar {
    .el-radio.is-bordered {
      height: auto;
      padding: 6px;
      margin: 0 10px 0 0;
      .el-radio__input {
        display: none;
      }
      .el-radio__label {
        padding: 0;
        width: 48px;
        display: block;
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
