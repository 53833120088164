import request from '@/utils/request'

// 查询
export function fetchList (data) {
  return request.post('/shop/label/page', data)
}

export function getGroupData (data) {
    return request.post('/shop/label/page', data)
  }

// 新建
export function create (data) {
  return request.post('/shop/label/addShopLabel', data, {
    trim: true
  })
}

// 修改
export function updateById (data) {
  return request.post('/shop/label/editShopLabel', data, {
    trim: true
  })
}

// 删除
export function deleteById (id) {
//   return request.post(`/shop/label/delShopLabel/${id}`,data)
  return request.post('/shop/label/delShopLabel', {id}, {
    trim: true
  })
}
