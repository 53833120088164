<template>
  <div class="home">
    <div class="pageC">
      <el-steps :active="active" finish-status="success">
        <el-step title="发送验证码"></el-step>
        <el-step title="重置密码"></el-step>
        <el-step title="重置成功"></el-step>
      </el-steps>


      <div v-if="active==0" class="center">
          <el-input class="curInput" placeholder="请输入手机号" v-model="account" prefix-icon="el-icon-user-solid" maxlength="50" v-trim>
            <template slot="append">
              <!-- <span  @click='sendCode' v-if="showSend">发送验证码</span>
              <span  v-if="!showSend">{{count}}s后可重试</span> -->
              <span  @click='sendCode'>{{showSend?verCodeLoading?'验证码发送中':'发送验证码':count+'s后可重试'}}</span>
              </template>
          </el-input>
          <el-input class="curInput" v-model="verificationCode" placeholder="请输入验证码"  prefix-icon="eva-icon-password" maxlength="30" />

          <el-button type="primary" size="medium" style="display:block;margin:20px auto 0;" @click="confirmVer">确认</el-button>
      </div>

      <div v-if="active==1" class="center">
          <el-form :model="changePwdData.form" ref="changePwdDataForm" :rules="changePwdData.rules" style="display:inline-block">
            <el-form-item prop="newPwd" required>
              <el-input  class="curInput" v-model="changePwdData.form.newPwd" type="password" placeholder="请输入新密码" maxlength="30" show-password></el-input>
            </el-form-item>
            <el-form-item  prop="confirmPwd" required>
              <el-input  class="curInput" v-model="changePwdData.form.confirmPwd" type="password" placeholder="请再次输入新密码" maxlength="30" show-password></el-input>
            </el-form-item>
          </el-form>

          <el-button type="primary" size="medium" style="display:block;margin:20px auto 0;" @click="confirmChangePwd" :loading="isWorking.changePwd">确认</el-button>
      </div>

      <div v-if="active==2" class="center">
          <el-button type="primary" size="medium" style="display:block;margin:70px auto 0;" @click="login">回到登录页重新登录</el-button>
      </div>

      <!-- <el-button style="margin-top: 12px;" @click="next">下一步</el-button> -->
    </div>
    
  </div>
</template>

<script>
import {verificationCode,forgotPwd} from '../api/system/common'
export default {
  name: 'ForgotPassword',
  data () {
    return {
      active:0,
      account:'',
      verCodeLoading:false,
      verificationCode:'',
      verCode:'',
      timeCount: 60,
      showSend: true,
      timer: null,
      count: '', // 初始化次数

      isWorking: {
        // 修改密码
        changePwd: false
      },
      // 修改密码弹框
      changePwdData: {
        form: {
          newPwd: '',
          confirmPwd: ''
        },
        rules: {
          oldPwd: [
            { required: true, message: '请输入原始密码' }
          ],
          newPwd: [
            { required: true, message: '请输入新密码' }
          ],
          confirmPwd: [
            { required: true, message: '请再次输入新密码' }
          ]
        }
      }
    }
  },
  methods:{
    next(){
      if (this.active++ > 2) this.active = 0;
    },
    //发送验证码倒计时
      sendCode() {
        if(!this.account.length>0){
            this.$message.error('请先输入手机号')
            return
        }
        if(this.showSend&&this.verCodeLoading){
            return 
        }
        if (!this.timer) {
          this.verCodeLoading = true;

          verificationCode({
            account:this.account,
            bakMobile:false,
          }).then((res) => {
            console.log(res, '验证码获取成功')
            if (res=='发送成功') {
              this.$message.success("验证码发送成功")
              this.count = this.timeCount
              this.showSend = false
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= this.timeCount) {
                  this.count--
                } else {
                  this.showSend = true
                  clearInterval(this.timer) // 清除定时器
                  this.timer = null
                }
              }, 1000)
              
            } else {
              this.$message.error(res)
            }
          }).catch((e)=>{
            this.$tip.apiFailed(e)
            this.showSend = true
            clearInterval(this.timer) // 清除定时器
            this.timer = null
            
          }).finally(()=>{
            this.verCodeLoading = false
          })


        }
      },
      //确认输入了验证码
      confirmVer(){
        if(!this.account.length>0){
            this.$message.error('请先输入手机号')
            return
        }
        if(!this.verificationCode.length>0){
            this.$message.error('请先输入验证码')
            return
        }
        this.next();
      },
      // 确定修改密码
    confirmChangePwd () {
      if (this.isWorking.changePwd) {
        return
      }
      this.$refs.changePwdDataForm.validate((valid) => {
        if (!valid) {
          return
        }
        // 验证两次密码输入是否一致
        if (this.changePwdData.form.newPwd !== this.changePwdData.form.confirmPwd) {
          this.$tip.warning('两次密码输入不一致')
          return
        }
        // 执行修改
        this.isWorking.changePwd = true
        forgotPwd({
          oneNewPwd: this.changePwdData.form.newPwd,
          phone: this.account,
          twoNewPwd: this.changePwdData.form.newPwd,
          verificationCode: this.verificationCode
        }).then(() => {
            this.$tip.apiSuccess('修改成功')
            this.next();
          })
          .catch(e => {
            this.$tip.apiFailed(e)
          })
          .finally(() => {
            this.isWorking.changePwd = false
          })
      })
    },
    //回到登录页
    login(){
      this.$router.push({name:'login'})
    }
  
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";
.curInput{
  width:400px;
  margin:10px 0;
}
.center{
  text-align: center;
  padding: 20px;
}
/deep/ .el-input {
  .el-input__inner {
    height: 50px;
    background: #F9F9F9;
    // border: 1px solid transparent;
    &:focus {
      // border: 1px solid $primary-color;
    }
  }
}
.home {
  background: #fff;
  width: 100%;
  height: 100vh;

  .pageC{
    width:700px;
    height:300px;
    margin:90px auto 0;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 10px #ccc;
  
  }
}
</style>
