import request from '@/utils/request'

// 查询
export function fetchList (data) {
  return request.post('/business/msg/page', data) 
}
// 删除
export function updateRemark (data) {
  return request.post(`/business/msg/updateRemark`,data)
}

